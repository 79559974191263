import {EventEmitter} from "eventemitter3"
import * as EMIT_ACTIONS from "@digienttech/typescript-core/lib/core/emit-actions"
import {environment} from "../../environment"
import {StaticKey} from "../../static_key"

import {LobbySubCategoryModel} from "../lobby/Model"
import {MockManagerService} from "../lobby/MockManagerService"
import {ConnectionManagerService} from "../ConnectionManagerService"
import {MockServiceData} from "./MockServiceData"

export class DataService extends EventEmitter {
    connectionManager: MockManagerService | ConnectionManagerService
    static classIns: any = {}
    sessionId: any
    isWatchingAutoResponse: any
    isFilterAutoResponse: any
    mockServiceData: MockServiceData = MockServiceData.getInstance()

    static getInstance(_instanceID: any = "default"): any {
        if (!DataService.classIns[_instanceID]) {
            DataService.classIns[_instanceID] = new DataService()
        }
        return DataService.classIns[_instanceID]
    }

    constructor() {
        super()
        /* if (environment.useMockConnection) {
            this.connectionManager = new MockManagerService()
        } else { */
        this.connectionManager = new ConnectionManagerService()
        //}

        this.connectionManager.on("connect", (_socket: any) => {
            console.log("Socket Connected", _socket.connectName)
            //this.getPlayerInfoResponse()
        })
        //this.createSocketConnection()
    }

    createSocketConnWithGame(game: any) {
        const socketConfig = environment["socketConfig"]
        const paramObject: any = []
        paramObject["nameSpace"] = "/ws/" + StaticKey.socketNamespace[game]
        paramObject["url"] =
            socketConfig["ws"] +
            "://" +
            socketConfig["mainLobbyServer"] +
            ":" +
            socketConfig["port"]
        this.createSocketConnection(paramObject)
    }

    onReconnectSocket = () => {
        console.log("socket connected")
        //StaticKey.GLOBAL_EMITTER.emit(SOCKET_DISCONNECT_EVENT)
    }

    onSessionExpiryPopup = (response: any) => {
        console.log("socket expiry")
        // StaticKey.GLOBAL_EMITTER.emit(SHOW_LOBBY_ERROR_POPUP, response)
    }

    onAutoResponseListeners = (response: any) => {
        const responseData =
            typeof response["data"] === "string" ? JSON.parse(response["data"]) : response["data"]
        if (responseData.classObj.action) {
            //
        } else {
            console.warn("LobbyConnectionDatasService::invalid response", responseData)
        }
    }

    disconnectSocket() {
        this.connectionManager.disconnectSocket()
    }

    createSocketConnection(rParamObject: any = null) {
        let paramObject: any = {}
        this.isWatchingAutoResponse = false
        this.isFilterAutoResponse = false
        const socketConfig = environment["socketConfig"]
        /* if (
            StaticKey.GAME_SERVER_CONFIG &&
            Object.keys(StaticKey.GAME_SERVER_CONFIG).length == 0 &&
            Object.getPrototypeOf(StaticKey.GAME_SERVER_CONFIG) === Object.prototype
        ) {
            socketConfig = StaticKey.GAME_SERVER_CONFIG
        } */
        if (rParamObject) {
            paramObject = rParamObject
        } else {
            paramObject["nameSpace"] = "/ws/thursdaylotto"
            paramObject["url"] =
                socketConfig["ws"] +
                "://" +
                socketConfig["mainLobbyServer"] +
                ":" +
                socketConfig["port"]
        }
        console.log("creating lobby socket, params:", paramObject)
        this.connectionManager.createSocketConnection(paramObject)
    }

    sendDrawResultRequest = (request: any) => {
        this.connectionManager.sendServerRequest(request).then((resp: any) => {
            console.log("resp", resp)
        })
    }
}
