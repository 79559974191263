import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/drawmanagement/drawListing.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DrawManageService} from "../../../game_core/type_script/services/drawmanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Loader} from "../../../customer_gl/screens/common/loader"
import DataTable from "react-data-table-component"
import Swal from "sweetalert2"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {Modal} from "reactstrap"
import {DataService} from "../../../game_core/type_script/socket_communication_services/lobby/DataService"
import { TotalRecords } from "../common/totalRecords"
import { PrintTableElement } from "../common/PrintTableElement"
import { ExportExcelReport } from "../downlinemanagement/ExportExcelReport"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`
// we will display the date as DD-MM-YYYY
export class DrawListing extends Component {
    DrawManageServices = DrawManageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    DataService: DataService = DataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    // Date object
    // drawIdToChange:any;
    // drawStatusToChange:any;
    socketIns: any = ""
    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Draw Number","Game Name","Draw Time","Price","Win Number","Bet","Win","Status"],
        tableBody:["DRAW_NUMBER","DRAW_GAME_TITLE","DRAW_START_DATE","DRAW_PRICE_toFixed","DRAW_WINNUMBER","DRAW_TOTALBET","DRAW_TOTALWIN","DRAW_STATUS"],
        exportHead:["Draw Number","Game Name","Draw Time","Price","Win Number","Bet","Win","Status"],
        exportBody:["DRAW_NUMBER","DRAW_GAME_TITLE","DRAW_START_DATE","DRAW_PRICE_toFixed","DRAW_WINNUMBER","DRAW_TOTALBET","DRAW_TOTALWIN","DRAW_STATUS"],
        number1ErrorClass:"",
        number2ErrorClass:"",
        number3ErrorClass:"",
        number4ErrorClass:"",
        number5ErrorClass:"",
        number6ErrorClass:"",
        drawGameIdForResult:"",
        searchFilterRow: [0, 1],
        fromPagePagination: 1,
        toPagePagination: 5,
        noRow: 25,
        totalCount: 0,
        drawError: false,
        drawWinNumberId: "",
        drawWinNumberDrawNumber: "",
        drawWinNumberStartTime: "",
        alertPopupShow: "hide",
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        alertConfirmation: true,
        drawGameId: "",
        drawStatus: "",
        fromDate: "",
        toDate: "",
        fromDateSearch: "",
        toDateSearch: "",
        drawIsDemo: "",
        isLoadedDropdown: true,
        fromDateCallback: true,
        dateRangeCallback: true,
        draw_priceCallback: true,
        fields: {
            "drawGameId": "",
            "drawStatus": "",
            "fromDate": "",
            "toDate": "",
            "drawIsDemo": "",
            "limit": 25,
            "page": 1
        },
        errors: {
            "drawGameIdError": "",
            "drawStatusError": "",
            "fromDateError": "",
            "toDateError": "",
            "drawIsDemoError": ""
        },
        winFields: {
            "number1": "",
            "number2": "",
            "number3": "",
            "number4": "",
            "number5": "",
            "number6": ""
        },
        showDrawListing: false,
        DrawSearchData: []
    }
    winNumberFields: any = [
        {
            "name": "number1",
            "id": "number1",
            "type": "number",
            "label": "Winnumber",
            "max": "49",
            "autoFocus": true,
            "class": "w-10",
            "required": true
        },
        {
            "name": "number2",
            "id": "number2",
            "type": "number",
            // "label": "Winnumber",
            "max": "49",
            "autoFocus": false,
            "class": "w-10",
            "required": true
        },
        {
            "name": "number3",
            "id": "number3",
            "type": "number",
            // "label": "Winnumber",
            "max": "49",
            "autoFocus": false,
            "class": "w-10",
            "required": true
        },
        {
            "name": "number4",
            "id": "number4",
            "type": "number",
            // "label": "Winnumber",
            "max": "49",
            "autoFocus": false,
            "class": "w-10",
            "required": true
        },
        {
            "name": "number5",
            "id": "number5",
            "type": "number",
            // "label": "Winnumber",
            "max": "49",
            "autoFocus": false,
            "class": "w-10",
            "required": true
        },
        {
            "name": "number6",
            "id": "number6",
            "type": "number",
            // "label": "Winnumber",
            "max": "49",
            "autoFocus": false,
            "class": "w-10",
            "required": true
        }
    ]
    createDrawsFields: any = {
        "": [
            {
                rowId:0,
                "name": "drawGameId",
                "type": "select",
                "label": "Game",
                "placeHolder": "Game",
                "autoFocus": true,
                "class": "form-control",
                "required": false,
                "options": {},
                "selectShowEmpty": true,
                "getDrawInfo": true
            },
            {
                rowId:1,
                "name": "fromDate",
                "type": "datetime",
                "label": "Start Date",
                "placeHolder": "Start Date",
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"   
            },
            {
                rowId:1,
                "name": "toDate",
                "id": "toDate",
                "type": "datetime",
                "label": "End Date",
                "placeHolder": "End Date",
                "maxLength": currentDate,
                // "disabled": true,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"
            },
            {
                rowId:0,
                "name": "drawStatus",
                "type": "select",
                "label": "Status",
                "placeHolder": "Status",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "options": StaticKey.DRAWSTATUS,
                "selectShowEmpty": true,
            },        
            {
                rowId: 1,
                name: "dateRange",
                type: "select",
                label: "Date Range",
                placeHolder: "Date Range",
                maxLength: "50",
                autoFocus: false,
                class: "form-control",
                defaultValue: "",
                required: false,
                "selectShowEmpty": true,
                options: StaticKey.DATERANGE
                // showEmpty: true
            },
            {
                rowId: 0,
                "name": "drawIsDemo",
                "id": "drawIsDemo",
                "type": "checkbox",
                "label": "Demo",
                "label1":" Is Demo",
                "placeHolder": "Is Demo",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
        ]
    }
    validatecreateDrawForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createDrawsFields).map((keys: any) => {
            {
                return this.createDrawsFields[keys].map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state.fields[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true
                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else if (fieldValObject.min != undefined || fieldValObject.max != undefined) {
                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        if (
                            fieldValObject.min != undefined &&
                            this.state.fields[fieldValObject.name] < fieldValObject.min
                        ) {
                            flagError[fieldValObject.name + "Error"] = true
                            return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                errorData + " is must be greater than " + fieldValObject.min)
                        }
                        if (
                            fieldValObject.max != undefined &&
                            this.state.fields[fieldValObject.name] > fieldValObject.max
                        ) {
                            flagError[fieldValObject.name + "Error"] = true
                            return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                errorData + " is must be lesser than " + fieldValObject.max)
                        }
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    componentDidMount() {
        // document.getElementById("toDate")?.setAttribute("readonly", "readonly")
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
        this.getGameList(1)
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any, values1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                        if (agentLoginHideFields.includes(fieldValObject1.name)) {
                                            return delete this.createDrawsFields[keys1][
                                                fieldValIndex1
                                            ]
                                        }
                                    }
                                )
                            })
                        } else {
                            if (agentLoginHideFields.includes(fieldValObject.name)) {
                                return delete this.createDrawsFields[keys][fieldValIndex]
                            }
                        }
                    }
                )
            }
        })
        this.setState({isLoadedDropdown: false})
       // this.handleSetDateFunction();
    }
    handleSetDateFunction(){
        
        const date = new Date()
        const currentDay = String(date.getDate()).padStart(2, "0")
        const lastDay = String(date.getDate()).padStart(2, "0")
        const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        const  lastMonth = String(date.getMonth() + 1).padStart(2, "0")
       
        const currentDateNew =  new Date(`${currentYear}-${currentMonth}-${currentDay} 00:00:00`)
        const currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
        this.setState({
            // ...this.state,
            DrawSearchData: [],
            showDrawListing: false,                  
            totalCount:0,
            totalCountPagination:[],
            currentPage:1,
            previousPage:0,
            nextPage:2,
            lastPage:0,            
            fromPagePagination:1,
            toPagePagination:5,
            fromDate: currentDateNew,
            fromDateSearch: (
                `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
            ),
            toDate: currentEndDateNew,
            toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
            fields: {
                ...this.state.fields,
                fromDate: currentDateNew,
                toDate: currentEndDateNew
            }
        })
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    handleDrawListingReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {
                this.setState({[this.createDrawsFields[keys].name]: ""})
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any, values1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                        // console.log(fieldValObject1);
                                        return (
                                            (newArrayError[fieldValObject1.name + "Error"] = ""),
                                            (newArrayFields[fieldValObject1.name] = ""),
                                            (newArrayFields[fieldValObject1.name + "Val"] = "")
                                        )
                                    }
                                )
                            })
                        } else {
                            this.createDrawsFields[""][2].required = false
                            this.createDrawsFields[""][2].min = ""
                            // this.createDrawsFields[""][2].type = "datetime-local"
                            document.getElementById("toDate")?.setAttribute("readonly", "readonly")
                            return (
                                (newArrayError[fieldValObject.name + "Error"] = ""),
                                (newArrayFields[fieldValObject.name] = ""),
                                (newArrayFields[fieldValObject.name + "Val"] = "")
                            )
                        }
                    }
                )
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields})
        this.setState({
            ...this.state,
            DrawSearchData: [],
            showDrawListing: false,
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 2,
            lastPage: 0,
            fromDateSearch:"",
            toDateSearch:"",
            fromPagePagination: 1,
            toPagePagination: 5,
            fields: {
                ...this.state.fields,
                "limit": 25,
                "page": 1
            }
        })
        // this.handleSetDateFunction()
    }

    handleAlertPopupClose = () => {
        this.uiData.setAlertShowMessage("hide", "", "")
        this.setState({
            alertPopupShow: "hide",
            drawWinNumberId: "",
            drawWinNumberDrawNumber: "",
            drawWinNumberStartTime: ""
        })
        document.getElementById('reset_form_winnumber')?.click();
    }
    handlePopupShow = (drawDesc:any,drawId: any, drawNumber: any, drawTime: any) => {
        this.setState({
            drawGameIdForResult:drawDesc,
            drawWinNumberId: drawId,
            alertPopupShow: "show",
            drawWinNumberDrawNumber: drawNumber,
            drawWinNumberStartTime: drawTime
        })
        setTimeout(function () {
            document.getElementById("number1")?.focus()
        }, 100)
    }
    handleResetWinnumber(e: any) {
        this.setState({
            winFields: {
                "number1": "",
                "number2": "",
                "number3": "",
                "number4": "",
                "number5": "",
                "number6": ""
            }
        })
    }
    
    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
        this.setState({
            fields:{
                ...this.state.fields,
                fromDate:date,
            },
            fromDateSearch:finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            fields:{
                ...this.state.fields,
                toDate: eDate,
            },
            toDateSearch:finalDate
        })
    }
    handlecreateDrawInputChange =(e: any) => {
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            },
            winFields: {
                ...this.state.winFields,
                [e.target.name]: value
            },
            drawError: false
        })
        if (this.state[e.target.name + "Callback"]) {
            if (e.target.name == "fromDate") {
                this.createDrawsFields[""][2].min = value
                this.createDrawsFields[""][2].type = "datetime-local"
                // this.createDrawsFields[""][2].required = true
                document.getElementById("toDate")?.removeAttribute("readonly")
            } 
            
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
        if (e.target.name.includes("number")) {
            if (e.target.value.length > 2) {
                value = e.target.value.slice(0, 2);
                const currentNumber = e.target.id.charAt(e.target.id.length - 1)
                const nextNumber = parseInt(currentNumber) + 1
                if(e.target.value > 49){                    
                    this.setState({drawError: true,[e.target.name+"ErrorClass"]:"errorClass"})
                }
                if (currentNumber == 6) {
                    document.getElementById("submit_form_modal")?.focus()
                }
                document.getElementById("number" + nextNumber)?.focus()
            }else{
                this.setState({drawError: false,[e.target.name+"ErrorClass"]:""})
            }
            if (e.target.value.length == 2) {
                const currentNumber = e.target.id.charAt(e.target.id.length - 1)
                const nextNumber = parseInt(currentNumber) + 1
                if(e.target.value > 49){                    
                    this.setState({drawError: true,[e.target.name+"ErrorClass"]:"errorClass"})
                }
                if (currentNumber == 6) {
                    document.getElementById("submit_form_modal")?.focus()
                }
                document.getElementById("number" + nextNumber)?.focus()
                
            }
        }
    }
    validateDrawResult = () => {
        const flagArr = []
        Object.keys(this.state.winFields).map((values1: any, ind: any) => {
            {
                if (
                    this.state.winFields[values1] == "" ||
                    this.state.winFields[values1].length < 2 || this.state.winFields[values1] > 49
                ) {
                  //console.log("asd");
                    flagArr.push(ind)
                }
            }
        })
        return flagArr.length > 0 ? false : true
    }
    handleDrawResult(e: any) {
        e.preventDefault()
        this.setState({drawError: false})
        if (this.validateDrawResult()) {
            document.getElementById("submit_form_modal")?.setAttribute('disabed','disabled')
            const drawWinNumbers: any = []
            Object.keys(this.state.winFields).map((values1: any, ind: any) => {
                {
                    drawWinNumbers[ind] = this.state.winFields[values1]
                }
            })
            //drawWinNumbers = drawWinNumbers.substring(0, drawWinNumbers.length - 1)
          //console.log("drawWinNumbers", drawWinNumbers)

            const params = {
                "drawId": this.state.drawWinNumberId,
                "winNumber": drawWinNumbers
            }
          //console.log(params)
            const response = this.DrawManageServices.drawResultUpdate(params)
            response
                .then((updateResultResponse: any) => {
                    if (!updateResultResponse.errorStatus) {
                        this.uiData.showToastMessage(updateResultResponse.message, "success")
                        this.setState({
                            winFields: {
                                "number1": "",
                                "number2": "",
                                "number3": "",
                                "number4": "",
                                "number5": "",
                                "number6": ""
                            },
                            drawError: false,
                            drawWinNumberId: "",
                            alertPopupShow: "hide"
                        })
                        this.handleDrawSearch(event)
                    } else {
                        this.uiData.showToastMessage(updateResultResponse.message, "error")
                    }
                })
                .catch((err: any) => {
                  //console.log("handleDrawSearch", err)
                    this.uiData.updateShowLoading(false)
                })
        } else {
            this.setState({drawError: true})
        }
    }
    handleDrawSearch = (e: any) => {
        e.preventDefault()
        this.searchFunction("search")
    }
    handleStatusChange(e: any, drawId: any, drawStatus: any, oldStatus: any, from: any) {
        // alert(oldStatus)
        e.preventDefault()
        const dataMessage = "Are you sure to " + e.target.value + " the draw?"
        Swal.fire({
            title: dataMessage,
            timer: 10000,
            confirmButtonText: "Yes",
            showDenyButton: true,
            denyButtonAriaLabel: "No",
            icon: "info"
        }).then(result => {
            if (result.isConfirmed) {
                this.changeStatusCall(drawId, drawStatus, e.target.value)
            } else {
                // document.getElementById("selectBox_"+drawId)?.setAttribute('value',oldStatus).change')
                // e.target.value = oldStatus
            }
        })
    }
    changeStatusCall = (drawId: any, drawStatus: any, value: any) => {
        const searchCondition = {
            drawId: drawId,
            is_active: value
        }
      //console.log(searchCondition)
        const response = this.DrawManageServices.drawStatusChange(searchCondition)
        response
            .then((changeStatusDrawResponse: any) => {
                if (!changeStatusDrawResponse.errorStatus) {
                    this.uiData.showToastMessage(changeStatusDrawResponse.message, "success")
                    this.handleDrawSearch(event)
                } else {
                    this.uiData.showToastMessage(changeStatusDrawResponse.message, "error")
                }
            })
            .catch((err: any) => {
              //console.log("handleDrawSearch", err)
                this.uiData.updateShowLoading(false)
            })
    }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getGameListDropdown(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                    this.createDrawsFields[""][0].options = getGameListResponse.data
                    this.setState({companyList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }
    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })

        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        setTimeout(() => {
            this.searchFunction("pagination")
        }, 50)
    }

    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
    searchFunction(param: any) {
        if (param == "search") {
            this.setState({
                // ...this.state,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
            this.setState({
                // username: "",
                // accountStatus: "",
                DrawSearchData: [],
                showDrawListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }

        this.setState({
            DrawSearchData: [],
            showDrawListing: false,
            totalCount: 0
        })
        if (this.validatecreateDrawForm()) {
            const searchCondition = {
                drawGameId: this.state.fields.drawGameId,
                drawIsDemo: this.state.fields.drawIsDemo,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                drawStatus: this.state.fields.drawStatus,
                page: this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            }
          //console.log(searchCondition)
            this.uiData.updateShowLoading(true)
            const response = this.DrawManageServices.getDrawListSearch(searchCondition)
            response
                .then((searchDrawResponse: any) => {
                    if (!searchDrawResponse.errorStatus) {
                        this.setState({
                            DrawSearchData: searchDrawResponse.data,
                            showDrawListing: true,
                            totalCount: searchDrawResponse.totalCount
                        })
                        const data = []

                        for (let i = 0; i < searchDrawResponse.totalCount / this.state.noRow; i++) {
                            data.push(i)
                        }
                        const divData = searchDrawResponse.totalCount / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        }
                        const dataResponse = searchDrawResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                let value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                if(this.state.exportBody[j] == "DRAW_STATUS"){
                                    for (let dra = 0;dra < StaticKey.DRAWSTATUS.length;dra++) {
                                        if (dataResponse[i][this.state.exportBody[j]] ==StaticKey.DRAWSTATUS[dra].id) {
                                            value = StaticKey.DRAWSTATUS[dra].name
                                        }
                                    }
                                }                                               
                                if(this.state.exportBody[j].includes("_toFixed")){
                                    const dataValue = this.state.exportBody[j].replace("_toFixed","")
                                    value = dataResponse[i][dataValue]
                                                ? parseFloat(dataResponse[i][dataValue]).toFixed(2)
                                                :"0.00"
                                }
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                      //console.log(obj)
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})                     
                    } else {
                        this.setState({
                            DrawSearchData: [],
                            showDrawListing: false,
                            totalCount: 0,
                            totalCountPagination: [],
                            lastPage: 0
                        })
                    }
                    this.closeLoader()
                })
                .catch((err: any) => {
                    // console.log("handleDrawSearch", err)
                    this.closeLoader()
                    this.setState({
                        DrawSearchData: [],
                        showDrawListing: false,
                        totalCount: 0
                    })
                })
        } else {
            this.uiData.updateShowLoading(false)
        }
    }
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
         a.print();
 setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    render() {
        let tabIndex = 1
        return (
            <>
                {<Loader showPageLoader={this.state.isLoadedDropdown} />}
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>Draw List</h2>
                        <form>
                        {this.state.searchFilterRow.map((valRow:any,i:any)=>{
                        return (
                            <div className={classes["main-div-input-sec"]} key={i}>
                                {Object.keys(this.createDrawsFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div className="w-100" key={values}>
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.createDrawsFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                            if(fieldValObject.rowId == valRow){
                                                            return (
                                                                <div
                                                                    className={classes["sub-div"]}
                                                                    key={fieldValIndex}
                                                                >
                                                                    <div>
                                                                        <label
                                                                            htmlFor={
                                                                                fieldValObject.id
                                                                            }
                                                                        >
                                                                            {fieldValObject.label}
                                                                        </label>
                                                                        {fieldValObject.required ? (
                                                                            <span
                                                                                className={
                                                                                    classes[
                                                                                        "mandatory-symbol"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                *{" "}
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <InputBox
                                                                            id={fieldValObject.id}
                                                                            autoComplete="off"
                                                                            maxLength={
                                                                                fieldValObject.maxLength
                                                                            }
                                                                            min={fieldValObject.min}
                                                                            max={fieldValObject.max}
                                                                            placeholder={
                                                                                fieldValObject.placeHolder
                                                                            }
                                                                            className={
                                                                                "form-control " +
                                                                                classes[
                                                                                    fieldValObject
                                                                                        .class
                                                                                ]
                                                                            }
                                                                            autoFocus={
                                                                                fieldValObject.autoFocus
                                                                            }
                                                                            value={
                                                                                fieldValObject.type !=
                                                                                "file"
                                                                                    ? this.state
                                                                                          .fields[
                                                                                          fieldValObject
                                                                                              .name
                                                                                      ]
                                                                                    : ""
                                                                            }
                                                                            name={
                                                                                fieldValObject.name
                                                                            }
                                                                            type={
                                                                                fieldValObject.type
                                                                            }
                                                                            tabIndex={tabIndex++}
                                                                            required={
                                                                                fieldValObject.required
                                                                            }
                                                                            // onChange={() =>
                                                                            //     this.handlecreateDrawInputChange(
                                                                            //         event
                                                                            //     )
                                                                            // }
                                                                            onChange={
                                                                                fieldValObject.type === "datetime"
                                                                                    ? fieldValObject.name == "fromDate"
                                                                                        ? this.handleStartDateChange
                                                                                        : this.handleEndDateChange
                                                                                    : this.handlecreateDrawInputChange
                                                                            }
                                                                            dateFormat={fieldValObject.dateFormat}
                                                                            startMaxDate={this.maxDate}
                                                                            endMinDate={this.minDate}
                                                                            label1={
                                                                                fieldValObject.label1
                                                                            }
                                                                            options={
                                                                                fieldValObject.type ==
                                                                                    "select" ||
                                                                                fieldValObject.type ==
                                                                                    "radio"
                                                                                    ? fieldValObject.options
                                                                                    : ""
                                                                            }
                                                                            selectShowEmpty={
                                                                                fieldValObject.selectShowEmpty
                                                                            }
                                                                        />
                                                                        <div>
                                                                            {fieldValObject.type ==
                                                                                "file" &&
                                                                                this.state.fields[
                                                                                    fieldValObject.name +
                                                                                        "Val"
                                                                                ]}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                classes["error"]
                                                                            }
                                                                        >
                                                                            {
                                                                                this.state.errors[
                                                                                    fieldValObject.name +
                                                                                        "Error"
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                                        }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )})}
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Search"
                                        onClick={() => this.handleDrawSearch(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleDrawListingReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {this.state.showDrawListing && (
                        
                        <>      
                        <div className="d-flex">
                            <TotalRecords totalCount={this.state.totalCount}/>
                             {this.state.totalCount > 0 ? (
                                <>
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                </>
                            ):""}
                        </div>
                        <div
                            className={classes["main-div"] + " " + classes["table-main-div"]}
                            style={{maxHeight: "400px"}}
                        >
                            <Table
                                className={ classes["text-whiteSpace"] + " "+
                                    "table table-bordered table-hover table-condensed"
                                }
                                id="draw_list_table_id"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Sl.No</Th>
                                        <Th>Draw Number</Th>
                                        <Th>Game Name</Th>
                                        <Th>Draw Time</Th>
                                        <Th>Price</Th>
                                        <Th>Win Number</Th>
                                        <Th>Bet</Th>
                                        <Th>Win</Th>
                                        <Th>Status</Th>
                                        <Th style={{zIndex:2}}>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.DrawSearchData.length > 0 ? (
                                        <>
                                            {this.state.DrawSearchData.map((val: any, ind: any) => {
                                                let status: any
                                                for (
                                                    let i = 0;
                                                    i <
                                                    StaticKey.KEYDRAWACTIVEINACTIVESTATUS.length;
                                                    i++
                                                ) {
                                                    if (
                                                        val.IS_ACTIVE ==
                                                        StaticKey.KEYDRAWACTIVEINACTIVESTATUS[i].id
                                                    ) {
                                                        status =
                                                            StaticKey.KEYDRAWACTIVEINACTIVESTATUS[i]
                                                                .id
                                                    }
                                                }
                                                let DRAW_STATUS: any
                                                for (
                                                    let i = 0;
                                                    i < StaticKey.DRAWSTATUS.length;
                                                    i++
                                                ) {
                                                    if (
                                                        val.DRAW_STATUS ==
                                                        StaticKey.DRAWSTATUS[i].id
                                                    ) {
                                                        DRAW_STATUS = StaticKey.DRAWSTATUS[i].name
                                                    }
                                                }
                                                let oddEven = "odd"
                                                if (ind % 2 == 0) {
                                                    oddEven = "even"
                                                }
                                                return (
                                                    <Tr
                                                        className={classes["tr_" + oddEven]}
                                                        key={ind}
                                                    >
                                                        <Td>{((this.state.currentPage-1)*this.state.noRow)+ind+1}</Td>
                                                        <Td>{val.DRAW_NUMBER}</Td>
                                                        <Td>{val.DRAW_GAME_TITLE}</Td>
                                                        <Td>{val.DRAW_START_DATE}</Td>
                                                        <Td className={classes["text-right"]}>
                                                            <div>
                                                                {val.DRAW_PRICE != ""
                                                                    ? parseFloat(
                                                                          val.DRAW_PRICE
                                                                      ).toFixed(2)
                                                                    : "0.00"}
                                                            </div>
                                                        </Td>
                                                        <Td>
                                                            {val.DRAW_WINNUMBER
                                                                ? val.DRAW_WINNUMBER
                                                                : <div className="text-center">  -  </div>
                                                                }
                                                        </Td>
                                                        <Td className={classes["text-right"]}>
                                                            <div>{val.DRAW_TOTALBET}</div>
                                                        </Td>
                                                        <Td className={classes["text-right"]}>
                                                            <div>{val.DRAW_TOTALWIN}</div>
                                                        </Td>
                                                        <Td>{DRAW_STATUS}</Td>
                                                        <Td>
                                                            {val.DRAW_STATUS == 1 || val.DRAW_STATUS == 2 ? (
                                                                <InputBox
                                                                    style={{"height": "32px","margin-top":"-6px","margin-bottom":"-5px"}}
                                                                    id={"selectBox_" + val.DRAW_ID}
                                                                    autoComplete="off"
                                                                    className={"w-auto form-control"}
                                                                    data_value={status}
                                                                    value={status}
                                                                    name={"selectBox_" + val.DRAW_ID}
                                                                    type={"select"}
                                                                    defaultCheck={false}
                                                                    selected={status}
                                                                    onChange={() =>
                                                                        this.handleStatusChange(
                                                                            event,
                                                                            val.DRAW_ID,
                                                                            val.IS_ACTIVE,
                                                                            status,
                                                                            "change"
                                                                        )
                                                                    }
                                                                    options={
                                                                        StaticKey.KEYDRAWACTIVEINACTIVESTATUS
                                                                    }
                                                                />
                                                            ) : (
                                                               <div className="text-center">  -  </div>
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <Tr className={classes["no_data_found"]}>
                                            <Td colSpan={10}>No record(s) available</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        {this.state.totalCountPagination.length > 0 ? (
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.DrawSearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/>  
                                {this.state.excelExport && (
                                        <ExportExcelReport mockData={this.state.mockData} sheetName="draw_list"/> 
                                    )}
                            </div>
                        ):""}
                        <div className={"table_pagination_div"}>
                        {this.state.totalCountPagination.length > 0 ? (
                            <>
                                <button
                                    className={classes["buttonClass"]}
                                    disabled={this.state.currentPage == 1 ? true : false}
                                    onClick={event => this.handlePagination(event, 1)}
                                >
                                    First
                                </button>
                                <button
                                    className={classes["buttonClass"]}
                                    disabled={this.state.currentPage == 1 ? true : false}
                                    onClick={event =>
                                        this.handlePagination(
                                            event,
                                            this.state.previousPage
                                        )
                                    }
                                >
                                    <i className="fa fa-angle-double-left"></i> Previous
                                </button>
                                {this.state.totalCountPagination.map(
                                    (val: any, ind: any) => {
                                        let btnClass = ""
                                        if (
                                            ind + 1 >= this.state.fromPagePagination &&
                                            ind + 1 <= this.state.toPagePagination
                                        ) {
                                            if (this.state.currentPage == ind + 1) {
                                                btnClass = "active"
                                            }
                                            return (
                                                <>
                                                    <button
                                                        className={
                                                            classes["buttonClass"] +
                                                            " " +
                                                            classes[btnClass]
                                                        }
                                                        onClick={event =>
                                                            this.handlePagination(
                                                                event,
                                                                ind + 1
                                                            )
                                                        }
                                                    >
                                                        {ind + 1}
                                                    </button>
                                                </>
                                            )
                                        }
                                    }
                                )}
                                <button
                                    className={classes["buttonClass"]}
                                    disabled={
                                        this.state.currentPage == this.state.lastPage
                                            ? true
                                            : false
                                    }
                                    onClick={event =>
                                        this.handlePagination(event, this.state.nextPage)
                                    }
                                >
                                    Next <i className="fa fa-angle-double-right"></i>
                                </button>
                                <button
                                    className={classes["buttonClass"]}
                                    disabled={
                                        this.state.currentPage == this.state.lastPage
                                            ? true
                                            : false
                                    }
                                    onClick={event =>
                                        this.handlePagination(event, this.state.lastPage)
                                    }
                                >
                                    Last
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    </>
                    )}
                </div>
                <>
                    {this.state.alertPopupShow == "show" && (
                        <Modal
                            isOpen={true}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>
                                    {this.state.drawWinNumberDrawNumber} (
                                    {this.state.drawWinNumberStartTime})
                                </h4>
                                <span
                                    onClick={this.handleAlertPopupClose}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2">
                                    <div className={"mb-3"}>Win Number</div>
                                    <div className=" d-flex">
                                        {this.winNumberFields.map((obj: any, key: any) => {
                                            
                                            return (
                                                <>
                                                    <InputBox
                                                        className={
                                                            " me-1 " +classes["form-control"] +
                                                            " " +
                                                            classes[obj.class] +" "+classes[this.state[obj.name+"ErrorClass"]]
                                                        }
                                                        name={obj.name}
                                                        type={obj.type}
                                                        id={obj.name}
                                                        label1={obj.label}
                                                        value={this.state.winFields[obj.name]}
                                                        onChange={() =>
                                                            this.handlecreateDrawInputChange(event)
                                                        }
                                                        tabIndex={tabIndex++}
                                                        autoFocus={obj.autoFocus}
                                                    />
                                                </>
                                            )
                                        })}
                                    </div>
                                    {this.state.drawError && (
                                        <div className={classes["drawerror"]}>
                                            Invalid Win number
                                        </div>
                                    )}
                                </div>
                                <div className={classes["modal-footer"]}>
                                    <div className={classes["sub-div"]}>
                                        <InputBox
                                            id="submit_form_modal"
                                            className="form-control btn btn-warning"
                                            type="submit"
                                            value="Update"
                                            onClick={() => this.handleDrawResult(event)}
                                            tabIndex={tabIndex++}
                                        />
                                    </div>
                                    <div className={classes["sub-div"]}>
                                        <InputBox
                                            id="reset_form_winnumber"
                                            className="form-control  btn btn-danger"
                                            type="reset"
                                            value="Reset"
                                            tabIndex={tabIndex++}
                                            onClick={() => this.handleResetWinnumber(event)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </Modal>
                    )}
                </>
            </>
        )
    }
}
