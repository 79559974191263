import React, {Component} from "react"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Modal} from "reactstrap"
import { Navigate } from "react-router"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"

export class ViewRetailer extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    state: any = {
        alertPopupShow: false,
        goToBack: false,
        companyList: {},
        pincodeData : [],
        pincodeData1 : [],
        retailerData :[],
        newPasswordError:"",
        confirmPasswordError:"",
        confirmPassword:"",
        newPassword:"",
        fields: {
            newPassword:"",
            confirmPassword:"",
            companyId: "",
            distributorId: "",
            retailerName: "",
            username: "",
            personalAddress1: "",
            personalAddress2: "",
            personalPinCode: "",
            personalLocality: "",
            personalTaluka: "",
            personalCity: "",
            personalState: "",
            personalMobileNo: "",
            personalWhatsappNo: "",
            personalTelegramNo: "",
            personalEmail: "",
            userAddress1: "",
            userAddress2: "",
            userPinCode: "",
            userLocality: "",
            personalLaluka: "",
            userCity: "",
            userState: "",
            userMobileNo: "",
            userWhatsappNo: "",
            userTelegramNo: "",
            userEmail: "",
            typeOfOrganisation: "",
            natureOfBusiness: "",
            userLandLineNo: "",
            establishmentType: "",
            establishmentNearBySchool: "",
            establishmentFarTransport: "",
            registrationPanCardNo: "",
            registrationAadharCardNo: "",
            registrationIfscCode: "",
            registrationBankName: "",
            registrationBranchName: "",
            registrationAccountNo: "",
            uploadAadhar: "",
            uploadAadharVal: "",
            uploadPanCard: "",
            uploadPanCardVal: "",
            uploadDrivingLicence: "",
            uploadDrivingLicenceVal: "",
            uploadEBBill: "",
            uploadEBBillVal: "",
            uploadPassport: "",
            uploadPassportVal: "",
            uploadPhoto: "",
            uploadPhotoVal: "",
            termsAgreed: "",
            terminalType: "",
        },
        errors:{   
            newPasswordError:"",
            confirmPasswordError:"",         
            companyIdError:"",
            distributorIdError:"",
            retailerNameError:"",
            usernameError:"",
            personalAddress1Error:"",
            personalAddress2Error:"",
            personalPinCodeError:"",
            personalLocalityError:"",
            personalTalukaError:"",
            personalCityError:"",
            personalStateError:"",
            personalMobileNoError:"",
            personalWhatsappNoError:"",
            personalTelegramNoError:"",
            personalEmailError:"",
            userAddress1Error:"",
            userAddress2Error:"",
            userPinCodeError:"",
            userLocalityError:"",
            personalLalukaError:"",
            userCityError:"",
            userStateError:"",
            userMobileNoError:"",
            userWhatsappNoError:"",
            userTelegramNoError:"",
            userEmailError:"",
            typeOfOrganisationError:"",
            natureOfBusinessError:"",
            establishmentTypeError: "",
            establishmentNearBySchoolError: "",
            establishmentFarTransportError: "",
            registrationPanCardNoError:"",
            registrationAadharCardNoError:"",
            registrationIfscCodeError:"",
            registrationBankNameError:"",
            registrationBranchNameError:"",
            registrationAccountNoError:"",
            uploadAadharError:"",
            uploadAadharValError:"",
            uploadPanCardError:"",
            uploadPanCardValError:"",
            uploadDrivingLicenceError:"",
            uploadDrivingLicenceValError:"",
            uploadEBBillError:"",
            uploadEBBillValError:"",
            uploadPassportError:"",
            uploadPassportValError:"",
            uploadPhotoError:"",
            uploadPhotoValError:"",
            termsAgreedError:"",
            terminalTypeError: "",
        }
    }
    passwordFields: any = [
        {
            "name": "newPassword",
            "id": "newPassword",
            "type": "password",
            "label": "New Password",
            // "max": "49",
            "autoFocus": true,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
        {
            "name": "confirmPassword",
            "id": "confirmPassword",
            "type": "password",
            "label": "Confirm Password",
            // "max": "49",
            "autoFocus": false,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
    ]
    componentDidMount() {
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        this.getRetailerDetail(id)
        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                    LoginpartnerTypeId: loginResponse.personalTypeId
            })
        }
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }

    getRetailerDetail = (id: any) => {
        
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            "userGuid": id,
        }
        const response = this.DownlineManageServices.getRetailerDetail(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                if (!searchPartnerLimitResponse.errorStatus) {
                    const responseData = searchPartnerLimitResponse.data;
                    this.setState({
                        ...this.state,
                        retailerData: searchPartnerLimitResponse.data,
                        guId: id
                    }) 
                    let dataTerminal = "";  
                    for(let keyKoop = 0;keyKoop<StaticKey.TERMINALTYPE.length;keyKoop++){
                        if(responseData.terminalType == StaticKey.TERMINALTYPE[keyKoop].id){
                            dataTerminal=StaticKey.TERMINALTYPE[keyKoop].name;
                        }
                    }     
                    // console.log(dataTerminal);      
                    this.setState({
                        ...this.state.fields,
                        fields: {
                            companyId:responseData.level3Name ? responseData.level3Name : "-",
                            distributorId: responseData.level5Name ? responseData.level5Name : "-",
                            retailerName: responseData.retailerName ? responseData.retailerName : "-",
                            username: responseData.username ? responseData.username : "-",
                            userAddress1: responseData.userAddress1 ? responseData.userAddress1 : "-",
                            userAddress2: responseData.userAddress2 ? responseData.userAddress2 : "-",
                            terminalType: dataTerminal,
                            userPinCode: responseData.userPincode ? responseData.userPincode : "-", 
                            userLocality: responseData.userLocality ? responseData.userLocality : "-",
                            userTaluka: responseData.userTaluk ? responseData.userTaluk : "-",
                            userCity: responseData.userCity ? responseData.userCity : "-",
                            userState: responseData.userState ? responseData.userState : "-",
                            userMobileNo: responseData.userMobileNo ? responseData.userMobileNo : "-",
                            userWhatsappNo: responseData.userWhatsappNo ? responseData.userWhatsappNo : "-",
                            userTelegramNo: responseData.userTelegramNo ? responseData.userTelegramNo : "-",
                            userwebsite: responseData.userwebsite ? responseData.userwebsite : "-",
                            userEmail: responseData.userEmail ? responseData.userEmail : "-",
                            personalAddress1: responseData.personalAddress ? responseData.personalAddress : "-",
                            personalAddress2: responseData.personalAddress1 ? responseData.personalAddress1 : "-",
                            personalPinCode: responseData.personalZipCode ? responseData.personalZipCode : "-",
                            personalLocality: responseData.personalLocality ? responseData.personalLocality : "-",
                            personalTaluka: responseData.personalTaluk ? responseData.personalTaluk : "-",
                            personalCity: responseData.personalCityName ? responseData.personalCityName : "-",
                            personalState: responseData.personalStateId ? responseData.personalStateId : "-",
                            personalMobileNo: responseData.personalMobileNo ? responseData.personalMobileNo : "-",
                            personalWhatsappNo: responseData.personalWhatsappNo ? responseData.personalWhatsappNo : "-",
                            personalTelegramNo: responseData.personalTelegramNo ? responseData.personalTelegramNo : "-",
                            personalwebsite: responseData.personalwebsite ? responseData.personalwebsite : "-",
                            personalEmail: responseData.personalEmailId ? responseData.personalEmailId : "-",
                            typeOfOrganisation: StaticKey.TYPEOFORGANISATION[responseData.typeOfOrganisation].name,
                            natureOfBusiness: responseData.natureOfBusiness ? responseData.natureOfBusiness : "-",
                            userLandLineNo: responseData.userLandLineNo ? responseData.userLandLineNo : "-",
                            establishmentType: responseData.establishmentType == 1 ? "Owned" : "Rental",
                            establishmentNearBySchool: responseData.establishmentNearbySchool ? responseData.establishmentNearbySchool : "-",
                            establishmentFarTransport: responseData.establishmentFarTransport ? responseData.establishmentFarTransport : "-",
                            registrationPanCardNo: responseData.pancardNo ? responseData.pancardNo : "-",
                            registrationAadharCardNo: responseData.aadharNo ? responseData.aadharNo : "-",
                            registrationIfscCode: responseData.ifscCode ? responseData.ifscCode : "-",
                            registrationBankName: responseData.bankName ? responseData.bankName : "-",
                            registrationBranchName: responseData.branchName ? responseData.branchName : "-",
                            registrationAccountNo: responseData.accountNo ? responseData.accountNo : "-", 
                            uploadAadhar: responseData.aadharImage !="" ? responseData.aadharImage : "-",
                            uploadAadharVal: responseData.aadharImageUrl ?  responseData.aadharImageUrl : "-",
                            uploadPanCard: responseData.pancardImage  !="" ? responseData.pancardImage : "-",
                            uploadPanCardVal:responseData.pancardImageUrl ?  responseData.pancardImageUrl : "-",
                            uploadDrivingLicence: responseData.drivingLicenceImage  !="" ? responseData.drivingLicenceImage : "-",
                            uploadDrivingLicenceVal: responseData.drivingLicenceImageUrl ?  responseData.drivingLicenceImageUrl : "-",
                            uploadEBBill: responseData.ebBillImage  !="" ? responseData.ebBillImage : "-",
                            uploadEBBillVal: responseData.ebBillImageUrl ?  responseData.ebBillImageUrl : "-",
                            uploadPassport: responseData.passportImage  !="" ? responseData.passportImage : "-",
                            uploadPassportVal: responseData.passportImageUrl ?  responseData.passportImageUrl : "-",
                            uploadPhoto: responseData.photo  !="" ? responseData.photo : "-", 
                            uploadPhotoVal: responseData.photoUrl ?  responseData.photoUrl : "-"
                        }
                    })
                    this.closeLoader()
                } else {
                    this.setState({
                        retailerData: []
                    })
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
                this.setState({
                    retailerData: []
                })
                this.closeLoader()
            })
    }
    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
   
    createRetailersFields: any = {
        "A. General Information": [
            {
                "name": "companyId",
                "type": "text",
                "label": "Company",
                // "placeHolder": "Company",
                "maxLength": 50,
                "autoFocus": true,
                "class": "form-control",
               "required": false, readonly:true,
                "disabled":"disabled",
                
            },
            {
                "name": "distributorId",
                "type": "text",
                "label": "Distributor",
                // "placeHolder": "Distributor",
                "maxLength": 50,
                "autoFocus": true,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
                
            },
            {"": []},
            {
                "name": "retailerName",
                "type": "text",
                "label": "Retailer Name",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            // {"": []},
            {
                "name": "terminalType",
                "type": "text",
                "label": "Terminal Type",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
                "options":StaticKey.TERMINALTYPE,
                "disabled":"disabled",
            },            
            {
                "name": "username",
                "type": "text",
                "label": "Agency Code",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                onblur: "checkUserExist",
                "disabled":"disabled",
            },
            {"": []},
            {
                "name": "userAddress1",
                "type": "text",
                "label": "Address1",
                "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userAddress2",
                "type": "text",
                "label": "Address2",
                "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {"": []},
            {
                "name": "userPinCode",
                "type": "text",
                "label": "Pincode",
                // "placeHolder": "PinCode",
                "maxLength": 10,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
            },
            {
                "name": "userLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true, 
               "disabled":"disabled",               
                
                
            },
            {
                "name": "userTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "userCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "userState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            // {"": []},
            {
                "name": "userMobileNo",
                "type": "text",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userWhatsappNo",
                "type": "text",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userTelegramNo",
                "type": "text",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userEmail",
                "type": "text",
                "label": "Email Id",
                // "placeHolder": "Email",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },   
            {"": []},          
            {
                "name": "typeOfOrganisation",
                "type": "text",
                "label": "Type of Organisation",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "options":StaticKey.TYPEOFORGANISATION,
                "selectShowEmpty":true,
                "disabled":"disabled",
            },         
            {
                "name": "natureOfBusiness",
                "type": "text",
                "label": "Nature of Business",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
        ],
        "B. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                // "placeHolder": "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "---- ---- ----",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },            
            {"": []},
            {
                "name": "registrationIfscCode",
                "type": "text",
                "label": "IFSC Code",
                // "placeHolder": "Registration Ifsc Code",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
                onblur:"handleIfsc",
                "disabled":"disabled",
            },
            {
                "name": "registrationBankName",
                "type": "text",
                "label": "Bank Name",
                // "placeHolder": "Registration Bank Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
               
            },
            {"": []},
            {
                "name": "registrationBranchName",
                "type": "text",
                "label": "Branch Name",
                // "placeHolder": "Registration Branch Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
               
            },
            {
                "name": "registrationAccountNo",
                "type": "text",
                "label": "Account No",
                // "placeHolder": "Registration Account No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
        ],
        "C. Establishment Details": [
            {
                "": [
                    {
                        "name": "establishmentType",
                        "type": "text",
                        "label": "Owned / Rental",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-control",
                        "required": false,
                        "disabled":"disabled",
                    },
                    {
                        "name": "establishmentNearBySchool",
                        "type": "text",
                        "label": "Is there a School, Place of worship within the limits of 200 meters from your establishment",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-check-input",
                        "required": false,
                        "options": {
                            1: "Yes",
                            2: "No"
                        },
                        "disabled":"disabled",
                    },
                    {
                        "name": "establishmentFarTransport",
                        "type": "text",
                        "label": "How far is the Railway station / Main Market / bus stand from the establishment (in meters)",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-control",
                        "required": false,
                        "disabled":"disabled",
                    }
                ]
            },
        ],
        "D. Personal Detail": [
           
            {
                "name": "personalAddress1",
                "type": "text",
                "label": "Personal Address1",
                "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "personalAddress2",
                "type": "text",
                "label": "Personal Address2",
                "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {"": []},
            {
                "name": "personalPinCode",
                "type": "text",
                "label": "PinCode",
                // "placeHolder": "PinCode",
                "maxLength": 6,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
            },
            {
                "name": "personalLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,   
               "disabled":"disabled",             
                
            },
            {
                "name": "personalTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "personalCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "personalState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {"": []},
            {
                "name": "personalMobileNo",
                "type": "text",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "personalWhatsappNo",
                "type": "text",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "personalTelegramNo",
                "type": "text",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            {
                "name": "uploadAadhar",
                "type": "file",
                "label": "Aadhar",
                // "placeHolder": "Upload Aadhar",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPanCard",
                "type": "file",
                "label": "Pan Card",
                // "placeHolder": "Upload Pan Card",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPhoto",
                "type": "file",
                "label": "Photo",
                // "placeHolder": "Upload Photo",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadDrivingLicence",
                "type": "file",
                "label": "Driving Licence",
                // "placeHolder": "Upload Driving Licence",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadEBBill",
                "type": "file",
                "label": "Light Bill",
                // "placeHolder": "Upload EBBill",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPassport",
                "type": "file",
                "label": "Passport",
                // "placeHolder": "Upload Passport",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
        ],
    }
    handleAlertPopupClose = () => {
        this.uiData.setAlertShowMessage("hide", "", "")
        this.setState({alertPopupShow: false})
    }
    handlePopupShow = (e: any) => {
        e.preventDefault()
        this.setState({
            alertPopupShow: true
        })
        setTimeout(()=>{
            document.getElementById("resetForm")?.click();
            document.getElementById("newPassword")?.focus();
        },100)
    }
    handleGoBack = (e: any) => {
        e.preventDefault()
        this.setState({
            goToBack: true
        })
        this.uiData.setRetailerBackList(true)
    }
    
    validatePasswordForm(){
        let flag = true
        // const errorMessage = StaticKey.LOCALE_DATA.Home
        this.passwordFields.map((val: any) => {
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    const errorData = this.uiData.textConversion(val.label)
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.Home.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        let pattern: any
                        let error: any
                        if (val.name == "newPassword" || val.name == "confirmPassword") {
                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                            error = StaticKey.PASSWORD_CONDITION
                        } 
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: error
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        return flag
    }
    handleResetPassword(e:any){
        e.preventDefault();    
        this.setState({
            ...this.state,
            "confirmPasswordError":"",
            "newPasswordError":"",
            "newPassword":"",
            "confirmPassword":"",
            fields : {
                ...this.state.fields,
                "newPassword":"",
                "confirmPassword":"",
            }
        })
        document.getElementById("newPassword")?.focus();
    }
    
    handleChangePassword(e:any){
        e.preventDefault();        
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        if(this.validatePasswordForm()){
            this.uiData.updateShowLoading(true)
            if (this.state["newPassword"] != this.state["confirmPassword"]) {
                this.setState({
                    ["confirmPasswordError"]:"New password and Confirm Password must be same"
                })
                this.uiData.updateShowLoading(false)
                return false
            }

            const searchCondition = {
                "userGuid":id,
                "newPassword":this.state['newPassword'],
                "confirmPassword":this.state['confirmPassword']
            }
          //console.log(searchCondition);
            const response = this.DownlineManageServices.userForcePasswordUpdate(searchCondition)
            response
                .then((updateResultResponse: any) => {
                    if (!updateResultResponse.errorStatus) {
                        this.uiData.showToastMessage(updateResultResponse.message, "")
                        this.setState({
                            ...this.state,
                            "confirmPasswordError":"",
                            "newPasswordError":"",
                            alertPopupShow:false,
                            fields : {
                                ...this.state.fields,
                                "newPassword":"",
                                "confirmPassword":"",
                            }
                        })
                    } else {
                        this.uiData.showToastMessage(updateResultResponse.message, "error")
                    }
                    this.uiData.updateShowLoading(false)
                })
                .catch((err: any) => {
                    this.uiData.showToastMessage("Something went Wrong", "error")
                  //console.log("handleDrawSearch", err)
                    this.uiData.updateShowLoading(false)
                })
        }
      
    }

    handleOnchange(e:any){
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value,
            [e.target.name + "Error"]:""
        })
    }
    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"] + " " + classes["general-form"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>View Retailer</h2>
                            <div className={classes["back-class"]}>                                
                                <a href="#" onClick={()=>this.handleGoBack(event)} >
                                    <i className="fa fa-arrow-left"></i> Go to List
                                </a>
                                {this.state.goToBack && (
                                    <Navigate to="/list-retailer.html" replace={true} />
                                )}
                            </div>
                            <div className={classes["action-class"]}>
                                <a href="#" onClick={()=>this.handlePopupShow(event)} >Change Password</a>
                            </div>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.createRetailersFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div
key={values}
className={classes["main-div-input-sec-2"]}
                                            >
<h5>{keys}</h5>
<div className={classes["main-div-input-sec"]}>
    {this.createRetailersFields[keys].map(
        (
            fieldValObject: any,
            fieldValIndex: any
        ) => {
            if (
                Object.keys(fieldValObject)
                    .length == 1
            ) {
                {
                    return Object.keys(
                        fieldValObject
                    ).map(
                        (
                            keys1: any,
                            values1: any
                        ) => {
                            return (
                                <div
                                    key={values1}
                                    className={
                                        classes[
                                            "main-div-input-sec-2-sub"
                                        ]
                                    }
                                >
                                    <h6>{keys1}</h6>
                                    <div
                                        className={
                                            classes[
"main-div-input-sec"
                                            ]
                                        }
                                    >
                                        {fieldValObject[
                                            keys1
                                        ].map(
                                            (
fieldValObject1: any,
fieldValIndex1: any
                                            ) => {
return (
    <div
        className={
            classes[
                "sub-div"
            ]
        }
        key={
            fieldValIndex1
        }
    >
        <label htmlFor="name">
            {
                fieldValObject1.label
            }
        </label>
        {fieldValObject1.required ? (
            <span
                className={
                    classes[
                        "mandatory-symbol"
                    ]
                }
            >
                *
            </span>
        ) : (
            ""
        )}
        {
            <>
                <InputBox
                    id={
                        fieldValObject1.id
                    }
                    autoComplete="off"
                    maxLength={
                        fieldValObject1.maxLength
                    }
                    className={
                        "form-control " +
                        classes[
                            fieldValObject1
                                .class
                        ]
                    }
                    autoFocus={
                        fieldValObject1.autoFocus
                    }
                    name={
                        fieldValObject1.name
                    }
                    disabled={
                        fieldValObject1.disabled
                    }
                    value={
                        this
                            .state
                            .fields[
                            fieldValObject1
                                .name
                        ]
                    }
                    type={
                        fieldValObject1.type
                    }
                    tabIndex={
                        tabIndex++
                    }
                    required={
                        fieldValObject1.required
                    }
                        
                    options={
                        fieldValObject1.options
                    }
                />
                <>
                    <div className={classes["uploaded_file_div"]}>
                        {fieldValObject.type ==
                            "file" &&
                            this.state
                                .fields[
                                fieldValObject.name +
                                    "Val"
                            ]}
                    
                    </div>
                    </>
                <div
                    className={
                        classes[
                            "error"
                        ]
                    }
                >
                    {
                        this
                            .state
                            .errors[
                            fieldValObject1.name +
                                "Error"
                        ]
                    }
                </div>
            </>
        }
    </div>
)
                                            }
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            } else {
                let classNew = ""
                if (
                    fieldValObject.label ==
                    "Terms Agreed"
                ) {
                    classNew = "subdiv-full-width"
                }

                return (
                    <div
                        className={
                            classes["sub-div"] +
                            " " +
                            classes[classNew]
                        }
                        key={fieldValIndex}
                    >
                        <div>
                        {fieldValObject.name != "termsAgreed" && (
                            <label
                                htmlFor={
                                    fieldValObject.id
                                }
                            >
                                {
                                    fieldValObject.label
                                }
                            </label>
                        )}
                            {fieldValObject.required  && fieldValObject.name != "termsAgreed" ? (
                                <span
                                    className={
                                        classes[
                                            "mandatory-symbol"
                                        ]
                                    }
                                >
                                    *
                                </span>
                            ) : (
                                ""
                            )}
 {fieldValObject.name == "password" && (
                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                    &nbsp;<i className="fa fa-info-circle"></i>
                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.PASSWORD_CONDITION}</span>
                                </div>   
                            )}
                            {fieldValObject.name == "transactionPassword" && (
                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                    &nbsp;<i className="fa fa-info-circle"></i>
                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.TXN_PASSWORD_CONDITION}</span>
                                </div>   
                            )}
                            
                            {fieldValObject.type != "file" && (
                            <InputBox
                                id={
                                    fieldValObject.id
                                }
                                autoComplete="off"
                                maxLength={
                                    fieldValObject.maxLength
                                }
                                readonly = {fieldValObject.readonly ? true : false}
                                
                    disabled={
                        fieldValObject.disabled
                    }
                                placeholder = {fieldValObject.placeHolder}
                                className={
                                    "form-control " +
                                    classes[
                                        fieldValObject
                                            .class
                                    ] +
                                    " " +
                                    classes[
                                        "upload_input_field"
                                    ]
                                }
                                autoFocus={
                                    fieldValObject.autoFocus
                                }
                                accept={
                                    fieldValObject.type ==
                                    "file"
                                        ? ".png, .jpg, .svg, .pdf"
                                        : ""
                                }
                                value={
                                    fieldValObject.type !=
                                    "file"
                                        ? this.state
                                              .fields[
                                              fieldValObject
  .name
                                          ]
                                        : ""
                                }
                                name={
                                    fieldValObject.name
                                }
                                type={
                                    fieldValObject.type
                                }
                                tabIndex={
                                    tabIndex++
                                }
                                required={
                                    fieldValObject.required
                                }
                              
                               
                                label1={
                                    fieldValObject.label1
                                }
                                options={
                                    fieldValObject.type ==
                                    "select"
                                        ? fieldValObject.options
                                        : ""
                                }
                                selectShowEmpty={
                                    fieldValObject.selectShowEmpty
                                }
                                showModalCondition={
                                    fieldValObject.name ==
                                    "termsAgreed"
                                        ? fieldValObject.showModalCondition
                                        : ""
                                }
                                showModalCallback={
                                    fieldValObject.name ==
                                    "termsAgreed"
                                        ? this
                                              .handlePopupShow
                                        : ""
                                }
                                /* style={
                                    fieldValObject.type ==
                                    "file"
                                        ? {
                                              display:
  "none"
                                          }
                                        : {}
                                }
  */
                            />
    )}
                            {/* <div
                                className={
                                    classes[
                                        "uploaded_file_div"
                                    ]
                                }
                            > */}
                            {fieldValObject.type == "file" && (
                                    <div className="ms-2">
                                        {this.state.fields[fieldValObject.name] != "-" ? 
                                            <a href={this.state.fields[fieldValObject.name+"Val"] ? this.state.fields[fieldValObject.name+"Val"] : "#"} download>{this.state.fields[fieldValObject.name]} </a> 
                                        : <>-</>}
                                        </div>
                                    // onClick={()=>this.handleViewImage(event,this.state.fields[fieldValObject.name+"Val"],this.state.fields[fieldValObject.name],fieldValObject.label)}
                                )}
                            </div>
                    </div>
                )
            }
        }
    )}
</div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>                           
                        </form>
                    </div>
                </div>
                
                {/* {this.state.alertPopupShow == "show" && ( */}
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.alertPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Change Password</h4>
                                <span
                                    onClick={this.handleAlertPopupClose}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2">
                                {this.passwordFields.map((obj: any, key: any) => {
                                            
                                            return (
                                                <>
                                    <div className="row  mb-4">
                                        <div className="col-4">
                                            <label htmlFor={obj.name}>{obj.label}</label>  
                                            <span className={classes["mandatory-symbol"]}>
                                                    *
                                                </span>
                                        </div>
                                        <div className="col-8">
                                            <InputBox
                                                className={
                                                    "form-control " +
                                                    classes[
                                                        obj.class
                                                    ]
                                                }
                                                name={obj.name}
                                                type={obj.type}
                                                id={obj.name}
                                                label1={obj.label}
                                                value={this.state[obj.name]}
                                                onChange={() =>
                                                    this.handleOnchange(event)
                                                }
                                                tabIndex={key+1}
                                                autoFocus={obj.autoFocus}
                                            />
                                            <div  className={classes["errorPass"]}>{this.state[obj.name+"Error"]}</div>
                                        </div>
                                    </div>
                                    </>
                                            )})}
                                </div>
                                <div className="modal-footer d-flex">
                                    <InputBox
                                        id={"submitForm"}
                                        autoComplete="off"
                                        className={"btn btn-success w-25"}
                                        autoFocus={false}
                                        name={"submitForm"}
                                        value={"Submit"}
                                        type={"submit"}
                                        tabIndex={3}
                                        onClick={()=>this.handleChangePassword(event)}
                                        required={true}
                                    />
                                    <InputBox
                                        id={"resetForm"}
                                        autoComplete="off"
                                        className={"btn btn-danger w-25"}
                                        autoFocus={false}
                                        name={"resetForm"}
                                        value={"Reset"}
                                        type={"reset"}
                                        tabIndex={4}
                                        onClick={()=>this.handleResetPassword(event)}
                                        required={true}
                                    />
                                </div>
                            </form>
                        </Modal>
                    {/* )} */}
            </>
        )
    }
}
