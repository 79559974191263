import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/drawmanagement/drawListing.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DrawManageService} from "../../../game_core/type_script/services/drawmanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {DataService} from "../../../game_core/type_script/socket_communication_services/lobby/DataService"
import Swal from "sweetalert2"

export class DrawResultApprove extends Component {
    DrawManageServices = DrawManageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    DataService: DataService = DataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        selectedType1:[],
        subResult:[],
        mainResult:[],
        drawResultApprove:"",
        selectedType2:[],
        selectedType3:"",
        selectedType4:[],
        selectedType1Length:0,
        selectedType2Length:0,
        selectedType3Length:0,
        selectedType4Length:0,
        selectedType1Required:false,
        selectedType2Required:false,
        selectedType3Required:false,
        selectedType4Required:false,
        selectedType1Error:"",
        selectedType2Error:"",
        selectedType3Error:"",
        selectedType4Error:"",
        drawGameId: "",
        drawIsDemo: "no",       
        fromDate: "",
        fromDateSearch: "",
        drawSearchResult:[],
        showSearchList:false,
        fields: {
            "drawGameId": "",
            "drawReferrenceId": "",
            "fromDate": "",
        },
        errors: {
            "drawGameIdError": "",
            "drawReferrenceIdError": "",
            "fromDateError": "",
        },
        showDrawResult: false,
        DrawSearchData: []
    }
    createDrawsFields: any = {
        "": [
            {
                rowId:0,
                "name": "drawGameId",
                "type": "select",
                "label": "Game",
                "placeHolder": "Game",
                "autoFocus": true,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true,
                "getDrawInfo": true
            },
            {
                rowId:0,
                "name": "fromDate",
                "type": "date",
                "label": "Draw Date",
                "placeHolder": "Draw Date",
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "dateFormat": "dd-MM-yyyy" 
            },
            {
                rowId:0,
                "name": "drawReferrenceId",
                "type": "select",
                "label": "Draw Number",
                "placeHolder": "Draw Number",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true,
                "defaultValue":""
            },  
        ]
    }
    validateDrawResultForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createDrawsFields).map((keys: any) => {
            {
                return this.createDrawsFields[keys].map((fieldValObject: any) => {
                    
                    const errorData = this.uiData.textConversion(fieldValObject.label);
                    if (fieldValObject.required && this.state.fields[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = errorData + " is required")
                        
                    }  else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    componentDidMount() {
        this.getGameList(1)
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    handleDrawResultReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {
                this.setState({[this.createDrawsFields[keys].name]: ""})
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any, values1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                      //console.log(fieldValObject1);
                                        return (
                                            (newArrayError[fieldValObject1.name + "Error"] = ""),
                                            (newArrayFields[fieldValObject1.name] = ""),
                                            (newArrayFields[fieldValObject1.name + "Val"] = "")
                                        )
                                    }
                                )
                            })
                        } else {
                            return (
                                (newArrayError[fieldValObject.name + "Error"] = ""),
                                (newArrayFields[fieldValObject.name] = ""),
                                (newArrayFields[fieldValObject.name + "Val"] = "")
                            )
                        }
                    }
                )
            }
        })
        
        this.createDrawsFields[""][2].options = {}
        this.dataCheckedData = [];
        this.dataChecked = 0;  
        this.dataCheckedDataType2 = [];
        this.dataCheckedType2 = 0;
        this.setState({
            ...this.state,
            showSearchList:false,
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
            errors: newArrayError, 
            fields: newArrayFields
        })
    }
    handleDrawUpdateReset = () => {
        this.dataCheckedData = [];
        this.dataChecked = 0;  
        this.dataCheckedDataType2 = [];
        this.dataCheckedType2 = 0;
        this.setState({
            ...this.state,
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
        })
    }
    
    handleApproveFunction =(e:any,val:any)=>{
        e.preventDefault();
        if (val == "approved") {
            const dataMessage = "Are you sure to " + val + " the Result?"
            Swal.fire({
                title: dataMessage,
                text: "Enter Transaction Password:",
                input: "password",
                inputAttributes: {
                    autocapitalize: "off",
                    id: "numberId"
                },
                didOpen: () => {
                    // Swal.disableButtons()
                    const input: any = Swal.getInput()
                    input.oninput = () => {
                        if (input.value.length > 6) {
                            input.value = input.value.slice(0, 6)
                        }
                        // if (input.value.length == 6) {
                        //     Swal.enableButtons()
                        // } else {
                        //     Swal.disableButtons()
                        // }
                    }
                },
                // timer: 10000,
                confirmButtonText: "Yes",
                showDenyButton: true,
                denyButtonAriaLabel: "No",
                icon: "info",
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage(
                            'Transaction Password is required!'
                        )
                    }
                }
            }).then(result => {
                if (result.isConfirmed) {
                    const input: any = Swal.getInput()
                  //console.log(input.value)
                    const drawWinNumbers: any = []
                    Object.keys(this.state.selectedType1).map((values1: any, ind: any) => {
                        {
                            drawWinNumbers[ind] = this.state.selectedType1[values1]
                        }
                    })
                    if (input.value != "" && input.value != null) {
                        this.changeStatusCall(this.state.fields.drawReferrenceId, val, input.value,this.state.fields.drawGameId,drawWinNumbers)                        
                        
                    } else {
                      //console.log("asd")
                    }
                } else {
                    val = ""
                }
            })
        } else {
            const dataMessage = "Are you sure to " + val + " the Result?"
            Swal.fire({
                title: dataMessage,
                timer: 10000,
                confirmButtonText: "Yes",
                showDenyButton: true,
                denyButtonAriaLabel: "No",
                icon: "info"
            }).then(result => {
                if (result.isConfirmed) {
                    const drawWinNumbers: any = []
                    Object.keys(this.state.selectedType1).map((values1: any, ind: any) => {
                        {
                            drawWinNumbers[ind] = this.state.selectedType1[values1]
                        }
                    })
                        this.changeStatusCall(this.state.fields.drawReferrenceId, val) 
                } else {
                    val = ""
                }
            })
        }
    }
    
    changeStatusCall = (drawId: any, value: any, trans: any = "", drawGameId:any ="", winNumber:any="") => {
        let searchCondition: any
        if (trans == "") {
            searchCondition = {
                drawId: drawId,
                drawApproveStatus: value
            }
        } else {
            searchCondition = {
                drawId: drawId,
                drawApproveStatus: value,
                transactionPassword: trans
            }
        }
      //console.log(searchCondition)
        const response = this.DrawManageServices.drawResultStatusChange(searchCondition)
        response
            .then((changeStatusDrawResponse: any) => {
                if (!changeStatusDrawResponse.errorStatus) {
                    if(value == "approved"){
                        this.DataService.createSocketConnWithGame(drawGameId)
                        const request = {
                            "data": {
                                "gameId": drawGameId,
                                "mainResult": this.state.mainResult,
                                "subResult": this.state.subResult,
                                // "drawResult": this.state.drawResultApprove,
                                "drawId": drawId
                            },
                            "type": "DrawWinResultRequest"
                        }
                      //console.log(request)
                        this.DataService.sendDrawResultRequest(request)
                    }
                    this.uiData.showToastMessage(changeStatusDrawResponse.message, "success")
                    document.getElementById('reset_form')?.click()
                    // this.searchFunction("search")
                    // this.handleDrawSearch(event);
                } else {
                    this.uiData.showToastMessage(changeStatusDrawResponse.message, "error")
                }                
            })
            .catch((err: any) => {
              //console.log("handleDrawSearch", err)
                this.uiData.updateShowLoading(false)
            })
    }
    dataCheckedData:any = [];
    dataChecked = 0;
    dataCheckedDataType2:any = [];
    dataCheckedType2 = 0;
    dataCheckedDataType4:any = [];
    dataCheckedType4 = 0;
    handleSelectType4Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType4.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedDataType4.push(value)           
        }else{
            const index = this.dataCheckedDataType4.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedDataType4.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType4:this.dataCheckedDataType4,
            selectedType4Error:"",
        })
    }
    handleSelectType1Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType1.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedData.push(value)           
        }else{
            const index = this.dataCheckedData.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedData.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType1:this.dataCheckedData,
            selectedType1Error:"",
        })
    }
    handleSelectType2Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType2.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedDataType2.push(value)           
        }else{
            const index = this.dataCheckedDataType2.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedDataType2.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType2:this.dataCheckedDataType2,
            selectedType2Error:"",
        })
    }
    handleSelectType3Function(e:any,minNumber:any,maxNumber:any){
      //console.log(e)
        if(e.target.value > maxNumber){
            e.preventDefault();
        }else{
            this.setState({
                selectedType3:e.target.value
            })            
        }
        if(e.target.value.length < minNumber.length){
          //console.log(e.target.value.length)
            this.setState({
                selectedType3:""
            })   
        }else{
            this.setState({
                ...this.state,
                selectedType3:e.target.value,
                selectedType3Error:"",
            })            
        }
    }
    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""        
        const currentYear = date.getFullYear()
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay}`);
        this.setState({
            fields:{
                ...this.state.fields,
                fromDate:date,
            },
            errors:{
                ...this.state.errors,
                fromDateError:"",
            },
            fromDateSearch:finalDate
        })
        const getDrawList = {
            "drawGameId":this.state.fields.drawGameId,
            "drawDate":finalDate
        };
        if(this.state.fields.drawGameId != ""){
            this.uiData.updateShowLoading(true)
            const response = this.DrawManageServices.getAllDrawListApprove(getDrawList)
            response.then((getGameListResponse: any) => {
                if (!getGameListResponse.errorStatus) {
                  //console.log(getGameListResponse.data)
                    const dataArray:any = [];
                    getGameListResponse.data.map((val:any,key:any)=>{
                        const arrayIn:any = [];
                        arrayIn['id']=val.drawNumber;
                        arrayIn['name']=val.drawStartTime+" ("+val.drawNumber+")";
                        dataArray.push(arrayIn)
                    })
                    this.createDrawsFields[""][2].options = dataArray
                    this.setState({companyList: dataArray})
                    this.closeLoader()
                }
                this.closeLoader()
            }).catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
        }else{
            this.setState({
                errors:{
                    drawGameIdError:'Please Select Game'
                }
            })
        }
    }
   
    handlecreateDrawInputChange = (e: any) => {
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        
        if(e.target.name == "drawGameId"){
            this.setState({
                fields:{
                    ...this.state.fields,
                    fromDate:"",
                    drawReferrenceId:"",
                    [e.target.name]: value
                },
                errors:{
                    ...this.state.errors,
                    fromDateError:"",
                    drawReferrenceIdError:"",
                    [e.target.name + "Error"]: ""
                },
                fromDateSearch:""
            })
        }else{
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [e.target.name]: value
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
       
    }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getAllMinigames(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                  //console.log(getGameListResponse.data)
                    this.createDrawsFields[""][0].options = getGameListResponse.data
                    this.setState({companyList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }
    handlesearchFunction(event:any) {  
        event.preventDefault()   
        
    this.dataCheckedData = [];
    this.dataChecked = 0;  
    this.dataCheckedDataType2 = [];
    this.dataCheckedType2 = 0;
        this.setState({
            // ...this.state,            
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Required:false,
            selectedType2Required:false,
            selectedType3Required:false,
            selectedType4Required:false,
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
            drawSearchResult:[],
            showSearchList:false,
        })
        if (this.validateDrawResultForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                drawGameId: this.state.fields.drawGameId,
                drawNumber:this.state.fields.drawReferrenceId,
                drawDate:this.state.fromDateSearch, 
            }
          //console.log(searchCondition)
            const response = this.DrawManageServices.getDrawResultDetail(searchCondition)
            response
                .then((searchDrawResponse: any) => {
                    if (!searchDrawResponse.errorStatus) {
                            const data = searchDrawResponse.data['gameDetail'];
                            const drawDetailResult = searchDrawResponse.data['drawDetail']; 
                            const drawWinNumber = searchDrawResponse.data['drawDetail']['drawWinNumber']; 
                            // console.log(drawWinNumber);
                            const mainResult = JSON.parse(drawWinNumber)[0]['data']['mainResult']; 
                            const subResult = JSON.parse(drawWinNumber)[0]['data']['subResult']; 
                            if(drawDetailResult != "" && drawDetailResult != null){
                                const drawDetail = searchDrawResponse.data['drawDetail']['result']; 
                                // console.log(drawDetailResult); return;
                                for(let i=0;i<drawDetail.length;i++){
                                    if(drawDetail[i].type == 1){
                                        this.setState({...this.state,selectedType1:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedData = drawDetail[i]['winNumber']
                                    }
                                    if(drawDetail[i].type == 2){
                                        this.setState({...this.state,selectedType2:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedDataType2 = drawDetail[i]['winNumber']
                                    }
                                    if(drawDetail[i].type == 3){
                                        this.setState({...this.state,selectedType3:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                    }
                                    if(drawDetail[i].type == 4){
                                        this.setState({...this.state,selectedType4:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedDataType4 = drawDetail[i]['winNumber']
                                    }
                                }
                            }
                            for(let i=0;i<data.length;i++){
                                if(data[i].type == 1){
                                    this.setState({...this.state,selectedType1Length:data[i].length,selectedType1Required:true}); 
                                }
                                if(data[i].type == 2){
                                    this.setState({...this.state,selectedType2Length:data[i].length,selectedType2Required:true}); 
                                }
                                if(data[i].type == 3){
                                    this.setState({...this.state,selectedType3Length:data[i].maxLength,selectedType3Required:true}); 
                                }
                                if(data[i].type == 4){
                                    this.setState({...this.state,selectedType4Length:data[i].length,selectedType4Required:true}); 
                                }
                            }
                            this.setState({
                                drawSearchResult:data,
                                showSearchList:true,
                                drawResultApprove:drawWinNumber,
                                subResult:subResult,
                                mainResult:mainResult
                            })
                           
                        this.closeLoader()
                    } else {
                        this.setState({
                            drawSearchResult:[],
                            showSearchList:false,
                            drawResultApprove:"",
                            subResult:[],
                            mainResult:[]
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    this.setState({
                        drawSearchResult:[],
                        showSearchList:false,
                    })
                    this.closeLoader()
                })
        }else{            
            this.setState({
                DrawSearchData: [],
                showDrawResult: false,
            })
            this.closeLoader()
        }
    }
    closeLoader(){        
        this.uiData.updateShowLoading(false)
    }
    render() {
        let tabIndex = 1
        const mainResultData = this.state.mainResult
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>Approve Result</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.createDrawsFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div className="w-100" key={values}>
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.createDrawsFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                           return (
                                                                <div
                                                                    className={classes["sub-div"]}
                                                                    key={fieldValIndex}
                                                                >
                                                                    <div>
                                                                        <label
                                                                            htmlFor={
                                                                                fieldValObject.id
                                                                            }
                                                                        >
                                                                            {fieldValObject.label}
                                                                        </label>
                                                                        {fieldValObject.required ? (
                                                                            <span
                                                                                className={
                                                                                    classes[
                                                                                        "mandatory-symbol"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                *{" "}
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <InputBox
                                                                            id={fieldValObject.id}
                                                                            autoFocus={fieldValObject.autoFocus}
                                                                            autoComplete="off"
                                                                            maxLength={
                                                                                fieldValObject.maxLength
                                                                            }
                                                                            min={fieldValObject.min}
                                                                            max={fieldValObject.max}
                                                                            placeholder={
                                                                                fieldValObject.placeHolder
                                                                            }
                                                                            className={
                                                                                "form-control " +
                                                                                classes[
                                                                                    fieldValObject
                                                                                        .class
                                                                                ]
                                                                            }
                                                                            value={
                                                                                fieldValObject.type !=
                                                                                "file"
                                                                                    ? this.state
                                                                                          .fields[
                                                                                          fieldValObject
                                                                                              .name
                                                                                      ]
                                                                                    : ""
                                                                            }
                                                                            name={
                                                                                fieldValObject.name
                                                                            }
                                                                            type={
                                                                                fieldValObject.type
                                                                            }
                                                                            tabIndex={tabIndex++}
                                                                            required={
                                                                                fieldValObject.required
                                                                            }
                                                                            onChange={
                                                                                fieldValObject.type === "date"
                                                                                        ? this.handleStartDateChange             
                                                                                    : this.handlecreateDrawInputChange
                                                                            }
                                                                            dateFormat={fieldValObject.dateFormat}
                                                                            startMaxDate={this.maxDate}
                                                                            endMinDate={this.minDate}
                                                                            label1={
                                                                                fieldValObject.label1
                                                                            }
                                                                            options={
                                                                                fieldValObject.type ==
                                                                                    "select" ||
                                                                                fieldValObject.type ==
                                                                                    "radio"
                                                                                    ? fieldValObject.options
                                                                                    : ""
                                                                            }
                                                                            selectShowEmpty={
                                                                                fieldValObject.selectShowEmpty
                                                                            }
                                                                        />
                                                                        <div>
                                                                            {fieldValObject.type ==
                                                                                "file" &&
                                                                                this.state.fields[
                                                                                    fieldValObject.name +
                                                                                        "Val"
                                                                                ]}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                classes["error"]
                                                                            }
                                                                        >
                                                                            {
                                                                                this.state.errors[
                                                                                    fieldValObject.name +
                                                                                        "Error"
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Search"
                                        onClick={() => this.handlesearchFunction(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleDrawResultReset()}
                                    />
                                </div>
                            </div>
                        </form>
                        <>
                        <div className="approve_res_box">
                            {this.state.showSearchList &&
                            <div>
                                {mainResultData.length > 0 &&
                                    <>
                                        <div className="approve_res_box_1" >
                                            <div className={"mt-0 "+classes["pick-number"]}>
                                                Confirm Main Result
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                {mainResultData.map((item:any) => 
                                                    <div className="draw-res-chk-bx text-center"  key={item.id}>
                                                        <div className={classes["box-class"]} >
                                                            {item}
                                                        </div>                                                 
                                                    </div>
                                                )}   
                                            </div>
                                        </div>
                                    </> 
                                }
                                {this.state.subResult && this.state.subResult.length > 0 && 
                                    <div className="approve_res_box_1" >
                                        <div className={"mt-0 "+classes["pick-number"]}>
                                            Confirm Sub Result
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {this.state.subResult.map((item:any) => 
                                                <div className="draw-res-chk-bx text-center"  key={item.id}>
                                                    <div className={classes["box-class"]} >
                                                        {item}
                                                    </div>                                                 
                                                </div>
                                            )}   
                                        </div>
                                    </div>
                                }
                                </div>
                            }
                         </div>
                         </>
                         {this.state.showSearchList && 
                         <div className={"justify-content-center "+classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="update_result_form"
                                        className="form-control"
                                        type="submit"
                                        value="Approve"
                                        onClick={() => this.handleApproveFunction(event,'approved')}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reject_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reject"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleApproveFunction(event,'reject')}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}
