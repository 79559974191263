import React, {Component} from "react"
import classes from "../../../configuration/style/base/screens/limitcontrol/partnerlimitcontrol.module.scss"
import {LedgerServices} from "../../../../game_core/type_script/services/ledger"
import {UIDataService} from "../../../../game_core/type_script/ui-data-service"
import {InputBox} from "../../common/elements/inputbox"
import {Link} from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactPaginate from 'react-paginate';
import { StaticKey } from "../../../../game_core/type_script/static_key"
import { TotalRecords } from "../../common/totalRecords"
import { PrintTableElement } from "../../common/PrintTableElement"
import { ExportExcelReport } from "../../downlinemanagement/ExportExcelReport"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`
export class Ledger extends Component {
    LedgerServices = LedgerServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    itemOffset = 0;
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Date","Name","Login ID","Old Limit","In","Out","New Limit","From/To - ID","Processed By"],
        tableBody:["CREATED_DATE","PARTNER_USER_NAME","loginId","CURRENT_TOT_BALANCE","IN_AMOUNT","OUT_AMOUNT","CLOSING_TOT_BALANCE","ledgerTransfer","PROCESSED_BY"],
        exportHead:["Date","Name","Login ID","Old Limit","In","Out","New Limit","From/To - ID","Processed By"],
        exportBody:["CREATED_DATE","PARTNER_USER_NAME","loginId","CURRENT_TOT_BALANCE","IN_AMOUNT","OUT_AMOUNT","CLOSING_TOT_BALANCE","ledgerTransfer","PROCESSED_BY"],
        searchFilterRow:[0,1],
        fromPagePagination:1,
        toPagePagination:5,
        noRow:25,
        totalCount:0,
        totalCountPagination:[],
        currentPage:1,
        previousPage:0,
        nextPage:2,
        lastPage:0,
        username: "",
        fromDate: "",
        toDate: "",
        fromDateSearch: "",
        toDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        processedBy: "",
        transactionId: "",
        toDateError: "",        
        showLedgerSearchListing: false,
        fields: {
            "fromDate": "",
            "toDate": "",
            "username": "",
            "processedBy": "",
            "transactionId": "",
            "limit":25,
            "page":1,
        },
        errors: {
            "toDateError": "",
            "fromDateError": "",
            "usernameError": ""
        },
        LedgerSearchData: []
    }

    createLedgerSearchFields: any = [
        {
            rowId: 0,
            name: "username",
            type: "text",
            label: "Player Name",
            // placeHolder: "Username",
            maxLength: "50",
            autoFocus: true,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId: 1,
            "name": "fromDate",
            "type": "datetime",
            "label": "Start Date",
            "placeHolder": "Start Date",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"       
        },
        {
            
            rowId: 1,
            "name": "toDate",
            "id": "toDate",
            "type": "datetime",
            "label": "End Date",
            "placeHolder": "End Date",
            "maxLength": currentDate,
            // "disabled": true,
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId: 0,
            "name": "transactionId",
            "id": "transactionId",
            "type": "text",
            "label": "Transaction Id",
            // "placeHolder": "Transaction Id",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
        },
        {            
            rowId: 0,
            "name": "processedBy",
            "id": "processedBy",
            "type": "text",
            "label": "Processed By",
            // "placeHolder": "Processed By",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
        },        
        {
            rowId: 1,
            name: "dateRange",
            type: "select",
            label: "Date Range",
            // placeHolder: "Date Range",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            defaultValue: "",
            required: false,
            options: StaticKey.DATERANGE
            // showEmpty: true
        }
    ];
    
  endOffset = this.itemOffset + 5;
  currentItems = this.state.totalCountPagination.slice( this.itemOffset,  this.endOffset);
  pageCount = Math.ceil( this.state.totalCountPagination.length / 5);

  handlePageClick(event:any){
    const newOffset = (event.selected *  this.state.noRow) % this.items.length;
    this.itemOffset=newOffset;
  //console.log(event);
  }
    componentDidMount(): void {
        this.handleSetDateFunction();
    }
    handleSetDateFunction(){
        
        const date = new Date()
        const currentDay = String(date.getDate()).padStart(2, "0")
        const lastDay = String(date.getDate()).padStart(2, "0")
        const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        const  lastMonth = String(date.getMonth() + 1).padStart(2, "0")
       
        const currentDateNew =  new Date(`${currentYear}-${currentMonth}-${currentDay} 00:00:00`)
        const currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
        this.setState({
            LedgerSearchData: [],
            showLedgerSearchListing: false,                       
            totalCount:0,
            totalCountPagination:[],
            currentPage:1,
            previousPage:0,
            nextPage:2,
            lastPage:0,            
            fromPagePagination:1,
            toPagePagination:5,
            fromDate: currentDateNew,
            fromDateSearch: (
                `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
            ),
            toDate: currentEndDateNew,
            toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
            fields: {
                ...this.state.fields,
                fromDate: currentDateNew,
                toDate: currentEndDateNew
            }
        })
    }
    handleSearchLedgerSearchReset = () => {
        this.setState({
            // username: "",
            // accountStatus: "",
            LedgerSearchData: [],
            showLedgerSearchListing: false,
            totalCount:0,
            totalCountPagination:[],
            currentPage:1,
            previousPage:0,
            nextPage:2,
            lastPage:0,            
            fromPagePagination:1,
            toPagePagination:5,
        });
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createLedgerSearchFields).map((keys: any, values: any) => {
            {
                this.setState({[this.createLedgerSearchFields[keys].name]:""});
                return this.createLedgerSearchFields.map(
                    (fieldValObject: any, fieldValIndex: any) => {
                            this.createLedgerSearchFields[2].required = false;
                            this.createLedgerSearchFields[2].min = "";
                            // this.createLedgerSearchFields[2].type = "text";
                            document.getElementById("toDate")?.setAttribute("readonly", "readonly")
                            return (
                                (newArrayError[fieldValObject.name + "Error"] = ""),
                                (newArrayFields[fieldValObject.name] = ""),
                                (newArrayFields[fieldValObject.name + "Val"] = "")
                                
                            )
                    }
                )
            }
        })
        
        this.setState({errors: newArrayError, fields: newArrayFields})
        this.handleSetDateFunction();        
    }
    validateLedgerSearchForm = () =>{
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createLedgerSearchFields).map((keys: any) => {
            {
                return this.createLedgerSearchFields.map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "" ) {
                        flagError[fieldValObject.name + "Error"] = true
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            fieldValObject.label + " is required")
                    }else{
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
        this.setState({
            fromDate: date,
            fromDateSearch:finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            toDate: eDate,
            toDateSearch:finalDate
        })
    }
    ledgerSearch(param:any){
        if(param == 'search'){
            this.setState({
                ...this.state,fields:{
                ...this.state.fields,page:1}})
                this.setState({
                    // username: "",
                    // accountStatus: "",
                    LedgerSearchData: [],
                    showLedgerSearchListing: false,
                    totalCount:0,
                    totalCountPagination:[],
                    currentPage:1,
                    previousPage:0,
                    nextPage:2,
                    lastPage:0,            
                    fromPagePagination:1,
                    toPagePagination:5,
                });
        }
        this.uiData.updateShowLoading(true)
        if (this.validateLedgerSearchForm()) {
            const searchCondition = {
                username: this.state.fields.username,
                toDate:this.state.toDateSearch,
                fromDate:this.state.fromDateSearch,                
                transactionId:this.state.fields.transactionId,                
                processedBy:this.state.fields.processedBy,                
                page:this.state.fields.page-1,
                length:this.state.fields.limit
            }
          //console.log(searchCondition)
            const response = this.LedgerServices.getLedgerSearch(searchCondition)
            response
                .then((searchLedgerSearchResponse: any) => {
                    if (!searchLedgerSearchResponse.errorStatus) {
                        this.setState({
                            LedgerSearchData: searchLedgerSearchResponse.data,
                            showLedgerSearchListing: true,
                            totalCount: searchLedgerSearchResponse.totalRecords,                               
                            // fromPagePagination:1,
                            // toPagePagination:5,
                        })
                        const data =[];
                        
                        for(let i =0;i<searchLedgerSearchResponse.totalRecords/this.state.noRow;i++){
                            data.push(i);
                        }
                        const divData = searchLedgerSearchResponse.totalRecords/this.state.noRow;
                        let lastpage = divData;
                        if(divData.toString().includes('.')){
                            const SplitData = divData.toString().split('.');
                            lastpage = parseInt(SplitData[0])+1;
                        }
                        const dataResponse = searchLedgerSearchResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                const value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})                     
                        this.currentItems = data.slice( this.itemOffset,  this.endOffset);
                        this.pageCount = Math.ceil( data.length / 5);
                        this.closeLoader()
                    } else {
                        this.setState({
                            LedgerSearchData: [],
                            showLedgerSearchListing: false,
                            totalCount:0,
                            totalCountPagination:[],
                            currentPage:1,
                            previousPage:0,
                            nextPage:2,
                            lastPage:0,            
                            fromPagePagination:1,
                            toPagePagination:5,
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                  //console.log("handleLedgerSearchError", err)
                    this.uiData.updateShowLoading(false)
                    this.setState({
                        LedgerSearchData: [],
                        showLedgerSearchListing: false,
                        totalCount:0,
                        totalCountPagination:[],
                        currentPage:1,
                        previousPage:0,
                        nextPage:2,
                        lastPage:0,            
                        fromPagePagination:1,
                        toPagePagination:5,
                    })
                    this.closeLoader()
                })
            }
    }
    closeLoader(){                      
        // setTimeout(()=>{
            this.uiData.updateShowLoading(false)
        // },500)
    }
    handleLedgerSearch = (e: any) => {
        e.preventDefault()        
        this.ledgerSearch('search');
    }

    handlePagination(e:any,page:any){
        this.setState(
            {...this.state,currentPage:page,previousPage:page-1,nextPage:page+1,
                fields:{...this.state.fields,page:page}
            }            
            )
            const nPage = page+1
            let fromPage = nPage - 2 > 1 ? nPage - 2 :  page; 
            let toPage =  page == this.state.lastPage ? this.state.lastPage : nPage + 2; 
            if(page <= 2){
                fromPage = 0;
                toPage = 5;
                this.setState({
                    fromPagePagination:fromPage,
                    toPagePagination:toPage
                   }) 
            }else if(page == this.state.lastPage || page == this.state.lastPage-1){
                this.setState({
                    fromPagePagination:this.state.lastPage-4,
                    toPagePagination:this.state.lastPage,
                   })
            }
            else{
                this.setState({
                    fromPagePagination:fromPage-1,
                    toPagePagination:toPage-1,
                   }) 
            }
        setTimeout(()=>{
            this.ledgerSearch('pagination');
        },50)
    }
    retailerLimitControlFilterChange = (e: any) => {
        const value = e.target.value
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            }
        })
        this.setState({[e.target.name]:value});
        if (this.state[e.target.name + "Callback"]) {
            if(e.target.name == "fromDate"){        
                this.setState({toDate:""})
                this.createLedgerSearchFields[2].min = value
                this.createLedgerSearchFields[2].type = "datetime-local"
                // this.createLedgerSearchFields[2].required = true
                document.getElementById('toDate')?.removeAttribute('readonly');
            }
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
    }
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
         a.print();
 setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    render() {
        return (
            <div className={classes["right-section"]}>
                <div className="error-msg-section"></div>
                <div className={classes["main-div"]}>
                    <h2>Ledger</h2>
                    <form>
                        {this.state.searchFilterRow.map((valRow:any,i:any)=>{
                        return (
                        <div className={classes["main-div-input-sec"]}  key={i}>
                            {this.createLedgerSearchFields.map((val: any, ind: any) => {
                                if(val.rowId == valRow){
                                return (
                                    <div className={classes["sub-div"]} key={ind}>
                                        <label htmlFor="name">{val.label}</label>
                                        {val.required ? (<span className={ classes[  "mandatory-symbol"] } > * </span>) : ( "" )}
                                        <InputBox
                                            id={val.id}
                                            autoComplete={val.autoComplete}
                                            maxLength={val.maxLength}
                                            className={"form-control " + classes[val.class]}
                                            autoFocus={val.autoFocus}
                                            name={val.name}
                                            type={val.type}
                                            tabIndex={1}
                                            value={this.state[val.name]}
                                            required={val.required}
                                            placeholder={val.placeHolder}
                                            min={val.min}
                                            options={val.options}
                                            selectShowEmpty={val.showEmpty}
                                            onChange={
                                                val.type === "datetime"
                                                    ? val.name == "fromDate"
                                                        ? this.handleStartDateChange
                                                        : this.handleEndDateChange
                                                    : this.retailerLimitControlFilterChange
                                            }
                                            dateFormat={val.dateFormat}
                                            startMaxDate={this.maxDate}
                                            endMinDate={this.minDate}
                                        />
                                        <div className={classes["error"]}>
                                            {this.state.errors[val.name + "Error"]}
                                        </div>
                                    </div>
                                )
                                }
                            })}
                        </div>
                        )})}
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="submit"
                                    value="Search"
                                    onClick={() => this.handleLedgerSearch(event)}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="reset"
                                    value="Reset"
                                    onClick={() => this.handleSearchLedgerSearchReset()}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {this.state.showLedgerSearchListing && (
                    <>            
                    <div className="d-flex">
                        <TotalRecords totalCount={this.state.totalCount}/>
                        {this.state.totalCount > 0 ? (
                            <>
                                <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                            </>
                        ):""}
                    </div>
                    <div className={"table-responsive "+classes["main-div"] + " " + classes["table-main-div"]} style={{maxHeight:"400px"}}>
                        {/* Total Records :  {this.state.LedgerSearchData.length} */}
                        <Table
                            className={
                                 classes["text-whiteSpace"] + " "+"table table-bordered table-hover table-condensed" +
                                classes["table"] +
                                " " +
                                classes["table-bordered"] +
                                " " +
                                classes["table-hover"] +
                                " " +
                                classes["table-condensed"]
                            }
                            id="provider_table_id"
                        >
                            <Thead>
                                <Tr>
                                    <Th>Sl.No</Th>
                                    <Th>Date</Th>
                                    {/* <Th>Trans.Id</Th> */}
                                    <Th>Name</Th>
                                    <Th>Login ID</Th>
                                    <Th>Old Limit</Th>
                                    <Th>In</Th>
                                    <Th>Out</Th>
                                    <Th>New Limit</Th>
                                    <Th>From/To - ID</Th>
                                    <Th>Processed By</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                            {this.state.LedgerSearchData.length > 0 ?  
                                    <> 
                                     {this.state.LedgerSearchData.map((val: any, ind: any) => {
                                     let oddEven ="odd";
                                     if(ind%2 == 0){
                                         oddEven = "even"
                                     }
                                     return (
                                         <Tr className={classes["tr_"+oddEven]} key={ind}>
                                            <Td>{((this.state.currentPage-1)*this.state.noRow)+ind+1}</Td>
                                            <Td>{val.CREATED_DATE}</Td>
                                            {/* <Td>{val.INTERNAL_REFERENCE_NO}</Td> */}
                                            <Td>{val.PARTNER_USER_NAME}</Td>
                                            <Td>{val.loginId ? val.loginId : "-"}</Td>
                                            <Td className={classes["text-right"]}>
                                                <div>{val.CURRENT_TOT_BALANCE}</div>
                                            </Td>
                                            <Td className={classes["text-right"]}>
                                                <div>{val.IN_AMOUNT}</div>
                                            </Td>
                                            <Td className={classes["text-right"]}>
                                                <div>{val.OUT_AMOUNT}</div>
                                            </Td>
                                            <Td className={classes["text-right"]}>
                                                <div>{val.CLOSING_TOT_BALANCE}</div>
                                            </Td>
                                            <Td>{val.ledgerTransfer ? val.ledgerTransfer : "-"}</Td>
                                            <Td>{val.PROCESSED_BY}</Td>
                                        </Tr>
                                    )
                                })}</> :
                                <Tr className={classes["no_data_found"]}>
                                    <Td colSpan={11}>No record(s) available</Td>
                                </Tr>
                            }
                            </Tbody>
                        </Table>
                       
                    </div> 
                    <div>               
                        {this.state.totalCountPagination.length > 0 ?  (
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.LedgerSearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/> {this.state.excelExport && (
                                    <ExportExcelReport mockData={this.state.mockData} sheetName="limit_details"/> 
                                )}
                            </div>
                        ) : ""}
                            
                        <div className={"table_pagination_div"}>                    
                        {this.state.totalCountPagination.length > 0 ?  
                            <> 
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == 1? true : false} onClick={(event)=>this.handlePagination(event,1)}>First</button>
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == 1? true : false} onClick={(event)=>this.handlePagination(event,this.state.previousPage)}><i className="fa fa-angle-double-left"></i> Previous</button>
                                {this.state.totalCountPagination.map((val: any, ind: any) => {
                                    let btnClass = "";
                                    if(ind+1 >= this.state.fromPagePagination && ind+1 <= this.state.toPagePagination){
                                        if(this.state.currentPage == ind+1){
                                            btnClass = "active";
                                            }
                                        return(
                                            <>
                                                <button className={classes['buttonClass'] + " " +classes[btnClass]} onClick={(event)=>this.handlePagination(event,ind+1)}>{ind+1}</button>
                                            </>
                                        )
                                    }

                                })}
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == this.state.lastPage? true : false} onClick={(event)=>this.handlePagination(event,this.state.nextPage)}>Next <i className="fa fa-angle-double-right"></i></button>
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == this.state.lastPage? true : false} onClick={(event)=>this.handlePagination(event,this.state.lastPage)}>Last</button>
                            </> :
                                <></>
                        }
                        </div>
                        </div>
                        </>
                )}
            </div>
        )
    }
}
