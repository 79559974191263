import {EventEmitter} from "eventemitter3"
import {Ajax, REQUEST_TYPE} from "@digienttech/typescript-core/lib/core/ajax"
// import { getUniqueId, getMacId } from '../react_native/utils';
import {LOADER_ANIMATE_EVENT} from "./event-emit-actions"
import {StaticKey} from "./static_key"
import {environment} from "./environment"
import {DataStorage} from "./services/localstorage"
export class HTTPConnectionDatasService extends EventEmitter {
    static classIns: any = {}
    dataStorage = new DataStorage()
    ajaxInstance: Ajax
    ajaxInstanceId: any = ""
    authtoken: any = ""
    uniqueucid: any = ""
    environmentapipath: any = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`

    generatedData: any = {
        userAgent: "",
        ucid: "", //Need to update
        channel: 1,
        ipAddress: ""
    }

    constructor(_instanceID: string) {
        super()
        this.ajaxInstance = Ajax.getInstance(_instanceID)
        this.uniqueucid = this.generatedData["ucid"]
    }
    static getInstance(_instanceID: any = "default"): any {
        if (!HTTPConnectionDatasService.classIns[_instanceID]) {
            return (HTTPConnectionDatasService.classIns[_instanceID] =
                new HTTPConnectionDatasService(_instanceID))
        } else {
            return HTTPConnectionDatasService.classIns[_instanceID]
        }
    }

    onOFFLoader(flag = false) {
        StaticKey.GLOBAL_EMITTER.emit(LOADER_ANIMATE_EVENT, flag)
    }
    sendHttpRequest = (
        requestType?: any,
        requestURL?: any,
        paramsData?: any,
        header?: any,
        isSkipXRequestType: any = true
    ) => {
        const loginData = this.dataStorage.getLoginData("loginData")
        if (loginData && typeof loginData.sessionId !== "undefined") {
            this.authtoken = loginData.sessionId
        }
        return new Promise((resolve, reject) => {
            const headers: any = {}
            const generatedData: any = {
                userAgent: "",
                ucid: "stringucid",
                channel: "1",
                ipAddress: ""
            }
            for (const key in paramsData) {
                if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
                    generatedData[key] = paramsData[key]
                }
            }
            for (const key in header) {
                if (Object.prototype.hasOwnProperty.call(header, key)) {
                    headers[key] = header[key];
                }
            }
            this.ajaxInstance
                .sendHTTPRequest(
                    requestURL,
                    generatedData,
                    headers,
                    // (headers = Object.assign({
                    //     "Content-Type": header != "" ?  "multipart/form-data" : "application/json",                        
                    //     //"Authorization": `Bearer ${this.authtoken}`
                    // },generatedData["headers"]),
                    // console.log("header data  ",headers)),
                    REQUEST_TYPE.POST,
                    false,
                    false,
                    false,
                    isSkipXRequestType,
                    {withCredentials: true}
                )
                .then(
                    (respObj: any) => {
                        this.onOFFLoader(false)
                        resolve(respObj.message)
                    },
                    (errorObj: any) => {
                        console.log("errorObj:", errorObj)
                        this.onOFFLoader(false)
                        reject(errorObj)
                    }
                )
        })
    }
}
