import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/retailerlisting.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {LimitControlServices} from "../../../game_core/type_script/services/limitcontrol"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {DataStorage} from "../../../game_core/type_script/services/localstorage"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {TotalRecords} from "../common/totalRecords"
import {Link} from "react-router-dom"
import {PrintTableElement} from "../common/PrintTableElement"
import {ExportExcelReport} from "./ExportExcelReport"
import Swal from "sweetalert2"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`
export class Invoice extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    LimitControlServices = LimitControlServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage()
    dataArray: any = []
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        
        printDistName : "",                        
        printDistAddress: "",                       
        printDisGSTIN: "",     
        printRetailerName: "",
        printRetailerCode: "",
        printRetailerAddress: "",
        printDate: "",
        printInvoiceNo: "",
        printGameData: [],
        sheetName: "",
        excelExport: false,
        mockData: [{}],
        tableHead: [],
        tableBody: [],
        exportHead: [],
        exportBody: [],
        partnerTypeId: 0,
        btnDisabled: true,
        pendingStatus: false,
        submitedUserGuid: [],
        LoginpartnerTypeId: "",
        searchFilterRow: [0, 1],
        fromPagePagination: 1,
        toPagePagination: 5,
        verifyPopupShow: false,
        installPopupShow: false,
        noRow: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        userType: "",
        loginId: "",
        accountStatus: "",
        dateRange: "",
        fromDate: "",
        fromDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        toDate: "",
        toDateSearch: "",
        companyNameCallback: true,
        toDateError: "",
        showRetailerListing: false,
        retailerSearchData: [],
        verifyUser: "",
        verifyUserError: "",
        installUser: "",
        installUserError: "",
        distributorCode: "",
        distributorGuid: "",

        userTypeSearch: "",
        fields: {
            "accountStatus": "",
            "dateRange": "",
            "companyName": "",
            "distributorName": "",
            "retailerName": "",
            "toDate": "",
            "fromDate": "",
            "userType": "",
            "userTypeSearch": "",
            "loginId": "",
            "limit": 25,
            "page": 1
        },
        errors: {
            "accountStatusError": "",
            "toDateError": "",
            "fromDateError": "",
            "userTypeError": "",
            "companyNameError": "",
            "distributorNameError": "",
            "retailerNameError": "",
            "loginIdError": ""
        }
    }
    setLimitFields: any = {
        "": [
            {
                rowId: 0,
                name: "userType",
                id: "userType",
                type: "select",
                label: StaticKey.LOCALE_DATA.invoice.userType,
                autoFocus: true,
                class: "form-control",
                required: true,
                style: {},
                selectShowEmpty: true,
                options: {},
                dnone: false
            },
            {
                rowId: 0,
                name: "companyName",
                id: "companyName",
                type: "select",
                label: StaticKey.LOCALE_DATA.invoice.companyName,
                autoFocus: true,
                class: "form-control",
                required: false,
                style: {},
                selectShowEmpty: true,
                options: {},
                dnone: true
            },
            {
                rowId: 0,
                name: "distributorName",
                id: "distributorName",
                type: "select",
                label: StaticKey.LOCALE_DATA.invoice.distributorName,
                autoFocus: false,
                class: "form-control",
                required: false,
                style: {},
                selectShowEmpty: true,
                options: {},
                dnone: true
            },
            {
                rowId: 0,
                name: "retailerName",
                id: "retailerName",
                type: "select",
                label: StaticKey.LOCALE_DATA.invoice.retailerName,
                autoFocus: false,
                class: "form-control",
                required: false,
                style: {},
                selectShowEmpty: true,
                options: {},
                dnone: true
            },
            {
                rowId: 1,
                "name": "fromDate",
                "id": "fromDate",
                "type": "datetime",
                "label": "From Date",
                // "placeHolder": "End Date",
                "maxLength": currentDate,
                // "disabled": true,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"
            },
            {
                rowId: 1,
                "name": "toDate",
                "id": "toDate",
                "type": "datetime",
                "label": "End Date",
                // "placeHolder": "End Date",
                "maxLength": currentDate,
                // "disabled": true,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"
            },
            {
                rowId: 1,
                name: "dateRange",
                type: "select",
                label: "Date Range",
                placeHolder: "Date Range",
                maxLength: "50",
                autoFocus: false,
                class: "form-control",
                defaultValue: "",
                required: false,
                options: StaticKey.DATERANGE,
                selectShowEmpty: true
            }
        ]
    }
    componentDidMount() {
        // this.getCompanyList({
        //     "partnerTypeId": 3
        // })
        const loginData = this.uiData.getLoginResponse()
        if (loginData.partnerTypeId == 0) {
            this.setLimitFields[""][0].options = StaticKey.userType
        }
        if (loginData.partnerTypeId == 3) {
            this.setLimitFields[""][0].options = StaticKey.userTypeComp
        }
        if (loginData.partnerTypeId == 5) {
            this.setLimitFields[""][0].options = StaticKey.userTypeDist
        }
    }

    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.setLimitFields[""][1].options = getCompanyListResponse.data
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    getDistributorsList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getDistributorsListDropdown(data)
        response
            .then((distributorListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!distributorListResponse.errorStatus) {
                    this.setLimitFields[""][2].options = distributorListResponse.data
                    this.setState({distributorList: distributorListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getDistributorsListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    validateCreatePartnerLimitSearchForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.setLimitFields[""]).map((keys: any) => {
            {
                return this.setLimitFields[""].map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state.fields[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true

                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        //console.log(newErrorMsgArray)
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })
        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        this.dataArray = []
        this.setState({btnDisabled: true})
        setTimeout(() => {
            this.handlePartnerSearchAjax("pagination")
        }, 50)
    }

    handleCreatePartnerLimitSearch = (e: any) => {
        e.preventDefault()
        this.handlePartnerSearchAjax("search")
    }
    handlePartnerSearchAjax(params: any) {
        if (params == "search") {
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
            this.setState({
                userTypeSearch: this.state.fields["userType"],
                // username: "",
                // accountStatus: "",
                retailerSearchData: [],
                showRetailerListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }
        if (this.validateCreatePartnerLimitSearchForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                userType: this.state.fields.userType,
                companyName: this.state.fields.companyName,
                distributorName: this.state.fields.distributorName,
                retailerName: this.state.fields.retailerName,
                accountStatus: this.state.accountStatus,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                loginId: this.state.fields.loginId,
                page: this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            }
            const storage: any = [
                this.state.fields.username,
                this.state.fields.companyName,
                this.state.fields.distributorName,
                this.state.accountStatus,
                this.state.toDate,
                this.state.fromDate,
                this.state.fields.loginId,
                this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                this.state.fields.limit,
                this.state.fields.dateRange,
                this.state.toDateSearch,
                this.state.fromDateSearch
            ]
            //console.log(storage)
            const string = JSON.stringify(storage)
            this.uiData.setRetailerBackList(false)
            this.localStorageService.removeSearchConditionLocalStorage()
            this.localStorageService.setSearchConditionLocalStorage(string)
            if (this.state.fields["accountStatus"] == "PENDING") {
                this.setState({pendingStatus: true})
            } else {
                this.setState({pendingStatus: false})
            }
            this.dataArray = []
            this.setState({btnDisabled: true})
            //console.log(searchCondition)
            const response = this.DownlineManageServices.getMethodCall(
                searchCondition,
                "getInvoiceList"
            )
            response
                .then((searchInvoiceResponse: any) => {
                    if (!searchInvoiceResponse.errorStatus) {
                        this.setState({
                            retailerSearchData: searchInvoiceResponse.data,
                            showRetailerListing: true,
                            totalCount: searchInvoiceResponse.totalRecords
                        })
                        const data = []

                        for (
                            let i = 0;
                            i < searchInvoiceResponse.totalRecords / this.state.noRow;
                            i++
                        ) {
                            data.push(i)
                        }
                        const divData = searchInvoiceResponse.totalRecords / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        }
                        const dataResponse = searchInvoiceResponse.data
                        const obj: any = []
                        for (let i = 0; i < dataResponse.length; i++) {
                            const obj1: any = {}
                            for (let j = 0; j < this.state.exportBody.length; j++) {
                                const keyDa = this.state.exportHead[j]
                                let value: any = dataResponse[i][this.state.exportBody[j]]
                                    ? dataResponse[i][this.state.exportBody[j]]
                                    : "-"
                                if (this.state.exportBody[j] == "retailerStatus") {
                                    value =
                                        StaticKey.RETAILERSTATUS[
                                            dataResponse[i][this.state.exportBody[j]]
                                        ].name
                                }
                                obj1[keyDa] = value
                            }
                            obj.push(obj1)
                        }
                        this.setState({
                            totalCountPagination: data,
                            lastPage: lastpage,
                            mockData: obj
                        })
                        this.closeLoader()
                    } else {
                        this.setState({
                            retailerSearchData: [],
                            showRetailerListing: false
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    //console.log("handleCreatePartnerLimitSearchErr", err)
                    this.closeLoader()
                    this.setState({
                        retailerSearchData: [],
                        showRetailerListing: false
                    })
                })
        } else {
            this.uiData.updateShowLoading(false)
        }
    }
    closeLoader() {
        this.uiData.updateShowLoading(false)
    }

    handlecustomerListReset = () => {
        this.setState({
            "userType": "",
            "companyName": "",
            "distributorName": "",
            "retailerName": "",
            "accountStatus": "",
            "loginId": "",
            "userTypeError": "",
            "companyNameError": "",
            "distributorNameError": "",
            "retailerNameError": "",
            "accountStatusError": "",
            "loginIdError": ""
        })
        document.getElementById("userType")?.focus()
        this.setLimitFields[""][1].required = false
        this.setLimitFields[""][2].required = false
        this.setLimitFields[""][3].required = false
        this.setLimitFields[""][1].dnone = true
        this.setLimitFields[""][2].dnone = true
        this.setLimitFields[""][3].dnone = true
    }

    handleInputChange = (e: any) => {
        e.preventDefault()
        this.uiData.updateShowLoading(true)
        const value = e.target.value

        // this.setState({
        //     ...this.state,
        //     fields:{...this.state.fields,[e.target.name]: value},
        //     errors:{...this.state.errors,[e.target.name + "Error"]: ""}
        // })

        if (e.target.name == "companyName" && this.state.userType != "company") {
            this.getDistributorsList({
                "partnerTypeId": 5,
                "companyId": value
            })
        }

        if (e.target.name == "distributorName" && this.state.userType == "retailer") {
            const data = {
                "partnerGuid": value
            }
            this.getRetailersList(data)
        }

        let tableHead: any = []
        let exportHead: any = []
        let sheetName: any = "customer_list"
        let tableBody: any = []
        if (e.target.name == "userType") {
            const loginData = this.uiData.getLoginResponse()
            this.setLimitFields[""][1].options = {}
            this.setLimitFields[""][2].options = {}
            this.setLimitFields[""][3].options = {}
            this.setState({
                "companyName": "",
                "distributorName": "",
                "retailerName": "",
                fields: {
                    "companyName": "",
                    "distributorName": "",
                    "retailerName": ""
                },
                errors: {
                    "companyNameError": "",
                    "distributorNameError": "",
                    "retailerNameError": ""
                }
            })
            if (value == "company") {
                sheetName = "company_list"
                tableHead = [
                    "Sl.NO",
                    "Invoice No",
                    "Company Code",
                    "Company Name",
                    "Date",
                    "Invoice Amount"
                ]
                exportHead = [
                    "Invoice No",
                    "Company Code",
                    "Company Name",
                    "Date",
                    "Invoice Amount"
                ]
                tableBody = [
                    "invoiceNo",
                    "companyCode",
                    "companyName",
                    "invoiceDate",
                    "invoiceAmount"
                ]
            } else if (value == "distributor") {
                sheetName = "distributor_list"
                tableHead = [
                    "Sl.NO",
                    "Invoice No",
                    "Distributor Code",
                    "Distributor Name",
                    "Date",
                    "Invoice Amount"
                ]
                exportHead = [
                    "Invoice No",
                    "Distributor Code",
                    "Distributor Name",
                    "Date",
                    "Invoice Amount"
                ]
                tableBody = [
                    "invoiceNo",
                    "distributorCode",
                    "distributorName",
                    "retailerAddress",
                    "invoiceDate",
                    "invoiceAmount"
                ]
            } else if (value == "retailer") {
                sheetName = "retailer_list"
                tableHead = [
                    "Sl.NO",
                    "Invoice No",
                    "Retailer Code",
                    "Retailer Name",
                    "Distributor Code",
                    "Distributor Name",
                    "Date",
                    "Invoice Amount"
                ]
                exportHead = [
                    "Invoice No",
                    "Retailer Code",
                    "Retailer Name",
                    "Distributor Code",
                    "Distributor Name",
                    "Date",
                    "Invoice Amount"
                ]
                tableBody = [
                    "invoiceNo",
                    "retailerCode",
                    "retailerName",
                    "distributorCode",
                    "distributorName",
                    "invoiceDate",
                    "invoiceAmount"
                ]
            } else {
                //c
            }
            this.setState({
                ...this.state,
                tableHead: tableHead,
                tableBody: tableBody,
                exportHead: exportHead,
                exportBody: tableBody,
                sheetName: sheetName,
            })
        }
        this.setState({
            // ...this.state,
            [e.target.name]: value,
            fields: {...this.state.fields, [e.target.name]: value},
            errors: {...this.state.errors, [e.target.name + "Error"]: ""}
        })

        if (e.target.name == "dateRange") {
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let valid = true
            const date = new Date()
            if (e.target.value == "") {
                this.setState({
                    ...this.state,
                    fromDate: "",
                    fromDateSearch: "",
                    toDate: "",
                    toDateSearch: "",
                    fields: {
                        ...this.state.fields,
                        dateRange: "",
                        fromDate: "",
                        toDate: ""
                    }
                })
            } else {
                if (e.target.value == "today") {
                    currentDay = String(date.getDate()).padStart(2, "0")
                    lastDay = String(date.getDate()).padStart(2, "0")
                    currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "yesterday") {
                    const yesterday = new Date(date)
                    yesterday.setDate(date.getDate() - 1)
                    currentDay = String(yesterday.getDate()).padStart(2, "0")
                    lastDay = String(yesterday.getDate()).padStart(2, "0")
                    currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thisWeek") {
                    const today = new Date()
                    const startOfWeek = new Date(today)
                    const endOfWeek = new Date(today)

                    startOfWeek.setDate(today.getDate() - today.getDay())
                    endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                    const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                    const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                    currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                    lastDay = String(endDate.getDate()).padStart(2, "0")
                    currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastweek") {
                    const dateL = new Date()
                    const to = new Date(
                        dateL.setTime(
                            dateL.getTime() -
                                (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                        )
                    )
                    const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                    currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                    lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                    currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastmonth") {
                    const d = new Date()
                    d.setDate(1)
                    const to = new Date(d.setHours(-1))
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(to.getDate()).padStart(2, "0")
                    currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thismonth") {
                    const d = new Date()
                    const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(lastDays.getDate()).padStart(2, "0")
                    currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                } else {
                    valid = false
                }
                let currentDateNew = null
                let currentEndDateNew = null
                if (valid) {
                    currentDateNew = new Date(
                        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                    )
                    currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                }

                this.setState({
                    ...this.state,
                    fromDate: currentDateNew,
                    fromDateSearch: `${currentYear}-${currentMonth}-${currentDay} 00:00:00`,
                    toDate: currentEndDateNew,
                    toDateSearch: `${currentYear}-${lastMonth}-${lastDay} 23:59:59`,
                    fields: {
                        ...this.state.fields,
                        dateRange: e.target.value,
                        fromDate: currentDateNew,
                        toDate: currentEndDateNew
                    }
                })
            }
        }
        this.uiData.updateShowLoading(false)
    }
    getRetailersList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getRetailersListDropdown(data)
        response
            .then((retailerListResponse: any) => {
                //console.log(retailerListResponse)
                if (!retailerListResponse.errorStatus) {
                    this.setLimitFields[""][3].options = retailerListResponse.data
                    this.setState({retailerList: retailerListResponse.data})
                }
                this.uiData.updateShowLoading(false)
            })
            .catch((err: any) => {
                //console.log("getRetailersListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if (String(dates.getHours()) != "0") {
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = `${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`
        this.setState({
            fromDate: date,
            fromDateSearch: finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if (String(dates.getHours()) != "0") {
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = `${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`

        this.setState({
            toDate: eDate,
            toDateSearch: finalDate
        })
    }
    handlePrint = () => {
        const divContents: any = document.getElementById("printTable")?.innerHTML
        const a: any = window.open("", "", "height:700px,width:700px")
        a.document.write("<html>")
        a.document.write(divContents)
        a.document.write("</body></html>")
        a.document.close()
        a.print()
        setTimeout(function () {
            a.close()
        }, 200)
    }

    handleExportExcel = (e: any) => {
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport: true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport: false
            })
        }, 100)
    }
    handleRetailerSearch(guid: any, event: any) {
        this.uiData.updateShowLoading(true)
        event.preventDefault()
        const sheetName = "retailer_list"
        const tableHead = [
            "Sl.NO",
            "Invoice No",
            "Retailer Code",
            "Retailer Name",
            "Distributor Code",
            "Distributor Name",
            "Date",
            "Invoice Amount"
        ]
        const exportHead = [
            "Invoice No",
            "Retailer Code",
            "Retailer Name",
            "Distributor Code",
            "Distributor Name",
            "Date",
            "Invoice Amount"
        ]
        const tableBody = [
            "invoiceNo",
            "retailerCode",
            "retailerName",
            "distributorCode",
            "distributorName",
            "invoiceDate",
            "invoiceAmount"
        ]

        setTimeout(() => {
            this.setState({
                ...this.state,
                userType: "retailer",
                userTypeSearch: "retailer",
                distributorName: guid,
                tableHead: tableHead,
                tableBody: tableBody,
                exportHead: exportHead,
                exportBody: tableBody,
                sheetName: sheetName,
                fields: {
                    ...this.state.fields,
                    userType: "retailer",
                    distributorName: guid
                }
            })
        }, 500)
        setTimeout(() => {
            this.handlePartnerSearchAjax("search")
        }, 500)
    }
    printInvoice(e:any,invoiceNo:any,invoiceDateStr:any,partnerType:any) {
        e.preventDefault();
        let invoiceSearchData = {};
        if(partnerType == ""){
             invoiceSearchData = {
                invoiceNo : invoiceNo,
                invoiceDate : invoiceDateStr
            }
        }else{
            invoiceSearchData = {
                invoiceNo : invoiceNo,
                invoiceDate : invoiceDateStr,
                partnerType : partnerType
            }
        }
        //console.log(invoiceSearchData);
        const response = this.DownlineManageServices.getMethodCall(
            invoiceSearchData,
            "getInvoiceDetail"
        )
        response
            .then((searchInvoiceDetailResponse: any) => {
                //console.log(searchInvoiceDetailResponse)
                if (!searchInvoiceDetailResponse.errorStatus) {
                    const dataInvoice = searchInvoiceDetailResponse.data
                    let printDistName = "";
                    let printDistAddress = "";
                    let printDisGSTIN = "";
                    let printRetailerName = "";
                    let printRetailerCode = "";
                    let printRetailerAddress = "";
                    let printDate = "";
                    let printInvoiceNo = "";
                    let printGameData = [];
                    if(partnerType == 3){                        
                        printDistName = dataInvoice.companyName;
                        printDistAddress = dataInvoice.companyAddress;
                        printDisGSTIN = dataInvoice.panNo;
                        printRetailerName = "-";
                        printRetailerAddress = "-";
                        printRetailerCode = "-";
                    }
                    if(partnerType == 5){
                        printDistName = dataInvoice.distributorName;
                        printDistAddress = dataInvoice.distributorAddress;
                        printDisGSTIN = dataInvoice.panNo;
                        printRetailerName = "-";
                        printRetailerAddress = "-";
                        printRetailerCode = "-";
                        // printDate = dataInvoice.invoiceDate;
                        // printInvoiceNo = dataInvoice.invoiceNo;
                    }
                    if(partnerType == ""){                        
                        printDistName = dataInvoice.distributorName;
                        printDistAddress = dataInvoice.distributorAddress;
                        printDisGSTIN = dataInvoice.panNo;
                        printRetailerName = dataInvoice.retailerName;
                        printRetailerAddress = dataInvoice.retailerAddress;
                        printRetailerCode = dataInvoice.retailerCode;
                        // printDate = dataInvoice.invoiceDate;
                        // printInvoiceNo = dataInvoice.invoiceNo;
                    }
                    printDate = dataInvoice.invoiceDate;
                    printInvoiceNo = dataInvoice.invoiceNo;
                    printGameData = dataInvoice.gameData;

                    this.setState({
                        ...this.state,
                        printDistName : printDistName,                        
                        printDistAddress: printDistAddress,                       
                        printDisGSTIN: printDisGSTIN,     
                        printRetailerName: printRetailerName,
                        printRetailerCode: printRetailerCode,
                        printRetailerAddress: printRetailerAddress,
                        printDate: printDate,
                        printInvoiceNo: printInvoiceNo,
                        printGameData: printGameData
                    })
                }
            })
            .catch((error:any)=>{
                //console.log(error)
            })
            setTimeout(()=>{
                const divContents: any = document.getElementById("printInvoice")?.innerHTML
                const a: any = window.open("", "", "height:700px,width:700px")
                a.document.write('<html><head><style type="text/css">.text-style{ text-align:end;}.text-start{ text-align:start;}.img-class{ width:100%;height:100px;}.fontclass{ font-weight:bold;}table{width: 100%;border: 1px solid;border-right: 1px solid;border-bottom: 1px solid;border-collapse: collapse;margin-bottom: 5px;}table td{text-align:center;border-right:1px solid;border-bottom:1px solid;padding:0.5em;}table th{text-align:center;border-right:1px solid;border-bottom:1px solid;padding:0.5em;}.lot_type h6{text-transform: uppercase;font-size: 13px;}.div3 h3{margin-top:0;margin-bottom:0;}.div1{display:inline-block;}.div2{display:inline-block;float:right;}.div3{display:inline-block;}.div4{display:inline-block;}.lot_type h5{}.float-right{float:right;}.cart_tbl{margin-bottom:25px;width: 49%;margin-right: 11px;overflow: hidden;display: inline-block;vertical-align: top;}.text-end{text-align:end;}</style></head>')
                a.document.write(divContents)
                a.document.write("</html>")
                a.document.close()
                a.print()
                setTimeout(function () {
                    a.close()
                }, 200)
            },100)
        
    }
    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>{StaticKey.LOCALE_DATA.invoice.titleLabel}</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.setLimitFields).map((keys: any, values: any) => {
                                    return (
                                        <div className="w-100" key={values}>
                                            {Object.keys(this.state.searchFilterRow).map(
                                                (valRow: any, i: any) => {
                                                    return (
                                                        <>
                                                            <h5>{keys}</h5>
                                                            <div
                                                                className={
                                                                    classes["main-div-input-sec"]
                                                                }
                                                            >
                                                                {this.setLimitFields[keys].map(
                                                                    (
                                                                        fieldValObject: any,
                                                                        fieldValIndex: any
                                                                    ) => {
                                                                        if (
                                                                            fieldValObject.rowId ==
                                                                            valRow
                                                                        ) {
                                                                            return (
                                                                                <>
                                                                                    {!fieldValObject.dnone && (
                                                                                        <div
                                                                                            className={
                                                                                                classes[
                                                                                                    "sub-div"
                                                                                                ]
                                                                                            }
                                                                                            key={
                                                                                                fieldValIndex
                                                                                            }
                                                                                        >
                                                                                            <label htmlFor="name">
                                                                                                {
                                                                                                    fieldValObject.label
                                                                                                }
                                                                                            </label>
                                                                                            {fieldValObject.required ? (
                                                                                                <span
                                                                                                    className={
                                                                                                        classes[
                                                                                                            "mandatory-symbol"
                                                                                                        ]
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                </span>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}{" "}
                                                                                            &nbsp;
                                                                                            <InputBox
                                                                                                id={
                                                                                                    fieldValObject.id
                                                                                                }
                                                                                                autoFocus={
                                                                                                    fieldValObject.autoFocus
                                                                                                }
                                                                                                autoComplete="off"
                                                                                                maxLength={
                                                                                                    fieldValObject.maxLength
                                                                                                }
                                                                                                min={
                                                                                                    fieldValObject.min
                                                                                                }
                                                                                                max={
                                                                                                    fieldValObject.max
                                                                                                }
                                                                                                placeholder={
                                                                                                    fieldValObject.placeHolder
                                                                                                }
                                                                                                className={
                                                                                                    "form-control " +
                                                                                                    classes[
                                                                                                        fieldValObject
                                                                                                            .class
                                                                                                    ]
                                                                                                }
                                                                                                value={
                                                                                                    this
                                                                                                        .state[
                                                                                                        fieldValObject
                                                                                                            .name
                                                                                                    ]
                                                                                                }
                                                                                                name={
                                                                                                    fieldValObject.name
                                                                                                }
                                                                                                type={
                                                                                                    fieldValObject.type
                                                                                                }
                                                                                                tabIndex={
                                                                                                    tabIndex++
                                                                                                }
                                                                                                required={
                                                                                                    fieldValObject.required
                                                                                                }
                                                                                                onChange={
                                                                                                    fieldValObject.type ===
                                                                                                    "datetime"
                                                                                                        ? fieldValObject.name ==
                                                                                                          "fromDate"
                                                                                                            ? this
                                                                                                                  .handleStartDateChange
                                                                                                            : this
                                                                                                                  .handleEndDateChange
                                                                                                        : this
                                                                                                              .handleInputChange
                                                                                                }
                                                                                                //     fieldValObject.type === "date"
                                                                                                //             ? this.handleStartDateChange
                                                                                                //         : this.handlecreateDrawInputChange
                                                                                                // }
                                                                                                dateFormat={
                                                                                                    fieldValObject.dateFormat
                                                                                                }
                                                                                                startMaxDate={
                                                                                                    this
                                                                                                        .maxDate
                                                                                                }
                                                                                                endMinDate={
                                                                                                    this
                                                                                                        .minDate
                                                                                                }
                                                                                                label1={
                                                                                                    fieldValObject.label1
                                                                                                }
                                                                                                options={
                                                                                                    fieldValObject.type ==
                                                                                                        "select" ||
                                                                                                    fieldValObject.type ==
                                                                                                        "radio"
                                                                                                        ? fieldValObject.options
                                                                                                        : ""
                                                                                                }
                                                                                                selectShowEmpty={
                                                                                                    fieldValObject.selectShowEmpty
                                                                                                }
                                                                                            />
                                                                                            <div
                                                                                                className={
                                                                                                    classes[
                                                                                                        "error"
                                                                                                    ]
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    this
                                                                                                        .state
                                                                                                        .errors[
                                                                                                        fieldValObject.name +
                                                                                                            "Error"
                                                                                                    ]
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value={StaticKey.LOCALE_DATA.invoice.submit}
                                        onClick={() => this.handleCreatePartnerLimitSearch(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value={StaticKey.LOCALE_DATA.invoice.reset}
                                        onClick={() => this.handlecustomerListReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {this.state.showRetailerListing && (
                        <>
                            <div className="d-flex">
                                {/* <div className={"ms-2 me-2"}>
                            <div className={classes["total_section_box"]}>
                                <span className={classes["total_section_box_sub1"]}>
                                    Total Records
                                </span>
                                :
                                <span className={classes["total_section_box_sub2"]}>
                                    {this.state.totalCount !=""  ? this.state.totalCount : "0"}
                                </span>
                            </div>
                        </div>   
                                                    */}

                                <TotalRecords totalCount={this.state.totalCount} />
                                {this.state.totalCount > 0 ? (
                                    <>
                                        <input
                                            type="button"
                                            className={"btn btn-primary " + classes["printBtn"]}
                                            value="Print"
                                            onClick={this.handlePrint}
                                        />
                                        <input
                                            type="button"
                                            className={"btn btn-primary " + classes["printBtn"]}
                                            value="Export Excel"
                                            onClick={this.handleExportExcel}
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div
                                className={classes["main-div"] + " " + classes["table-main-div"]}
                                style={{maxHeight: "400px"}}
                            >
                                <Table
                                    className={
                                        "table table-bordered table-hover table-condensed" +
                                        classes["table"] +
                                        " " +
                                        classes["table-bordered"] +
                                        " " +
                                        classes["table-hover"] +
                                        " " +
                                        classes["table-condensed"]
                                    }
                                    id="provider_table_id"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Sl.No</Th>
                                            <Th>Invoice No</Th>
                                            {this.state.userTypeSearch == "company" && (
                                                <Th>Company Code</Th>
                                            )}
                                            {this.state.userTypeSearch == "company" && (
                                                <Th>Company Name</Th>
                                            )}
                                            {this.state.userTypeSearch == "retailer" && (
                                                <Th>Retailer Code</Th>
                                            )}
                                            {this.state.userTypeSearch == "retailer" && (
                                                <Th>Retailer Name</Th>
                                            )}
                                            {(this.state.userTypeSearch == "distributor" ||
                                                this.state.userTypeSearch == "retailer") && (
                                                <Th>Distributor Code</Th>
                                            )}
                                            {(this.state.userTypeSearch == "distributor" ||
                                                this.state.userTypeSearch == "retailer") && (
                                                <Th>Distributor Name</Th>
                                            )}
                                            <Th>Date</Th>
                                            <Th>Invoice Amount</Th>
                                            <Th style={{zIndex: 1}}>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.retailerSearchData.length > 0 ? (
                                            <>
                                                {this.state.retailerSearchData.map(
                                                    (val: any, ind: any) => {
                                                        let oddEven = "odd"
                                                        if (ind % 2 == 0) {
                                                            oddEven = "even"
                                                        }
                                                        let invoiceCode = "";
                                                        let partnerType = "";
                                                        if(this.state.userTypeSearch ==
                                                            "company"){
                                                                invoiceCode = val.companyCode;
                                                                partnerType = "3";
                                                            }
                                                        if(this.state.userTypeSearch ==
                                                            "distributor"){
                                                                invoiceCode = val.distributorCode;
                                                                partnerType = "5";
                                                            }
                                                        if(this.state.userTypeSearch ==
                                                            "retailer"){
                                                                invoiceCode = val.retailerCode;
                                                                partnerType = "";
                                                            }
                                                        return (
                                                            <Tr
                                                                className={classes["tr_" + oddEven]}
                                                                key={ind}
                                                            >
                                                                <Td>
                                                                    {(this.state.currentPage - 1) *
                                                                        this.state.noRow +
                                                                        ind +
                                                                        1}
                                                                </Td>
                                                                <Td>
                                                                    {val.invoiceNo != "" ? (
                                                                        <>{val.invoiceNo}</>
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </Td>
                                                                {this.state.userTypeSearch ==
                                                                    "company" && (
                                                                    <Td>
                                                                        {" "}
                                                                        {val.companyCode &&
                                                                        val.companyCode != ""
                                                                            ? val.companyCode
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                {this.state.userTypeSearch ==
                                                                    "company" && (
                                                                    <Td>
                                                                        {" "}
                                                                        {val.companyName &&
                                                                        val.companyName != ""
                                                                            ? val.companyName
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                {this.state.userTypeSearch ==
                                                                    "retailer" && (
                                                                    <Td>
                                                                        {val.retailerCode &&
                                                                        val.retailerCode != ""
                                                                            ? val.retailerCode
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                {this.state.userTypeSearch ==
                                                                    "retailer" && (
                                                                    <Td>
                                                                        {val.retailerName &&
                                                                        val.retailerName != ""
                                                                            ? val.retailerName
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                {(this.state.userTypeSearch ==
                                                                    "distributor" ||
                                                                    this.state.userTypeSearch ==
                                                                        "retailer") && (
                                                                    <Td>
                                                                        {val.distributorCode &&
                                                                        val.distributorCode != ""
                                                                            ? val.distributorCode
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                {(this.state.userTypeSearch ==
                                                                    "distributor" ||
                                                                    this.state.userTypeSearch ==
                                                                        "retailer") && (
                                                                    <Td>
                                                                        {val.distributorName &&
                                                                        val.distributorName != ""
                                                                            ? val.distributorName
                                                                            : "-"}
                                                                    </Td>
                                                                )}
                                                                <Td>
                                                                    {val.invoiceDate
                                                                        ? val.invoiceDate
                                                                        : "-"}
                                                                </Td>
                                                                <Td>
                                                                    {val.invoiceAmount
                                                                        ? val.invoiceAmount
                                                                        : "-"}
                                                                </Td>
                                                                <Td>
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        this.printInvoice(event,val.invoiceNo,val.invoiceDateStr,partnerType)
                                                                    }
                                                                >
                                                                    <div className={" text-warning "+classes["tooltip"]}>
                                                                         <i className="fa fa-print text-danger"></i>
                                                                        <span
                                                                        style= {{"width": "65px"}}
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-danger"
                                                                                ]
                                                                            }
                                                                        >
                                                                          Print
                                                                        </span>
                                                                    </div>                  
                                                                </a>
                                                                    {/* <InputBox
                                                                        type={"button"}
                                                                        value={"Print"}
                                                                        onClick={() => {
                                                                            this.printInvoice()
                                                                        }}
                                                                    /> */}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            <Tr className={classes["no_data_found"]}>
                                                <Td colSpan={15}>No record(s) available</Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </Table>
                            </div>
                            {this.state.totalCountPagination.length > 0 ? (
                                <div className={"d-none"} id={"printTable"}>
                                    <PrintTableElement
                                        tableData={this.state.retailerSearchData}
                                        currentPage={this.state.currentPage}
                                        noRow={this.state.noRow}
                                        tHead={this.state.tableHead}
                                        tBody={this.state.tableBody}
                                    />
                                    {this.state.excelExport && (
                                        <ExportExcelReport
                                            mockData={this.state.mockData}
                                            sheetName={this.state.sheetName}
                                        />
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                            <div className={"table_pagination_div"}>
                                {this.state.totalCountPagination.length > 0 ? (
                                    <>
                                        <button
                                            className={classes["buttonClass"]}
                                            disabled={this.state.currentPage == 1 ? true : false}
                                            onClick={event => this.handlePagination(event, 1)}
                                        >
                                            First
                                        </button>
                                        <button
                                            className={classes["buttonClass"]}
                                            disabled={this.state.currentPage == 1 ? true : false}
                                            onClick={event =>
                                                this.handlePagination(
                                                    event,
                                                    this.state.previousPage
                                                )
                                            }
                                        >
                                            <i className="fa fa-angle-double-left"></i> Previous
                                        </button>
                                        {this.state.totalCountPagination.map(
                                            (val: any, ind: any) => {
                                                let btnClass = ""
                                                if (
                                                    ind + 1 >= this.state.fromPagePagination &&
                                                    ind + 1 <= this.state.toPagePagination
                                                ) {
                                                    if (this.state.currentPage == ind + 1) {
                                                        btnClass = "active"
                                                    }
                                                    return (
                                                        <>
                                                            <button
                                                                className={
                                                                    classes["buttonClass"] +
                                                                    " " +
                                                                    classes[btnClass]
                                                                }
                                                                onClick={event =>
                                                                    this.handlePagination(
                                                                        event,
                                                                        ind + 1
                                                                    )
                                                                }
                                                            >
                                                                {ind + 1}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            }
                                        )}
                                        <button
                                            className={classes["buttonClass"]}
                                            disabled={
                                                this.state.currentPage == this.state.lastPage
                                                    ? true
                                                    : false
                                            }
                                            onClick={event =>
                                                this.handlePagination(event, this.state.nextPage)
                                            }
                                        >
                                            Next <i className="fa fa-angle-double-right"></i>
                                        </button>
                                        <button
                                            className={classes["buttonClass"]}
                                            disabled={
                                                this.state.currentPage == this.state.lastPage
                                                    ? true
                                                    : false
                                            }
                                            onClick={event =>
                                                this.handlePagination(event, this.state.lastPage)
                                            }
                                        >
                                            Last
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div id="printInvoice" className={"d-none"}>
                    <div className="div_print" id="div_print" style={{"background": "white"}}>
                        <table className="table-bordered text-sub table table-bordered"  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}}>
                            <tbody  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}}>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}}>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6} className={"text-center fontclass"}>
                                        Distributor Name - {this.state.printDistName}
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}}>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6} className="text-center fontclass">
                                        {this.state.printDistAddress}
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}}>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6} className="text-center fontclass">
                                        GSTIN - {this.state.printDisGSTIN}
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={1} className="fontclass text-start">
                                        Retailer Name / Address
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={2} className="fontclass text-start">
                                        {this.state.printRetailerName} / {this.state.printRetailerAddress}
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={1} className="fontclass text-start">
                                        Date
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={2} className="fontclass ">
                                        {this.state.printDate}
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={1} className="fontclass text-start">
                                        Retailer Code
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={2} className="fontclass text-start">
                                        {this.state.printRetailerCode}
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={1} className="fontclass">
                                        Inv No
                                    </td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={2} className="fontclass">
                                        {this.state.printInvoiceNo}
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}>Sl NO.</td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}>Game Name</td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright">Ticket Price</td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright">Quantity</td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright">Sale Rate (Rs.)</td>
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright">Sale Amount (Rs.)</td>
                                </tr>
                                {this.state.printGameData.map((values:any,keys:any)=>{
                                    return(
                                        <tr key={keys} style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}>{keys+1}</td>
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}>{values.gameName} </td>
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright text-end">{values.ticketPrice}</td>
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright text-end">{values.qty}</td>
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright text-end">{values.saleRate}</td>
                                            <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  className="alignright text-end">{values.saleAmt}</td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                            <tfoot>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6}>
                                        <span className="text-start">
                                            1.Appropriate CGST on above lottery tickets are to be
                                            paid by Distributer, appointed by the relevant
                                            organizing State Government, 1/2017-Central Tax (Rate).
                                        </span>
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6}>
                                        <span className="text-start">
                                            2.Appropriate SGST on above lottery tickets are to be
                                            paid by Distributer, appointed by the relevant
                                            organizing State Government, 1/2017-State Tax (Rate).
                                        </span>
                                    </td>
                                </tr>
                                <tr  style={{"borderCollapse": "collapse","border": "1px solid black","width": "100%"}} >
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6} className="text-start">
                                        <span className="text-start">
                                            3.Ticket supplied by us to you are exempted from CGST
                                            and SGST vide the Notification no. 02/2017-Central Tax
                                            (Rate) and Notification No. 02/2017-State Tax (Rate)
                                            respectively.
                                        </span>
                                    </td>
                                </tr>
                                <tr className="mt-2">
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6}>
                                        <span className="fontclass text-start">
                                            For: {this.state.printDistAddress}
                                        </span>
                                    </td>
                                </tr>
                                <tr className="mt-2">
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6}>
                                        <span className="text-start">(Authorized Signatory)</span>
                                    </td>
                                </tr>
                                <tr className="mt-2">
                                    <td  style={{"borderCollapse": "collapse","border": "1px solid black"}}  colSpan={6}>
                                        <span className="text-start">
                                            This is a system generated Invoice, Thus does not
                                            require Signature.
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}
