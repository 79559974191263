import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/sidemenu/styles.module.scss"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {Link} from "react-router-dom"
import {StaticKey} from "../../../game_core/type_script/static_key"
import Accordion from "react-bootstrap/Accordion"
import {DataStorage} from "../../../game_core/type_script/services/localstorage"
import {AuthCheck} from "../../../game_core/type_script/services/authcheck"
interface Props {
    selectedMenu?: any
}
export class SideBar extends Component<Props> {
    uiData: UIDataService = UIDataService.getInstance()
    authCheck: AuthCheck = AuthCheck.getInstance()
    localStorageService = new DataStorage()
    dataService = new DataStorage()
    state: any = {
        activeKey: parseInt(this.props.selectedMenu),
        menuActive: "dashboard",
        menuDashboardActive: "",
        toggleSideBar: false,
        partnerTypeId: 0,
        menuArr: StaticKey.MENU_LIST,
        assignedMenus: [],
    }
    menuChange = (e: any, clickedMenu: any) => {
        e.preventDefault()
        this.uiData.menuChange(clickedMenu)
    }
    componentDidMount() {
        const selectedMenu = localStorage.getItem("menu")
            ? localStorage.getItem("menu")
            : "Dashboard"
        const selectedMenuId = this.dataService.getCurrentClickedMenuGroup()
        this.dataService.setCurrentClickedMenuGroup(selectedMenuId)
        this.setState({menuActive: selectedMenu})
        this.uiData.on("toggleSideBar", this.toggleSideBar)
    }
    setMenuActive(menuName:any){
        // console.log("asd")
        this.setState({menuActive: menuName})
    }
    componentWillUnmount() {
        this.uiData.off("toggleSideBar", this.toggleSideBar)
    }
    toggleSideBar = () => {
        const toggleStatus = this.uiData.getToggleStatus()
        this.setState({toggleSideBar: toggleStatus})
    }
    handleMenuChangeFun(clickedMenu: any, roleIndex: any, rolePath: any) {
        this.localStorageService.removeSearchConditionLocalStorage()
        this.authCheck.checkLoginStatus()
        const width = window.innerWidth
        if (width <= 990) {
            const allWithClass = Array.from(document.querySelectorAll(".sidebar_class"))
            const classN = allWithClass[0].className
            const myArray = classN.split(" ")
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i].includes("open")) {
                    this.uiData.toggleSideBar(false)
                }
            }
        }
        localStorage.setItem("menu", clickedMenu)
        this.setState({activeKey: roleIndex, menuActive: clickedMenu})
        this.dataService.setCurrentClickedMenuGroup(roleIndex)
        this.dataService.setCurrentClickedMenuPath(rolePath != "/" ? "/" + rolePath : "/")
    }
    render() {
        const toggleStatus = this.state.toggleSideBar ? "open" : ""
        const assignedMenus = this.state.menuArr[this.uiData.getLoginPartnerType()]
        let dashboardActive = ""
        this.state.menuActive == StaticKey.LOCALE_DATA.Home.dashboard
            ? (dashboardActive = "active")
            : ""
        return (
            <div
                className={
                    " sidebar_class " + classes["sidebar-section"] + " " + classes[toggleStatus]
                }
                style={{textAlign: "left"}}
            >
                <ul className={classes["ul-menu-list"]}>
                    <Accordion defaultActiveKey={this.state.activeKey}>
                        {assignedMenus &&
                            assignedMenus.map((val: any, ind: any) => {
                                return (
                                    <div key={ind}>
                                        <Accordion.Item eventKey={ind}>
                                            <Accordion.Header>
                                                <i className={val.icon} aria-hidden="true"></i>
                                                {val.roleName}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {val.subMenus.map((sVal: any, sInd: any) => {
                                                    let activeMenu = ""
                                                    this.state.menuActive == sVal.roleName
                                                        ? (activeMenu = "active")
                                                        : ""
                                                    return (
                                                        <div key={sInd}>
                                                            <ul className={classes["sub-menu"]}>
                                                                <li>
                                                                    <Link
                                                                        id={sVal.roleAction}
                                                                        className={
                                                                            "show_loading siv " +
                                                                            classes[activeMenu]
                                                                        }
                                                                        to={sVal.roleAction}
                                                                        onClick={() =>
                                                                            this.handleMenuChangeFun(
                                                                                sVal.roleName,
                                                                                ind,
                                                                                sVal.roleAction
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="nav-label">
                                                                            {sVal.roleName} 
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                )
                            })}
                    </Accordion>
                </ul>
            </div>
        )
    }
}
