import React, {Component} from "react"
import {StaticKey} from "../../../../game_core/type_script/static_key"
import {UIDataService} from "../../../../game_core/type_script/ui-data-service"
export class HeaderTimer extends Component {
    uiData: UIDataService = UIDataService.getInstance()
    currDate = new Date()
    timerInterval: any = ""
    state = {
        dateTime:
            this.currDate.getDate().toString().padStart(2, "0") +
            "-" +
            this.currDate.getMonth().toString().padStart(2, "0") +
            "-" +
            this.currDate.getFullYear().toString().padStart(2, "0") +
            " " +
            this.currDate.getHours().toString().padStart(2, "0") +
            ":" +
            this.currDate.getMinutes().toString().padStart(2, "0") +
            ":" +
            this.currDate.getSeconds().toString().padStart(2, "0"),
        currentDateTime: StaticKey.CURRENT_TIMESTAMP
    }
    componentDidMount() {
        this.timerInterval = setInterval(this.tick, 1000)
    }
    tick = () => {
        const newDate = new Date(this.state.currentDateTime)
        newDate.setSeconds(newDate.getSeconds() + 1)
        const convertDate = new Date(newDate)
        const conveMonth = convertDate.getMonth() + 1
        this.setState({
            dateTime:
                convertDate.getDate().toString().padStart(2, "0") +
                "-" +
                conveMonth.toString().padStart(2, "0") +
                "-" +
                convertDate.getFullYear().toString().padStart(2, "0") +
                " " +
                convertDate.getHours().toString().padStart(2, "0") +
                ":" +
                convertDate.getMinutes().toString().padStart(2, "0") +
                ":" +
                convertDate.getSeconds().toString().padStart(2, "0"),
            currentDateTime: convertDate
        })
    }
    componentWillUnmount() {
        clearInterval(this.timerInterval)
    }
    render() {
        return <div className="server-date">{this.state.dateTime}</div>
    }
}
