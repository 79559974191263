import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {LOGIN_IMAGES} from "../../configuration/assets/base/importer/login"
import {HomePageService} from "../../../game_core/type_script/services/homepage"
import {LoginService} from "../../../game_core/type_script/services/login"
import styleClasses from "../../configuration/style/base/screens/login/styles.module.scss"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import Swal from "sweetalert2"
import {Navigate} from "react-router-dom"

export class Forcechangepassword extends Component {
    HomePageService = HomePageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    LoginService: any = LoginService.getInstance()
    changePasswordLang: any = StaticKey.LOCALE_DATA.changePassword
    nameInput: any
    state: any = {
        redirectToLogin: false,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        loginRes: "",
        eyeTag: {
            oldPassword: "eye-slash",
            newPassword: "eye-slash",
            confirmPassword: "eye-slash"
        },
        changePasswordFields: [
            {
                id: "oldPassword",
                name: "oldPassword",
                label: this.changePasswordLang.oldPassword,
                type: "password",
                autoFocus: true,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                placeHolder: "Old Password",
                tooltipEnabled: false,
                tooltipContent: "",
                passwordCondition: false
            },
            {
                id: "newPassword",
                name: "newPassword",
                label: this.changePasswordLang.newPassword,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                placeHolder: "New Password",
                tooltipEnabled: true,
                tooltipContent: StaticKey.INVALIDPATTERN,
                passwordCondition: true
            },
            {
                id: "confirmPassword",
                name: "confirmPassword",
                label: this.changePasswordLang.confirmPassword,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                placeHolder: "Confirm Password",
                tooltipEnabled: true,
                tooltipContent: StaticKey.INVALIDPATTERN,
                passwordCondition: true
            }
        ]
    }

    componentDidMount() {
        this.setState({loginRes: this.uiData.getLoginResponse()})
      //console.log(this.uiData.getLoginResponse())
    }
    validateChangePasswordForm = () => {
        let flag = true
        this.state.changePasswordFields.map((val: any) => {
            const errorData = this.uiData.textConversion(val.label)
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    this.setState({
                        [val.name + "Error"]: errorData + " " + this.changePasswordLang.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        const pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: StaticKey.INVALIDPATTERN
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        if (flag && this.state.newPassword !== this.state.confirmPassword) {
            flag = false
            this.setState({
                ["confirmPasswordError"]: this.changePasswordLang.newConfirmPasswordEqual
            })
        }
        return flag
    }

    handleChangePasswordSubmit = (e: any) => {
        e.preventDefault()
        //  return;
        if (this.validateChangePasswordForm()) {
            this.uiData.updateShowLoading(true)
            const passwordChangeRequest = {
                // "username":this.state.loginRes['adminUsername'],
                "currentPassword": this.state.oldPassword,
                "newPassword": this.state.newPassword,
                "confirmPassword": this.state.confirmPassword
            }
            const response = this.HomePageService.forcePasswordUpdate(passwordChangeRequest)
            response
                .then((updatePasswordResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!updatePasswordResponse.errorStatus) {
                        Swal.fire({
                            title: this.changePasswordLang.passwordUpdateSuccess,
                            timer: 4000,
                            icon: "success"
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.handleChangePasswordReset()
                                this.uiData.logoutUser()
                                this.setState({redirectToLogin: true})
                            }
                        })
                    } else {
                        Swal.fire({
                            title: updatePasswordResponse.message,
                            timer: 4000,
                            icon: "error"
                        })
                    }
                })
                .catch((err: any) => {
                  //console.log("handleChangePasswordSubmitError", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERROS[300001], "error")
                    // this.handleChangePasswordReset()
                })
        }
    }

    handleChangePasswordReset = () => {
        this.setState({
            "oldPassword": "",
            "oldPasswordError": "",
            "newPassword": "",
            "newPasswordError": "",
            "confirmPassword": "",
            "confirmPasswordError": ""
        })
        this.uiData.updateShowLoading(true)
        const response = this.LoginService.logoutUser()
        response
            .then(() => {
                this.uiData.logoutUser(true)
                this.uiData.updateShowLoading(false)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId: "",
                        ipAddress: "",
                        lastLogin: ""
                    }
                })
                // window.location.href = "/"
                localStorage.removeItem("menu")
            })
            .catch((err: any) => {
              //console.log("handleLogoutError", err)
                this.uiData.logoutUser(true)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId: "",
                        ipAddress: "",
                        lastLogin: ""
                    }
                })
            })
    }

    handlePasswordInputChange = (e: any) => {
        const value = e.target.value
        this.setState({
            ...this.state,
            [e.target.name]: value,
            [e.target.name + "Error"]: ""
        })
    }
    handleEyeCheck(evt: any, id: any) {
        if (document.getElementById(id)?.getAttribute("type") == "password") {
            document.getElementById(id)?.setAttribute("type", "text")
            this.setState({eyeTag: {...this.state.eyeTag, [id]: "eye"}})
        } else {
            document.getElementById(id)?.setAttribute("type", "password")
            this.setState({eyeTag: {...this.state.eyeTag, [id]: "eye-slash"}})
        }
    }

    handleGotoLogin(evt: any) {
        evt.preventDefault()
        const response = this.LoginService.logoutUser()
        response
            .then(() => {
                this.uiData.logoutUser(true)
                this.uiData.updateShowLoading(false)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId: "",
                        ipAddress: "",
                        lastLogin: ""
                    }
                })
                //window.location.href = "/"
                localStorage.removeItem("menu")
            })
            .catch((err: any) => {
              //console.log("handleLogoutError", err)
                this.uiData.logoutUser(true)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId: "",
                        ipAddress: "",
                        lastLogin: ""
                    }
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className={styleClasses["login-box-main"]}>
                    <div
                        className={
                            styleClasses["col-half"] + " " + styleClasses["background-white"]
                        }
                        style={{backgroundImage: "url(" + LOGIN_IMAGES["loginBg"] + ")"}}
                    >
                        <div className={styleClasses["login-box"] + " " + "forgot-password-pg"}>
                            <div id="header" className={styleClasses["login-logo"]}>
                                Password expired - Change password <br />
                                <p>(Login ID - {this.state.loginRes["loginId"]})</p>
                            </div>
                            <div className={styleClasses["login-error-message"]}>
                                {this.state.loginErrorMsg}
                            </div>
                            <form method="post" acceptCharset="utf-8">
                                {this.state.changePasswordFields.map((value: any, key: any) => {
                                    return (
                                        <>
                                            <div
                                                className={
                                                    styleClasses["form-group"] + " " + "pwd-frm-grp"
                                                }
                                                key={key}
                                            >
                                                <div className={styleClasses["input-group"]}>
                                                    <span
                                                        className={
                                                            classes["input-group-addon-eye"] +
                                                            " " +
                                                            "pwd-eye-icn " +
                                                            classes["cursor-pointer"]
                                                        }
                                                        id={"spanEye" + value.id}
                                                        onClick={() =>
                                                            this.handleEyeCheck(event, value.id)
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                "fa fa-" +
                                                                this.state.eyeTag[value.id]
                                                            }
                                                        ></i>
                                                    </span>
                                                    <div
                                                        className={
                                                            classes["tooltip"] +
                                                            " " +
                                                            classes["copyDiv"] +
                                                            " " +
                                                            "pwd-tooltip"
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle"></i>
                                                        <span
                                                            id="tooltiptext"
                                                            className={classes["tooltiptext"]}
                                                        >
                                                            {StaticKey.PASSWORD_CONDITION}
                                                        </span>
                                                    </div>
                                                    <input
                                                        ref={
                                                            value.name == "username"
                                                                ? input => {
                                                                      this.nameInput = input
                                                                  }
                                                                : ""
                                                        }
                                                        type={value.type}
                                                        name={value.name}
                                                        id={value.id}
                                                        autoComplete={value.autoComplete}
                                                        placeholder={value.placeHolder}
                                                        maxLength={value.maxLength}
                                                        className={
                                                            "form-control " +
                                                            styleClasses["form-control"]
                                                        }
                                                        autoFocus={value.autoFocus}
                                                        required={value.required}
                                                        onChange={this.handlePasswordInputChange}
                                                        value={this.state[value.name]}
                                                    />
                                                    {/* <label htmlFor="name">{value.label}</label>
                                                    <span className={classes["mandatory-symbol"]}>
                                                        * &nbsp;
                                                    </span> */}
                                                    {/* {value.tooltipEnabled && (
                                                         <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                                &nbsp;<i className="fa fa-info-circle"></i>
                                                                <span id="tooltiptext" className={classes["tooltiptext"]}>{value.tooltipContent}</span>
                                                        </div>  
                                                    )} */}
                                                    {/* <InputBox
                                                        id={value.id}
                                                        autoComplete={value.autoComplete}
                                                        maxLength={value.maxLength}
                                                        className={
                                                            "form-control " + classes["form-control"]
                                                        }
                                                        autoFocus={value.autoFocus}
                                                        name={value.name}
                                                        type={value.type}
                                                        value={this.state[value.name]}
                                                        onChange={this.handlePasswordInputChange}
                                                    />
                                                    <span id={"spanEye"+value.id} className={classes["eyeOpenForce"]} onClick={()=>this.handleEyeCheck(event,value.id)}>
                                                        <i className={"fa fa-"+this.state.eyeTag[value.id]}></i>
                                                    </span>*/}
                                                    <div
                                                        className={classes["error"] + " " + "error"}
                                                    >
                                                        {this.state[value.name + "Error"]}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <div
                                    className={
                                        classes["submit-clear-section"] +
                                        " " +
                                        "justify-content-center"
                                    }
                                >
                                    <div>
                                        <InputBox
                                            id="submit_form"
                                            className={
                                                "btn btn-primary btn-lg btn-block mt-0 " +
                                                styleClasses["btn-primary"] +
                                                " " +
                                                styleClasses["login-button"] +
                                                " " +
                                                styleClasses["login-submit-btn"]
                                            }
                                            type="submit"
                                            value={this.changePasswordLang.submit}
                                            tabIndex={5}
                                            onClick={() => this.handleChangePasswordSubmit(event)}
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <a
                                    style={{display: "block", textAlign: "center", color: "#573B93", fontWeight:"bolder"}}
                                    href="/"
                                    onClick={() => this.handleGotoLogin(event)}
                                >
                                    Go to login
                                </a>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.redirectToLogin && <Navigate to="/" replace={true} />}
            </React.Fragment>
        )
    }
}
