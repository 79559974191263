import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/dashboard/styles.module.scss"
import {DashboardService} from "../../../game_core/type_script/services/dashboard"
import {GameHistoryService} from "../../../game_core/type_script/services/gamehistory"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {LoginService} from "../../../game_core/type_script/services/login"
import {StaticKey} from "../../../game_core/type_script/static_key"
import { Link } from "react-router-dom"

export class Dashboard extends Component {
    DashboardServices = DashboardService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    GameHistoryServices = GameHistoryService.getInstance()
    isLoggedIn = this.uiData.getLoginStatus()
    gameList:any = [];
    gamewisesales:any = [];
    state: any = {
        isLoggedIn: false,
        postLoginValues: {
            username: "",
            balance: "",
            partnerTypeId: ""
        },
        limit_control: [],
        gameList: [],
        gamewisesales: [],
        gameSalesArray: [],
        gameSalesArrayMobile: [],
        liveSale: "",
        liveRetailers: "",
        retailer_detail: [],
        company_detail: [],
        distributor_detail: []
    }
    LoginService: any = LoginService.getInstance()
    componentDidMount(): void {
        this.getMiniGames()
        this.getDashboard()
        // this.uiData.on("setLoginResponse", this.setLoginResponse)
        // this.uiData.on("setLogoutResponse", this.setLogoutResponse)
        // this.uiData.on("setAlertShowMessage", this.setAlertShowMessage)
       
        

        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                isLoggedIn: 1,
                postLoginValues: {
                    username: loginResponse.username,
                    balance: loginResponse.userBalance,
                    partnerTypeId: loginResponse.partnerTypeId
                }
            })
        }
    }
    getMiniGames: any =()=>{
        const data = {
            "partnerTypeId": 3
        };
        const response = this.GameHistoryServices.getGameListDropdown(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                   // // console.log(getGameListResponse.data)
                   this.gameList = getGameListResponse.data;
                    this.setState({gameList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }
    getDashboard: any = () => {
        this.uiData.updateShowLoading(true)
        const response = this.DashboardServices.getDashboardData()
        response
            .then((dashboardResponse: any) => {
                this.uiData.updateShowLoading(false)
                // console.log(dashboardResponse)
                if (!dashboardResponse.errorStatus) {
                    const data = dashboardResponse.data
                    this.gamewisesales = data["gamewisesales"]
                    this.setState({
                        limit_control: data["limitControl"],
                        gamewisesales: data["gamewisesales"],
                        liveSale: data["liveSale"],
                        liveRetailers: data["liveRetailers"],
                        company_detail: data["companyDetail"],
                        retailer_detail: data["retailerDetail"],
                        distributor_detail: data["distributorArr"]
                    })
                } else {
                    this.setState({
                        limit_control: [],
                        gamewisesales: [],
                        liveSale: "",
                        liveRetailers: "",
                        company_detail: [],
                        retailer_detail: [],
                        distributor_detail: []
                    })
                }
            })
            .catch((err: any) => {
                // // console.log(err)
                this.uiData.updateShowLoading(false)
                this.setState({
                    limit_control: [],
                    liveSale: "",
                    liveRetailers: "",
                    company_detail: [],
                    gamewisesales: [],
                    retailer_detail: [],
                    distributor_detail: []
                })
            })

            setTimeout(()=>{
                for(let i=0; i < this.gameList.length; i++){
                    // console.log(this.gameList[i].MINIGAMES_NAME)
                    // console.log(this.state.gamewisesales)
                    // console.log(this.state.gamewisesales[this.gameList[i].MINIGAMES_NAME])
                    if(this.state.gamewisesales[this.gameList[i].MINIGAMES_NAME] != undefined){
                        // console.log(i);
                        if(this.gameList[i].MINIGAMES_NAME.includes("_mob")){                            
                            this.setState({
                                ...this.state,                            
                                gameSalesArrayMobile:[...this.state.gameSalesArrayMobile,this.gameList[i].name +" : "+ this.gamewisesales[this.gameList[i].MINIGAMES_NAME]]
                            })
                        }else{
                            this.setState({
                                ...this.state,                            
                                gameSalesArray:[...this.state.gameSalesArray,this.gameList[i].name +" : "+ this.gamewisesales[this.gameList[i].MINIGAMES_NAME]]
                            })
                        }                       
                        
                    }else{
                        if(this.gameList[i].MINIGAMES_NAME.includes("_mob")){ 
                            this.setState({
                                ...this.state,
                                gameSalesArrayMobile:[...this.state.gameSalesArrayMobile,this.gameList[i].name +" : "+ "0.00"]
                            })
                        }else{
                            this.setState({
                                ...this.state,
                                gameSalesArray:[...this.state.gameSalesArray,this.gameList[i].name +" : "+ "0.00"]
                            })
                        }
                    }
                }
            },500)
       
    }
    render() {
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>{StaticKey.LOCALE_DATA.Home.dashboard}</h2>
                        <div className="state-overview">
                            <div className="row">
                                <div className="col-xl-4 col-md-6 col-12" >
                                    <div style={{background:"#deb0fb"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.liveTerminal}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                {this.state.liveRetailers && this.state.liveRetailers != "" ? this.state.liveRetailers : "-"}
                                            </span>   
                                            {this.state.liveRetailers && this.state.liveRetailers != "" ?  <span style={{fontSize:"13px"}} className={classes["info-box-text"]}>
                                                 <Link to={ "/view-live-terminal.html"}>
                                                    Click here
                                                 </Link>
                                            </span> : ""}
                                                                                      
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-12" >
                                    <div style={{background:"#f3d33d"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.liveSales}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                {/* {StaticKey.LOCALE_DATA.Home.liveSales} :{" "} */}
                                                {this.state.liveSale && this.state.liveSale != "" ? this.state.liveSale : "0.00"}
                                            </span>                                            
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.postLoginValues["partnerTypeId"] != 0 ? (
                                <div className="col-xl-4 col-md-6 col-12">
                                    <div
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-green"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.limitControl}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            {/* <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.receivable} :{" "}
                                                {this.state.limit_control["TOTAL_CREDIT_LIMIT"]}
                                            </span> */}
                                {/* {this.state.postLoginValues["partnerTypeId"] == 3 ? ( */}
                                            {/* <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.payable} :{" "}
                                                {this.state.limit_control["TOTAL_CREDIT_LIMIT"]}
                                            </span> */}
                                {/* )  : (
                                     <></>
                                 )} */}
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.creditLimit} :{" "}
                                                {this.state.limit_control["totalAvailableBalance"] && this.state.limit_control["totalAvailableBalance"]  != "" ? this.state.limit_control["totalAvailableBalance"]  : "0.00" }
                                            </span>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.salePossible} :{" "}
                                                {this.state.limit_control["totalNetSalePossible"] && this.state.limit_control["totalNetSalePossible"]  != "" ? this.state.limit_control["totalNetSalePossible"]  : "0.00" }
                                            </span>
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 ):("")}
                                {this.state.postLoginValues["partnerTypeId"] != 0 ? (
                                <div className="col-xl-4 col-md-6 col-12">
                                    <div style={{background:"#4f606e"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>

                                            {StaticKey.LOCALE_DATA.Home.receivable}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                {/* {StaticKey.LOCALE_DATA.Home.receivable} :{" "} */}
                                                {this.state.limit_control["totalReceivable"]  && this.state.limit_control["totalReceivable"]  != "" ? this.state.limit_control["totalReceivable"]  : "0.00" }
                                            </span>                                
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 ):("")} 
                                {this.state.postLoginValues["partnerTypeId"] == 5 ? (
                                <div className="col-xl-4 col-md-6 col-12">
                                    <div style={{background:"#4f914"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>

                                            {StaticKey.LOCALE_DATA.Home.payable}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                                    <span className={classes["info-box-text"]}>
                                                        {/* {StaticKey.LOCALE_DATA.Home.payable} :{" "} */}
                                                        {this.state.limit_control["totalPayable"]  && this.state.limit_control["totalPayable"]  != "" ? this.state.limit_control["totalPayable"]  : "0.00"}
                                                    </span>                                       
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ):("")}
                                {/* <div className="col-xl-4 col-md-6 col-12">
                                    <div
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.retailers}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.total} :{" "}
                                                {this.state.retailer_detail["TOTAL_RETAILER"]}
                                            </span>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.active} :{" "}
                                                {
                                                    this.state.retailer_detail[
                                                        "TOTAL_ACTIVE_RETAILER"
                                                    ]
                                                }
                                            </span>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.inActive} :{" "}
                                                {
                                                    this.state.retailer_detail[
                                                        "TOTAL_IN_ACTIVE_RETAILER"
                                                    ]
                                                }
                                            </span>
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {this.state.postLoginValues["partnerTypeId"] < 3 ? (
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <div
                                            className={
                                                classes["info-box"] + " " + classes["bg-b-pink"]
                                            }
                                        >
                                            <span className={classes["input-box-header"]}>
                                                {StaticKey.LOCALE_DATA.Home.companies}
                                            </span>{" "}
                                            <br />
                                            <span
                                                className={
                                                    classes["info-box-icon"] +
                                                    " " +
                                                    classes["push-bottom"]
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-users"
                                                >
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                            </span>
                                            <div className={classes["info-box-content"]}>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.total} :{" "}
                                                    {this.state.company_detail["totalPartner"] ? this.state.company_detail["totalPartner"] : "0"}
                                                </span>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.active} :{" "}
                                                    {
                                                        this.state.company_detail[
                                                            "totalActivePartner"
                                                        ]  && this.state.company_detail[
                                                            "totalActivePartner"
                                                        ]  != "" ? this.state.company_detail[
                                                            "totalActivePartner"
                                                        ] : "0"
                                                    }
                                                </span>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.inActive} :{" "}
                                                    {
                                                        this.state.company_detail[
                                                            "totalInActivePartner"
                                                        ]  && this.state.company_detail[
                                                            "totalInActivePartner"
                                                        ]  != "" ? this.state.company_detail[
                                                            "totalInActivePartner"
                                                        ]  : "0"
                                                    }
                                                </span>
                                                <div className={classes["progress"]}>
                                                    <div
                                                        className="progress-bar"
                                                        style={{width: "50%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}   
                                {this.state.postLoginValues["partnerTypeId"] < 5 ? (
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <div
                                            className={
                                                classes["info-box"] + " " + classes["bg-b-blue"]
                                            }
                                        >
                                            <span className={classes["input-box-header"]}>
                                                {StaticKey.LOCALE_DATA.Home.distributors}
                                            </span>{" "}
                                            <br />
                                            <span
                                                className={
                                                    classes["info-box-icon"] +
                                                    " " +
                                                    classes["push-bottom"]
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-users"
                                                >
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                            </span>
                                            <div className={classes["info-box-content"]}>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.total} :{" "}
                                                    {this.state.distributor_detail["totalPartner"]  && this.state.distributor_detail["totalPartner"]  != "" ? this.state.distributor_detail["totalPartner"] : "0"}
                                                </span>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.active} :{" "}
                                                    {
                                                        this.state.distributor_detail[
                                                            "totalActivePartner"
                                                        ]  && this.state.distributor_detail[
                                                            "totalActivePartner"
                                                        ]  != "" ? this.state.distributor_detail[
                                                            "totalActivePartner"
                                                        ] : "0"
                                                    }
                                                </span>
                                                <span className={classes["info-box-text"]}>
                                                    {StaticKey.LOCALE_DATA.Home.inActive} :{" "}
                                                    {
                                                        this.state.distributor_detail[
                                                            "totalInActivePartner"
                                                        ]  && this.state.distributor_detail[
                                                            "totalInActivePartner"
                                                        ]  != "" ? this.state.distributor_detail[
                                                            "totalInActivePartner"
                                                        ]  : "0"
                                                    }
                                                </span>
                                                <div className={classes["progress"]}>
                                                    <div
                                                        className="progress-bar"
                                                        style={{width: "10%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}                             
                                <div className="col-xl-4 col-md-6 col-12">
                                    <div
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.retailers}
                                        </span>{" "}
                                        <br />
                                        <span
                                            className={
                                                classes["info-box-icon"] +
                                                " " +
                                                classes["push-bottom"]
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg>
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                        <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.total} :{" "}
                                                {this.state.retailer_detail["totalRetailer"] ? this.state.retailer_detail["totalRetailer"] : "0"}
                                            </span>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.active} :{" "}
                                                {
                                                    this.state.retailer_detail[
                                                        "totalActiveRetailer"
                                                    ]  && this.state.retailer_detail[
                                                        "totalActiveRetailer"
                                                    ]   != "" ? this.state.retailer_detail[
                                                        "totalActiveRetailer"
                                                    ]  : "0"
                                                }
                                            </span>
                                            <span className={classes["info-box-text"]}>
                                                {StaticKey.LOCALE_DATA.Home.inActive} :{" "}
                                                {
                                                    this.state.retailer_detail[
                                                        "totalInActiveRetailer"
                                                    ]  && this.state.retailer_detail[
                                                        "totalInActiveRetailer"
                                                    ]  != "" ? this.state.retailer_detail[
                                                        "totalInActiveRetailer"
                                                    ] : "0"
                                                }
                                            </span>                                           
                                            <div className={classes["progress"]}>
                                                <div
                                                    className="progress-bar"
                                                    style={{width: "50%"}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 col-12" >
                                    <div style={{background:"#c6cf46"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.gameWiseSales} - (Desktop)
                                        </span>  
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                <div className={"row"}>
                                                {Object.keys(this.state.gameSalesArray).map((key:any,value:any)=>{
                                                    return (
                                                        <div className={'col-6'} style={{"fontSize":"12px"}} key={key}>{this.state.gameSalesArray[key]}</div>  
                                                    )
                                                })
                                            }
                                            </div>
                                            </span> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-12" >
                                    <div style={{background:"#009f90"}}
                                        className={
                                            classes["info-box"] + " " + classes["bg-b-yellow"]
                                        }
                                    >
                                        <span className={classes["input-box-header"]}>
                                            {StaticKey.LOCALE_DATA.Home.gameWiseSales} - (Mobile)
                                        </span>
                                        <div className={classes["info-box-content"]}>
                                            <span className={classes["info-box-text"]}>
                                                <div className={"row"}>
                                                {Object.keys(this.state.gameSalesArrayMobile).map((key:any,value:any)=>{
                                                    return (
                                                        <div className={'col-6'} style={{"fontSize":"12px"}} key={key}>{this.state.gameSalesArrayMobile[key].replace("Mobile","")}</div>  
                                                    )
                                                })
                                            }
                                            </div>
                                            </span>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
