import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {HomePageService} from "../../../game_core/type_script/services/homepage"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"

export class ChangePassword extends Component {
    HomePageService = HomePageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    changePasswordLang: any = StaticKey.LOCALE_DATA.changePassword
    state: any = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        eyeTag:{
            oldPassword: "eye-slash",
            newPassword: "eye-slash",
            confirmPassword: "eye-slash",
        },
        changePasswordFields: [
            {
                id: "oldPassword",
                name: "oldPassword",
                label: this.changePasswordLang.oldPassword,
                type: "password",
                autoFocus: true,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                tooltipEnabled: false,
                tooltipContent: "",
                passwordCondition: false
            },
            {
                id: "newPassword",
                name: "newPassword",
                label: this.changePasswordLang.newPassword,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                tooltipEnabled: true,
                tooltipContent: StaticKey.PASSWORD_CONDITION,
                passwordCondition: true
            },
            {
                id: "confirmPassword",
                name: "confirmPassword",
                label: this.changePasswordLang.confirmPassword,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                tooltipEnabled: true,
                tooltipContent: StaticKey.PASSWORD_CONDITION,
                passwordCondition: true
            }
        ]
    }

    validateChangePasswordForm = () => {
        let flag = true
        this.state.changePasswordFields.map((val: any) => {
            
            const errorData = this.uiData.textConversion(val.label);
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    this.setState({
                        [val.name + "Error"]:
                        errorData +" "+
                            this.changePasswordLang.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        const pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: StaticKey.PASSWORD_CONDITION
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        if (flag && this.state.newPassword !== this.state.confirmPassword) {
            flag = false
            this.setState({
                ["confirmPasswordError"]: this.changePasswordLang.newConfirmPasswordEqual
            })
        }
        return flag
    }

    handleChangePasswordSubmit = (e: any) => {
        e.preventDefault()
        if (this.validateChangePasswordForm()) {
            this.uiData.updateShowLoading(true)
            const passwordChangeRequest = {
                "currentPassword": this.state.oldPassword,
                "newPassword": this.state.newPassword,
                "confirmPassword": this.state.confirmPassword
            }
            const response = this.HomePageService.updateChangePassword(passwordChangeRequest)
            response
                .then((updatePasswordResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!updatePasswordResponse.errorStatus) {
                        this.uiData.showToastMessage(
                            this.changePasswordLang.passwordUpdateSuccess,
                            ""
                        )
                        this.handleChangePasswordReset()
                    } else {
                        //this.uiData.showToastMessage(updatePasswordResponse.message, "error")
                        this.uiData.showToastMessage(
                            this.changePasswordLang.passwordUpdateFailed,
                            "error"
                        )
                        // this.handleChangePasswordReset()
                    }
                })
                .catch((err: any) => {
                    // console.log("handleChangePasswordSubmitError", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERROS[300001], "error")
                    // this.handleChangePasswordReset()
                })
        }
    }

    handleChangePasswordReset = () => {
        this.setState({
            "oldPassword": "",
            "oldPasswordError": "",
            "newPassword": "",
            "newPasswordError": "",
            "confirmPassword": "",
            "confirmPasswordError": ""
        })
    }

    handlePasswordInputChange = (e: any) => {
        const value = e.target.value
        this.setState({
            ...this.state,
            [e.target.name]: value,
            [e.target.name + "Error"]: ""
        })
    }
    handleEyeCheck(evt:any,id:any){
        if(document.getElementById(id)?.getAttribute('type')== "password"){
            document.getElementById(id)?.setAttribute('type',"text")
            this.setState({eyeTag:{...this.state.eyeTag,[id]:"eye"}})
        }else{
            document.getElementById(id)?.setAttribute('type',"password")
            this.setState({eyeTag:{...this.state.eyeTag,[id]:"eye-slash"}})
        }
    }
    render() {
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>{this.changePasswordLang.changeaPassword}</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {this.state.changePasswordFields.map((value: any, key: any) => {
                                    return (
                                        <div className={classes["sub-div"]} key={key}>
                                            <label htmlFor="name">{value.label}</label>
                                            <span className={classes["mandatory-symbol"]}>
                                                * &nbsp;
                                            </span>
                                            {value.tooltipEnabled && (
                                                    <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                        &nbsp;<i className="fa fa-info-circle"></i>
                                                        <span id="tooltiptext" className={classes["tooltiptext"]}>{value.tooltipContent}</span>
                                                </div>  
                                            )}
                                            <InputBox
                                                id={value.id}
                                                autoComplete={value.autoComplete}
                                                maxLength={value.maxLength}
                                                className={
                                                    "form-control " + classes["form-control"]
                                                }
                                                autoFocus={value.autoFocus}
                                                name={value.name}
                                                type={value.type}
                                                value={this.state[value.name]}
                                                onChange={this.handlePasswordInputChange}
                                            />
                                            <span id={"spanEye"+value.id} className={classes["eyeOpen"]} onClick={()=>this.handleEyeCheck(event,value.id)}>
                                                <i className={"fa fa-"+this.state.eyeTag[value.id]}></i>
                                            </span>
                                            <div className={classes["error"]}>
                                                {this.state[value.name + "Error"]}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value={this.changePasswordLang.submit}
                                        tabIndex={5}
                                        onClick={() => this.handleChangePasswordSubmit(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value={this.changePasswordLang.reset}
                                        tabIndex={6}
                                        onClick={this.handleChangePasswordReset}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
