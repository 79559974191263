import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/companylisting.module.scss"
interface Props {
    handlePagination?:any    
    fromPagePagination?:any  
    toPagePagination?:any  
    noRow?:any  
    totalCount?:any  
    totalCountPagination?:any  
    currentPage?:any  
    previousPage?:any  
    nextPage?:any  
    lastPage?:any  
}
export class PaginationElement extends Component<Props> {
    state:any={
        fromPagePagination: this.props.fromPagePagination,
        toPagePagination: this.props.toPagePagination,
        noRow: this.props.noRow,
        totalCount: this.props.totalCount,
        totalCountPagination: this.props.totalCountPagination,
        currentPage:this.props.currentPage,
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
        lastPage: this.props.lastPage,
    }
    handlePagination(e:any,page:any){
        this.props.handlePagination(e,page);
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
        })
    }
    render(){
        return(
            <>
            <div className={"table_pagination_div"}>
            {this.state.totalCountPagination.length > 0 ? (
                <>
                    <button
                        className={classes["buttonClass"]}
                        disabled={this.state.currentPage == 1 ? true : false}
                        onClick={event =>this.handlePagination(event, 1)}
                    >
                        First
                    </button>
                    <button
                        className={classes["buttonClass"]}
                        disabled={this.state.currentPage == 1 ? true : false}
                        onClick={event =>
                           this.handlePagination(event, this.state.previousPage)
                        }
                    >
                        <i className="fa fa-angle-double-left"></i> Previous
                    </button>
                    {this.state.totalCountPagination.map((val: any, ind: any) => {
                        let btnClass = ""
                        if (
                            ind + 1 >= this.state.fromPagePagination &&
                            ind + 1 <= this.state.toPagePagination
                        ) {
                            if (this.state.currentPage == ind + 1) {
                                btnClass = "active"
                            }
                            return (
                                <>
                                    <button
                                        className={
                                            classes["buttonClass"] +
                                            " " +
                                            classes[btnClass]
                                        }
                                        onClick={event =>
                                           this.handlePagination(event, ind + 1)
                                        }
                                    >
                                        {ind + 1}
                                    </button>
                                </>
                            )
                        }
                    })}
                    <button
                        className={classes["buttonClass"]}
                        disabled={
                            this.state.currentPage == this.state.lastPage
                                ? true
                                : false
                        }
                        onClick={event =>
                           this.handlePagination(event, this.state.nextPage)
                        }
                    >
                        Next <i className="fa fa-angle-double-right"></i>
                    </button>
                    <button
                        className={classes["buttonClass"]}
                        disabled={
                            this.state.currentPage == this.state.lastPage
                                ? true
                                : false
                        }
                        onClick={event =>
                           this.handlePagination(event, this.state.lastPage)
                        }
                    >
                        Last
                    </button>
                </>
            ) : (
                <></>
            )}
            </div>
            </>
        )
    }
}