import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/companylisting.module.scss"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import { SideBar } from "../common/sidebar"
import {InputBox} from "../common/elements/inputbox"
import {Link} from "react-router-dom"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {Modal} from "reactstrap"
import copy from "copy-to-clipboard"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"
import jsPDF from "jspdf"
import { PrintTableElement } from "../common/PrintTableElement"
import { TotalRecords } from "../common/totalRecords"
import { ExportExcelReport } from "./ExportExcelReport"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`

export class CompanyListing extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage()
    
    selectedMeny = {
        selectedMenu:"company listing"
    }
    SideBarService:any = new SideBar(this.selectedMeny,"")
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Login ID","Company Name","Company Code","Distributor","Retailer","Credit Limit","Status"],
        tableBody:["loginId","PARTNER_NAME","PARTNER_USERNAME","distributorCount","retaillerCount","creditLimit","COMPANYACCOUNT_STATUS"],
        exportHead:["Login ID","Company Name","Company Code","Distributor","Retailer","Credit Limit","Status"],
        exportBody:["loginId","PARTNER_NAME","PARTNER_USERNAME","distributorCount","retaillerCount","creditLimit","ACCOUNT_STATUS"],
        companyData:[],
        tooltipText: "Click to Copy",
        searchFilterRow: [0, 1],
        fromPagePagination: 1,
        toPagePagination: 5,
        alertPopupShow: "hide",
        noRow: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        username: "",
        accountStatus: "",
        fromDate: "",
        fromDateSearch: "",
        toDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        toDate: "",
        toDateError: "",
        showCompanyListing: false,
        distributorSearchData: [],
        securityCodeUsername: "",
        securityCodeLoginId: "",
        securityCode: "",
        loginId: "",
        fields: {
            "accountStatus": "",
            "toDate": "",
            "fromDate": "",
            "loginId": "",
            "username": "",
            "limit": 25,
            "page": 1
        },
        errors: {
            "accountStatusError": "",
            "toDateError": "",
            "fromDateError": "",
            "usernameError": ""
        }
    }
    getCompanyListFields: any = [
        {
            rowId: 0,
            name: "loginId",
            type: "number",
            label: "Login ID",
            placeHolder: "loginId",
            maxLength: "50",
            autoFocus: true,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId: 0,
            name: "username",
            type: "text",
            label: "Company Code",
            placeHolder: "Username",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId: 0,
            name: "accountStatus",
            type: "select",
            label: "Status",
            placeHolder: "Status",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            defaultValue: "",
            required: false,
            options: StaticKey.COMPANYSTATUS,
            showEmpty: true
        },
        {
            rowId: 1,
            "name": "fromDate",
            "type": "datetime",
            "label": "Start Date",
            // "placeHolder": "Start Date",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId: 1,
            "name": "toDate",
            "id": "toDate",
            "type": "datetime",
            "label": "End Date",
            // "placeHolder": "End Date",
            "maxLength": currentDate,
            // "disabled": true,
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId: 1,
            name: "dateRange",
            type: "select",
            label: "Date Range",
            placeHolder: "Date Range",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            defaultValue: "",
            required: false,
            options: StaticKey.DATERANGE,
            showEmpty: true
        }
    ]
    handleSearchCompanyListingReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.getCompanyListFields).map((keys: any, values: any) => {
            {
                this.setState({[this.getCompanyListFields[keys].name]: ""})
                return this.getCompanyListFields.map((fieldValObject: any, fieldValIndex: any) => {
                    this.getCompanyListFields[2].required = false
                    this.getCompanyListFields[2].min = ""
                    document.getElementById("toDate")?.setAttribute("readonly", "readonly")
                    // this.getCompanyListFields[2].type = "text";
                    return (
                        (newArrayError[fieldValObject.name + "Error"] = ""),
                        (newArrayFields[fieldValObject.name] = ""),
                        (newArrayFields[fieldValObject.name + "Val"] = "")
                    )
                })
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields,
            distributorSearchData: [],
            showCompanyListing: false,
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 2,
            lastPage: 0,
            fromDateSearch:"",
            toDateSearch:"",
            fromPagePagination: 1,
            toPagePagination: 5
            // fields:{
            //     "limit":25,
            //     "page":1,
            // }
        })

        // const date = new Date()
        // const currentDay = String(date.getDate()).padStart(2, "0")
        // const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        // const currentYear = date.getFullYear()
        // const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
        // const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`
        // this.setState({

        //     fromDate:currentDate,
        //     toDate:currentEndDate,
        //     fields:{
        //         // ...this.state.fields,
        //         fromDate:currentDate,
        //         toDate:currentEndDate,

        //     }
        // })
        // this.getCompanyListFields[4].required = false
        // document.getElementById("toDate")?.setAttribute("min",currentDate)
        document.getElementById("toDate")?.removeAttribute("readonly")
    }
    validateCreateCompanyListingSearchForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.getCompanyListFields).map((keys: any) => {
            {
                return this.getCompanyListFields.map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true
                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handleGeneratePdf = (e:any,partnerId:any,partnerName:any) => {	
        this.uiData.updateShowLoading(true)
        e.preventDefault();	
        this.checkPartnerLimit(partnerId)        
        setTimeout(()=>{
            const Canvas:any = document.getElementById("downloadPdf");
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.internal.pageSize.getWidth();
            pdf.internal.pageSize.getHeight();
            const opt:any = {
                callback: function (pdf:any) {
                    pdf.save(partnerName+".pdf");
                },
                margin: [10, 10, 10, 2],
                autoPaging: 'text',
                html2canvas: {
                    allowTaint: true,
                    dpi: 50,
                    letterRendering: true,
                    logging: false,
                    scale: .2
                }
            };
            pdf.html(Canvas, opt);
            this.uiData.updateShowLoading(false)
        },3000);
	};
    
    
    checkPartnerLimit = (id: any) => {
        const searchCondition = {
            "partnerGuid": id,
            "partnerTypeId":3
        }
        const response = this.DownlineManageServices.getCompanyListSearch(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                if (!searchPartnerLimitResponse.errorStatus) {
                    this.setState({
                        companyData: searchPartnerLimitResponse.data[0],
                        guId: id
                    })
                } else {
                    this.setState({
                        companyData: [],                       
                    })
                }
            })
            .catch((err: any) => {
                //console.log("handleCreatePartnerLimitSearchErr", err)
                this.setState({
                    companyData: [],                   
                })
            })
    }
    handleCreateCompanyListingSearch = (e: any) => {
        e.preventDefault()
        this.handleSearchFunction("search")
    }
    handleSearchFunction(param: any) {
        if (param == "search") {
            this.setState({
                // ...this.state,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
            this.setState({
                // username: "",
                // accountStatus: "",
                LedgerSearchData: [],
                showLedgerSearchListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }
        if (this.validateCreateCompanyListingSearchForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                username: this.state.fields.username,
                loginId: this.state.fields.loginId,
                accountStatus: this.state.accountStatus,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                partnerTypeId: 3,
                page: this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            }
            //console.log(searchCondition)
            const storage:any = [
                this.state.fields.username,
                this.state.fields.loginId,
                this.state.accountStatus,
                this.state.toDate,
                this.state.fromDate,
                this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                this.state.fields.limit,
                this.state.fields.dateRange,
                this.state.toDateSearch,
                this.state.fromDateSearch,
            ]
            //console.log(storage)
            const string = JSON.stringify(storage)
            this.uiData.setRetailerBackList(false)
            this.localStorageService.removeSearchConditionLocalStorage();
            this.localStorageService.setSearchConditionLocalStorage(string);
            const response = this.DownlineManageServices.getCompanyListSearch(searchCondition)
            response
                .then((searchDistributorResponse: any) => {
                    if (!searchDistributorResponse.errorStatus) {
                        this.setState({
                            distributorSearchData: searchDistributorResponse.data,
                            showCompanyListing: true,
                            totalCount: searchDistributorResponse.totalRecords
                        })
                        const data = []

                        for (
                            let i = 0;
                            i < searchDistributorResponse.totalRecords / this.state.noRow;
                            i++
                        ) {
                            data.push(i)
                        }
                        const divData = searchDistributorResponse.totalRecords / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        }
                        const dataResponse = searchDistributorResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                let value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                if(this.state.exportBody[j] == "ACCOUNT_STATUS"){
                                    value = dataResponse[i][this.state.exportBody[j]] == 0 ? "Inactive" : "Active"
                                }
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})
                        this.closeLoader()
                    } else {
                        this.setState({
                            distributorSearchData: [],
                            showCompanyListing: false,
                            totalCount: 0,
                            totalCountPagination: [],
                            currentPage: 1,
                            previousPage: 0,
                            nextPage: 2,
                            lastPage: 0,
                            fromPagePagination: 1,
                            toPagePagination: 5
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    //console.log("handleCreateCompanyListingSearchErr", err)
                    this.closeLoader()
                    this.setState({
                        distributorSearchData: [],
                        showCompanyListing: false,
                        totalCount: 0,
                        totalCountPagination: [],
                        currentPage: 1,
                        previousPage: 0,
                        nextPage: 2,
                        lastPage: 0,
                        fromPagePagination: 1,
                        toPagePagination: 5
                    })
                })
        }
    }
    closeLoader() {
        // setTimeout(() => {
        this.uiData.updateShowLoading(false)
        // }, 200)
    }
    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })

        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        setTimeout(() => {
            this.handleSearchFunction("pagination")
        }, 50)
    }
    componentDidMount(): void {
        
        const retailerBack = this.uiData.getRetailerBackList()
        if(retailerBack){
            const localDataSearch:any = this.localStorageService.getSearchConditionLocalStorage();
            const localSearchData = JSON.parse(localDataSearch)
            //console.log(localSearchData)
            this.setState({
                username: localSearchData[0],
                loginId: localSearchData[1],
                accountStatus: localSearchData[2],
                toDate: localSearchData[3]!= "" ? new Date(localSearchData[3]) : "",
                fromDate:  localSearchData[4]!= "" ? new Date(localSearchData[4]) : "",
                toDateSearch: localSearchData[8],
                fromDateSearch: localSearchData[9],
                dateRange: localSearchData[7],
                fields:{                    
                    username: localSearchData[0],
                    loginId: localSearchData[1],
                    accountStatus: localSearchData[2],
                    toDate: localSearchData[3]!= "" ? new Date(localSearchData[3]) : "",
                    fromDate:  localSearchData[4]!= "" ? new Date(localSearchData[4]) : "",
                    dateRange: localSearchData[7],
                    page: 0,
                    limit:this.state.fields.limit
                }
            })
            
            setTimeout(()=>{
                this.handleSearchFunction("search")                               
                //this.localStorageService.removeSearchConditionLocalStorage();
            },100)           
        }
        this.uiData.updateShowLoading(true)
        this.closeLoader()
    }

    handlePartnerCreditLimit(e: any) {
        e.preventDefault()
    }

    handleAlertPopupClose = () => {
        this.setState({
            alertPopupShow: "hide",
            securityCode: "",
            securityCodeLoginId: "",
            securityCodeUsername: "",
            tooltipText: "Click to Copy"
        })
    }
    handleSecurityCode = (evt: any, username: any, val: any, loginId: any) => {
        evt.preventDefault()
        // const data = username + "," + val
        this.setState({
            securityCode: val,
            securityCodeUsername: username,
            securityCodeLoginId: loginId,
            alertPopupShow: "show"
        })
        // this.uiData.showToastMessageToCopy(data, "info", "copy")
    }

    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
        this.setState({
            fromDate: date,
            fromDateSearch:finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            toDate: eDate,
            toDateSearch:finalDate
        })
    }
    companyListingFilterChange = (e: any) => {
        const value = e.target.value
        // this.setState({
        //     ...this.state,
        //     [e.target.name]: value,
        //     [e.target.name + "Error"]: ""
        // })
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            }
        })
        this.setState({[e.target.name]: value})
        if (this.state[e.target.name + "Callback"]) {
            if (e.target.name == "fromDate") {
                this.setState({toDate: ""})
                this.getCompanyListFields[4].min = value
                this.getCompanyListFields[4].type = "datetime-local"
                // this.getCompanyListFields[4].required = true
                // this.getCompanyListFields[2].readonly = false
                document.getElementById("toDate")?.removeAttribute("readonly")
            }
        }
        if (this.state[e.target.name + "Callback"]) {
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            dateRange:e.target.value,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
    }
    copyToClipboard() {
        this.setState({tooltipText: "Copied"})
        copy(this.state.securityCode)
    }

    handleSecurityCodeBlur = () => {
        this.setState({
            tooltipText: "Click to Copy"
        })
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
        //console.log(e)
    }
    handlePrintRecord(e:any){
        e.preventDefault()
        //console.log(e)
    }
    handleDistributorLink(e:any,company:any){
        
        const storage:any = [
            "","",company,"","","","","","","","","","","","",
        ]
        //console.log(storage)
        const string = JSON.stringify(storage)
        this.uiData.setRetailerBackList(true)
        this.localStorageService.removeSearchConditionLocalStorage();
        this.localStorageService.setSearchConditionLocalStorage(string);
        localStorage.setItem("menu", "Distributor Report")
        this.SideBarService.setMenuActive("Distributor Report")
    }
    handleRetailerLink(e:any,company:any){
        
        const storage:any = [
            "",company,"","","","","","","","","","","","",
        ]
        //console.log(storage)
        const string = JSON.stringify(storage)
        this.uiData.setRetailerBackList(true)
        this.localStorageService.removeSearchConditionLocalStorage();
        this.localStorageService.setSearchConditionLocalStorage(string);
        localStorage.setItem("menu", "Retailer Report")
        this.SideBarService.setMenuActive("Retailer Report")
    }
    
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
         a.print();
 setTimeout(function () { a.close(); }, 200);
    }
    render() {
        return (
            <div className={classes["right-section"]}>
                <div className="error-msg-section"></div>
                <div className={classes["main-div"]}>
                    <h2>Company Report</h2>    
                    <form>
                        {this.state.searchFilterRow.map((valRow: any, i: any) => {
                            return (
                                <div className={classes["main-div-input-sec"]} key={i}>
                                    {this.getCompanyListFields.map((val: any, ind: any) => {
                                        if (val.rowId == valRow) {
                                            return (
                                                <div className={classes["sub-div"]} key={ind}>
                                                    <label htmlFor="name">{val.label}</label>

                                                    {val.required ? (
                                                        <span
                                                            className={classes["mandatory-symbol"]}
                                                        >
                                                            {" "}
                                                            *{" "}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <InputBox
                                                        id={val.id}
                                                        autoComplete={val.autoComplete}
                                                        maxLength={val.maxLength}
                                                        className={
                                                            "form-control " + classes[val.class]
                                                        }
                                                        autoFocus={val.autoFocus}
                                                        // placeholder={val.placeHolder}
                                                        name={val.name}
                                                        type={val.type}
                                                        tabIndex={1}
                                                        value={this.state[val.name]}
                                                        min={val.min}
                                                        options={val.options}
                                                        required={val.required}
                                                        selectShowEmpty={val.showEmpty}
                                                        onChange={
                                                            val.type === "datetime"
                                                                ? val.name == "fromDate"
                                                                    ? this.handleStartDateChange
                                                                    : this.handleEndDateChange
                                                                : this.companyListingFilterChange
                                                        }
                                                        dateFormat={val.dateFormat}
                                                        startMaxDate={this.maxDate}
                                                        endMinDate={this.minDate}
                                                    />
                                                    <div className={classes["error"]}>
                                                        {this.state.errors[val.name + "Error"]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            )
                        })}
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="submit"
                                    value="Search"
                                    onClick={() => this.handleCreateCompanyListingSearch(event)}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="reset"
                                    value="Reset"
                                    onClick={() => this.handleSearchCompanyListingReset()}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                {this.state.showCompanyListing && (
                    <>                
                    
                        {this.state.totalCountPagination.length > 0 ? (
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.distributorSearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/>
                                {this.state.excelExport && (
                                    <ExportExcelReport mockData={this.state.mockData} sheetName="company_list"/> 
                                )}
                            </div>    
                        ):""}
                        <div className="d-flex">
                            {/* <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Records
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.totalCount !=""  ? this.state.totalCount : "0"}
                                    </span>
                                </div>
                            </div>  */}
                            
                            <TotalRecords totalCount={this.state.totalCount}/>
                            {this.state.totalCount > 0 ? (
                                <>
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                </>
                            ):""}
                        </div>
                        <div
                            className={classes["main-div"] + " " + classes["table-main-div"]}
                            style={{maxHeight: "400px"}}
                        >
                            <Table
                                className={
                                    "table table-bordered table-hover table-condensed" +
                                    classes["table"] +
                                    " " +
                                    classes["table-bordered"] +
                                    " " +
                                    classes["table-hover"] +
                                    " " +
                                    classes["table-condensed"]
                                }
                                id="provider_table_id"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Sl.No</Th>
                                        <Th>Login ID</Th>
                                        <Th>Company Name</Th>
                                        <Th>Company Code</Th>
                                        <Th>Distributor</Th>
                                        <Th>Retailer</Th>
                                        <Th>Credit Limit</Th>
                                        <Th>Status</Th>
                                        <Th style={{zIndex: 1}}>Action</Th>
                                        {/* <Th style={{zIndex: 1}}>Security Code</Th> */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.distributorSearchData.length > 0 ? (
                                        <>
                                            {this.state.distributorSearchData.map(
                                                (val: any, ind: any) => {
                                                    let status = "Active"
                                                    if (val.ACCOUNT_STATUS == 0) {
                                                        status = "Inactive"
                                                    }
                                                    let oddEven = "odd"
                                                    if (ind % 2 == 0) {
                                                        oddEven = "even"
                                                    }
                                                    return (
                                                        <Tr
                                                            className={classes["tr_" + oddEven]}
                                                            key={ind}
                                                        >
                                                            <Td>
                                                                {(this.state.currentPage - 1) *
                                                                    this.state.noRow +
                                                                    ind +
                                                                    1}
                                                            </Td>
                                                            <Td>{val.loginId}</Td>
                                                            <Td>
                                                                <Link onClick={()=>this.localStorageService.setFileNameLocalStorage("list-company.html")}
                                                                    to={
                                                                        "/view-company.html?id=" +
                                                                        val.PARTNER_GUID
                                                                    }
                                                                >
                                                                    {val.PARTNER_NAME}
                                                                </Link>
                                                            </Td>
                                                            <Td>
                                                                {/* <Link
                                                                to={
                                                                    "/view-company.html?id=" +
                                                                    val.PARTNER_GUID
                                                                }
                                                            > */}
                                                                {val.PARTNER_USERNAME}
                                                                {/* </Link> */}
                                                            </Td>
                                                            <Td>
                                                                <Link  to={
                                                                    "/list-distributor.html"
                                                                } onClick={()=>this.handleDistributorLink(event, val.PARTNER_GUID)}>
                                                                    {val.distributorCount}
                                                                </Link>
                                                            </Td>
                                                            <Td>
                                                                <Link  to={
                                                                    "/list-retailer.html"
                                                                } onClick={()=>this.handleRetailerLink(event, val.PARTNER_GUID)}>
                                                                    {val.retaillerCount}
                                                                </Link>
                                                            </Td>
                                                            {/* <Td>{val.retaillerCount}</Td> */}
                                                            
                                                            <Td>
                                                                <div
                                                                    className={
                                                                        classes["text-right"]
                                                                    }
                                                                >
                                                                    {val.creditLimit == ""
                                                                        ? "0.00"
                                                                        : val.creditLimit}
                                                                </div>
                                                            </Td>
                                                            <Td>{status}</Td>
                                                            <Td>
                                                                <Link onClick={()=>this.localStorageService.setFileNameLocalStorage("list-company.html")}
                                                                    to={
                                                                        "/set-company-limit.html?id=" +
                                                                        val.PARTNER_GUID
                                                                    }
                                                                >
                                                                     <div
                                                                        className={" text-danger "+
                                                                            classes["tooltip"]
                                                                        }
                                                                    >
                                                                        <i id={"pdf_"+val.PARTNER_GUID} className="fa fa-exchange"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-danger"
                                                                                ]
                                                                            }
                                                                        >
                                                                             Transfer Points
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                                &nbsp;
                                                                &nbsp; 
                                                                <a
                                                                    href="#"
                                                                    onClick={()=>this.handleGeneratePdf(event,val.PARTNER_GUID,val.PARTNER_NAME)}
                                                                >
                                                                    <div
                                                                        className={" text-success "+
                                                                            classes["tooltip"]
                                                                        }
                                                                    >
                                                                        <i id={"pdf_"+val.PARTNER_GUID} className="fa fa-file-pdf-o"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-success"
                                                                                ]
                                                                            }
                                                                        >
                                                                            Download Pdf
                                                                        </span>
                                                                    </div>
                                                                </a>{" "}
                                                                &nbsp;           
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        this.handleSecurityCode(
                                                                            event,
                                                                            val.PARTNER_USERNAME,
                                                                            val.DECRYPT_SECURITY_CODE,
                                                                            val.loginId
                                                                        )
                                                                    }
                                                                >
                                                                    <div className={" text-warning "+classes["tooltip"]}>
                                                                         <i className="fa fa-eye text-warning"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-warning"
                                                                                ]
                                                                            }
                                                                        >
                                                                           Security Code
                                                                        </span>
                                                                    </div>                  
                                                                </a>
                                                            </Td>
                                                            {/* <Td>
                                                                <div className={"text-center"}>
                                                                    <a
                                                                        href="#"
                                                                        onClick={() =>
                                                                            this.handleSecurityCode(
                                                                                event,
                                                                                val.PARTNER_USERNAME,
                                                                                val.DECRYPT_SECURITY_CODE,
                                                                                val.loginId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa fa-eye text-warning"></i>
                                                                    </a>
                                                                </div>
                                                            </Td> */}
                                                            {/* <td>{val.PARTNER_CREATED_DATE}</td> */}
                                                        </Tr>
                                                    )
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <Tr className={classes["no_data_found"]}>
                                            <Td colSpan={10}>No record(s) available</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        <div className={"table_pagination_div"}>
                            {this.state.totalCountPagination.length > 0 ? (
                                <>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event => this.handlePagination(event, 1)}
                                    >
                                        First
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event =>
                                            this.handlePagination(event, this.state.previousPage)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left"></i> Previous
                                    </button>
                                    {this.state.totalCountPagination.map((val: any, ind: any) => {
                                        let btnClass = ""
                                        if (
                                            ind + 1 >= this.state.fromPagePagination &&
                                            ind + 1 <= this.state.toPagePagination
                                        ) {
                                            if (this.state.currentPage == ind + 1) {
                                                btnClass = "active"
                                            }
                                            return (
                                                <>
                                                    <button
                                                        className={
                                                            classes["buttonClass"] +
                                                            " " +
                                                            classes[btnClass]
                                                        }
                                                        onClick={event =>
                                                            this.handlePagination(event, ind + 1)
                                                        }
                                                    >
                                                        {ind + 1}
                                                    </button>
                                                </>
                                            )
                                        }
                                    })}
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.nextPage)
                                        }
                                    >
                                        Next <i className="fa fa-angle-double-right"></i>
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.lastPage)
                                        }
                                    >
                                        Last
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                )}

                <>
                    {this.state.alertPopupShow == "show" && (
                        <Modal
                            className={"modal-dialog-centered"}
                            isOpen={true}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Security Code</h4>
                                <span
                                    onClick={this.handleAlertPopupClose}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <div className="modal-body mb-2 d-flex">
                                <div className="me-5">{this.state.securityCodeLoginId}</div>
                                <div className="me-5">{this.state.securityCodeUsername}</div>
                                <div className="me-5">
                                    <b>{this.state.securityCode}</b>
                                </div>
                                <div
                                    onClick={() => this.copyToClipboard()}
                                    onMouseLeave={() => this.handleSecurityCodeBlur()}
                                    className={classes["tooltip"] + " " + classes["copyDiv"]}
                                    style={{cursor: "pointer"}}
                                >
                                    <i className="fa fa-copy"></i>
                                    <span id="tooltiptext" className={classes["tooltiptext"]}>
                                        {this.state.tooltipText}
                                    </span>
                                </div>
                            </div>
                        </Modal>
                    )}
                    
                    <div  style={{height:"0",overflow:"auto"}}> 
                    
                        <div id="downloadPdf" > 
                        <div className="list-detail-page">
                        <div className={"row"}>
                            <div className="col-sm-6">
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Username
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.PARTNER_USERNAME}
                                    </div>
                                </div>
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Name
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.LEVEL_3_NAME}
                                    </div>
                                </div> */}
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Available Limit
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.creditLimit}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Opening OS
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.creditLimit}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Closing Balance
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.closingBalance}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Payable
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.payable}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Account Status
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.ACCOUNT_STATUS == 1 ? "Active" : "In-Active"}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Net. Sale Possible
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.netSalePossible}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        MRP
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.mrpSale}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Payout
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.payout}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Collectable
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.receivable}
                                    </div>
                                </div>
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Security Code
                                    </div>
                                    <div className="col-6">
                                        <a href="#" onClick={() => this.handleSecurityCode( event,this.state.companyData.PARTNER_USERNAME , this.state.companyData. DECRYPT_SECURITY_CODE ) } >
                                            <i className="fa fa-eye text-warning"></i>
                                        </a>
                                    </div>
                                </div> */}
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Created Date
                                    </div>
                                    <div className="col-6">
                                        {this.state.companyData.PARTNER_CREATED_DATE}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </>
            </div>
        )
    }
}
