import React, {Component} from "react"
//import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
// import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Modal} from "reactstrap"

export class EditDistributor extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    state: any = {
        alertPopupShow: "hide",
        termsConditionRead:false,
        termsAgreedCallback: true,
        noOfPartnershipCallback:true,
        registrationAadharCardNoCallback:true,
        // registrationIfscCodeCallback:true,
        companyList: {},
        pincodeData : [],
        fields: {
            distribtutorGuid: "",
            companyId: "",
            noOfPartnership: "",
            partnerName: "",
            partner1name: "",
            partner2name: "",
            partner3name: "",
            partnerAadharCardNo: "",
            partnerUsername: "",
            // partnerPassword: "",
            // partnerTransactionPassword: "",
            partnerPanCardNo: "",
            partnerAddress1: "",
            partnerAddress2: "",
            // partnerAddress3: "",
            partnerAddress4: "",
            partnerPinCode: "",
            partnerLocality: "",
            partnerTaluka: "",
            partnerCity: "",
            partnerState: "",
            partnerMobileNo: "",
            partnerWhatsappNo: "",
            partnerTelegramNo: "",
            // partnerwebsite: "",
            partnerEmail: "",
            // partnerLandLineNo: "",
            businessTotalYears: "",
            businessTotalManpower: "",
            businessSalesManpower: "",
            businessOfficeArea: "",
            businessOfficeManpower: "",
            businessOfficeCount: "",
            businessOfficeType: 1,
            businessWarehouseArea: "",
            businessWarehouseManpower: "",
            businessWarehouseCount: "",
            businessWarehouseType: "2",
            registrationPanCardNo: "",
            registrationAadharCardNo: "",
            registrationIfscCode: "",
            registrationBankName: "",
            registrationBranchName: "",
            registrationAccountNo: "",
            companyName1: "",
            companyName2: "",
            companyName3: "",
            companyCount: "",
            companyProductHandle: "",
            companyOwnedVehicleCount: "",
            companyTowneCount: "",
            uploadAadhar: "",
            uploadAadharVal: "",
            uploadPanCard: "",
            uploadPanCardVal: "",
            uploadDrivingLicence: "",
            uploadDrivingLicenceVal: "",
            uploadEBBill: "",
            uploadEBBillVal: "",
            uploadPassport: "",
            uploadPassportVal: "",
            uploadPhoto: "",
            uploadPhotoVal: "",
            // termsAgreed: ""
        },
        errors: {
            noOfPartnershipError: "",
            partnerNameError: "",
            partner1nameError: "",
            partner2nameError: "",
            partner3nameError: "",
            partnerAadharCardNoError: "",
            partnerUsernameError: "",
            partnerPanCardNoError: "",
            partnerAddress1Error: "",
            partnerAddress2Error: "",
            // partnerAddress3Error: "",
            partnerAddress4Error: "",
            partnerPinCodeError: "",
            partnerLocalityError: "",
            partnerTalukaError: "",
            partnerCityError: "",
            partnerStateError: "",
            partnerMobileNoError: "",
            partnerWhatsappNoError: "",
            partnerTelegramNoError: "",
            partnerwebsiteError: "",
            partnerEmailError: "",
            // partnerPasswordError: "",
            // partnerTransactionPasswordError: "",
            businessTotalYearsError: "",
            businessTotalManpowerError: "",
            businessSalesManpowerError: "",
            businessOfficeAreaError: "",
            businessOfficeManpowerError: "",
            businessOfficeCountError: "",
            businessOfficeTypeError: "",
            businessWarehouseAreaError: "",
            businessWarehouseManpowerError: "",
            businessWarehouseCountError: "",
            businessWarehouseTypeError: "",
            registrationPanCardNoError: "",
            registrationAadharCardNoError: "",
            registrationIfscCodeError: "",
            registrationBankNameError: "",
            registrationBranchNameError: "",
            registrationAccountNoError: "",
            companyName1Error: "",
            companyName2Error: "",
            companyName3Error: "",
            companyCountError: "",
            companyProductHandleError: "",
            companyOwnedVehicleCountError: "",
            companyTowneCountError: "",
            uploadAadharError: "",
            uploadPanCardError: "",
            uploadDrivingLicenceError: "",
            uploadEBBillError: "",
            uploadPassportError: "",
            uploadPhotoError: "",
            // termsAgreedError: ""
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        
        this.getDistributorDetail(id)
        this.setState({fields:{distribtutorGuid:id}});
        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                    LoginpartnerTypeId: loginResponse.partnerTypeId
            })
        }
        const loginData = this.uiData.getLoginResponse()
        if (loginData.partnerTypeId == 0) {
            this.getCompanyList({
                partnerTypeId: 3
            })
        } else {
            Object.keys(this.editDistributorFields).map((keys: any) => {
                {
                    return this.editDistributorFields[keys].map(
                        (fieldValObject: any, fieldValIndex: any) => {
                            if (Object.keys(fieldValObject).length == 1) {
                                return Object.keys(fieldValObject).map((keys1: any) => {
                                    fieldValObject[keys1].map(() => {
                                        return ""
                                    })
                                })
                            } else {
                                if (fieldValObject.name == "companyId") {
                                    return this.editDistributorFields[keys].splice(
                                        fieldValIndex,
                                        1
                                    )
                                }
                            }
                        }
                    )
                }
            })            
        }
    }
    
    getDistributorDetail = (id: any) => {
        
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            "partnerGuid": id,
        }
        const response = this.DownlineManageServices.getDistributorDetail(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                //console.log(searchPartnerLimitResponse)
                if (!searchPartnerLimitResponse.errorStatus) {
                    const responseData = searchPartnerLimitResponse.data;
                    this.setState({
                        distributorData: searchPartnerLimitResponse.data,
                        guId: id
                    })
                            if(responseData.noOfPartnership == 1){
                                // this.editDistributorFields["A. General Information"][2].required=true;
                                // this.editDistributorFields["A. General Information"][3].required=false;
                                // this.editDistributorFields["A. General Information"][4].required=false;
                                this.editDistributorFields["A. General Information"][2].dnone=false;
                                this.editDistributorFields["A. General Information"][3].dnone=true;
                                this.editDistributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 2){
                                // this.editDistributorFields["A. General Information"][2].required=true;
                                // this.editDistributorFields["A. General Information"][3].required=true;
                                // this.editDistributorFields["A. General Information"][4].required=false;
                                this.editDistributorFields["A. General Information"][2].dnone=false;
                                this.editDistributorFields["A. General Information"][3].dnone=false;
                                this.editDistributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 3){
                                // this.editDistributorFields["A. General Information"][2].required=true;
                                // this.editDistributorFields["A. General Information"][3].required=true;
                                // this.editDistributorFields["A. General Information"][4].required=true;
                                this.editDistributorFields["A. General Information"][2].dnone=false;
                                this.editDistributorFields["A. General Information"][3].dnone=false;
                                this.editDistributorFields["A. General Information"][4].dnone=false;
                                
                            }
                            if(responseData.noOfPartnership == 0 || responseData.noOfPartnership == ""){
                                // this.editDistributorFields["A. General Information"][2].required=false;
                                // this.editDistributorFields["A. General Information"][3].required=false;
                                // this.editDistributorFields["A. General Information"][4].required=false;
                                this.editDistributorFields["A. General Information"][2].dnone=true;
                                this.editDistributorFields["A. General Information"][3].dnone=true;
                                this.editDistributorFields["A. General Information"][4].dnone=true;
                                
                            }
                    let partner1 ="";
                    let partner2 ="";
                    let partner3 ="";
                    if(responseData.partnershipName && responseData.partnershipName !=""){
                        const dataSplit = responseData.partnershipName.split(",");
                        partner1 = dataSplit[0] ? dataSplit[0] : "";
                        partner2 = dataSplit[1] ? dataSplit[1] : "";
                        partner3 = dataSplit[2] ? dataSplit[2] : "";
                    }
                    
                    this.handlePincodeFunction(responseData.zipcode);
                    setTimeout(()=>{
                        this.setState({
                            ...this.state,
                            fields: {
                                ...this.state.fields,
                                noOfPartnership: responseData.noOfPartnership ? responseData.noOfPartnership:"0",
                                companyId: responseData.companyGuid ? responseData.companyGuid:"",
                                partnerName: responseData.partnerName ? responseData.partnerName:"",
                                partner1name: partner1,
                                partner2name: partner2,
                                partner3name: partner3,
                                // partnerAadharCardNo: "1231 2312 3123",
                                partnerUsername: responseData.partnerUsername ? responseData.partnerUsername : "",
                                // partnerPassword: "",
                                // partnerTransactionPassword: "",
                                // partnerPanCardNo: "",
                                partnerAddress1:  responseData.address? responseData.address:"",
                                partnerAddress2: responseData.address1 ? responseData.address1:"",
                                // partnerAddress3: "",
                                // partnerAddress4: "",
                                partnerPinCode: responseData.zipcode ? responseData.zipcode:"",
                                partnerLocality: responseData.locality ? responseData.locality:"",
                                partnerTaluka: responseData.taluk ? responseData.taluk:"",
                                partnerCity: responseData.cityName ? responseData.cityName:"",
                                partnerState: responseData.stateName ? responseData.stateName:"",
                                partnerMobileNo: responseData.mobileNo ? responseData.mobileNo:"",
                                partnerWhatsappNo: responseData.whatsappNo ? responseData.whatsappNo:"",
                                partnerTelegramNo: responseData.telegramNo ? responseData.telegramNo:"",
                                // partnerwebsite: "",
                                partnerEmail: responseData.emailId ? responseData.emailId:"",
                                // partnerLandLineNo: "",
                                businessTotalYears: responseData.noOfYearsBusiness ? responseData.noOfYearsBusiness:"",
                                businessTotalManpower: responseData.totalManPower ? responseData.totalManPower:"",
                                businessSalesManpower: responseData.salesManPower ? responseData.salesManPower:"",
                                businessOfficeArea:  responseData.totalOfficeArea ? responseData.totalOfficeArea:"",
                                businessOfficeManpower: responseData.officeManPower ? responseData.officeManPower:"",
                                businessOfficeCount: responseData.totalNoOfOffice ? responseData.totalNoOfOffice:"",
                                businessOfficeType: responseData.officeType == 1 ? "Owned" : "Rental",
                                businessWarehouseArea: responseData.totalWarehouseArea ? responseData.totalWarehouseArea:"",
                                businessWarehouseManpower: responseData.warehouseManpower ? responseData.warehouseManpower:"",
                                businessWarehouseCount: responseData.totalNoofWarehouse ? responseData.totalNoofWarehouse:"",
                                businessWarehouseType: responseData.warehouseType == 1 ? "Owned" : "Rental",
                                registrationPanCardNo: responseData.pancardNo ? responseData.pancardNo:"",
                                registrationAadharCardNo: responseData.aadharNo ? responseData.aadharNo:"",
                                registrationIfscCode: responseData.ifscCode ? responseData.ifscCode:"",
                                registrationBankName: responseData.bankName ? responseData.bankName:"",
                                registrationBranchName: responseData.branchName ? responseData.branchName:"",
                                registrationAccountNo: responseData.accountNo ? responseData.accountNo:"",
                                companyName1: responseData.listOfCompany1 ? responseData.listOfCompany1:"",
                                companyName2:  responseData.listOfCompany2 ? responseData.listOfCompany2:"",
                                companyName3:  responseData.listOfCompany3 ? responseData.listOfCompany3:"",
                                companyCount: responseData.noOfCompanies ? responseData.noOfCompanies:"",
                                companyProductHandle: responseData.productHandled ? responseData.productHandled:"",
                                companyOwnedVehicleCount: responseData.noOfOwnedVehicles ? responseData.noOfOwnedVehicles:"",
                                companyTowneCount: responseData.noOfTownCovered ? responseData.noOfTownCovered:"",
                                uploadAadhar: responseData.aadharImage !="" ? responseData.aadharImage : "",
                                uploadAadharVal: responseData.aadharImageUrl ? responseData.aadharImageUrl:"" ,
                                uploadPanCard: responseData.pancardImage  !="" ? responseData.pancardImage : "",
                                uploadPanCardVal:responseData.pancardImageUrl ? responseData.pancardImageUrl:"",
                                uploadDrivingLicence: responseData.drivingLicenceImage  !="" ? responseData.drivingLicenceImage : "",
                                uploadDrivingLicenceVal: responseData.drivingLicenceImageUrl ? responseData.drivingLicenceImageUrl:"",
                                uploadEBBill: responseData.ebBillImage  !="" ? responseData.ebBillImage : "",
                                uploadEBBillVal: responseData.ebBillImageUrl ? responseData.ebBillImageUrl:"",
                                uploadPassport: responseData.passportImage  !="" ? responseData.passportImage : "",
                                uploadPassportVal: responseData.passportImageUrl ? responseData.passportImageUrl:"",
                                uploadPhoto: responseData.photo  !="" ? responseData.photo : "", 
                                uploadPhotoVal: responseData.photoUrl ? responseData.photoUrl:"",
                                // termsAgreed: ""
                            }
                        })

                    },100)
                    this.closeLoader()
                } else {
                    this.setState({
                        distributorData: []
                    })
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // //console.log("handleCreatePartnerLimitSearchErr", err)
                this.uiData.updateShowLoading(false)
                this.setState({
                    distributorData: []
                })
                this.closeLoader()
            })
    }
    
    
    closeLoader() {
        this.uiData.updateShowLoading(false)
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    handleClearImage(e:any,value:any){
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [value]: "",
                [value + "Val"]: ""
            },
            errors: {
                ...this.state.errors,
                [value + "Error"]: ""
            }
        })

    }
    handleIfsc(e:any) {
        if(e && e.target ) {
            
            if(e.target.name == "partnerUsername"){
                this.checkPartnerExist()
            }else if(e.target.name == "registrationIfscCode"){
                if(this.state.fields['registrationIfscCode'] != "") {
                    this.uiData.updateShowLoading(true)
                    fetch('https://ifsc.razorpay.com/'+this.state.fields['registrationIfscCode'])
                    .then((response:any) => response.json())
                    .then((json) => {
                        if(json.BRANCH != undefined){
                            this.setState({ 
                                ...this.state, 
                                fields:{
                                    ...this.state.fields,
                                "registrationBankName":json['BANK'],
                                "registrationBranchName":json['BRANCH'],
                                    },
                                    errors:{
                                        ...this.state.errors,
                                    "registrationBankNameError":"",
                                    "registrationBranchNameError":"",
                                }
                            })
                        }else{
                            this.setState({
                                ...this.state,
                                errors:{
                                    ...this.state.errors,
                                    "registrationIfscCodeError":"Invalid IFSC",
                                },                                
                                fields:{
                                    ...this.state.fields,
                                    "registrationIfscCode": "",
                                    "registrationBankName":"",
                                    "registrationBranchName":""
                                }
                            })
                            document.getElementById(e.target.id)?.focus()
                        }                            
                        this.uiData.updateShowLoading(false)
                    })
                    .catch((error:any) => { 
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "registrationIfscCodeError":"Invalid IFSC",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "registrationIfscCode": "",
                                "registrationBankName":"",
                                "registrationBranchName":""
                            }
                        })              
                        document.getElementById(e.target.id)?.focus()              
                        this.uiData.updateShowLoading(false)
                    } );
                }
            }else if(e.target.name == "partnerPinCode"){
                if(this.state.fields['partnerPinCode'] != "") {
                    this.uiData.updateShowLoading(true)
                    fetch('https://api.postalpincode.in/pincode/'+this.state.fields['partnerPinCode'])
                    .then((response:any) => response.json())
                    .then((json) => {
                        // // //console.log(json[0].Status)
                        const dataArray:any =[];
                        const dataArrayData:any =[];
                        for(let iLoop = 0; iLoop<this.editDistributorFields["A. General Information"].length;iLoop++){             
                            if(this.editDistributorFields["A. General Information"][iLoop] && this.editDistributorFields["A. General Information"][iLoop].name && this.editDistributorFields["A. General Information"][iLoop].name != undefined && this.editDistributorFields["A. General Information"][iLoop].name ==  "partnerLocality"){
                                this.editDistributorFields["A. General Information"][iLoop].options = []
                            }
                        }   
                        if(json[0].Status == "Success"){
                            const dataFromSuccess = json[0].PostOffice;
                            for(let i=0;i<dataFromSuccess.length;i++){
                                let arrayData:any = [];
                                const arrayData1:any = [];
                                // const arrayData2:any = [];
                                arrayData = {'id':dataFromSuccess[i].Name,'name':dataFromSuccess[i].Name}
                                arrayData1[dataFromSuccess[i].Name] = {'taluk':dataFromSuccess[i].Block,'state':dataFromSuccess[i].Circle,'city':dataFromSuccess[i].District}
                                dataArray.push(arrayData)
                                dataArrayData.push(arrayData1)
                            }
                            this.setState({
                                ...this.state,
                                pincodeData:dataArrayData
                            })            
                            for(let iLoop = 0; iLoop<this.editDistributorFields["A. General Information"].length;iLoop++){             
                                if(this.editDistributorFields["A. General Information"][iLoop] && this.editDistributorFields["A. General Information"][iLoop].name && this.editDistributorFields["A. General Information"][iLoop].name != undefined && this.editDistributorFields["A. General Information"][iLoop].name ==  "partnerLocality"){
                                    this.editDistributorFields["A. General Information"][iLoop].options =dataArray
                                }
                            }   
                            this.setState({
                                ...this.state,
                                errors:{
                                    ...this.state.errors,
                                    "partnerPinCodeError":"",
                                    "partnerLocalityError":"",
                                    "partnerTalukaError":"",
                                    "partnerCityError":"",
                                    "partnerStateError":"",
                                },                                
                                fields:{
                                    ...this.state.fields,
                                    "partnerTaluka": "" ,
                                    "partnerLocality":"",
                                    "partnerCity":"",
                                    "partnerState":"",
                                }
                            })
                        }else{                              
                            this.setState({
                                ...this.state,
                                errors:{
                                    ...this.state.errors,
                                    "partnerPinCodeError":"Invalid Pincode",
                                },                                
                                fields:{
                                    ...this.state.fields,
                                    "partnerPinCode": "" ,
                                    "partnerTaluka": "" ,
                                    "partnerLocality":"",
                                    "partnerCity":"",
                                    "partnerState":"",
                                }
                            })
                            document.getElementById(e.target.id)?.focus()
                        }                            
                        this.uiData.updateShowLoading(false)

                    })
                    .catch((error:any) => { 
                        // //console.log(error);
                        // this.uiData.showToastMessage(
                        //     "Invalid Ifsc" , "error"
                        // )
                                                   
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "partnerPinCodeError":"Invalid Pincode",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "partnerPinCode": "" ,
                                "partnerTaluka": "" ,
                                "partnerLocality":"",
                                "partnerCity":"",
                                "partnerState":"",
                            }
                        })                            
                        this.uiData.updateShowLoading(false)
                    } );
                }
            }else if(e.target.name == "registrationPanCardNo"){
                const inputvalues = e.target.value; 
                if(inputvalues != ""){
                    const regex = /[A-Z a-z]{5}[0-9]{4}[A-Z a-z]{1}$/;    
                    if(!regex.test(inputvalues)){   
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "registrationPanCardNoError":"Invalid Pan Number",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "registrationPanCardNo": "" ,
                            }
                        })
                        document.getElementById(e.target.id)?.focus()
                    } else{
                        // //console.log("assd")
                    }   

                }     
    
            }

        }
    }

    handlePincodeFunction(pincode:any){
        this.uiData.updateShowLoading(true)
        fetch('https://api.postalpincode.in/pincode/'+pincode)
        .then((response:any) => response.json())
        .then((json) => {
            // // //console.log(json[0].Status)
            const dataArray:any =[];
            const dataArrayData:any =[];
            for(let iLoop = 0; iLoop<this.editDistributorFields["A. General Information"].length;iLoop++){             
                if(this.editDistributorFields["A. General Information"][iLoop] && this.editDistributorFields["A. General Information"][iLoop].name && this.editDistributorFields["A. General Information"][iLoop].name != undefined && this.editDistributorFields["A. General Information"][iLoop].name ==  "partnerLocality"){
                    this.editDistributorFields["A. General Information"][iLoop].options = []
                }
            }   
            if(json[0].Status == "Success"){
                const dataFromSuccess = json[0].PostOffice;
                for(let i=0;i<dataFromSuccess.length;i++){
                    let arrayData:any = [];
                    const arrayData1:any = [];
                    // const arrayData2:any = [];
                    arrayData = {'id':dataFromSuccess[i].Name,'name':dataFromSuccess[i].Name}
                    arrayData1[dataFromSuccess[i].Name] = {'taluk':dataFromSuccess[i].Block,'state':dataFromSuccess[i].Circle,'city':dataFromSuccess[i].District}
                    dataArray.push(arrayData)
                    dataArrayData.push(arrayData1)
                }
                this.setState({
                    ...this.state,
                    pincodeData:dataArrayData
                })            
                for(let iLoop = 0; iLoop<this.editDistributorFields["A. General Information"].length;iLoop++){             
                    if(this.editDistributorFields["A. General Information"][iLoop] && this.editDistributorFields["A. General Information"][iLoop].name && this.editDistributorFields["A. General Information"][iLoop].name != undefined && this.editDistributorFields["A. General Information"][iLoop].name ==  "partnerLocality"){
                        this.editDistributorFields["A. General Information"][iLoop].options =dataArray
                    }
                }   
                this.setState({
                    ...this.state,
                    // errors:{
                    //     ...this.state.errors,
                    //     "partnerPinCodeError":"",
                    //     "partnerLocalityError":"",
                    //     "partnerTalukaError":"",
                    //     "partnerCityError":"",
                    //     "partnerStateError":"",
                    // },                                
                    // fields:{
                    //     ...this.state.fields,
                    //     "partnerTaluka": "" ,
                    //     "partnerLocality":"",
                    //     "partnerCity":"",
                    //     "partnerState":"",
                    // }
                })
            }else{                              
                this.setState({
                    ...this.state,
                    errors:{
                        ...this.state.errors,
                        "partnerPinCodeError":"Invalid Pincode",
                    },                                
                    fields:{
                        ...this.state.fields,
                        "partnerPinCode": "" ,
                        "partnerTaluka": "" ,
                        "partnerLocality":"",
                        "partnerCity":"",
                        "partnerState":"",
                    }
                })
                document.getElementById("partnerPincode")?.focus()
            }
                
            this.uiData.updateShowLoading(false)

        })
        .catch((error:any) => {                 
            this.setState({
                ...this.state,
                errors:{
                    ...this.state.errors,
                    "partnerPinCodeError":"Invalid Pincode",
                },                                
                fields:{
                    ...this.state.fields,
                    "partnerPinCode": "" ,
                    "partnerTaluka": "" ,
                    "partnerLocality":"",
                    "partnerCity":"",
                    "partnerState":"",
                }
            })                            
            this.uiData.updateShowLoading(false)
        } );
    }
    editDistributorFields: any = {
        "A. General Information": [
            {
                name: "noOfPartnership",
                type: "number",
                label: "No of Partnership",
                //placeHolder: "No of Partnership",
                maxLength: 50,
                autoFocus: true,
                class: "form-control",dnone:false,
                required: true
            },
            {"": []},
            {
                name: "partner1name",
                type: "text",
                label: "Partner1",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",dnone:true,
                required: false,
                // readonly:true
            },
            {
                name: "partner2name",
                type: "text",
                label: "Partner2",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",dnone:true,
                required: false,
                // readonly:true
            },
            {
                name: "partner3name",
                type: "text",
                label: "Partner3",
                // placeHolder:  "Third Partner Name",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",dnone:true,
                required: false,
                // readonly:true
                // options: {},
                // selectShowEmpty: true
            },
            {
                name: "",
                type: "",
                label: "Name 3",
                // placeHolder:  "Third Partner Name",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",dnone:true,
                required: false,
                // readonly:true
                // options: {},
                // selectShowEmpty: true
            },
            {"": []},
            {
                name: "companyId",
                type: "select",
                label: "Company",
                //placeHolder: "Company",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true,
                options: {},
                selectShowEmpty: true
            },
            {
                name: "partnerName",
                type: "text",
                label: "Distributor Name",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },{
                name: "partnerUsername",
                type: "text",
                label: "Distributor Code",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false,
                onblur: "checkPartnerExist"
            },
            {"": []},
            {
                name: "partnerAddress1",
                type: "text",
                label: "Address1",
                // placeHolder: "Address1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
            // {"": []},
            {
                name: "partnerAddress2",
                type: "text",
                label: "Address2",
                // placeHolder: "Address2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            // {
            //     name: "partnerAddress3",
            //     type: "text",
            //     label: "Establishment Address3",
            //     // placeHolder: "Address3",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: false
            // },
            // {"": []},
            
            // {
            //     name: "partnerPassword",
            //     type: "password",
            //     label: "Password",
            //     //placeHolder: "Password",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: true,
            //     passwordCondition: true
            // },
            // {
            //     name: "partnerTransactionPassword",
            //     type: "password",
            //     label: "Transaction Password",
            //     //placeHolder: "Transaction Password",
            //     maxLength: 6,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: true,
            //     passwordCondition: true
            // },            
            {"": []},
            {
                "name": "partnerPinCode",
                "type": "text",
                "label": "Pincode",
                //"placeHolder": "PinCode",
                "maxLength": 7,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            },
            {
                "name": "partnerLocality",
                "type": "select",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true,                
                "options": {},
                "selectShowEmpty": true
            },
            {
                "name": "partnerTaluka",
                "type": "text",
                "label": "Taluka",
                //"placeHolder: "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "readonly":true,
                "required": true
            },
            {
                "name": "partnerCity",
                "type": "text",
                "label": "City",
                //"placeHolder: "City",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "readonly":true,
                "required": true
            },
            {
                "name": "partnerState",
                "type": "text",
                "label": "State",
                //"placeHolder: "State",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "readonly":true,
                "required": true
            },
            // {
            //     name: "partnerAadharCardNo",
            //     type: "number",
            //     label: "Aadhar Card No",
            //     //placeHolder: "Partner Aadhar Card No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: true
            // },
            // {
            //     name: "partnerPanCardNo",
            //     type: "text",
            //     label: "PAN No",
            //     //placeHolder: "Partner Pan No",
            //     maxLength: 10,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: true
            // },  
            // {"": []},
            {
                name: "partnerMobileNo",
                type: "number",
                label: "Mobile No",
                //placeHolder: "Mobile No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
            // {
            //     name: "partnerLandLineNo",
            //     type: "number",
            //     label: "Landline No",
            //     //placeHolder: "Landline No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: false
            // },
            {
                name: "partnerWhatsappNo",
                type: "number",
                label: "Whatsapp No",
                //placeHolder: "Whatsapp No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "partnerTelegramNo",
                type: "number",
                label: "Telegram Id",
                //placeHolder: "Telegram Id",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "partnerEmail",
                type: "text",
                label: "Email Id",
                //placeHolder: "Email",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
            // {
            //     name: "partnerwebsite",
            //     type: "text",
            //     label: "Website Address",
            //     //placeHolder: "Website Address",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",dnone:false,
            //     required: false
            // }
        ],
        "B. Current Business Information": [
            {
                name: "businessTotalYears",
                type: "number",
                label: "Number of years in business",
                //placeHolder: "Business Total Years",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "businessTotalManpower",
                type: "number",
                label: "Total Manpower",
                //placeHolder: "Total Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "businessSalesManpower",
                type: "number",
                label: "Sales Manpower",
                //placeHolder: "Sales Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                Office: [
                    {
                        name: "businessOfficeArea",
                        type: "number",
                        label: "Total Office Area",
                        //placeHolder: "Total Office Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessOfficeManpower",
                        type: "number",
                        label: "Office Manpower",
                        //placeHolder: "Office Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessOfficeCount",
                        type: "number",
                        label: "Total No.Of Office",
                        //placeHolder: "Business Office Count",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessOfficeType",
                        type: "radio",
                        label: "Owned / Rental",
                        //placeHolder: "Business Office Type",
                        checked:2,
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-check-input",
                        required: false,
                        options: {
                            1: "Owned",
                            2: "Rental"
                        }
                    }
                ]
            },
            {
                Warehouse: [
                    {
                        name: "businessWarehouseArea",
                        type: "number",
                        label: "Total Warehouse Area",
                        //placeHolder: "Business Warehouse Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessWarehouseManpower",
                        type: "number",
                        label: "Warehouse Manpower",
                        //placeHolder: "Business Warehouse Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessWarehouseCount",
                        type: "number",
                        label: "Total No.Of Warehouse",
                        //placeHolder: "Business Warehouse Count",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",dnone:false,
                        required: false
                    },
                    {
                        name: "businessWarehouseType",
                        type: "radio",
                        label: "Owned / Rental",
                        //placeHolder: "Business Warehouse Type",
                        maxLength: 50,
                        checked:2,
                        autoFocus: false,
                        class: "form-check-input",
                        required: false,
                        options: {
                            1: "Owned",
                            2: "Rental"
                        }
                    }
                ]
            }
        ],
        "C. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                //"placeHolder: "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            }, 
            {
                "name": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "____ ____ ____",
                "maxLength": 14,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            },   
            {"": []},
            {
                name: "registrationIfscCode",
                type: "text",
                label: "IFSC Code",
                //placeHolder: "Registration Ifsc Code",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
            {
                name: "registrationBankName",
                type: "text",
                label: "Bank Name",
                //placeHolder: "Registration Bank Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true,
                readonly:true
            },
            {"":[]},
            {
                name: "registrationBranchName",
                type: "text",
                label: "Branch Name",
                //placeHolder: "Registration Branch Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true,
                 readonly:true
            },
            {
                name: "registrationAccountNo",
                type: "number",
                label: "Account No",
                //placeHolder: "Registration Account No",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
        ],
        "D. Company Details As Distributor/Dealer": [
            {
                name: "companyName1",
                type: "text",
                label: "Company represented1",
                // placeHolder: "Company Name1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: true
            },
            // {"":[]},
            {
                name: "companyName2",
                type: "text",
                label: "Company represented2",
                // placeHolder: "Company Name2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            // {"":[]},
            {
                name: "companyName3",
                type: "text",
                label: "Company represented3",
                // placeHolder: "Company Name3",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {"":[]},
            {
                name: "companyCount",
                type: "number",
                label: "No.of Companies represented",
                //placeHolder: "Company Count",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "companyProductHandle",
                type: "number",
                label: "Product Handled",
                //placeHolder: "Product Handle",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "companyOwnedVehicleCount",
                type: "number",
                label: "No.of Owned vehicles",
                //placeHolder: "No.of Owned vehicles",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            },
            {
                name: "companyTowneCount",
                type: "number",
                label: "No.of Town covered",
                //placeHolder: "No.of Town covered",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",dnone:false,
                required: false
            }
        ],
        "Upload Proof": [
            {
                "name": "uploadAadhar",
                "type": "file",
                "label": "Aadhar",
                //"placeHolder: "Upload Aadhar",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            },
            {
                "name": "uploadPanCard",
                "type": "file",
                "label": "Pan Card",
                //"placeHolder: "Upload Pan Card",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            },
            {
                "name": "uploadEBBill",
                "type": "file",
                "label": "Light Bill",
                //"placeHolder: "Upload EBBill",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": true
            },
            {
                "name": "uploadDrivingLicence",
                "type": "file",
                "label": "Driving Licence",
                //"placeHolder: "Upload Driving Licence",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": false
            },
            {
                "name": "uploadPassport",
                "type": "file",
                "label": "Passport",
                //"placeHolder: "Upload Passport",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": false
            },
            {
                "name": "uploadPhoto",
                "type": "file",
                "label": "Photo",
                //"placeHolder: "Upload Photo",
                "maxLength": 50,
                "autoFocus": false,
                class: "form-control",dnone:false,
                "required": false
            }
        ],
        // "Terms & Conditions": [
        //     {
        //         name: "termsAgreed",
        //         type: "checkbox",
        //         label: "Terms Agreed",
        //         label1: "I willingly accept all the terms and condition laid down by SmartLotto to assign me as an authorised Distributor",
        //         //placeHolder: "Terms Agreed",
        //         maxLength: 1,
        //         autoFocus: false,
        //         class: "form-control",dnone:false,
        //         required: true,
        //         showModalCondition: true
        //     }
        // ]
    }

    handleAlertPopupClose = () => {
        this.uiData.setAlertShowMessage("hide", "", "")
        this.setState({alertPopupShow: "hide", drawWinNumberId: ""})
    }
    handlePopupShow = (e: any) => {
        e.preventDefault()
        this.setState({
            alertPopupShow: "show",
            termsConditionRead: true
        })
    }

    validateEditDistributorForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.editDistributorFields).map((keys: any) => {
            {
                return this.editDistributorFields[keys].map((fieldValObject: any) => {
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                if (
                                    fieldValObject1.required &&
                                    this.state.fields[fieldValObject1.name] == ""
                                ) {
                                    flagError[fieldValObject1.name + "Error"] = true

                                    const errorData = this.uiData.textConversion(
                                        fieldValObject1.label
                                    )
                                    return (newErrorMsgArray[fieldValObject1.name + "Error"] =
                                        errorData + " is required")
                                } else {
                                    if (fieldValObject1.passwordCondition) {
                                        let pattern: any
                                        let error: any
                                        if (
                                            fieldValObject1.name == "partnerPassword" ||
                                            fieldValObject1.name == "confirmPassword"
                                        ) {
                                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                                            error = StaticKey.PASSWORD_CONDITION
                                        } else if (
                                            fieldValObject1.name == "partnerTransactionPassword"
                                        ) {
                                            pattern = new RegExp(StaticKey.TXN_PASSWORD_REG_EXP)
                                            error = StaticKey.TXN_PASSWORD_CONDITION
                                        }
                                        if (!pattern.test(this.state[fieldValObject1.name])) {
                                            // flag = false
                                            flagError[fieldValObject1.name + "Error"] = true
                                            return (newErrorMsgArray[
                                                fieldValObject1.name + "Error"
                                            ] = "")
                                        }
                                    } else {
                                        return (newErrorMsgArray[fieldValObject1.name + "Error"] =
                                            "")
                                    }
                                }
                            })
                        })
                    } else {
                        if (fieldValObject.type == "file") {
                            if (
                                fieldValObject.required &&
                                this.state.fields[fieldValObject.name + "Val"] == ""
                            ) {
                                flagError[fieldValObject.name + "Error"] = true

                                const errorData = this.uiData.textConversion(fieldValObject.label)
                                return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                    errorData + " is required")
                            } else {
                                return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                            }
                        } else {
                            if (
                                fieldValObject.required &&
                                this.state.fields[fieldValObject.name] == ""
                            ) {
                                flagError[fieldValObject.name + "Error"] = true

                                const errorData = this.uiData.textConversion(fieldValObject.label)
                                return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                    errorData + " is required")
                            } else {
                                if (fieldValObject.passwordCondition) {
                                    let pattern: any
                                    let error: any
                                    if (
                                        fieldValObject.name == "partnerPassword" ||
                                        fieldValObject.name == "confirmPassword"
                                    ) {
                                        pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                                        error = StaticKey.PASSWORD_CONDITION
                                    } else if (
                                        fieldValObject.name == "partnerTransactionPassword"
                                    ) {
                                        pattern = new RegExp(StaticKey.TXN_PASSWORD_REG_EXP)
                                        error = StaticKey.TXN_PASSWORD_CONDITION
                                    }
                                    if (!pattern.test(this.state.fields[fieldValObject.name])) {
                                        // flag = false
                                        flagError[fieldValObject.name + "Error"] = true
                                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                            error)
                                    }
                                } else if (fieldValObject.name == "partnerUsername" && this.state.fields[fieldValObject.name] != "") {
                                    const pattern = new RegExp(StaticKey.USERNAME_REG_EXP)
                                    const error = StaticKey.USERNAME_REG_EXP_ERROR
                                    if (!pattern.test(this.state.fields[fieldValObject.name])) {
                                        flagError[fieldValObject.name + "Error"] = true
                                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                            error)
                                    }
                                } else {
                                    return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                                }
                            }
                        }
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        let mapBreak = false;
        Object.keys(this.editDistributorFields).map((keys: any) => {
            {
                return this.editDistributorFields[keys].map((fieldValObject: any) => {
                    
        if(!mapBreak){
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                //console.log("asdasd")
                                if (
                                    fieldValObject1.required &&
                                    this.state.fields[fieldValObject1.name] == ""
                                ) {
                                    const element = document.getElementById(fieldValObject1.id);
                                    //console.log(element)
                                    // element.scrollIntoView({behavior: 'smooth'});
                                    return;
                                }
                            })
                        })
                    }
                    else {
                        if (
                            fieldValObject.required &&
                            this.state.fields[fieldValObject.name] == ""
                        ) {
                            document.getElementById(fieldValObject.name)?.focus();
                            mapBreak=true;
                        }
                    }
                }
                })
            }})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handleEditDistributorReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.editDistributorFields).map((keys: any) => {
            {
                return this.editDistributorFields[keys].map((fieldValObject: any) => {
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                return (
                                    (newArrayError[fieldValObject1.name + "Error"] = ""),
                                    (newArrayFields[fieldValObject1.name] = ""),
                                    (newArrayFields[fieldValObject1.name + "Val"] = "")
                                )
                            })
                        })
                    } else {
                        return (
                            (newArrayError[fieldValObject.name + "Error"] = ""),
                            (newArrayFields[fieldValObject.name] = ""),
                            (newArrayFields[fieldValObject.name + "Val"] = "")
                        )
                    }
                })
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields})
    }

    convertBase64 = (file: any) => {
        // // //console.log(file);
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = error => {
                reject(error)
            }
        })
    }

    handleEditDistributorKeyDown = (event: any) => {
        if (event.target.name == "partnerTransactionPassword") {
            const keycode = event.keyCode || event.which
            if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46) {
                //
            } else {
                event.preventDefault()
            }
        }
        
        if (event.target.name == "registrationAadharCardNo") {
            const keycode = event.keyCode || event.which
            if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46 || keycode == 37 || keycode == 38 || keycode == 39 || keycode == 40 || keycode == 32 ) {
                let value = event.target.value;
                if(keycode != 8  && keycode != 46 && ( value.length == 4 || value.length == 9 )) {
                    value = value += ' ';
                }
                this.setState({...this.state,"registrationAadharCardNo" : value , fields:{...this.state.fields,"registrationAadharCardNo" : value }})
            } else {
                event.preventDefault()
            }
        }
    }
    handleEditDistributorOnClick(e: any){
        // //console.log(e.target.name)
        // if(e.target.name.includes('upload')){
        //     this.setState({
        //         ...this.state,
        //         fields: {
        //             ...this.state.fields,
        //             [e.target.name]: "",
        //             [e.target.name + "Val"]: ""
        //         },
        //         errors: {
        //             ...this.state.errors,
        //             [e.target.name + "Error"]: ""
        //         }
        //     })
        // }
    }
    handleEditDistributorInputChange = async (e: any) => {
        // //console.log(e.target.name )
        let value = e.target.value
        if(e.target.name == "registrationPanCardNo"){
            value=value.toUpperCase();
            if(value.length > 10){
                e.preventDefault();
                return false;
            }
        }
        if(e.target.name == "registrationIfscCode"){
            value=value.toUpperCase();
            e.preventDefault();
        }

        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        if (e.target.type == "radio") {
            if(e.target.name == "businessOfficeType"){
                this.editDistributorFields["B. Current Business Information"][3]["Office"][3].checked=value;
            }
            if(e.target.name == "businessWarehouseType"){
                this.editDistributorFields["B. Current Business Information"][4]["Warehouse"][3].checked=value;
            }
        }
        if (e.target.type == "number" && value.length > StaticKey.MAX_AMOUNT_LENGTH) {
            return false
        }
       
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            if(file.size <=  StaticKey.MAX_FILE_SIZE){
                const newValue = await this.convertBase64(file)
                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        [e.target.name]: newValue,
                        [e.target.name + "Val"]: file.name
                    },
                    errors: {
                        ...this.state.errors,
                        [e.target.name + "Error"]: ""
                    }
                })
            }else{
                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        [e.target.name]: "",
                        [e.target.name + "Val"]: ""
                    },
                    errors: {
                        ...this.state.errors,
                        [e.target.name + "Error"]:  StaticKey.MAX_FILE_SIZE_ERROR
                    }
                })
            }
            // const newValue = await this.convertBase64(file)
            // this.setState({
            //     ...this.state,
            //     fields: {
            //         ...this.state.fields,
            //         [e.target.name]: newValue,
            //         [e.target.name + "Val"]: file.name
            //     },
            //     errors: {
            //         ...this.state.errors,
            //         [e.target.name + "Error"]: ""
            //     }
            // })
        } else {
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [e.target.name]: value
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        } 
        if (this.state[e.target.name + "Callback"]) {
            
            // if(e.target.name == "termsAgreed"){
            //     if(!this.state.termsConditionRead){
            //         setTimeout(()=>{
            //             this.setState({
            //                 alertPopupShow: "show",
            //                 termsConditionRead : true
            //             })
            //         },100)
                    
            //     }
            // }
            if (e.target.name == "noOfPartnership") {
                if(e.target.value <=3 && e.target.value >0){
                    const value = e.target.value;
                    if(value == 1){
                        this.editDistributorFields["A. General Information"][2].required=true;
                        this.editDistributorFields["A. General Information"][3].required=false;
                        this.editDistributorFields["A. General Information"][4].required=false;
                        this.editDistributorFields["A. General Information"][2].dnone=false;
                        this.editDistributorFields["A. General Information"][3].dnone=true;
                        this.editDistributorFields["A. General Information"][4].dnone=true;
                    }
                    if(value == 2){
                        
                        this.editDistributorFields["A. General Information"][2].required=true;
                        this.editDistributorFields["A. General Information"][3].required=true;
                        this.editDistributorFields["A. General Information"][4].required=false;
                        this.editDistributorFields["A. General Information"][2].dnone=false;
                        this.editDistributorFields["A. General Information"][3].dnone=false;
                        this.editDistributorFields["A. General Information"][4].dnone=true;
                    }
                    if(value == 3){
                        this.editDistributorFields["A. General Information"][2].required=true;
                        this.editDistributorFields["A. General Information"][3].required=true;
                        this.editDistributorFields["A. General Information"][4].required=true;
                        this.editDistributorFields["A. General Information"][2].dnone=false;
                        this.editDistributorFields["A. General Information"][3].dnone=false;
                        this.editDistributorFields["A. General Information"][4].dnone=false;
                        
                    }
                    if(value == 0){
                        this.editDistributorFields["A. General Information"][2].required=false;
                        this.editDistributorFields["A. General Information"][3].required=false;
                        this.editDistributorFields["A. General Information"][4].required=false;
                        this.editDistributorFields["A. General Information"][2].dnone=true;
                        this.editDistributorFields["A. General Information"][3].dnone=true;
                        this.editDistributorFields["A. General Information"][4].dnone=true;
                        
                    }
                    if(value == ""){
                        this.editDistributorFields["A. General Information"][2].required=false;
                        this.editDistributorFields["A. General Information"][3].required=false;
                        this.editDistributorFields["A. General Information"][4].required=false;
                        this.editDistributorFields["A. General Information"][2].dnone=true;
                        this.editDistributorFields["A. General Information"][3].dnone=true;
                        this.editDistributorFields["A. General Information"][4].dnone=true;
                        
                    }
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            [e.target.name]: e.target.value,
                            [e.target.name + "Val"]: e.target.value
                        },
                        errors: {
                            ...this.state.errors,
                            [e.target.name + "Error"]: ""
                        }
                    })
                }else{
                     document.getElementById('partner1name')?.setAttribute('readonly','readonly');
                    document.getElementById('partner2name')?.setAttribute('readonly','readonly');
                    document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                    this.editDistributorFields["A. General Information"][2].required=false;
                    this.editDistributorFields["A. General Information"][3].required=false;
                    this.editDistributorFields["A. General Information"][4].required=false;
                    this.editDistributorFields["A. General Information"][2].dnone=true;
                    this.editDistributorFields["A. General Information"][3].dnone=true;
                    this.editDistributorFields["A. General Information"][4].dnone=true;
                    this.setState({
                        ...this.state,
                        [e.target.name]:"",
                        fields: {
                            ...this.state.fields,
                            [e.target.name]: "",
                        },
                        errors: {
                            ...this.state.errors,
                            [e.target.name + "Error"]: "Max 3 partners allowed"
                        }
                    })
                }
            }
        }
        
        if(e.target.name == "partnerLocality"){
            // alert(this.state.fields['partnerLocality'])
            const data = this.state.pincodeData;
            for(let i=0;i<data.length;i++){
                if(Object.keys(data[i]) == value){
                    // //console.log(data[i])
                    this.setState({
                        ...this.state,
                        fields:{
                            ...this.state.fields,
                            partnerLocality:value,
                            partnerTaluka:data[i][value].taluk,
                            partnerCity:data[i][value].city,
                            partnerState:data[i][value].state,
                        },
                        errors: {
                            ...this.state.errors,
                            ["partnerLocalityError"]: "",
                            ["partnerTalukaError"]: "",
                            ["partnerCityError"]: "",
                            ["partnerStateError"]: "",
                        }
                    })
                }
            }
        }
        
    }

    handleEditDistributorSubmit = (e: any) => {
        e.preventDefault()
        //console.log("this.state.fields", this.state.fields)
        if (this.validateEditDistributorForm()) {
            this.uiData.updateShowLoading(true)
            // //console.log("this.state.fields", this.state.fields)
            const response = this.DownlineManageServices.editDistributor(this.state.fields)
            response
                .then((editDistributorResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!editDistributorResponse.errorStatus) {
                        const successMessage = "Distributor created successfully. Application No. :"+editDistributorResponse.applicationId;
                        this.uiData.showToastMessage(successMessage, "")
                        this.handleEditDistributorReset()
                        document.getElementById('reset_form')?.click();
                    } else {
                        this.uiData.showToastMessage(
                            "Distributor creation failed, Try again!",
                            "error"
                        )
                    }
                })
                .catch((err: any) => {
                    // //console.log("handleEditDistributorSubmitErr", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage("Something went wrong!. Try again.", "error")
                })
        } else {
            // window.scrollTo(0, 0)
            //console.log("sdssad")
        }
    }

    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                if (!getCompanyListResponse.errorStatus) {
                    for(let iLoop = 0; iLoop<this.editDistributorFields["A. General Information"].length;iLoop++){             
                        if(this.editDistributorFields["A. General Information"][iLoop] && this.editDistributorFields["A. General Information"][iLoop].name && this.editDistributorFields["A. General Information"][iLoop].name != undefined && this.editDistributorFields["A. General Information"][iLoop].name ==  "companyId"){
                            this.editDistributorFields["A. General Information"][iLoop].options = getCompanyListResponse.data
                        }
                    }                           
                    this.setState({companyList: getCompanyListResponse.data})
                }
                this.uiData.updateShowLoading(false)
            })
            .catch((err: any) => {
                // //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    checkPartnerExist = () => {
        if (this.state.fields.partnerUsername != "") {
            const pattern = new RegExp(StaticKey.USERNAME_REG_EXP)
            const error = StaticKey.USERNAME_REG_EXP_ERROR
            if (!pattern.test(this.state.fields.partnerUsername)) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        ["partnerUsernameError"]: error
                    }
                })
            } else {
                this.uiData.updateShowLoading(true)
                const customerCreateObject = {
                    username: this.state.fields.partnerUsername
                }
                const response = this.DownlineManageServices.checkPartnerExist(customerCreateObject)
                response
                    .then((checkPartnerResponse: any) => {
                        this.uiData.updateShowLoading(false)
                        if (checkPartnerResponse.errorStatus) {
                            // this.uiData.showToastMessage(
                            //     StaticKey.LOCALE_DATA.Home.usernameAlreadyExist,
                            //     "error"
                            // )
                            this.setState({
                                ...this.state,
                                "partnerUsername": "", 
                                errors:{
                                    ...this.state.errors,
                                    "partnerUsernameError":"Distributor Code Already Exists",
                                },                                
                               
                                ...this.state.fields,
                                fields:{
                                    "partnerUsername": ""
                                }
                            })
                            document.getElementById("partnerUsername")?.focus();

                            // this.setState({
                            //     username: ""
                            // })
                        }
                    })
                    .catch((err: any) => {
                        // //console.log("checkPartnerExistErr", err)
                        this.uiData.updateShowLoading(false)
                        this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                    })
            }
        }
    }

    checkPasswordConfirm = () => {
        if (this.state.confirmPassword != "" && this.state.confirmPassword != this.state.password) {
            this.uiData.showToastMessage(
                StaticKey.LOCALE_DATA.Home.passwordAndConfirmPasswordShouldBeSame,
                "error"
            )
            this.setState({
                confirmPassword: ""
            })
        }
    }

    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"] + " " + classes["general-form"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>Edit Distributor</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.editDistributorFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div
                                                key={values}
                                                className={classes["main-div-input-sec-2"]}
                                            >
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.editDistributorFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                            if (
                                                                Object.keys(fieldValObject)
                                                                    .length == 1
                                                            ) {
                                                                {
                                                                    return Object.keys(
                                                                        fieldValObject
                                                                    ).map(
                                                                        (
                                                                            keys1: any,
                                                                            values1: any
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={values1}
                                                                                    className={
                                                                                        classes[
                                                                                            "main-div-input-sec-2-sub"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    <h6>{keys1}</h6>
                                                                                    <div
                                                                                        className={
                                                                                            classes[
                                                                                                "main-div-input-sec"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {fieldValObject[
                                                                                            keys1
                                                                                        ].map(
                                                                                            (
                                                                                                fieldValObject1: any,
                                                                                                fieldValIndex1: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            classes[
                                                                                                                "sub-div"
                                                                                                            ]
                                                                                                        }
                                                                                                        key={
                                                                                                            fieldValIndex1
                                                                                                        }
                                                                                                    >
                                                                                                        <label htmlFor="name">
                                                                                                            {
                                                                                                                fieldValObject1.label
                                                                                                            }
                                                                                                        </label>
                                                                                                        {fieldValObject1.required ? (
                                                                                                            <span
                                                                                                                className={ "text-right "+
                                                                                                                    classes[
                                                                                                                        "mandatory-symbol"
                                                                                                                    ]
                                                                                                                }
                                                                                                            >
                                                                                                                *
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            <>
                                                                                                                <InputBox
                                                                                                                    id={
                                                                                                                        fieldValObject1.id
                                                                                                                    }
              
                                                                                                                    checked={fieldValObject1.checked}                                                                                                      autoComplete="off"
                                                                                                                    maxLength={
                                                                                                                        fieldValObject1.maxLength
                                                                                                                    }
                                                                                                                    className={
                                                                                                                        "form-control " +
                                                                                                                        classes[
                                                                                                                            fieldValObject1
                                                                                                                                .class
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    autoFocus={
                                                                                                                        fieldValObject1.autoFocus
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        fieldValObject1.name
                                                                                                                    }
                                                                                                                    readonly={
                                                                                                                        fieldValObject1.readonly
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .fields[
                                                                                                                            fieldValObject1
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    type={
                                                                                                                        fieldValObject1.type
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        tabIndex++
                                                                                                                    }
                                                                                                                    placeholder={
                                                                                                                        fieldValObject1.placeHolder
                                                                                                                    }
                                                                                                                    required={
                                                                                                                        fieldValObject1.required
                                                                                                                    }
                                                                                                                    onChange={() =>
                                                                                                                        this.handleEditDistributorInputChange(
                                                                                                                            event
                                                                                                                        )
                                                                                                                    }
                                                                                                                    options={
                                                                                                                        fieldValObject1.options
                                                                                                                    }
                                                                                                                />
                                                                                                                <div
                                                                                                                    className={
                                                                                                                        classes[
                                                                                                                            "error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .errors[
                                                                                                                            fieldValObject1.name +
                                                                                                                                "Error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            } else {
                                                                let classNew = ""
                                                                if (
                                                                    fieldValObject.label ==
                                                                    "Terms Agreed"
                                                                ) {
                                                                    classNew = "subdiv-full-width"
                                                                }
                                                                let uploadClass = ""
                                                                if (
                                                                    fieldValObject.type =="file"
                                                                ) {
                                                                    uploadClass = "uploadClass"
                                                                }

                                                                return (
                                                                    <>
                                                                    {!fieldValObject.dnone && 
                                                                    <div
                                                                        className={ 
                                                                            classes["sub-div"] +
                                                                            " " +
                                                                            classes[classNew]
                                                                        }
                                                                        key={fieldValIndex}
                                                                    >
                                                                        <div >
                                                                            {fieldValObject.name != "termsAgreed" && (
                                                                            <label 
                                                                                htmlFor={
                                                                                    fieldValObject.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    fieldValObject.label
                                                                                }
                                                                            </label>
                                                                            )}
                                                                            {fieldValObject.required && fieldValObject.name != "termsAgreed" ? (
                                                                                <span
                                                                                    className={ "text-right "+
                                                                                        classes[
                                                                                            "mandatory-symbol"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )} 
                                                    
                                                                            {fieldValObject.name ==
                                                                                "partnerPassword" && (
                                                                                <div
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltip"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "copyDiv"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    &nbsp;
                                                                                    <i className="fa fa-info-circle"></i>
                                                                                    <span
                                                                                        id="tooltiptext"
                                                                                        className={
                                                                                            classes[
                                                                                                "tooltiptext"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            StaticKey.PASSWORD_CONDITION
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            {fieldValObject.name ==
                                                                                "partnerTransactionPassword" && (
                                                                                <div
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltip"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "copyDiv"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    &nbsp;
                                                                                    <i className="fa fa-info-circle"></i>
                                                                                    <span
                                                                                        id="tooltiptext"
                                                                                        className={
                                                                                            classes[
                                                                                                "tooltiptext"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            StaticKey.TXN_PASSWORD_CONDITION
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            <InputBox
                                                                                id={
                                                                                    fieldValObject.name
                                                                                }
                                                                                autoComplete="off"
                                                                                maxLength={
                                                                                    fieldValObject.maxLength
                                                                                }
                                                                                onBlur={()=>{
                                                                                    // fieldValObject.name ==
                                                                                    // "username"
                                                                                    //     ? this
                                                                                    //           .checkUserExist
                                                                                    //     : fieldValObject.name ==
                                                                                    //       "confirmPassword"
                                                                                    //     ? this
                                                                                    //           .checkPasswordConfirm
                                                                                    //     :  
                                                                                    // fieldValObject.name == "registrationIfscCode" ?
                                                                                     this.handleIfsc(event)  }
                                                                                    // : void ""
                                                                                }
                                                                                readonly={
                                                                                    fieldValObject.readonly
                                                                                }
                                                                                className={ 
                                                                                    "form-control " +
                                                                                    classes[ 
                                                                                        fieldValObject
                                                                                            .class
                                                                                    ] +
                                                                                    " " +
                                                                                    classes[
                                                                                        "upload_input_field"
                                                                                    ]+
                                                                                    " " +
                                                                                    classes[
                                                                                        uploadClass
                                                                                    ]
                                                                                }
                                                                                autoFocus={
                                                                                    fieldValObject.autoFocus
                                                                                }
                                                                                accept={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? ".png, .jpg, .svg, .pdf"
                                                                                        : ""
                                                                                }
                                                                                checked={fieldValObject.checked}
                                                                                value={
                                                                                    fieldValObject.type !=
                                                                                    "file"
                                                                                        ? this.state
                                                                                              .fields[
                                                                                              fieldValObject
                                                                                                  .name
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                name={
                                                                                    fieldValObject.name
                                                                                }
                                                                                type={
                                                                                    fieldValObject.type
                                                                                }
                                                                                tabIndex={
                                                                                    tabIndex++
                                                                                }
                                                                                placeholder={
                                                                                    fieldValObject.placeHolder
                                                                                }
                                                                                required={
                                                                                    fieldValObject.required
                                                                                }
                                                                                onClick={() =>
                                                                                    this.handleEditDistributorOnClick(
                                                                                        event
                                                                                    )
                                                                                }
                                                                                onChange={() =>
                                                                                    this.handleEditDistributorInputChange(
                                                                                        event
                                                                                    )
                                                                                }
                                                                                onKeyDown={() =>
                                                                                    this.handleEditDistributorKeyDown(
                                                                                        event
                                                                                    )
                                                                                }
                                                                                label1={
                                                                                    fieldValObject.label1
                                                                                }
                                                                                options={
                                                                                    fieldValObject.type ==
                                                                                    "select"
                                                                                        ? fieldValObject.options
                                                                                        : ""
                                                                                }
                                                                                selectShowEmpty={
                                                                                    fieldValObject.selectShowEmpty
                                                                                }
                                                                                showModalCondition={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? fieldValObject.showModalCondition
                                                                                        : ""
                                                                                }
                                                                                showModalCallback={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? this
                                                                                              .handlePopupShow
                                                                                        : ""
                                                                                }
                                                                                /* style={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? {
                                                                                              display:
                                                                                                  "none"
                                                                                          }
                                                                                        : {}
                                                                                }
  */
                                                                            />
                                                                            <>
                                                                            <div className={classes["uploaded_file_div"]}>
                                                                                {fieldValObject.type ==
                                                                                    "file" &&
                                                                                    this.state
                                                                                        .fields[
                                                                                        fieldValObject.name +
                                                                                            "Val"
                                                                                    ]}
                                                                               
                                                                            </div>
                                                                            {fieldValObject.type =="file" && this.state.fields[fieldValObject.name +"Val"] != "" && this.state.fields[fieldValObject.name +"Val"] != null && this.state.fields[fieldValObject.name +"Val"] != undefined  && (
                                                                                <span className={classes["cursor-pointer"]} onClick={()=>{this.handleClearImage(event,fieldValObject.name)}}> x </span>
                                                                            )}
                                                                            </>
                                                                            {
                                                                                    this.state
                                                                                        .errors[
                                                                                        fieldValObject.name +
                                                                                            "Error"
                                                                                    ] !=  "" && 
                                                                            <div
                                                                                className={
                                                                                    classes["error"]
                                                                                }
                                                                            >
                                                                                {
                                                                                    this.state
                                                                                        .errors[
                                                                                        fieldValObject.name +
                                                                                            "Error"
                                                                                    ]
                                                                                }
                                                                            </div> 
                                                            }
                                                                        </div>
                                                                    
                                                                    </div>
                                                                }  </>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            {/* <div
                                className={classes["guidelines-button"]}
                                onClick={() => this.handlePopupShow(event)}
                            >
                                Guidelines <i className="fa fa-info-circle" aria-hidden="true"></i>
                            </div> */}

                            {this.state.alertPopupShow == "show" && (
                                <Modal
                                className={classes["terms-popup1"] + " modal-lg"}
                                    isOpen={true}
                                    onClosed={() => {
                                        document.body.style.overflow = "unset"
                                    }}
                                    onExit={() => {
                                        document.body.style.overflow = "unset"
                                    }}
                                    unmountOnClose={true}
                                >
                                    <div className={classes["modal-header"]}>
                                        <h4 className={classes["modal-title"]}>Terms & Conditions</h4>
                                        <span onClick={this.handleAlertPopupClose}
                                            className={classes["login-modal-close"]}
                                            aria-hidden="true">
                                            &times;
                                        </span>
                                    </div>
                                    {/* <form> */}
                                    <div className="modal-body mb-2">
                                        <span
                                            onClick={this.handleAlertPopupClose}
                                            className={classes["login-modal-close"]}
                                            aria-hidden="true"
                                        >
                                            &times;
                                        </span>
                                        {/* <h5 style={{textAlign: "left"}}>Terms & Conditions :</h5> */}
                                        <ol style={{textAlign: "left"}}>
                                            <li>
                                                The name of the person ( Applicant ), Who signs an
                                                agreement need to submit the proof of address.
                                                <p>
                                                    * Mandatory proofs are : Light Bill,  Aadhar Card, Pan Card.
                                                </p>
                                            </li>
                                            <li>
                                                The address of the premises of the Retail outlet,
                                                which is entering an agreement with SmartLotto.
                                            </li>
                                            <li>
                                                If there exist more than partners, please provide
                                                the details on an additional sheet. Each partner
                                                needs to submit the proof of address.
                                                <p>
                                                    * Mandatory proofs are : Driving Licence, Light
                                                    Bill, Passport, Aadhar Card, Pan Card.
                                                </p>
                                            </li>
                                            <li>
                                                If represents the total staff at the premises where
                                                the lottery will be played. A monthly payroll
                                                statement and bank statement need to to be
                                                submitted.
                                            </li>
                                            <li>
                                                Proof of ownership of the premises has to be
                                                submitted.
                                            </li>
                                            <li>
                                                A copy of agreement with the represented companies
                                                needs to be submitted.
                                            </li>
                                            <li>An audited balance sheet needs to be submitted.</li>
                                            <li>
                                                The statement of permission from the appropriate
                                                authority is needed.
                                            </li>
                                            <li>
                                                PAN detail and Type of Organisation is Mandatory.
                                            </li>
                                            <li>
                                                The courts in Mumbai shall have exclusive
                                                jurisdiction.
                                            </li>
                                        </ol>
                                    </div>
                                    {/* <div className={classes["modal-footer"]}>
                                    
                                    <div className={classes["sub-div"]}>
                                        <InputBox
                                            id="reset_form"
                                            className="form-control  btn btn-danger"
                                            type="reset"
                                            value="Reset"
                                            tabIndex={tabIndex++}
                                        />
                                    </div>
                                </div> */}
                                    {/* </form> */}
                                </Modal>
                            )}

                            {/* <div className={classes["guidelines-div1"]} style={{display: "none"}}>
                                <h6>
                                    Other guidelines for filling the form : Supporting document
                                    required
                                </h6>
                            </div> */}
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Submit"
                                        onClick={() => this.handleEditDistributorSubmit(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleEditDistributorReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
