import React, {Component} from "react"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {
    CHANGE_SCREEN_EVENT,
    LOADER_ANIMATE_EVENT
} from "../../../game_core/type_script/event-emit-actions"
import {ChangeScreen, SCREEN_NAMES} from "../../../game_core/type_script/screen_maintenace"
import classes from "../../configuration/style/base/screens/maintenance/styles.module.scss"
import {MaintenanceTimer} from "./maintenancetimer"
import {MaintenanceService} from "../../../game_core/type_script/services/maintenance"
import {COMMON_IMAGES} from "../../configuration/assets/base/importer/common"
import {AuthCheck} from "../../../game_core/type_script/services/authcheck"
interface Props {
    onComplete?: any
}

export class MaintenanceScreen extends Component<Props> {
    screenObj: ChangeScreen = {screenName: ""}
    state = {
        MaintenanceMsg: "",
        isMsgShow: false,
        isMaintenanceTimeShow: false
    }
    startTime: any = new Date()
    endTime: any = new Date()
    currentTime: any = new Date()
    MaintenanceService: any = MaintenanceService.getInstance()
    authCheck = AuthCheck.getInstance()

    maintenanceCheck = () => {
        //this.authCheck.checkLoginStatus()
        const response = this.MaintenanceService.getMaintenanceInfo()
        response
            .then((value: any) => {
                if (value && value.maintenanceInfo) {
                    const maintenanceInfo = value.maintenanceInfo ? value.maintenanceInfo : ""
                    if (maintenanceInfo !== "") {
                        if (maintenanceInfo.maintenanceMode === 0) {
                            if (this.props.onComplete) {
                                this.props.onComplete("maintenance")
                            }
                        } else if (maintenanceInfo.maintenanceMode === 1) {
                            this.startTime = new Date(maintenanceInfo.from)
                            this.endTime = new Date(maintenanceInfo.to)
                            this.currentTime = new Date(maintenanceInfo.currentTime)
                            StaticKey.GLOBAL_EMITTER.emit(LOADER_ANIMATE_EVENT, false)
                            this.showMaintenance(maintenanceInfo.maintenanceMsg)
                            this.setState({isMaintenanceTimeShow: true})
                        }
                    } else {
                        if (this.props.onComplete) {
                            this.props.onComplete("maintenance")
                        }
                        //this.showMaintenance()
                    }
                } else {
                    if (this.props.onComplete) {
                        this.props.onComplete("maintenance")
                    }
                    //this.showMaintenance()
                }
            })
            .catch((err: any) => {
              //console.log("maintenanceCheckErr", err)
                if (this.props.onComplete) {
                    this.props.onComplete("maintenance")
                }
                //this.showMaintenance()
            })
    }

    showMaintenance = (msg?: any) => {
        this.screenObj.screenName = SCREEN_NAMES.MAINTENANCE
        StaticKey.GLOBAL_EMITTER.emit(CHANGE_SCREEN_EVENT, this.screenObj)
        this.setState({
            MaintenanceMsg: msg,
            isMsgShow: true,
            isMaintenanceTimeShow: true
        })
    }
    openPreloader = () => {
        if (this.props.onComplete) {
            this.props.onComplete("maintenance")
        }
    }
    componentDidMount() {
        this.maintenanceCheck()
    }
    render() {
        return (
            <div className={classes[`${StaticKey.ORIENTATION_TYPE}_container`]}>
                {this.state.isMaintenanceTimeShow && (
                    <div className={classes["body"]}>
                        <div className={classes["counter-box-main"]}>
                            {this.state.isMaintenanceTimeShow && (
                                <MaintenanceTimer
                                    currTime={this.currentTime}
                                    startTime={this.startTime}
                                    endTime={this.endTime}
                                    maintenanceCheck={this.maintenanceCheck}
                                ></MaintenanceTimer>
                            )}
                        </div>
                        <img
                            className={classes["img-fluid"] + " " + classes["errorimg"]}
                            src={COMMON_IMAGES["maintenTechnicalLandscape"]}
                        />
                        <img
                            className={classes["img-fluid"] + " " + classes["errorimg_portrait"]}
                            src={COMMON_IMAGES["maintenTechnicalPort"]}
                        ></img>
                    </div>
                )}
            </div>
        )
    }
}
