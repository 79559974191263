import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import {HTTPMockDataService} from "../../http-mock-data-service"
import {StaticKey} from "../../static_key"

export class LoginService extends HTTPConnectionDatasService {
    header: any = {
        Authorization: ""
        // Authorization :  `Bearer ${StaticKey.LOCALE_DATA['authToken']}`
    }
    params: any = {}
    url: any = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID: any = "default"): any {
        if (!LoginService.classIns[_instanceID]) {
            return (LoginService.classIns[_instanceID] = new LoginService(_instanceID))
        } else {
            return LoginService.classIns[_instanceID]
        }
    }
    sendLoginRequest = (paramsData?: any) => {
        //this function handles API request and response for authentication
        return new Promise((resolve, reject) => {
            const requestType = `doLogin`
            const requestURL: any = `${this.url}/login`
            this.httpConnectionIns.sendHttpRequest(requestType, requestURL, paramsData, {}).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    logoutUser = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/logout`
            this.httpConnectionIns.sendHttpRequest("logout", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getConfigDetails = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getConfigDetails`
            this.httpConnectionIns.sendHttpRequest("getConfigDetails", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getCaptchaImage = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getRefreshCaptcha`
            this.httpConnectionIns
                .sendHttpRequest("getRefreshCaptcha", requestURL, paramsData)
                .then(
                    (respObj: any) => {
                        StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                        resolve(respObj)
                    },
                    (errorObj: any) => {
                        console.log("errorObj:", errorObj)
                        reject(errorObj)
                    }
                )
        })
    }
}
