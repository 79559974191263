export enum SCREEN_NAMES {
    PRELOADER = "preLoader",
    MAINTENANCE = "maintenance",
    LOGIN = "login",
    LOGINSCREEN = "loginScreen",
    SIGNUP = "signupScreen",
    FORGOTPASSWORD = "forgotpasswordScreen",
    CHANGEPASSWORD = "changepasswordScreen",
    OTPSCREEN = "MobileOTPScreen",
    LOGINWITHOTP = "loginWithOtp",
    LAUNCHER = "launcher",
    LOGINREGISERSCREEN = "loginRegisterScreen",
    SPLASHSCREEN = "splashscreen",
    MAINSCREEN = "mainscreen",
    GAMESCREEN = "gamescreen",
    RESPONSIBLEGAMINGSCREEN = "responsibleGamingScreen",
    PROFILESCREEN = "profileScreen",
    ACCOUNTDETAILSSCREEN = "AccountDetails",
    MYACCOUNT = "myaccount",
    REFEREARNSCREEN = "ReferEarnScreen",
    REFERFRIEND = "Refer A Friend",
    FRIENDSINVITED = "Friends Invited",
    HOWITSWORKD = "How it works?",
    ADDCASH = "Add Cash",
    RNG = "rng",
    LEGALITY = "legality",
    WITHDRAWCHIPS = "WithDrawChips",
    WALLETTRANSFER = "WalletTransfer",
    CASHIER = "Cashier",
    OFFERS = "Offers",
    CHANGEPASSWORDSCREEN = "changepasswordScreen",
    KYCSCREEN = "KYCscreen",
    PROFILEOTPSCREEN = "ProfileOtpScreen",
    TERMSANDCONDITION = "terms-of-use",
    INAPPSCREENS = "InAppScreens",
    HOME = "home"
}
export interface ChangeScreen {
    screenName: string
}

export interface Popup {
    title?: string
    message: string
    buttons?: Array<ButtonsType>
}

export interface ButtonsType {
    text: string
    onClick?: any
}
