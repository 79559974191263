import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/drawmanagement/createDraw.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DrawManageService} from "../../../game_core/type_script/services/drawmanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Loader} from "../../../customer_gl/screens/common/loader"

const date = new Date();
const currentDay= String(date.getDate()).padStart(2, '0');
const currentMonth = String(date.getMonth()+1).padStart(2,"0");
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;

// we will display the date as DD-MM-YYYY 
export class CreateDraw extends Component {
    DrawManageServices = DrawManageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    // Date object
    inputElement:any;
    state: any = {
        drawGameId: "",
        drawPrice: "",
        drawStatus: "active",
        drawIsDemo: "",
        fromDate: null,
        toDate: null,
        fromDateSearch: "",
        toDateSearch: "",
        drawBetCloseTime: "",
        drawTime: "",
        drawInterval: "",
        isLoadedDropdown: true,
        fromDateCallback: true,
        drawPriceCallback: true,
        fields: {
            "drawGameId": "",
            "drawPrice": "",
            "drawStatus": "active",
            "drawIsDemo": "",
            "fromDate": "",
            "toDate": "",
            "drawInterval": "",
            "drawBetCloseTime": "",
            "drawTime": ""
        },
        errors: {
            "drawGameIdError": "",
            "drawPriceError": "",
            "drawStatusError": "",
            "drawIsDemoError": "",
            "fromDateError": "",
            "toDateError": "",
            "drawIntervalError": "",
            "drawBetCloseTimeError": "",
            "drawTimeError": ""
        },
    }
    createDrawsFields: any = {
        "":[
            {
                "name": "drawGameId",
                "id": "drawGameId",
                "type": "select",
                "label": "Game",
                "placeHolder": "Game",
                "autoFocus": true,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true,
                "getDIstributorInfo": true
            },
            {
                "name": "drawPrice",
                "type": "number",
                "label": "Price",
                "placeHolder": "Price",
                "min":StaticKey.DRAWMINPRICE,
                "max":StaticKey.DRAWMMAXPRICE,
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
            },
            {
                "name": "drawStatus",
                "type": "select",
                "label": "Status",
                "placeHolder": "Status",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options": StaticKey.DRAWACTIVEINACTIVESTATUS
            },
            {
                "name": "drawInterval",
                "type": "select",
                "label": "Interval",
                "placeHolder": "Interval",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options": StaticKey.DRAWINTERVALOPTION
            },
            {
                "name": "fromDate",
                "type": "datetime",
                "label": "Start Date",
                // "placeHolder": "Start Date",
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"             
            },
            {
                "name": "toDate",
                "id": "toDate",
                "type": "datetime",
                "label": "End Date",
                // "placeHolder": "End Date",
                "maxLength": currentDate,
                // "disabled": true,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"
            },
            {
                "name": "drawBetCloseTime",
                "type": "time",
                "label": "Bet Close time",
                "placeHolder": "Bet Close time",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "drawTime",
                "type": "time",
                "label": "Draw time",
                "placeHolder": "Draw time",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "drawIsDemo",
                "id": "drawIsDemo",
                "type": "checkbox",
                "label": "Demo",
                "label1":" Is Demo",
                "placeHolder": "Is Demo",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
        ]
    }


    componentDidMount() {
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
            this.getGameList({
                "partnerTypeId": 3
            })
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any, values1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                        if (agentLoginHideFields.includes(fieldValObject1.name)) {
                                            return delete this.createDrawsFields[keys1][
                                                fieldValIndex1
                                            ]
                                        }
                                    }
                                )
                            })
                        } else {
                            if (agentLoginHideFields.includes(fieldValObject.name)) {
                                return delete this.createDrawsFields[keys][fieldValIndex]
                            }
                        }
                    }
                )
            }
        })
        this.setState({isLoadedDropdown: false})
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    validatecreateDrawForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createDrawsFields).map((keys: any) => {
            {
                return this.createDrawsFields[keys].map((fieldValObject: any) => {
                    if (  fieldValObject.required && this.state.fields[fieldValObject.name] == "" ) {
                        flagError[fieldValObject.name + "Error"] = true
                        
                        const errorData = this.uiData.textConversion(fieldValObject.label);
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = errorData + " is required")
                        
                    } else if(fieldValObject.min != undefined || fieldValObject.max != undefined){
                        if(fieldValObject.min != undefined && this.state.fields[fieldValObject.name] < fieldValObject.min){
                            flagError[fieldValObject.name + "Error"] = true
                            const errorData = this.uiData.textConversion(fieldValObject.label);
                            return (newErrorMsgArray[fieldValObject.name + "Error"] =  errorData + " is must be greater than or equal to "+fieldValObject.min)
                        }
                        if(fieldValObject.max != undefined && this.state.fields[fieldValObject.name] > fieldValObject.max){
                            flagError[fieldValObject.name + "Error"] = true
                            const errorData = this.uiData.textConversion(fieldValObject.label);
                            return (newErrorMsgArray[fieldValObject.name + "Error"] =  errorData + " is must be lesser than or equal to"+fieldValObject.max)
                        }
                    }else{
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        // this.handleCreateDrawReset();
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handleCreateDrawReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {   
                
                this.setState({[this.createDrawsFields[keys].name]:""});
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        //if(fieldValObject.type == "select"){
                        //     fieldValObject.options= {};
                        // }
                        // this.createDrawsFields[""][2].options = StaticKey.DRAWACTIVEINACTIVESTATUS;
                        // this.createDrawsFields[""][6].options = StaticKey.DRAWINTERVALOPTION;
                        // this.getGameList(5);
                        return (
                            (newArrayError[fieldValObject.name + "Error"] = ""),
                            (newArrayFields[fieldValObject.name] = "")
                            // (newArrayFields[fieldValObject.name + "Val"] = null)

                        )
                    }
                )
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields})
    }

    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
        this.setState({
            ...this.state,
            fromDate:date,
            fromDateSearch:finalDate,
            fields:{
                ...this.state.fields,
                fromDate:date,
            },
            errors:{
                ...this.state.errors,
                fromDateError:"",
            },
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
      //console.log(date)
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            ...this.state,
            toDate: eDate,
            toDateSearch:finalDate,
            fields:{
                ...this.state.fields,
                toDate: eDate,
            },
            errors:{
                ...this.state.errors,
                toDateError:"",
            },
        })
    }
    handlecreateDrawInputChange = (e: any) => {
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        if (this.state[e.target.name + "Callback"]) {
            if(e.target.name == "fromDate"){
                this.createDrawsFields[""][5].min = value
                this.createDrawsFields[""][5].value = ""
                this.createDrawsFields[""][5].type = "datetime-local"
                document.getElementById('toDate')?.removeAttribute("readonly");
            }
            if(e.target.name =="drawPrice"){
                if(e.target.value.length > 2){
                    value = e.target.value.slice(0, 2);
                }
            }
        }
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
        } else {
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [e.target.name]: value
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
    }

    handleCreateDrawSubmit = (e: any) => {
        e.preventDefault()
        if (this.validatecreateDrawForm()) {
           
            const submitData = {
                drawBetCloseTime: this.state.fields.drawBetCloseTime,
                drawGameId: this.state.fields.drawGameId,
                drawInterval: this.state.fields.drawInterval,
                drawIsDemo: this.state.fields.drawIsDemo,
                drawStatus: this.state.fields.drawStatus,
                drawPrice: this.state.fields.drawPrice,
                drawTime: this.state.fields.drawTime,
                drawStartDate: this.state.fromDateSearch,
                drawEndDate: this.state.toDateSearch,
            }
          //console.log(submitData)
            this.uiData.updateShowLoading(true)
            const response = this.DrawManageServices.createDraw(submitData)
            response
                .then((createDrawResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!createDrawResponse.errorStatus) {
                        this.uiData.showToastMessage("Draw created successfully", "")
                        // this.handleCreateDrawReset();
                        document.getElementById('reset_form')?.click();
                        
                    } else {
                        this.uiData.showToastMessage(createDrawResponse.validationError, "error")
                        // this.handleCreateDrawReset()
                    }
                })
                .catch((err: any) => {
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage("Something went wrong!. Try again.", "error")
                    // this.handleCreateDrawReset()
                    // document.getElementById('reset_form')?.click();
                })
        }else{
          //console.log("error occured")
        }
    }

    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getGameListDropdown(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                    this.createDrawsFields[""][0].options = getGameListResponse.data
                    this.setState({companyList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }

    render() {
        let tabIndex = 1
        return (
            <>
                {<Loader showPageLoader={this.state.isLoadedDropdown} />}
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>Create Draw</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.createDrawsFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div key={values}>
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.createDrawsFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                        return (
                                                            <div  className={ classes["sub-div"]  }
                                                                key={fieldValIndex}
                                                            >
                                                                <div>
                                                                    <label htmlFor={fieldValObject.id}>
                                                                        {fieldValObject.label}
                                                                    </label>
                                                                    {fieldValObject.required ? (
                                                                        <span className={classes["mandatory-symbol"]}>
                                                                            *
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <InputBox
                                                                        id={fieldValObject.id}
                                                                        autoComplete="off"
                                                                        maxLength={fieldValObject.maxLength}
                                                                        min = {fieldValObject.min}
                                                                        max = {fieldValObject.max}
                                                                        placeholder = {fieldValObject.placeHolder}
                                                                        className={"form-control " + classes[  fieldValObject.class]}
                                                                        autoFocus={fieldValObject.autoFocus}
                                                                        accept={fieldValObject.type ==    "file"        ? ".png, .jpg, .svg, .pdf"        : ""}
                                                                        value={this.state.fields[fieldValObject.name]}
                                                                        name={fieldValObject.name}
                                                                        type={fieldValObject.type}
                                                                        tabIndex={tabIndex++}
                                                                        required={fieldValObject.required}
                                                                        // onChange={() =>this.handlecreateDrawInputChange(        event    )}
                                                                        onChange={
                                                                            fieldValObject.type === "datetime" 
                                                                                ? fieldValObject.name == "fromDate"
                                                                                    ? this.handleStartDateChange
                                                                                    : this.handleEndDateChange
                                                                                : this.handlecreateDrawInputChange
                                                                        }
                                                                        dateFormat={fieldValObject.dateFormat}
                                                                        startMaxDate={this.maxDate}
                                                                        endMinDate={this.minDate}
                                                                        label1={fieldValObject.label1}
                                                                        options={fieldValObject.type ==        "select" ||fieldValObject.type ==        "radio"        ? fieldValObject.options: ""}
                                                                        selectShowEmpty={fieldValObject.selectShowEmpty}
                                                                    />
                                                
                                                                    <div>
                                                                        {fieldValObject.type == "file" &&this.state.fields[ fieldValObject.name +"Val"]}
                                                                    </div>
                                                                    <div className={classes["error"]}>
                                                                        {
                                                                            this.state.errors[fieldValObject.name + "Error"]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Submit"
                                        onClick={() => this.handleCreateDrawSubmit(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        ref={this.inputElement}
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleCreateDrawReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
