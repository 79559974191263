import {EventEmitter} from "eventemitter3"

export class MockServiceData extends EventEmitter {
    static classIns: any = {}
    static getInstance(_instanceID: any = "default"): any {
        if (!MockServiceData.classIns[_instanceID]) {
            MockServiceData.classIns[_instanceID] = new MockServiceData()
        }
        return MockServiceData.classIns[_instanceID]
    }
}
