import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/loader/styles.module.scss"
import "../../configuration/style/base/screens/loader/styles.module.css"
interface Props {
    showPageLoader: any
}
export class Loader extends Component<Props> {
    render() {
        let style = {display: "none", zIndex: 1}
        if (this.props.showPageLoader) {
            style = {display: "block", zIndex: 10000}
        }
        return (
            <div className={classes["loader-main"]} id="loader" style={style}>
                <div className={classes["loader-cont"]}>
                    <div className={classes["loader-div"] + " " + "fa-spin"}></div>
                </div>
            </div>
        )
    }
}
