import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import { HTTPMockDataService } from "../../http-mock-data-service"
import { StaticKey } from "../../static_key"

export class LedgerServices extends HTTPConnectionDatasService {
    params: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any = HTTPConnectionDatasService.getInstance()
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID = "default"): any {
        if (!LedgerServices.classIns[_instanceID]) {
            return (LedgerServices.classIns[_instanceID] = new LedgerServices(_instanceID))
        } else {
            return LedgerServices.classIns[_instanceID]
        }
    }

    getLedgerSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getLedgerHistory`
            this.httpConnectionIns.sendHttpRequest("getLedgerHistory", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
}
