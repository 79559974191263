import React, {Component} from "react"
import {Modal} from "reactstrap"
import styleClasses from "../../configuration/style/base/screens/login/styles.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
interface Props {
    alertPopupShow: any
    alertType: any
    alertMessage: any
    handleAlertPopupClose: any
}
export class AlertPopup extends Component<Props> {
    handleAlertPopupClose = () => {
        this.props.handleAlertPopupClose()
    }
    componentWillUnmount() {
        document.body.style.overflow = "unset"
    }
    render() {
        let isOpen = false
        if (this.props.alertPopupShow == "show") {
            isOpen = true
        }
        return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    onClosed={() => {
                        document.body.style.overflow = "unset"
                    }}
                    onExit={() => {
                        document.body.style.overflow = "unset"
                    }}
                    unmountOnClose={true}
                >
                    <div
                        className={styleClasses["modal-header"]}
                        
                    >
                        <h4 className={styleClasses["modal-title"]}>
                            <img
                                className={styleClasses["popuplogin-img"] + " img-fluid"}
                                
                            />
                            {StaticKey.LOCALE_DATA.HOMEPAGE.info}
                        </h4>
                        <span
                            onClick={this.handleAlertPopupClose}
                            className={styleClasses["login-modal-close"]}
                            aria-hidden="true"
                        >
                            &times;
                        </span>
                    </div>
                    <div className="modal-body text-center">
                        <img
                            className={"img-fluid " + styleClasses["popuplogin-img"]}
                            
                        ></img>
                        {this.props.alertMessage}
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
