import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import {CustomerManager} from "./customer_gl"
import * as serviceWorker from "./serviceWorker"
import "font-awesome/css/font-awesome.css"
import {BrowserRouter} from "react-router-dom"
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <CustomerManager />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
)
serviceWorker.unregister() 
