import React, {Component} from "react"
//import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
// import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Modal} from "reactstrap"

export class CreateRetailer extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    state: any = {
        companyIdCallback: true,
        userAadharCardNoCallback: true,
        termsAgreedCallback: true,
        registrationAadharCardNoCallback: true,
        // registrationIfscCodeCallback: true,
        alertPopupShow: "hide",
        termsConditionRead: false,
        companyList: {},
        pincodeData : [],
        pincodeData1 : [],
        fields: {
            companyId: "",
            distributorId: "",
            retailerName: "",
            username: "",
            personalAddress1: "",
            personalAddress2: "",
            personalPinCode: "",
            personalLocality: "",
            personalTaluka: "",
            personalCity: "",
            personalState: "",
            personalMobileNo: "",
            personalWhatsappNo: "",
            personalTelegramNo: "",
            personalEmail: "",
            userAddress1: "",
            userAddress2: "",
            userPinCode: "",
            userLocality: "",
            userTaluka: "",
            userCity: "",
            userState: "",
            userMobileNo: "",
            userWhatsappNo: "",
            userTelegramNo: "",
            userEmail: "",
            typeOfOrganisation: "",
            natureOfBusiness: "",
            userLandLineNo: "",
            establishmentType: 1,
            establishmentNearBySchool: "",
            establishmentFarTransport: "",
            registrationPanCardNo: "",
            registrationAadharCardNo: "",
            registrationIfscCode: "",
            registrationBankName: "",
            registrationBranchName: "",
            registrationAccountNo: "",
            uploadAadhar: "",
            uploadAadharVal: "",
            uploadPanCard: "",
            uploadPanCardVal: "",
            uploadDrivingLicence: "",
            uploadDrivingLicenceVal: "",
            uploadEBBill: "",
            uploadEBBillVal: "",
            uploadPassport: "",
            uploadPassportVal: "",
            uploadPhoto: "",
            uploadPhotoVal: "",
            termsAgreed: "",
            terminalType: 1,
        },
        errors:{            
            companyIdError:"",
            distributorIdError:"",
            retailerNameError:"",
            usernameError:"",
            personalAddress1Error:"",
            personalAddress2Error:"",
            personalPinCodeError:"",
            personalLocalityError:"",
            personalTalukaError:"",
            personalCityError:"",
            personalStateError:"",
            personalMobileNoError:"",
            personalWhatsappNoError:"",
            personalTelegramNoError:"",
            personalEmailError:"",
            userAddress1Error:"",
            userAddress2Error:"",
            userPinCodeError:"",
            userLocalityError:"",
            userTalukaError:"",
            userCityError:"",
            userStateError:"",
            userMobileNoError:"",
            userWhatsappNoError:"",
            userTelegramNoError:"",
            userEmailError:"",
            typeOfOrganisationError:"",
            natureOfBusinessError:"",
            establishmentTypeError: "",
            establishmentNearBySchoolError: "",
            establishmentFarTransportError: "",
            registrationPanCardNoError:"",
            registrationAadharCardNoError:"",
            registrationIfscCodeError:"",
            registrationBankNameError:"",
            registrationBranchNameError:"",
            registrationAccountNoError:"",
            uploadAadharError:"",
            uploadAadharValError:"",
            uploadPanCardError:"",
            uploadPanCardValError:"",
            uploadDrivingLicenceError:"",
            uploadDrivingLicenceValError:"",
            uploadEBBillError:"",
            uploadEBBillValError:"",
            uploadPassportError:"",
            uploadPassportValError:"",
            uploadPhotoError:"",
            uploadPhotoValError:"",
            termsAgreedError:"",
            terminalTypeError: "",
        }
    }

    componentDidMount() {
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
        //loginData.partnerTypeId = 4
        if (loginData.partnerTypeId == 0) {
            this.getCompanyList({
                "partnerTypeId": 3
            })
            /* this.getDistributorsList({
                "partnerTypeId": 3
            }) */
        } else if (loginData.partnerTypeId == 3) {
            this.getDistributorsList({
                "partnerTypeId": 5
            })
            agentLoginHideFields.push("companyId")
        } else {
            agentLoginHideFields.push("companyId")
            agentLoginHideFields.push("distributorId")
        }
        Object.keys(this.createRetailersFields).map((keys: any) => {
            {
                return this.createRetailersFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                        if (agentLoginHideFields.includes(fieldValObject1.name)) {
                                            return delete this.createRetailersFields[keys1][
                                                fieldValIndex1
                                            ]
                                        }
                                    }
                                )
                            })
                        } else {
                            if (agentLoginHideFields.includes(fieldValObject.name)) {
                                return delete this.createRetailersFields[keys][fieldValIndex]
                            }
                           
                        }
                    }
                )
            }
        })
            if (loginData.partnerTypeId == 3 ) {
                document.getElementById("distributorId")?.focus();
            }
            if (loginData.partnerTypeId == 5 ) {
                document.getElementById("retailerName")?.focus();
            }
       
        this.setState({isLoadedDropdown: false})
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }

    getDistributorsList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getDistributorsListDropdown(data)
        response
            .then((distributorListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!distributorListResponse.errorStatus) {
                    this.createRetailersFields["A. General Information"][1].options =
                        distributorListResponse.data
                    this.setState({distributorList: distributorListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getDistributorsListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    handleClearImage(e:any,value:any){
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [value]: "",
                [value + "Val"]: ""
            },
            errors: {
                ...this.state.errors,
                [value + "Error"]: ""
            }
        })

    }
    handleIfsc(e:any) {
        if(e.target.name == "username"){
            this.checkUserExist()
        }else if(e.target.name == "registrationIfscCode"){
            if(this.state.fields['registrationIfscCode'] != "") {
                this.uiData.updateShowLoading(true)
                fetch('https://ifsc.razorpay.com/'+this.state.fields['registrationIfscCode'])
                .then((response:any) => response.json())
                .then((json) => {
                    if(json.BRANCH != undefined){
                        this.setState({ 
                            ...this.state, 
                            fields:{
                                ...this.state.fields,
                            "registrationBankName":json['BANK'],
                            "registrationBranchName":json['BRANCH'],
                                },
                                errors:{
                                    ...this.state.errors,
                                "registrationBankNameError":"",
                                "registrationBranchNameError":"",
                            }
                        })
                    }else{                       
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "registrationIfscCodeError":"Invalid IFSC",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "registrationIfscCode": "",
                                "registrationBankName":"",
                                "registrationBranchName":""
                            }
                        })
                        document.getElementById(e.target.id)?.focus()
                    }
                        
                    this.uiData.updateShowLoading(false)

                })
                .catch((error:any) => { 
                    // //console.log(error);
                    // this.uiData.showToastMessage(
                    //     "Invalid Ifsc" , "error"
                    // )
                    // this.setState({
                    //     "registrationIfscCode": ""
                    // })
                        
                    this.setState({
                        ...this.state,
                        errors:{
                            ...this.state.errors,
                            "registrationIfscCodeError":"Invalid IFSC",
                        },                                
                        fields:{
                            ...this.state.fields,
                            "registrationIfscCode": "",
                            "registrationBankName":"",
                            "registrationBranchName":""
                        }
                    })
                    document.getElementById(e.target.id)?.focus()
                    this.uiData.updateShowLoading(false)
                 } );
            }

        }else if(e.target.name == "personalPinCode"){
            if(this.state.fields['personalPinCode'] != "") {
                this.uiData.updateShowLoading(true)
                fetch('https://api.postalpincode.in/pincode/'+this.state.fields['personalPinCode'])
                .then((response:any) => response.json())
                .then((json) => {
                    // //console.log(json[0].Status)
                    const dataArray:any =[];
                    const dataArrayData:any =[];
                    // this.createRetailersFields["D. Personal Detail"][5].options =[]
                    for(let iLoop = 0; iLoop<this.createRetailersFields["D. Personal Detail"].length;iLoop++){      
                        if(this.createRetailersFields["D. Personal Detail"][iLoop] && this.createRetailersFields["D. Personal Detail"][iLoop].name && this.createRetailersFields["D. Personal Detail"][iLoop].name != undefined && this.createRetailersFields["D. Personal Detail"][iLoop].name == "personalLocality"){ 
                            this.createRetailersFields["D. Personal Detail"][iLoop].options =[]
                        }
                    }

                    if(json[0].Status == "Success"){
                        const dataFromSuccess = json[0].PostOffice;
                        for(let i=0;i<dataFromSuccess.length;i++){
                            let arrayData:any = [];
                            const arrayData1:any = [];
                            // const arrayData2:any = [];
                            arrayData = {'id':dataFromSuccess[i].Name,'name':dataFromSuccess[i].Name}
                            arrayData1[dataFromSuccess[i].Name] = {'taluk':dataFromSuccess[i].Block,'state':dataFromSuccess[i].Circle,'city':dataFromSuccess[i].District}
                            dataArray.push(arrayData)
                            dataArrayData.push(arrayData1)
                        }
                        this.setState({
                            ...this.state,
                            pincodeData1:dataArrayData
                        })                                                    
                        // this.createRetailersFields["D. Personal Detail"][5].options =dataArray
                        for(let iLoop = 0; iLoop<this.createRetailersFields["D. Personal Detail"].length;iLoop++){      
                            if(this.createRetailersFields["D. Personal Detail"][iLoop] && this.createRetailersFields["D. Personal Detail"][iLoop].name && this.createRetailersFields["D. Personal Detail"][iLoop].name != undefined && this.createRetailersFields["D. Personal Detail"][iLoop].name == "personalLocality"){ 
                                this.createRetailersFields["D. Personal Detail"][iLoop].options =dataArray
                            }
                        }
    

                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "personalPinCodeError":"",
                                "personalLocalityError":"",
                                "personalTalukaError":"",
                                "personalCityError":"",
                                "personalStateError":"",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "personalTaluka": "" ,
                                "personalLocality":"",
                                "personalCity":"",
                                "personalState":"",
                            }
                        })
                        
                    }else{
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "personalPinCodeError":"Invalid Pincode",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "personalPinCode": "" ,
                                "personalTaluka": "" ,
                                "personalLocality":"",
                                "personalCity":"",
                                "personalState":"",
                            }
                        })
                        document.getElementById(e.target.id)?.focus()
                    }
                        
                    this.uiData.updateShowLoading(false)

                })
                .catch((error:any) => { 
                    //console.log(error);
                    this.setState({
                        ...this.state,
                        errors:{
                            ...this.state.errors,
                            "personalPinCodeError":"Invalid Pincode",
                        },                                
                        fields:{
                            ...this.state.fields,
                            "personalPinCode": "" ,
                            "personalTaluka": "" ,
                            "personalLocality":"",
                            "personalCity":"",
                            "personalState":"",
                        }
                    })
                    document.getElementById(e.target.id)?.focus()
                        
                    this.uiData.updateShowLoading(false)
                } );
            }
        }else if(e.target.name == "userPinCode"){
            if(this.state.fields['userPinCode'] != "") {
                this.uiData.updateShowLoading(true)
                fetch('https://api.postalpincode.in/pincode/'+this.state.fields['userPinCode'])
                .then((response:any) => response.json())
                .then((json) => {
                    // //console.log(json[0].Status)
                    const dataArray:any =[];
                    const dataArrayData:any =[];
                    for(let iLoop = 0; iLoop<this.createRetailersFields["A. General Information"].length;iLoop++){      
                        if(this.createRetailersFields["A. General Information"][iLoop] && this.createRetailersFields["A. General Information"][iLoop].name && this.createRetailersFields["A. General Information"][iLoop].name != undefined && this.createRetailersFields["A. General Information"][iLoop].name == "userLocality"){ 
                            this.createRetailersFields["A. General Information"][iLoop].options =[]
                        }
                    }  
                    if(json[0].Status == "Success"){
                        const dataFromSuccess = json[0].PostOffice;
                        for(let i=0;i<dataFromSuccess.length;i++){
                            let arrayData:any = [];
                            const arrayData1:any = [];
                            // const arrayData2:any = [];
                            arrayData = {'id':dataFromSuccess[i].Name,'name':dataFromSuccess[i].Name}
                            arrayData1[dataFromSuccess[i].Name] = {'taluk':dataFromSuccess[i].Block,'state':dataFromSuccess[i].Circle,'city':dataFromSuccess[i].District}
                            dataArray.push(arrayData)
                            dataArrayData.push(arrayData1)
                        }
                        this.setState({
                            ...this.state,
                            pincodeData:dataArrayData
                        })   
                        for(let iLoop = 0; iLoop<this.createRetailersFields["A. General Information"].length;iLoop++){      
                            if(this.createRetailersFields["A. General Information"][iLoop] && this.createRetailersFields["A. General Information"][iLoop].name && this.createRetailersFields["A. General Information"][iLoop].name != undefined && this.createRetailersFields["A. General Information"][iLoop].name == "userLocality"){ 
                                this.createRetailersFields["A. General Information"][iLoop].options =dataArray
                            }
                        }    
                        
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "userPinCodeError":"",
                                "userLocalityError":"",
                                "userTalukaError":"",
                                "userCityError":"",
                                "userStateError":"",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "userTaluka": "" ,
                                "userLocality":"",
                                "userCity":"",
                                "userState":"",
                            }
                        })                                                
                    }else{
                        this.setState({
                        ...this.state,
                        errors:{
                            ...this.state.errors,
                            "userPinCodeError":"Invalid Pincode",
                        },                                
                        fields:{
                            ...this.state.fields,
                            "userPinCode": "" ,
                            "userTaluka": "" ,
                            "userLocality":"",
                            "userCity":"",
                            "userState":"",
                        }
                    })
                        document.getElementById(e.target.id)?.focus()
                    }
                        
                    this.uiData.updateShowLoading(false)

                })
                .catch((error:any) => { 
                    this.setState({
                        ...this.state,
                        errors:{
                            ...this.state.errors,
                            "userPinCodeError":"Invalid Pincode",
                        },                                
                        fields:{
                            ...this.state.fields,
                            "userPinCode": "" ,
                            "userTaluka": "" ,
                            "userLocality":"",
                            "userCity":"",
                            "userState":"",
                        }
                    })
                    document.getElementById(e.target.id)?.focus()
                        
                    this.uiData.updateShowLoading(false)
                } );
            }
        }else if(e.target.name == "registrationPanCardNo"){
                const inputvalues = e.target.value; 
                if(inputvalues != ""){
                    const regex = /[A-Z a-z]{5}[0-9]{4}[A-Z a-z]{1}$/;    
                    if(!regex.test(inputvalues)){   
                        this.setState({
                            ...this.state,
                            errors:{
                                ...this.state.errors,
                                "registrationPanCardNoError":"Invalid Pan Number",
                            },                                
                            fields:{
                                ...this.state.fields,
                                "registrationPanCardNo": "" ,
                            }
                        })
                        document.getElementById(e.target.id)?.focus()
                    // return regex.test(inputvalues);    
                    } else{
                        //console.log("assd")
                    }   

                }     
    
            }
    }

    createRetailersFields: any = {
        "A. General Information": [
            {
                "name": "companyId",
                "type": "select",
                "label": "Company",
                // "placeHolder": "Company",
                "maxLength": 50,
                "autoFocus": true,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true
            },
            {
                "name": "distributorId",
                "type": "select",
                "label": "Distributor",
                // "placeHolder": "Distributor",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true
            },
            {"": []},
            {
                "name": "retailerName",
                "type": "text",
                "label": "Retailer Name",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            // {"": []},
            {
                "name": "terminalType",
                "type": "select",
                "label": "Terminal Type",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options":StaticKey.TERMINALTYPE,
            },            
            {
                "name": "username",
                "id": "username",
                "type": "text",
                "label": "Retailer Code",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "required": true,
                onblur: "checkUserExist"
            },
            {"": []},
            {
                "name": "userAddress1",
                "type": "text",
                "label": "Address 1",
                // "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            // {"": []},
            {
                "name": "userAddress2",
                "type": "text",
                "label": "Address 2",
                // "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {"": []},
            {
                "name": "userPinCode",
                "type": "number",
                "label": "Pincode",
                // "placeHolder": "PinCode",
                "maxLength": 6,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                
            },
            {
                "name": "userLocality",
                "type": "select",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,                
                "options": {},
                "selectShowEmpty": true
            },
            {
                "name": "userTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            {
                "name": "userCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            {
                "name": "userState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            // {"": []},
            {
                "name": "userMobileNo",
                "type": "number",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "userWhatsappNo",
                "type": "number",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "userTelegramNo",
                "type": "number",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "userEmail",
                "type": "email",
                "label": "Email Id",
                // "placeHolder": "Email",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },   
            {"": []},          
            {
                "name": "typeOfOrganisation",
                "type": "select",
                "label": "Type of Organisation",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "options":StaticKey.TYPEOFORGANISATION,
                "selectShowEmpty":true
            },         
            {
                "name": "natureOfBusiness",
                "type": "text",
                "label": "Nature of Business",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
            },
        ],
        "B. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "id": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                // "placeHolder": "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "registrationAadharCardNo",
                "id": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "____ ____ ____",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },            
            {"": []},
            {
                "name": "registrationIfscCode",
                "id": "registrationIfscCode",
                "type": "text",
                "label": "IFSC Code",
                // "placeHolder": "Registration Ifsc Code",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                onblur:"handleIfsc",
            },
            {
                "name": "registrationBankName",
                "type": "text",
                "label": "Bank Name",
                // "placeHolder": "Registration Bank Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "readonly":true,
            },
            {"": []},
            {
                "name": "registrationBranchName",
                "type": "text",
                "label": "Branch Name",
                // "placeHolder": "Registration Branch Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "readonly":true,
            },
            {
                "name": "registrationAccountNo",
                "type": "number",
                "label": "Account No",
                // "placeHolder": "Registration Account No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
        ],
        "C. Establishment Details": [
            {
                "": [
                    {
                        "name": "establishmentType",
                        "type": "radio",
                        "label": "Owned / Rental",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-check-input",
                        "required": false,
                        checked:2,
                        "options": {
                            1: "Owned",
                            2: "Rental"
                        }
                    },
                    {
                        "name": "establishmentNearBySchool",
                        "type": "radio",
                        "label": "Is there a School, Place of worship within the limits of 200 meters from your establishment",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-check-input",
                        "required": false,
                        checked:2,
                        "options": {
                            1: "Yes",
                            2: "No"
                        }
                    },
                    {
                        "name": "establishmentFarTransport",
                        "type": "number",
                        "label": "How far is the Railway station / Main Market / bus stand from the establishment (in meters)",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-control",
                        "required": false
                    }
                ]
            },
        ],
        "D. Personal Detail": [
           
            {
                "name": "personalAddress1",
                "type": "text",
                "label": "Personal Address1",
                // "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            // {"": []},
            {
                "name": "personalAddress2",
                "type": "text",
                "label": "Personal Address 2",
                // "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {"": []},
            {
                "name": "personalPinCode",
                "type": "number",
                "label": "Pincode",
                // "placeHolder": "PinCode",
                "maxLength": 6,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                
            },
            {
                "name": "personalLocality",
                "type": "select",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,                
                "options": {},
                "selectShowEmpty": true
            },
            {
                "name": "personalTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            {
                "name": "personalCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            {
                "name": "personalState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                readonly:true
            },
            {"": []},
            {
                "name": "personalMobileNo",
                "type": "number",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "personalWhatsappNo",
                "type": "number",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "personalTelegramNo",
                "type": "number",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {"":[]},
            {
                "name": "uploadAadhar",
                "type": "file",
                "label": "Aadhar",
                // "placeHolder": "Upload Aadhar",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "uploadPanCard",
                "type": "file",
                "label": "Pan Card",
                // "placeHolder": "Upload Pan Card",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "uploadPhoto",
                "type": "file",
                "label": "Photo",
                // "placeHolder": "Upload Photo",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true
            },
            {
                "name": "uploadDrivingLicence",
                "type": "file",
                "label": "Driving Licence",
                // "placeHolder": "Upload Driving Licence",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadEBBill",
                "type": "file",
                "label": "Light Bill",
                // "placeHolder": "Upload EBBill",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPassport",
                "type": "file",
                "label": "Passport",
                // "placeHolder": "Upload Passport",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
        ],
        "Terms & Conditions": [
            {
                "name": "termsAgreed",
                "type": "checkbox",
                "label": "Terms Agreed",
                "label1":
                    "I willingly accept all the terms and condition laid down by SmartLotto to assign me as an authorised Retailer",
                // "placeHolder": "Terms Agreed",
                "maxLength": 1,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "showModalCondition": true
            }
        ]
    }

    handleAlertPopupClose = () => {
        this.uiData.setAlertShowMessage("hide", "", "")
        this.setState({alertPopupShow: "hide", drawWinNumberId: ""})
    }
    handlePopupShow = (e: any ) => {
       e.preventDefault()
        this.setState({
            alertPopupShow: "show",
            termsConditionRead : true
        })
    }

    validateCreateRetailerForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createRetailersFields).map((keys: any) => {
            {
                return this.createRetailersFields[keys].map((fieldValObject: any) => {
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                if (
                                    fieldValObject1.required &&
                                    this.state.fields[fieldValObject1.name] == ""
                                ) {
                                    flagError[fieldValObject1.name + "Error"] = true

                                    const errorData = this.uiData.textConversion(
                                        fieldValObject1.label
                                    )
                                    return (newErrorMsgArray[fieldValObject1.name + "Error"] =
                                        errorData + " is required")
                                } else {
                                    if (fieldValObject1.passwordCondition) {
                                        let pattern: any
                                        let error: any
                                        if (
                                            fieldValObject1.name == "password" ||
                                            fieldValObject1.name == "confirmPassword"
                                        ) {
                                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                                            error = StaticKey.PASSWORD_CONDITION
                                        } else if (fieldValObject1.name == "transactionPassword") {
                                            pattern = new RegExp(StaticKey.TXN_PASSWORD_REG_EXP)
                                            error = StaticKey.TXN_PASSWORD_CONDITION
                                        }
                                        if (!pattern.test(this.state[fieldValObject1.name])) {
                                            // flag = false
                                            flagError[fieldValObject1.name + "Error"] = true
                                            return (newErrorMsgArray[
                                                fieldValObject1.name + "Error"
                                            ] = "")
                                        }
                                    } else {
                                        return (newErrorMsgArray[fieldValObject1.name + "Error"] =
                                            "")
                                    }
                                }
                            })
                        })
                    } else {
                        if (fieldValObject.type == "file") {
                            if (
                                fieldValObject.required &&
                                this.state.fields[fieldValObject.name + "Val"] == ""
                            ) {
                                flagError[fieldValObject.name + "Error"] = true

                                const errorData = this.uiData.textConversion(fieldValObject.label)
                                return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                    errorData + " is required")
                            } else {
                                return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                            }
                        } else {
                            if (
                                fieldValObject.required &&
                                this.state.fields[fieldValObject.name] == ""
                            ) {
                                flagError[fieldValObject.name + "Error"] = true

                                const errorData = this.uiData.textConversion(fieldValObject.label)
                                return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                    errorData + " is required")
                            } else {
                                if (fieldValObject.passwordCondition) {
                                    let pattern: any
                                    let error: any
                                    if (
                                        fieldValObject.name == "password" ||
                                        fieldValObject.name == "confirmPassword"
                                    ) {
                                        pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                                        error = StaticKey.PASSWORD_CONDITION
                                    } else if (fieldValObject.name == "transactionPassword") {
                                        pattern = new RegExp(StaticKey.TXN_PASSWORD_REG_EXP)
                                        error = StaticKey.TXN_PASSWORD_CONDITION
                                    }
                                    if (!pattern.test(this.state.fields[fieldValObject.name])) {
                                        // flag = false
                                        flagError[fieldValObject.name + "Error"] = true
                                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                            error)
                                    }
                                } else if (fieldValObject.name == "username" && this.state.fields["username"] != "") {
                                    const pattern = new RegExp(StaticKey.USERNAME_REG_EXP)
                                    const error = StaticKey.USERNAME_REG_EXP_ERROR
                                    if (!pattern.test(this.state.fields[fieldValObject.name])) {
                                        flagError[fieldValObject.name + "Error"] = true
                                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                                            error)
                                    }
                                } else {
                                    return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                                }
                            }
                        }
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handleCreateRetailerReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createRetailersFields).map((keys: any) => {
            {
                return this.createRetailersFields[keys].map((fieldValObject: any) => {
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                return (
                                    (newArrayError[fieldValObject1.name + "Error"] = ""),
                                    (newArrayFields[fieldValObject1.name] = ""),
                                    (newArrayFields[fieldValObject1.name + "Val"] = "")
                                )
                            })
                        })
                    } else {
                        return (
                            (newArrayError[fieldValObject.name + "Error"] = ""),
                            (newArrayFields[fieldValObject.name] = ""),
                            (newArrayFields[fieldValObject.name + "Val"] = "")
                        )
                    }
                })
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields})
         
        const date = new Date()
        const currentDay = String(date.getDate()).padStart(2, "0")
        const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        const currentYear = date.getFullYear()
        const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
        const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`
        this.setState({
            
            fromDate:currentDate,
            toDate:currentEndDate,
            fields:{
                ...this.state.fields,
                fromDate:currentDate,
                toDate:currentEndDate,

            }
        })
        // document.getElementById("toDate")?.setAttribute("min",currentDate)
        document.getElementById("toDate")?.removeAttribute("readonly")
    }

    convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = error => {
                reject(error)
            }
        })
    }

    handleCreateRetailerKeyDown = (event: any) => {
        const keycode = event.keyCode || event.which
        if(keycode != 13){
            if (event.target.name == "transactionPassword") {
                const keycode = event.keyCode || event.which
                if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46 ) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            [event.target.name + "Error"]: ""
                        }
                    })
                } else {
                    event.preventDefault()
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            [event.target.name + "Error"]: "numbers only allowed"
                        }
                    })
                }
            }
            if (event.target.name == "registrationAadharCardNo") {
                const keycode = event.keyCode || event.which
                if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46 || keycode == 37 || keycode == 38 || keycode == 39 || keycode == 40 || keycode == 32 ) {
                    let value = event.target.value;
                    if(keycode != 8  && keycode != 46 && ( value.length == 4 || value.length == 9 )) {
                        value = value += ' ';
                    }
                    this.setState({...this.state,"registrationAadharCardNo" : value , fields:{...this.state.fields,"registrationAadharCardNo" : value }})
                } else {
                    event.preventDefault()
                }
            }
            if (event.target.name == "userAadharCardNo") {
                const keycode = event.keyCode || event.which
                if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46 || keycode == 37 || keycode == 38 || keycode == 39 || keycode == 40 || keycode == 32 ) {
                    let value = event.target.value;
                    if(keycode != 8  && keycode != 46 && ( value.length == 4 || value.length == 9 )) {
                        value = value += ' ';
                    }
                      this.setState({...this.state,fields:{...this.state.fields,"userAadharCardNo" : value }})
                } else {
                    event.preventDefault()
                }
            }

        }
    }

    handleCreateRetailerInputChange = async (e: any) => {
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        if(e.target.name == "registrationPanCardNo"){
            value=value.toUpperCase();
            if(value.length > 10){
                e.preventDefault();
                return false;
            }
        }

        if(e.target.name == "registrationIfscCode"){
            value=value.toUpperCase();
            e.preventDefault();
        }

        if(e.target.name == "personalPinCode" || e.target.name == "userPinCode" ){
            // value=value.toUpperCase();
            if(value.length > 7){
                e.preventDefault();
                return false;
            }
        }

        if (e.target.type == "number" && value.length > StaticKey.MAX_AMOUNT_LENGTH) {
            return false
        }
        
        if (e.target.type == "radio") {
            if(e.target.name == "establishmentType"){
                this.createRetailersFields["C. Establishment Details"][0][""][0].checked=value;
            }
            if(e.target.name == "establishmentNearBySchool"){
                this.createRetailersFields["C. Establishment Details"][0][""][1].checked=value;
            }
        }

        if (this.state[e.target.name + "Callback"]) {
            if(e.target.name == "companyId"){
                this.getDistributorsList({
                    "partnerTypeId": 5,
                    "companyId": value
                })
            }
            // if(e.target.name == "termsAgreed"){
            //     if(!this.state.termsConditionRead){
            //         setTimeout(()=>{
            //             this.setState({
            //                 alertPopupShow: "show",
            //                 termsConditionRead : true
            //             })
            //         },100)
                    
            //     }
            // }
            if(e.target.name == "registrationAadharCardNo" || e.target.name == "userAadharCardNo" ){
                
                const keycode = e.keyCode || e.which
                    
                if ((keycode >= 48 && keycode <= 57) || keycode == 9 || keycode == 8 || keycode == 46) {
                    //
                } else {
                    e.preventDefault()
                }

            }
            
            // if(e.target.name == "registrationIfscCode"){
            //     if(e.target.value.length > 9){
            //         this.handleIfsc(e.target.value)

            //     }
            // }
        }
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            if(file.size <= StaticKey.MAX_FILE_SIZE){
                const newValue = await this.convertBase64(file)
                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        [e.target.name]: newValue,
                        [e.target.name + "Val"]: file.name
                    },
                    errors: {
                        ...this.state.errors,
                        [e.target.name + "Error"]: ""
                    }
                })
            }else{
                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        [e.target.name]: "",
                        [e.target.name + "Val"]: ""
                    },
                    errors: {
                        ...this.state.errors,
                        [e.target.name + "Error"]: StaticKey.MAX_FILE_SIZE_ERROR
                    }
                })
            }
        } else {
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [e.target.name]: value
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
        if(e.target.name == "personalLocality"){
            // alert(this.state.fields['userLocality'])
            const data = this.state.pincodeData1;
            for(let i=0;i<data.length;i++){
                if(Object.keys(data[i]) == value){
                    //console.log(data[i])
                    this.setState({
                        ...this.state,
                        fields:{
                            ...this.state.fields,
                            personalLocality:value,
                            personalTaluka:data[i][value].taluk,
                            personalCity:data[i][value].city,
                            personalState:data[i][value].state,
                        },
                        errors: {
                            ...this.state.errors,
                            ["personalLocalityError"]: "",
                            ["personalTalukaError"]: "",
                            ["personalCityError"]: "",
                            ["personalStateError"]: "",
                        }
                    })
                }
            }
        }
        if(e.target.name == "userLocality"){
            // alert(this.state.fields['userLocality'])
            const data = this.state.pincodeData;
            for(let i=0;i<data.length;i++){
                if(Object.keys(data[i]) == value){
                    //console.log(data[i])
                    this.setState({
                        ...this.state,
                        fields:{
                            ...this.state.fields,
                            userLocality:value,
                            userTaluka:data[i][value].taluk,
                            userCity:data[i][value].city,
                            userState:data[i][value].state,
                        },
                        errors: {
                            ...this.state.errors,
                            ["userLocalityError"]: "",
                            ["userTalukaError"]: "",
                            ["userCityError"]: "",
                            ["userStateError"]: "",
                        }
                    })
                }
            }
        }
    }

    handleCreateRetailerSubmit = (e: any) => {
        e.preventDefault()
        // //console.log(this.state.fields); return false;
        if (this.validateCreateRetailerForm()) {
            this.uiData.updateShowLoading(true)
            //console.log("this.state.fields", this.state.fields)

            const response = this.DownlineManageServices.createRetailer(this.state.fields)
            response
                .then((createRetailerResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!createRetailerResponse.errorStatus) {
                         const successMessage = "Retailer created successfully. Application No. :"+createRetailerResponse.applicationId;
                        this.uiData.showToastMessage(successMessage, "")
                        this.handleCreateRetailerReset()
                        document.getElementById('reset_form')?.click();
                    } else {
                        this.uiData.showToastMessage(
                            "Retailer creation failed, Try again!",
                            "error"
                        )
                    }
                })
                .catch((err: any) => {
                    //console.log("handleCreateRetailerSubmitErr", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage("Something went wrong!. Try again.", "error")
                })
        } else {
            window.scrollTo(0, 0)
        }
    }

    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.createRetailersFields["A. General Information"][0].options =
                        getCompanyListResponse.data
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    checkUserExist = () => {
        if (this.state.fields.username != "") {
            const pattern = new RegExp(StaticKey.USERNAME_REG_EXP)
            const error = StaticKey.USERNAME_REG_EXP_ERROR
            if (!pattern.test(this.state.fields.username)) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        ["usernameError"]: error
                    }
                })
            } else {
                this.uiData.updateShowLoading(true)
                const customerCreateObject = {
                    "username": this.state.fields.username
                }
                const response = this.DownlineManageServices.checkUserExist(customerCreateObject)
                response
                    .then((checkPartnerResponse: any) => {
                        this.uiData.updateShowLoading(false)
                        if (checkPartnerResponse.errorStatus) {
                            //console.log(checkPartnerResponse)
                            this.setState({
                                ...this.state,
                                "username": "", 
                                errors:{
                                    ...this.state.errors,
                                    "usernameError":"Retailer Code Already Exists",
                                },                                
                               
                                ...this.state.fields,
                                fields:{
                                    "username": ""
                                }
                            })
                            document.getElementById("username")?.focus();
                        }
                    })
                    .catch((err: any) => {
                        //console.log("checkUserExistErr", err)
                        this.uiData.updateShowLoading(false)
                        this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                    })
            }
        }
    }

    checkPasswordConfirm = () => {
        if (this.state.confirmPassword != "" && this.state.confirmPassword != this.state.password) {
            this.uiData.showToastMessage(
                StaticKey.LOCALE_DATA.Home.passwordAndConfirmPasswordShouldBeSame,
                "error"
            )
            this.setState({
                "confirmPassword": ""
            })
        }
    }

    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"] + " " + classes["general-form"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>Create Retailer</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.createRetailersFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div
                                                key={values}
                                                className={classes["main-div-input-sec-2"]}
                                            >
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.createRetailersFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                            if (
                                                                Object.keys(fieldValObject)
                                                                    .length == 1
                                                            ) {
                                                                {
                                                                    return Object.keys(
                                                                        fieldValObject
                                                                    ).map(
                                                                        (
                                                                            keys1: any,
                                                                            values1: any
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={values1}
                                                                                    className={
                                                                                        classes[
                                                                                            "main-div-input-sec-2-sub"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    <h6>{keys1}</h6>
                                                                                    <div
                                                                                        className={
                                                                                            classes[
                                                                                                "main-div-input-sec"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {fieldValObject[
                                                                                            keys1
                                                                                        ].map(
                                                                                            (
                                                                                                fieldValObject1: any,
                                                                                                fieldValIndex1: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            classes[
                                                                                                                "sub-div"
                                                                                                            ]
                                                                                                        }
                                                                                                        key={
                                                                                                            fieldValIndex1
                                                                                                        }
                                                                                                    >
                                                                                                        <label htmlFor="name">
                                                                                                            {
                                                                                                                fieldValObject1.label
                                                                                                            }
                                                                                                        </label>
                                                                                                        {fieldValObject1.required ? (
                                                                                                            <span
                                                                                                                className={
                                                                                                                    classes[
                                                                                                                        "mandatory-symbol"
                                                                                                                    ]
                                                                                                                }
                                                                                                            >
                                                                                                                *
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                        {
                                                                                                            <>
                                                                                                                <InputBox
                                                                                                                    id={
                                                                                                                        fieldValObject1.name
                                                                                                                    }
                                                                                                                    checked={fieldValObject1.checked}                                                                                                     autoComplete="off"
                                                                                                                    maxLength={
                                                                                                                        fieldValObject1.maxLength
                                                                                                                    }
                                                                                                                    className={
                                                                                                                        "form-control " +
                                                                                                                        classes[
                                                                                                                            fieldValObject1
                                                                                                                                .class
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    autoFocus={
                                                                                                                        fieldValObject1.autoFocus
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        fieldValObject1.name
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .fields[
                                                                                                                            fieldValObject1
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    type={
                                                                                                                        fieldValObject1.type
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        tabIndex++
                                                                                                                    }
                                                                                                                    required={
                                                                                                                        fieldValObject1.required
                                                                                                                    }
                                                                                                                    onChange={() =>
                                                                                                                        this.handleCreateRetailerInputChange(
                                                                                                                            event
                                                                                                                        )
                                                                                                                    }
                                                                                                                    options={
                                                                                                                        fieldValObject1.options
                                                                                                                    }
                                                                                                                />
                                                                                                                <>
                                                                                                                    <div className={classes["uploaded_file_div"]}>
                                                                                                                        {fieldValObject.type ==
                                                                                                                            "file" &&
                                                                                                                            this.state
                                                                                                                                .fields[
                                                                                                                                fieldValObject.name +
                                                                                                                                    "Val"
                                                                                                                            ]}
                                                                                                                    
                                                                                                                    </div>
                                                                                                                    {fieldValObject.type ==
                                                                                                                            "file" && this.state.fields[fieldValObject.name +"Val"] != "" ? <span className={classes["cursor-pointer"]} onClick={()=>{this.handleClearImage(event,fieldValObject.name)}}> x </span> : <></>
                                                                                                                                }
                                                                                                                    </>
                                                                                                                <div
                                                                                                                    className={
                                                                                                                        classes[
                                                                                                                            "error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .errors[
                                                                                                                            fieldValObject1.name +
                                                                                                                                "Error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            } else {
                                                                let classNew = ""
                                                                if (
                                                                    fieldValObject.label ==
                                                                    "Terms Agreed"
                                                                ) {
                                                                    classNew = "subdiv-full-width"
                                                                }
                                                                let uploadClass = ""
                                                                if (
                                                                    fieldValObject.type =="file"
                                                                ) {
                                                                    uploadClass = "uploadClass"
                                                                }

                                                                return (
                                                                    <div
                                                                        className={
                                                                            classes["sub-div"] +
                                                                            " " +
                                                                            classes[classNew]
                                                                        }
                                                                        key={fieldValIndex}
                                                                    >
                                                                        <div>
                                                                        {fieldValObject.name != "termsAgreed" && (
                                                                            <label
                                                                                htmlFor={
                                                                                    fieldValObject.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    fieldValObject.label
                                                                                }
                                                                            </label>
                                                                        )}
                                                                            {fieldValObject.required  && fieldValObject.name != "termsAgreed" ? (
                                                                                <span
                                                                                    className={
                                                                                        classes[
                                                                                            "mandatory-symbol"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
 {fieldValObject.name == "password" && (
                                                                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                                                    &nbsp;<i className="fa fa-info-circle"></i>
                                                                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.PASSWORD_CONDITION}</span>
                                                                                </div>   
                                                                            )}
                                                                            {fieldValObject.name == "transactionPassword" && (
                                                                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                                                    &nbsp;<i className="fa fa-info-circle"></i>
                                                                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.TXN_PASSWORD_CONDITION}</span>
                                                                                </div>   
                                                                            )}
                                                                            <InputBox
                                                                                id={
                                                                                    fieldValObject.name
                                                                                }
                                                                                checked={fieldValObject.checked}     
                                                                                autoComplete="off"
                                                                                maxLength={
                                                                                    fieldValObject.maxLength
                                                                                }
                                                                                readonly = {fieldValObject.readonly ? true : false}
                                                                                onBlur={()=>{
                                                                                    // fieldValObject.name ==
                                                                                    // "username"
                                                                                    //     ? this
                                                                                    //           .checkUserExist
                                                                                    //     : fieldValObject.name ==
                                                                                    //       "confirmPassword"
                                                                                    //     ? this
                                                                                    //           .checkPasswordConfirm
                                                                                    //     :  
                                                                                    // fieldValObject.name == "registrationIfscCode" ?
                                                                                     this.handleIfsc(event)  }
                                                                                    // : void ""
                                                                                }
                                                                                placeholder = {fieldValObject.placeHolder}
                                                                                className={
                                                                                    "form-control " +
                                                                                    classes[
                                                                                        fieldValObject
                                                                                            .class
                                                                                    ] +
                                                                                    " " +
                                                                                    classes[
                                                                                        "upload_input_field"
                                                                                    ]+
                                                                                    " " +
                                                                                    classes[
                                                                                        uploadClass
                                                                                    ]
                                                                                }
                                                                                autoFocus={
                                                                                    fieldValObject.autoFocus
                                                                                }
                                                                                accept={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? ".png, .jpg, .svg, .pdf"
                                                                                        : ""
                                                                                }
                                                                                value={
                                                                                    fieldValObject.type !=
                                                                                    "file"
                                                                                        ? this.state
                                                                                              .fields[
                                                                                              fieldValObject
                                                                                                  .name
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                name={
                                                                                    fieldValObject.name
                                                                                }
                                                                                type={
                                                                                    fieldValObject.type
                                                                                }
                                                                                tabIndex={
                                                                                    tabIndex++
                                                                                }
                                                                                required={
                                                                                    fieldValObject.required
                                                                                }
                                                                                onChange={() =>
                                                                                    this.handleCreateRetailerInputChange(
                                                                                        event
                                                                                    )
                                                                                }
                                                                                onKeyDown={() =>
                                                                                    this.handleCreateRetailerKeyDown(
                                                                                        event
                                                                                    )
                                                                                }
                                                                                label1={
                                                                                    fieldValObject.label1
                                                                                }
                                                                                options={
                                                                                    fieldValObject.type ==
                                                                                    "select"
                                                                                        ? fieldValObject.options
                                                                                        : ""
                                                                                }
                                                                                selectShowEmpty={
                                                                                    fieldValObject.selectShowEmpty
                                                                                }
                                                                                showModalCondition={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? fieldValObject.showModalCondition
                                                                                        : ""
                                                                                }
                                                                                showModalCallback={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? this
                                                                                              .handlePopupShow
                                                                                        : ""
                                                                                }
                                                                                /* style={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? {
                                                                                              display:
                                                                                                  "none"
                                                                                          }
                                                                                        : {}
                                                                                }
  */
                                                                            />
                                                                            
                                                                            {/* <div
                                                                                className={
                                                                                    classes[
                                                                                        "uploaded_file_div"
                                                                                    ]
                                                                                }
                                                                            > */}
                                                                               <>
                                                                            <div className={classes["uploaded_file_div"]}>
                                                                                {fieldValObject.type ==
                                                                                    "file" &&
                                                                                    this.state
                                                                                        .fields[
                                                                                        fieldValObject.name +
                                                                                            "Val"
                                                                                    ]}
                                                                               
                                                                            </div>
                                                                            {fieldValObject.type ==
                                                                                    "file" && this.state.fields[fieldValObject.name +"Val"] != "" ? <span className={classes["cursor-pointer"]} onClick={()=>{this.handleClearImage(event,fieldValObject.name)}}> x </span> : <></>
                                                                                        }
                                                                            </>
                                                                            </div>
                                                                            {
                                                                                    this.state
                                                                                        .errors[
                                                                                        fieldValObject.name +
                                                                                            "Error"
                                                                                    ] != "" && 
                                                                            <div
                                                                                className={
                                                                                    classes["error"]
                                                                                }
                                                                            >
                                                                                {
                                                                                    this.state
                                                                                        .errors[
                                                                                        fieldValObject.name +
                                                                                            "Error"
                                                                                    ]
                                                                                }
                                                                            </div>
                                                            }
                                                                        {/* </div> */}
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            {/* <div
                                className={classes["guidelines-button"]}
                                onClick={() => this.handlePopupShow(event)}
                            >
                                Guidelines <i className="fa fa-info-circle" aria-hidden="true"></i>
                            </div> */}

                            {this.state.alertPopupShow == "show" && (
                                <Modal
                                    className={classes["terms-popup1"] + " modal-lg"}
                                    isOpen={true}
                                    onClosed={() => {
                                        document.body.style.overflow = "unset"
                                    }}
                                    onExit={() => {
                                        document.body.style.overflow = "unset"
                                    }}
                                    unmountOnClose={true}
                                >
                                  <div className={classes["modal-header"]}>
                                        <h4 className={classes["modal-title"]}>Terms & Conditions</h4>
                                        <span onClick={this.handleAlertPopupClose}
                                            className={classes["login-modal-close"]}
                                            aria-hidden="true">
                                            &times;
                                        </span>
                                    </div>
                                    {/* <form> */}
                                    <div className="modal-body mb-2">
                                        <span
                                            onClick={this.handleAlertPopupClose}
                                            className={classes["login-modal-close"]}
                                            aria-hidden="true"
                                        >
                                            &times;
                                        </span>
                                        {/* <h5 style={{textAlign: "left"}}>Terms & Conditions :</h5> */}
                                        <ol style={{textAlign: "left"}}>
                                            <li>
                                                The name of the person ( Applicant ), Who signs an
                                                agreement need to submit the proof of address.
                                                <p>
                                                    * Mandatory proofs are : Driving Licence, Light
                                                    Bill, Passport, Aadhar Card, Pan Card.
                                                </p>
                                            </li>
                                            <li>
                                                The address of the premises of the Retail outlet,
                                                which is entering an agreement with SmartLotto.
                                            </li>
                                            <li>
                                                If there exist more than partners, please provide
                                                the details on an additional sheet. Each partner
                                                needs to submit the proof of address.
                                                <p>
                                                    * Mandatory proofs are : Driving Licence, Light
                                                    Bill, Passport, Aadhar Card, Pan Card.
                                                </p>
                                            </li>
                                            <li>
                                                If represents the total staff at the premises where
                                                the lottery will be played. A monthly payroll
                                                statement and bank statement need to to be
                                                submitted.
                                            </li>
                                            <li>
                                                Proof of ownership of the premises has to be
                                                submitted.
                                            </li>
                                            <li>
                                                A copy of agreement with the represented companies
                                                needs to be submitted.
                                            </li>
                                            <li>An audited balance sheet needs to be submitted.</li>
                                            <li>
                                                The statement of permission from the appropriate
                                                authority is needed.
                                            </li>
                                            <li>
                                                PAN detail and Type of Organisation is Mandatory.
                                            </li>
                                            <li>
                                                The courts in Mumbai shall have exclusive
                                                jurisdiction.
                                            </li>
                                        </ol>
                                    </div>
                                    {/* <div className={classes["modal-footer"]}>
                                    
                                    <div className={classes["sub-div"]}>
                                        <InputBox
                                            id="reset_form"
                                            className="form-control  btn btn-danger"
                                            type="reset"
                                            value="Reset"
                                            tabIndex={tabIndex++}
                                        />
                                    </div>
                                </div> */}
                                    {/* </form> */}
                                </Modal>
                            )}

                            {/* <div className={classes["guidelines-div1"]} style={{display: "none"}}>
                                <h6>
                                    Other guidelines for filling the form : Supporting document
                                    required
                                </h6>
                            </div> */}
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Submit"
                                        onClick={() => this.handleCreateRetailerSubmit(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleCreateRetailerReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
