import {UIDataService} from "../../../../game_core/type_script/ui-data-service"
import {HTTPConnectionDatasService} from "../../../../game_core/type_script/http-connection-data-service"
import {HTTPMockDataService} from "../../../../game_core/type_script/http-mock-data-service"
import {environment} from "../../../../game_core/type_script/environment"
import {EventEmitter} from "eventemitter3"
import {StaticKey} from "../../static_key"
import { DataStorage } from "../localstorage"
export class AuthCheck extends EventEmitter {
    static classIns: any = {}
    uiData: UIDataService = UIDataService.getInstance()
    dataService = new DataStorage()
    state = {
        loading: false
    }
    ajaxInstanceId: any = ""
    httpConnectionIns: any

    constructor(_instanceID: any) {
        super()
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
        console.log('_instanceID',_instanceID); 
    }
    static getInstance(_instanceID: any = "default"): any {
        if (!AuthCheck.classIns[_instanceID]) {
            return (AuthCheck.classIns[_instanceID] = new AuthCheck(_instanceID))
        } else {
            return AuthCheck.classIns[_instanceID]
        }
    }
    checkLoginStatus = () => {
        const requestType = `myProfile`
        const requestURL = `http${environment.s}://${environment.apiPath}${environment.apiVersion}/myProfile`
        this.httpConnectionIns.sendHttpRequest(requestType, requestURL).then(
            (respObj: any) => {
                StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                if (!respObj.errorStatus) {
                    this.uiData.setLoginStatus(true);                    
                    this.uiData.setLoginResponse(respObj.loginUser)
                } else {
                    this.uiData.setLoginStatus(false)
                    this.uiData.setLoginResponse({})
                }
            },
            () => {
                this.uiData.setLoginStatus(false)
                this.uiData.setLoginResponse({})
            }
        )
    }
}
