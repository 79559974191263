import React, {Component} from "react"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import { LimitControlServices } from "../../../game_core/type_script/services/limitcontrol"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"

export class SetLimit extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    LimitControlServices = LimitControlServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    state: any = {
        searchFilterRow:[0,1],
        userType: "",
        companyName: "",
        distributorName: "",
        retailerName: "",
        adjustType: "",
        points: "",
        userTypeError: "",
        companyNameError: "",
        distributorNameError: "",
        retailerNameError: "",
        adjustTypeError: "",
        pointsError: "",
        retailerParentCreditLimit:"",
        retailerCreditLimit:"",
        distributorParentCreditLimit:"",
        distributorCreditLimit:"",
        showCompanyLimit:false,
        showDistLimit:false,
        showRetailerLimit:false,
    }
    setLimitFields: any = {
        "": [
            {
                rowId:0,
                name: "userType",
                id: "userType",
                type: "select",
                label: StaticKey.LOCALE_DATA.limitControl.userType,
                autoFocus: true,
                class: "form-control",
                required: true,
                style: {},
                selectShowEmpty:true,
                options:{},
                dnone:false,
            },
            {
                rowId:0,
                name: "companyName",
                id: "companyName",
                type: "select",
                label: StaticKey.LOCALE_DATA.limitControl.companyName,
                autoFocus: true,
                class: "form-control",
                required: true,
                style: {},
                selectShowEmpty:true,
                options:{},
                dnone:true,
            },
            {
                rowId:0,
                name: "distributorName",
                id: "distributorName",
                type: "select",
                label: StaticKey.LOCALE_DATA.limitControl.distributorName,
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                selectShowEmpty:true,
                options:{},
                dnone:true,
            },
            {
                rowId:0,
                name: "retailerName",
                id: "retailerName",
                type: "select",
                label: StaticKey.LOCALE_DATA.limitControl.retailerName,
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                selectShowEmpty:true,
                options:{},
                dnone:true,
            },
            {
                rowId:1,
                name: "adjustType",
                id: "adjustType",
                type: "select",
                label: StaticKey.LOCALE_DATA.limitControl.adjustType,
                options:  StaticKey.POINTSTRANSFERTYPE,
                autoFocus: false,
                class: "form-control",
                selectShowEmpty:true,
                required: true,
                dnone:false,
            },
            {
                rowId:1,
                name: "points",
                id: "points",
                type: "number",
                label: StaticKey.LOCALE_DATA.setLimitControl.points,
                placeHolder: StaticKey.LOCALE_DATA.setLimitControl.points,
                // maxLength: "6",
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                autoComplete: "off",
                dnone:false,
            }
        ]
    }
    componentDidMount() {        
        const loginData = this.uiData.getLoginResponse()
        if(loginData.partnerTypeId == 0){
            this.setLimitFields[""][0].options = StaticKey.userType
        }
        if(loginData.partnerTypeId == 3){
            this.setLimitFields[""][0].options = StaticKey.userTypeComp
        }
        if(loginData.partnerTypeId == 5){
            this.setLimitFields[""][0].selectShowEmpty=false;
            this.setLimitFields[""][0].options = StaticKey.userTypeDist
            this.setLimitFields[""][0].required = false 
            this.setLimitFields[""][1].required = false 
            this.setLimitFields[""][2].required = false 
            this.setLimitFields[""][3].required = true
            this.setLimitFields[""][1].dnone = true 
            this.setLimitFields[""][2].dnone = true 
            this.setLimitFields[""][3].dnone = false 
            const data = {
                "partnerGuid" : loginData.partnerGUID
            }
            this.getRetailersList(data)
        }
    }

    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.setLimitFields[""][1].options = getCompanyListResponse.data                   
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
              //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    getDistributorsList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getDistributorsListDropdown(data)
        response
            .then((distributorListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!distributorListResponse.errorStatus) {
                    this.setLimitFields[""][2].options= distributorListResponse.data
                    this.setState({distributorList: distributorListResponse.data})
                }
            })
            .catch((err: any) => {
              //console.log("getDistributorsListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    getRetailersList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getRetailersListDropdown(data)
        response
            .then((retailerListResponse: any) => {
              //console.log(retailerListResponse)
                if (!retailerListResponse.errorStatus) {
                    this.setLimitFields[""][3].options= retailerListResponse.data
                    this.setState({retailerList: retailerListResponse.data,userType:"retailer"})
                }
                this.uiData.updateShowLoading(false)
            })
            .catch((err: any) => {
              //console.log("getRetailersListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    validateLimitSettingSubmit = () => {
        let flag = true
        const errorMessage = StaticKey.LOCALE_DATA.limitControl
        this.setLimitFields[""].map((val: any) => {
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    const errorData = this.uiData.textConversion(errorMessage[val.name])
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.limitControl.isRequired
                    })
                    return
                } else {                    
                    this.setState({[val.name + "Error"]: ""})
                }
            }
        })
        return flag
    }

    handleLimitSettingSubmit = (e: any) => {
        e.preventDefault()
        if (this.validateLimitSettingSubmit()) {
            let limitSettingObject = {};
            if(this.state.userType == "retailer"){
                if(this.state.adjustType == "add"){
                    if(parseInt(this.state.retailerParentCreditLimit) < parseInt(this.state.points) ){
                        this.uiData.showToastMessage("Failed to Transfer", "error")
                        return;
                    }
                }else{
                    if(parseInt(this.state.retailerCreditLimit) < parseInt(this.state.points) ){
                        this.uiData.showToastMessage("Failed to Transfer", "error")
                        return;
                    }
                }
                limitSettingObject = {
                    "retailerGuid": this.state.retailerName,
                    "action": this.state.adjustType,
                    "limit": this.state.points
                }
            }else{
                let partnerGuid = "";
                if(this.state.userType == "distributor"){
                    if(this.state.adjustType == "add"){
                        if(parseInt(this.state.distributorParentCreditLimit) < parseInt(this.state.points) ){
                            this.uiData.showToastMessage("Failed to Transfer", "error")
                            return;
                        }
                    }else{
                        if(parseInt(this.state.distributorCreditLimit) < parseInt(this.state.points) ){
                            this.uiData.showToastMessage("Failed to Transfer", "error")
                            return;
                        }
                    }
                    partnerGuid = this.state.distributorName;
                }else{
                    if(this.state.adjustType != "add"){
                        if(parseInt(this.state.companyCreditLimit) < parseInt(this.state.points) ){
                            this.uiData.showToastMessage("Failed to Transfer", "error")
                            return;
                        }
                    }
                    partnerGuid = this.state.companyName;
                }
                limitSettingObject = {
                    "partnerGuid": partnerGuid,
                    "action": this.state.adjustType,
                    "limit": this.state.points
                }
            }
            
            this.uiData.updateShowLoading(true)
            let response = this.LimitControlServices.partnerSetLimit(limitSettingObject)
            if(this.state.userType == "retailer"){
                 response = this.LimitControlServices.retailerSetLimit(limitSettingObject)
            }
            response
                .then((limitSettingResponse: any) => {
                    this.uiData.updateShowLoading(false)
                  //console.log(limitSettingResponse)
                    if (!limitSettingResponse.errorStatus) {
                        this.uiData.showToastMessage(
                            StaticKey.LOCALE_DATA.limitControl.setLimitSuccess,
                            ""
                        )
                        this.handlelimitSettingReset()
                    } else {
                        this.uiData.showToastMessage(
                            StaticKey.LOCALE_DATA.limitControl.setLimitFailed,
                            "error"
                        )
                    }
                })
                .catch((err: any) => {
                  //console.log(err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                })
        }
    }
   
    handlelimitSettingReset = () => {
        document.getElementById("userType")?.focus();
        this.setLimitFields[""][1].required = false 
        this.setLimitFields[""][2].required = false 
        this.setLimitFields[""][3].required = false 
        this.setLimitFields[""][1].dnone = true 
        this.setLimitFields[""][2].dnone = true 
        this.setLimitFields[""][3].dnone = true 
        this.setState({                            
            "showCompanyLimit":false,
            "showDistLimit":false,
            "showRetailerLimit":false,
            "userType": "",
            "companyName": "",
            "distributorName": "",
            "retailerName": "",
            "adjustType": "",
            "points": "",
            "userTypeError": "",
            "companyNameError": "",
            "distributorNameError": "",
            "retailerNameError": "",
            "adjustTypeError": "",
            "pointsError": "",
        })
    }

    handleInputChange = (e:any)=>{
        e.preventDefault();
        this.uiData.updateShowLoading(true)
        const value = e.target.value
        
        this.setState({
            ...this.state,
            [e.target.name]: value,
           [e.target.name + "Error"]: ""
        })

        if (e.target.name == "companyName" && this.state.userType == "company") {
            const searchCondition = {
                "partnerTypeId": 3,
                "partnerGuid": value
            }
          //console.log(searchCondition);
            const response = this.LimitControlServices.getPartnerLimitListSearchSingle(searchCondition)
          //console.log(response);
            response
                .then((searchPartnerLimitResponse: any) => {
                  //console.log(searchPartnerLimitResponse)
                    if (!searchPartnerLimitResponse.errorStatus) {
                        this.setState({
                            ...this.state,
                            companyCreditLimit:searchPartnerLimitResponse.data['availableLimit'],                            
                            showCompanyLimit:true,
                            showDistLimit:false,
                            showRetailerLimit:false,
                        })
                    }
                }).catch((err: any) => {
                  //console.log("handleCreatePartnerLimitSearchErr", err)
                })
        }
        if (e.target.name == "companyName" && this.state.userType != "company") {
            this.getDistributorsList({
                "partnerTypeId": 5,
                "companyId": value
            })
            
        }

        if (e.target.name == "distributorName" && this.state.userType == "distributor") {
            const searchCondition = {
                "partnerTypeId": 5,
                "partnerGuid": value
            }
          //console.log(searchCondition);
            const response = this.LimitControlServices.getPartnerLimitListSearchSingle(searchCondition)
          //console.log(response);
            response
                .then((searchPartnerLimitResponse: any) => {
                  //console.log(searchPartnerLimitResponse)
                    if (!searchPartnerLimitResponse.errorStatus) {
                        this.setState({
                            ...this.state,
                            distributorCreditLimit:searchPartnerLimitResponse.data['CREDIT_LIMIT'],
                            distributorParentCreditLimit:searchPartnerLimitResponse.data['parentAvailableLimit'],                         
                            showCompanyLimit:false,
                            showDistLimit:true,
                            showRetailerLimit:false,
                        })
                    }
                }).catch((err: any) => {
                  //console.log("handleCreatePartnerLimitSearchErr", err)
                })
        }
        if (e.target.name == "distributorName" && this.state.userType == "retailer") {
            const data = {
                "partnerGuid" : value
            }
            this.getRetailersList(data)
          
        }
        if (e.target.name == "retailerName") {
            const searchCondition = {
                "retailerGuid": value
            }
          //console.log(searchCondition);
            const response = this.LimitControlServices.getRetailerLimitListSearchSingle(searchCondition)
          //console.log(response);
            response
                .then((searchPartnerLimitResponse: any) => {
                  //console.log(searchPartnerLimitResponse)
                    if (!searchPartnerLimitResponse.errorStatus) {
                        this.setState({
                            ...this.state,
                            retailerCreditLimit:searchPartnerLimitResponse.data['CREDIT_LIMIT'],
                            retailerParentCreditLimit:searchPartnerLimitResponse.data['parentAvailableLimit'],                                                   
                            showCompanyLimit:false,
                            showDistLimit:false,
                            showRetailerLimit:true,
                        })
                    }
                }).catch((err: any) => {
                  //console.log("handleCreatePartnerLimitSearchErr", err)
                })
        }
        if (e.target.name == "userType") {
            const loginData = this.uiData.getLoginResponse()
          //console.log(loginData)
            this.setState({                                               
                "showCompanyLimit":false,
                "showDistLimit":false,
                "showRetailerLimit":false,
                "companyName": "",
                "companyNameError": "",
                "distributorName": "",
                "distributorNameError": "",
                "retailerName": "",
                "retailerNameError": "",
            })
            this.setLimitFields[""][1].required = false 
            this.setLimitFields[""][2].required = false 
            this.setLimitFields[""][3].required = false 
            this.setLimitFields[""][1].dnone = true 
            this.setLimitFields[""][2].dnone = true 
            this.setLimitFields[""][3].dnone = true 
            if(loginData.partnerTypeId == 0){
                if(value == "company"){
                    this.setLimitFields[""][1].required = true 
                    this.setLimitFields[""][2].required = false 
                    this.setLimitFields[""][3].required = false 
                    this.setLimitFields[""][1].dnone = false 
                    this.setLimitFields[""][2].dnone = true 
                    this.setLimitFields[""][3].dnone = true 
                    this.getCompanyList({
                        "partnerTypeId": 3
                    })
                }else if(value == "distributor"){
                    this.setLimitFields[""][1].required = true 
                    this.setLimitFields[""][2].required = true 
                    this.setLimitFields[""][3].required = false 
                    this.setLimitFields[""][1].dnone = false 
                    this.setLimitFields[""][2].dnone = false 
                    this.setLimitFields[""][3].dnone = true 
                    this.getCompanyList({
                        "partnerTypeId": 3
                    })
                }else if(value == "retailer"){
                    this.setLimitFields[""][1].required = true 
                    this.setLimitFields[""][2].required = true 
                    this.setLimitFields[""][3].required = true 
                    this.setLimitFields[""][1].dnone = false 
                    this.setLimitFields[""][2].dnone = false 
                    this.setLimitFields[""][3].dnone = false 
                    this.getCompanyList({
                        "partnerTypeId": 3
                    })
                }else{
                    this.setLimitFields[""][1].required = false 
                    this.setLimitFields[""][2].required = false 
                    this.setLimitFields[""][3].required = false 
                    this.setLimitFields[""][1].dnone = true 
                    this.setLimitFields[""][2].dnone = true 
                    this.setLimitFields[""][3].dnone = true 
                }
            }else if(loginData.partnerTypeId == 3){
                this.setLimitFields[""][1].dnone = true 
                this.setLimitFields[""][1].required = false 
                if(value == "distributor"){
                    this.setLimitFields[""][2].required = true 
                    this.setLimitFields[""][3].required = false 
                    this.setLimitFields[""][2].dnone = false 
                    this.setLimitFields[""][3].dnone = true 
                    this.getDistributorsList({
                        "partnerTypeId": 5
                    })
                }else if(value == "retailer"){
                    this.setLimitFields[""][2].required = true 
                    this.setLimitFields[""][3].required = true 
                    this.setLimitFields[""][2].dnone = false 
                    this.setLimitFields[""][3].dnone = false 
                    this.getDistributorsList({
                        "partnerTypeId": 5
                    })
                }else{
                    this.setLimitFields[""][2].required = false 
                    this.setLimitFields[""][3].required = false 
                    this.setLimitFields[""][2].dnone = true 
                    this.setLimitFields[""][3].dnone = true 
                }
            }  else{
                this.setLimitFields[""][2].required = false 
                this.setLimitFields[""][3].required = true 
                this.setLimitFields[""][2].dnone = true 
                this.setLimitFields[""][3].dnone = false 
                const data = {
                    "partnerGuid" : loginData.partnerGUID
                }
                this.getRetailersList(data)
            }         
        }
        this.uiData.updateShowLoading(false)
    }    
    render() {
        let tabIndex=1;
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>{StaticKey.LOCALE_DATA.limitControl.limitSetting}</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.setLimitFields).map((keys: any, values: any) => {
                                    return (
                                        <div className="w-100" key={values}>
                                            {Object.keys(this.state.searchFilterRow).map((valRow: any, i: any) => {
                                                return (
                                                    <>
                                                    <h5>{keys}</h5>
                                                    <div className={classes["main-div-input-sec"]}>
                                                        {this.setLimitFields[keys].map(
                                                            (fieldValObject: any,
                                                                fieldValIndex: any) => {
                                                                    if (fieldValObject.rowId == valRow) {
                                                                return (
                                                                    <>
                                                                    {!fieldValObject.dnone &&   
                                                                        <div
                                                                            className={classes["sub-div"]}
                                                                            key={fieldValIndex}
                                                                        >
                                                                            <label htmlFor="name">
                                                                                {fieldValObject.label}
                                                                            </label>
                                                                            {fieldValObject.required ? (
                                                                                <span
                                                                                    className={
                                                                                        classes[
                                                                                            "mandatory-symbol"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}{" "}
                                                                            &nbsp;
                                                                            <InputBox
                                                                                        id={fieldValObject.id}
                                                                                        autoFocus={fieldValObject.autoFocus}
                                                                                        autoComplete="off"
                                                                                        maxLength={
                                                                                            fieldValObject.maxLength
                                                                                        }
                                                                                        min={fieldValObject.min}
                                                                                        max={fieldValObject.max}
                                                                                        placeholder={
                                                                                            fieldValObject.placeHolder
                                                                                        }
                                                                                        className={
                                                                                            "form-control " +
                                                                                            classes[
                                                                                                fieldValObject
                                                                                                    .class
                                                                                            ]
                                                                                        }
                                                                                        value={this.state[fieldValObject.name]}
                                                                                        name={
                                                                                            fieldValObject.name
                                                                                        }
                                                                                        type={
                                                                                            fieldValObject.type
                                                                                        }
                                                                                        tabIndex={tabIndex++}
                                                                                        required={
                                                                                            fieldValObject.required
                                                                                        }
                                                                                        onChange={
                                                                                            this.handleInputChange}
                                                                                        //     fieldValObject.type === "date"
                                                                                        //             ? this.handleStartDateChange             
                                                                                        //         : this.handlecreateDrawInputChange
                                                                                        // }
                                                                                        // dateFormat={fieldValObject.dateFormat}
                                                                                        // startMaxDate={this.maxDate}
                                                                                        // endMinDate={this.minDate}
                                                                                        label1={
                                                                                            fieldValObject.label1
                                                                                        }
                                                                                        options={
                                                                                            fieldValObject.type ==
                                                                                                "select" ||
                                                                                            fieldValObject.type ==
                                                                                                "radio"
                                                                                                ? fieldValObject.options
                                                                                                : ""
                                                                                        }
                                                                                        selectShowEmpty={
                                                                                            fieldValObject.selectShowEmpty
                                                                                        }
                                                                                    />
                                                                            <div className={classes["error"]}>
                                                                                {this.state[fieldValObject.name + "Error"]}
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </>
                                                                )
                                                                    }
                                                            }
                                                        )}
                                                    </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                            {this.state.showCompanyLimit && 
                                <div className={"d-flex "}>
                                    <div className={classes["total_section_box"]}>
                                        <span className={classes["total_section_box_sub1"]}>
                                            Limit
                                        </span>
                                        :
                                        <span className={classes["total_section_box_sub2"]}>
                                            {this.state.companyCreditLimit != ""  ? this.state.companyCreditLimit : "0.00"}
                                        </span>
                                    </div>
                                </div>
                            }
                            {this.state.showDistLimit && 
                                <div className={"d-flex "}>
                                    <div className={classes["total_section_box"]}>
                                        <span className={classes["total_section_box_sub1"]}>
                                            Parent Limit
                                        </span>
                                        :
                                        <span className={classes["total_section_box_sub2"]}>
                                            {this.state.distributorParentCreditLimit != ""  ? this.state.distributorParentCreditLimit : "0.00"}
                                        </span>
                                    </div>
                                    <div className={classes["total_section_box"]}>
                                        <span className={classes["total_section_box_sub1"]}>
                                        Distributor Limit
                                        </span>
                                        :
                                        <span className={classes["total_section_box_sub2"]}>
                                            {this.state.distributorCreditLimit != ""  ? this.state.distributorCreditLimit : "0.00"}
                                        </span>
                                    </div>
                                </div>
                            }
                            { this.state.showRetailerLimit && 
                                <div className={"d-flex "}>                                    
                                    <div className={classes["total_section_box"]}>
                                        <span className={classes["total_section_box_sub1"]}>
                                            Parent Limit
                                        </span>
                                        :
                                        <span className={classes["total_section_box_sub2"]}>
                                            {this.state.retailerParentCreditLimit != ""  ? this.state.retailerParentCreditLimit : "0.00"}
                                        </span>
                                    </div>
                                    
                                    <div className={classes["total_section_box"]}>
                                        <span className={classes["total_section_box_sub1"]}>
                                            Retailer Limit
                                        </span>
                                        :
                                        <span className={classes["total_section_box_sub2"]}>
                                            {this.state.retailerCreditLimit != ""  ? this.state.retailerCreditLimit : "0.00"}
                                        </span>
                                    </div>
                                </div>
                            }
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value={StaticKey.LOCALE_DATA.limitControl.submit}
                                        onClick={() => this.handleLimitSettingSubmit(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value={StaticKey.LOCALE_DATA.limitControl.reset}
                                        onClick={() => this.handlelimitSettingReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
