import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/companylisting.module.scss"
import { StaticKey } from "../../../game_core/type_script/static_key"
interface Props {
    tableData?:any  
    tHead?:any  
    tBody?:any  
    noRow?:any  
    currentPage?:any  
}
export class PrintTableElement extends Component<Props> {
    state:any={
        tableData: this.props.tableData,
        tHead: this.props.tHead,
        tBody: this.props.tBody,
        currentPage: this.props.currentPage,
        noRow: this.props.noRow,
    }
    componentDidUpdate(props:any){
        if (this.props.currentPage != this.state.currentPage) {
            this.setState({
                tableData: this.props.tableData,
                tHead: this.props.tHead,
                tBody: this.props.tBody,
                currentPage: this.props.currentPage,
                noRow: this.props.noRow,
            })
        }
    }
    render(){
        return(
            <>
                <table style={{"borderCollapse": "collapse","border": "1px solid black"}}>
                    <thead style={{"borderCollapse": "collapse","border": "1px solid black"}}>
                        <tr style={{"borderCollapse": "collapse","border": "1px solid black"}}>
                            {this.state.tHead.map((values:any,keys:any)=>{
                                return(
                                    <th key={keys} style={{"borderCollapse": "collapse","border": "1px solid black"}}>{values}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{"borderCollapse": "collapse","border": "1px solid black"}}>
                        {this.state.tableData.length > 0 ? (
                            <>
                                {this.state.tableData.map(
                                    (val: any, ind: any) => {
                                        let oddEven = "odd"
                                        if (ind % 2 == 0) {
                                            oddEven = "even"
                                        }
                                        return (
                                            <tr style={{"borderCollapse": "collapse","border": "1px solid black"}} key={ind} >
                                                <td style={{"borderCollapse": "collapse","border": "1px solid black"}}> 
                                                    {(this.state.currentPage - 1) *
                                                        this.state.noRow +
                                                        ind +
                                                        1}
                                                </td>
                                                {this.state.tBody.map((tBodyValues:any,tBodyKeys:any)=>{
                                                    let dataDisplay: any
                                                    if(tBodyValues == "DRAW_STATUS"){
                                                        for (let i = 0;i < StaticKey.DRAWSTATUS.length;i++) {
                                                            if (val[tBodyValues] == StaticKey.DRAWSTATUS[i].id) {
                                                                dataDisplay = StaticKey.DRAWSTATUS[i].name
                                                            }
                                                        }
                                                    }else if(tBodyValues == "RETAILER_ACCOUNT_STATUS"){
                                                        dataDisplay = StaticKey.RETAILERSTATUS[val['ACCOUNT_STATUS']].name
                                                    }else if(tBodyValues == "ACCOUNT_STATUS"){
                                                        dataDisplay = StaticKey.DISTRIBUTORSTATUS[val['ACCOUNT_STATUS']].name
                                                    }else if(tBodyValues == "COMPANYACCOUNT_STATUS"){
                                                        dataDisplay = val['ACCOUNT_STATUS'] == 0 ? "Inactive" : "Active"
                                                    }else{
                                                        if(typeof(val[tBodyValues]) ==  "string"){
                                                            dataDisplay = val[tBodyValues]?val[tBodyValues]:"-"
                                                        }else{
                                                            if(tBodyValues != "" && tBodyValues != null && tBodyValues != undefined ) {
                                                                if(tBodyValues.includes("_toFixed")){
                                                                    const dataValue = tBodyValues.replace("_toFixed","")
                                                                    dataDisplay = val[dataValue]
                                                                                ? parseFloat(val[dataValue]).toFixed(2)
                                                                                :"0.00"
                                                                }else{
                                                                    dataDisplay = val[tBodyValues]?val[tBodyValues]:"-"
                                                                }   
                                                            }else{
                                                                    dataDisplay = val[tBodyValues]?val[tBodyValues]:"bb-"
                                                                }        
                                                        }
                                                    }
                                                    return(
                                                        <td key={tBodyKeys} style={{"borderCollapse": "collapse","border": "1px solid black"}}>
                                                            {dataDisplay}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    }
                                )}
                            </>
                        ) : (
                            <>
                                {" "}
                                <tr className={classes["no_data_found"]}>
                                    <td colSpan={10}>No record(s) available</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </>
        )
    }
}