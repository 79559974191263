export class DataStorage {
    getData = (params: any) => {
        // this function gets local storage data
        return new Promise(resolve => {
            const data: any = localStorage.getItem(params)
            resolve(JSON.parse(data))
        })
    }
    getLoginData = (params: any) => {
        const data: any = localStorage.getItem(params)
        return JSON.parse(data)
    }
    setData = (params: any) => {
        localStorage.setItem("loginData", JSON.stringify(params))
    }
    removeData = (params: any) => {
        // this function removes local storage data
        localStorage.removeItem(params)
        localStorage.clear()
    }

    clearAllData = () => {
        const myItem: any = localStorage.getItem("rememberMe")
        localStorage.clear()
        localStorage.setItem("rememberMe", myItem)
    }

    setLoginStatus = (value: any) => {
        localStorage.setItem("isLoggedIn", value)
    }
    getLoginStatus = () => {
        return localStorage.getItem("isLoggedIn") ? localStorage.getItem("isLoggedIn") : false
    }
    setSiteLanguage = (lang: any) => {
        localStorage.setItem("siteLang", lang)
    }
    getSiteLanguage = () => {
        return localStorage.getItem("siteLang")
    }

    setCurrentClickedMenuGroup(menuId: any) {
        localStorage.setItem("menuId", menuId)
    }
    setCurrentClickedMenuPath(path: any) {
        localStorage.setItem("rolePath", path)
    }
    getCurrentClickedMenuGroup() {
        // console.log('localStorage.getItem("menuId")',localStorage.getItem("menuId"));
        return localStorage.getItem("menuId") ? localStorage.getItem("menuId") : "0"
    }
    getCurrentClickedMenuPath() {
        // console.log('localStorage.getItem("menuId")',localStorage.getItem("menuId"));
        return localStorage.getItem("rolePath") ? localStorage.getItem("rolePath") : false
    }
    setSearchConditionLocalStorage(searchData: any) {
        localStorage.setItem("searchCondition", searchData)
    }
    getSearchConditionLocalStorage() {
        return localStorage.getItem("searchCondition")
            ? localStorage.getItem("searchCondition")
            : "0"
    }
    removeSearchConditionLocalStorage() {
        return localStorage.removeItem("searchCondition")
    }
    setFileNameLocalStorage(searchData: any) {
        localStorage.setItem("fileName", searchData)
    }
    getFileNameLocalStorage() {
        return localStorage.getItem("fileName") ? localStorage.getItem("fileName") : "0"
    }
    removeFileNameLocalStorage() {
        return localStorage.removeItem("fileName")
    }
    setUnload(unload: any) {
        localStorage.setItem("unload", unload)
    }
    getUnloadStatus() {
        return localStorage.getItem("unload")
    }
    removeUnloadStatus() {
        return localStorage.removeItem("unload")
    }
    setRememberMeLocalStorage(searchData: any) {
        localStorage.setItem("rememberMe", searchData)
    }
    getRememberMeLocalStorage() {
        return localStorage.getItem("rememberMe") ? localStorage.getItem("rememberMe") : "0"
    }
    removeRememberMeLocalStorage() {
        return localStorage.removeItem("rememberMe")
    }
}
