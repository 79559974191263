export const CHANGE_SCREEN_EVENT = "changeScreenEvent",
    LOADER_ANIMATE_EVENT = "loaderanimationEvent",
    CHANGE_LOGIN_POPUP_EVENT = 'changeLoginPopup',
    DATA_TRANSFER_EVENT  = 'dataTransferEvent',
    CHANGE_MAIN_EVENT = 'changemainscreen',
    CHANGE_INAPP_SCREEN_EVENT = "changeInAppScreenEvent",
    DISPATCH_URL_INFO = "sendUrlInfo",
    OPEN_OTP_SCREEN='openotpscreen',
    VERIFICATION_STATUS='verificationstatus',
    CHANGEPROFILEDATA='changeprofiledata',
    KYCPROOFDATA='kycprooftypedata',
    WALLETTRANSFER='wallettransfer',
    SIGNUP_RESPONSE='signupresponse',
    UPDATEPROFILEDATA='updateprofiledata',
    LANGUAGE_CHANGE_UPDATE='languageChangeupdate'
