import React, {Component} from "react"
import {StaticKey} from "../../../../game_core/type_script/static_key"
import classes from "../../../configuration/style/base/screens/maintenance/styles.module.scss"

interface Props {
    currTime?: any
    startTime?: any
    endTime?: any
    maintenanceCheck?: any
}

export class MaintenanceTimer extends Component<Props> {
    start?: any
    now?: any = this.props.currTime
    state = {
        hour: "00",
        min: "00",
        seconds: "00"
    }
    pad(num?: any) {
        return ("0" + parseInt(num)).substr(-2)
    }

    tick = () => {
        this.now.setSeconds(this.now.getSeconds() + 1)
        if (this.now > this.start) {
            // too late, go to tomorrow
            this.start.setDate(this.start.getDate() + 1)
        }
        const remain = (this.start - this.now) / 1000
        const hh = this.pad((remain / 60 / 60) % 60)
        this.setState({hour: hh})
        const mm = this.pad((remain / 60) % 60)
        this.setState({min: mm})
        const ss = this.pad(remain % 60)
        this.setState({seconds: ss})
        if (hh + ":" + mm + ":" + ss === "00:00:00") {
            this.props.maintenanceCheck()
        }
    }

    componentDidMount() {
        const startTime = this.props.endTime
        this.start = new Date()
        this.start.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds())
        setInterval(this.tick, 1000)
    }

    render() {
        return (
            <React.Fragment>
                <span>{StaticKey.LOCALE_DATA["MAINTENANCE"]["visitBackAfter"]}</span>
                <span
                    className={classes["span_count_timer"] + " " + classes["span_count_timer_span"]}
                >
                    {this.state.hour + ":" + this.state.min + ":" + this.state.seconds}
                </span>
            </React.Fragment>
        )
    }
}
