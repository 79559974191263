import {SocketioManager} from '@digienttech/typescript-core/lib/socketIO/socketio-manager';
import * as EMIT_ACTIONS from '@digienttech/typescript-core/lib/core/emit-actions';
import {EventEmitter} from 'eventemitter3';
import {MockServiceData} from './lobby/MockServiceData';

export class ConnectionManagerService extends EventEmitter {
	urlParmas: any;
	sessionKey: any = '';
	serverForceDisconnection: any = false;
	socketIOService: SocketioManager;
	mockServiceData: MockServiceData;

	constructor() {
		super();
		this.socketIOService = new SocketioManager();
		this.mockServiceData = new MockServiceData();
	}

	createSocketConnection(requestParams: any) {
		// console.log('Creating Socket connection main', requestParams);
		this.serverForceDisconnection = false;
		this.socketIOService.createSocket(requestParams, false);
		this.createAutoResponseListener();
		this.socketIOService.on(
			EMIT_ACTIONS.CONNECT_ACTION,
			this.onConnect.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.CONNECT_ERROR,
			this.onConnectErrorHandler.bind(this),
		);
	}

	disconnectSocket() {
		this.removeAutoResponseListener();
		this.socketIOService.disconnectSocket();
		this.socketIOService.removeAllListeners();
		this.socketIOService.removeListener(
			EMIT_ACTIONS.CONNECT_ACTION,
			this.onConnect.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.CONNECT_ERROR,
			this.onConnectErrorHandler.bind(this),
		);
	}

	createAutoResponseListener() {
		this.socketIOService.on(
			EMIT_ACTIONS.RESPONSE,
			this.onAutoResponseHandler.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.DISCONNECT,
			this.onDisconnectHandler.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.RECONNECT_FAILED,
			this.onServerReconnectFailedHandler.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.ERROR,
			this.onServerConnectErrorHandler.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.RECONNECT,
			this.onServerReconnectHandler.bind(this),
		);
		this.socketIOService.on(
			EMIT_ACTIONS.ERROR,
			this.onServerErrorHandler.bind(this),
		);
	}

	removeAutoResponseListener() {
		this.socketIOService.removeListener(
			EMIT_ACTIONS.RESPONSE,
			this.onAutoResponseHandler.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.DISCONNECT,
			this.onDisconnectHandler.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.RECONNECT_FAILED,
			this.onServerReconnectFailedHandler.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.ERROR,
			this.onServerConnectErrorHandler.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.RECONNECT,
			this.onServerReconnectHandler.bind(this),
		);
		this.socketIOService.removeListener(
			EMIT_ACTIONS.ERROR,
			this.onServerErrorHandler.bind(this),
		);
	}

	onConnect(data: any) {
		this.emit(EMIT_ACTIONS.CONNECT_ACTION, data);
		// console.log('socket connection is succesful', data.connectName);
	}

	onAutoResponseHandler(_rData: any) {
		// console.log('autoResponsehandler', _rData);
		this.emit(EMIT_ACTIONS.RESPONSE, _rData);
	}

	onDisconnectHandler(reason: string) {
		if (this.serverForceDisconnection) {
			return;
		}
		this.emit(EMIT_ACTIONS.DISCONNECT, reason);
	}

	onConnectErrorHandler(reason: string) {
		if (this.serverForceDisconnection) {
			return;
		}
		this.emit(EMIT_ACTIONS.CONNECT_ERROR, reason);
	}

	onServerConnectErrorHandler(reason: string) {
		if (this.serverForceDisconnection) {
			return;
		}
		this.emit(EMIT_ACTIONS.ERROR, reason);
	}

	onServerReconnectHandler(reason: string) {
		this.emit(EMIT_ACTIONS.RECONNECT, reason);
	}

	onServerErrorHandler(reason: string) {
		this.emit(EMIT_ACTIONS.CONNECT_ERROR, reason);
	}

	onServerReconnectFailedHandler(reason: string) {
		if (this.serverForceDisconnection) {
			return;
		}
		this.emit(EMIT_ACTIONS.RECONNECT_FAILED, reason);
	}

	sendServerRequest(request: any) {
		return new Promise(resolve => {
			if (this.serverForceDisconnection) {
				resolve({});
			}
			this.socketIOService.sendRequest(request, (err: any, resp: any) => {
				// console.log('sendServerRequest', resp);
				if (!err) {
					resolve({err: err, data: resp});
				} else {
					resolve({err: err, data: {}});
				}
			});
		});
	}

	destroy() {
		this.disconnectSocket();
		this.socketIOService.dispose();
	}
}
