export const environment = {
    app: {
        name: "launcher_mobile_react",
        product: "com.glapp"
    },
    useMockConnection: false,
    isStoreUpdate: false,
    mode: true,
    b2bURL: "",
    isB2BAuth: false,

    s: `s`, //`s` for secure or `` for normal
    hostName: `qaslapi.xlivegaming.com`,//`qaslapi.xlivegaming.com`,
    launcherVersion: "0.0.4",
    server: `ipdev.test2plays.com`,
    apiPath: `qaslapi.xlivegaming.com`,
    restApiPath: `http://devbooking.xlivegaming.com/slim-backend/SL365/v1/`,
    staticApiPath: "qaslapi.xlivegaming.com",
    apiVersion: ``,
    appUpdateURL: "https://dev.pokerdangal.co.in/launcher/apk/version/version.json",
    appDownloadURL: "https://dev.pokerdangal.co.in/launcher/apk/PokerDangal_Dev.apk",
    cashFreeConfig: {
        environment: "TEST",
        appId: "34670e6883f4f7604839bab1a07643",
        orderCurrency: "INR",
        requestType: "Payment",
        websiteName: "WEBSTAGING",
        channelId: "WAP",
        ucid: "UCIDSTRING",
        channel: 1
    },
    socketConfig: {
        ws: "ws",
        mainLobbyServer: "15.206.254.39",
        port: "7000"
    }
}
