import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import {HTTPMockDataService} from "../../http-mock-data-service"
import {environment} from "../../environment"
import {EventEmitter} from "eventemitter3"
import { StaticKey } from "../../static_key"
export class MaintenanceService extends EventEmitter {
    static classIns: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    httpConnectionIns: any

    constructor(_instanceID: string) {
        super()
        console.log('_instanceID',_instanceID);
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID: any = "default"): any {
        if (!MaintenanceService.classIns[_instanceID]) {
            return (MaintenanceService.classIns[_instanceID] = new MaintenanceService(_instanceID))
        } else {
            return MaintenanceService.classIns[_instanceID]
        }
    }
    getMaintenanceInfo = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getMaintenanceInfo`
            this.httpConnectionIns.sendHttpRequest("maintenance", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
}
