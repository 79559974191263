import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import { HTTPMockDataService } from "../../http-mock-data-service"

export class HomePageService extends HTTPConnectionDatasService {
    params: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any = HTTPConnectionDatasService.getInstance()
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID = "default"): any {
        if (!HomePageService.classIns[_instanceID]) {
            return (HomePageService.classIns[_instanceID] = new HomePageService(_instanceID))
        } else {
            return HomePageService.classIns[_instanceID]
        }
    }
    updateChangePassword = (paramsData?: any) => {
        console.log('paramsData',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/changePassword`
            this.httpConnectionIns.sendHttpRequest("changePassword", requestURL, paramsData).then(
                (respObj: any) => {
                    console.log('respObj',respObj);
                    
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    forcePasswordUpdate = (paramsData?: any) => {
        console.log('paramsData',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/forcePasswordUpdate`
            this.httpConnectionIns.sendHttpRequest("forcePasswordUpdate", requestURL, paramsData).then(
                (respObj: any) => {
                    console.log('respObj',respObj);
                    
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
}
