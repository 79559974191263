import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/retailerlisting.module.scss"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Link} from "react-router-dom"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Swal from "sweetalert2"
import {Modal} from "reactstrap"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"
import jsPDF from "jspdf"
import { PrintTableElement } from "../common/PrintTableElement"
import { TotalRecords } from "../common/totalRecords"
import { ExportExcelReport } from "./ExportExcelReport"

const date = new Date();
const currentDay= String(date.getDate()).padStart(2, '0');
const currentMonth = String(date.getMonth()+1).padStart(2,"0");
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`;

export class RetailerListing extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    dataArray:any = [];
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Application No","Login ID","Retailer Code","Retailer Name","Terminal Type","Distributor Login ID","Distributor Name","Address","Status"],
        tableBody:["applicationNo","loginId","USERNAME","retaillerName","terminalType","distributorLoginId","distributorName","address","RETAILER_ACCOUNT_STATUS"],
        exportHead:["Application No","Login ID","Retailer Code","Retailer Name","Terminal Type","Distributor Login ID","Distributor Name","Address","Status"],
        exportBody:["applicationNo","loginId","USERNAME","retaillerName","terminalType","distributorLoginId","distributorName","address","ACCOUNT_STATUS"],
        partnerTypeId:0,
        btnDisabled:  true,
        pendingStatus:false,
        submitedUserGuid:[],
        LoginpartnerTypeId:"",
        searchFilterRow:[0,1],
        fromPagePagination: 1,
        toPagePagination: 5,
        verifyPopupShow: false,
        installPopupShow: false,
        noRow: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        username: "",
        loginId: "",
        accountStatus: "",
        dateRange: "",
        fromDate:"",
        fromDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        toDate: "",
        toDateSearch: "",
        companyNameCallback: true,
        toDateError: "",
        showRetailerListing: false,
        retailerSearchData: [],
        verifyUser:"",
        verifyUserError:"",
        installUser:"",
        installUserError:"",
        distributorCode:"",
        distributorGuid:"",
        fields: {
            "accountStatus": "",
            "dateRange": "",
            "companyName": "",
            "distributorName": "",
            "toDate": "",
            "fromDate":"",
            "username": "",
            "loginId": "",
            "limit": 25,
            "page": 1,
                newPassword:"",
                confirmPassword:"",
                companyId: "",
                distributorId: "",
                retailerName: "",
                username1: "",
                personalAddress1: "",
                personalAddress2: "",
                personalPinCode: "",
                personalLocality: "",
                personalTaluka: "",
                personalCity: "",
                personalState: "",
                personalMobileNo: "",
                personalWhatsappNo: "",
                personalTelegramNo: "",
                personalEmail: "",
                userAddress1: "",
                userAddress2: "",
                userPinCode: "",
                userLocality: "",
                personalLaluka: "",
                userCity: "",
                userState: "",
                userMobileNo: "",
                userWhatsappNo: "",
                userTelegramNo: "",
                userEmail: "",
                typeOfOrganisation: "",
                natureOfBusiness: "",
                userLandLineNo: "",
                establishmentType: "",
                establishmentNearBySchool: "",
                establishmentFarTransport: "",
                registrationPanCardNo: "",
                registrationAadharCardNo: "",
                registrationIfscCode: "",
                registrationBankName: "",
                registrationBranchName: "",
                registrationAccountNo: "",
                uploadAadhar: "",
                uploadAadharVal: "",
                uploadPanCard: "",
                uploadPanCardVal: "",
                uploadDrivingLicence: "",
                uploadDrivingLicenceVal: "",
                uploadEBBill: "",
                uploadEBBillVal: "",
                uploadPassport: "",
                uploadPassportVal: "",
                uploadPhoto: "",
                uploadPhotoVal: "",
                termsAgreed: "",
                terminalType: "",
        },
        errors: {
            "accountStatusError": "",
            "toDateError": "",
            "fromDateError": "",
            "usernameError": "",
            "loginIdError": ""
        }
    }
    getRetailerListFields: any = [
        {
            rowId:0,
            name: "companyName",
            type: "select",
            label: "Company Name",
            // placeHolder: "companyName",
            maxLength: "50",
            autoFocus: true,
            class: "form-control",
            required: false,
            style: {},
            options: {},
            autoComplete: "off",
            "selectShowEmpty": true
        },
        {
            rowId:0,
            name: "distributorName",
            type: "select",
            label: "Distributor Name",
            // placeHolder: "distributorName",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            style: {},
            options: {},
            autoComplete: "off",
            "selectShowEmpty": true
        },
        {
            rowId:0,
            name: "username",
            type: "text",
            label: "Retailer Code",
            // placeHolder: "Username",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId:0,
            name: "loginId",
            type: "number",
            label: "Login ID",
            // placeHolder: "Username",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId:1,
            name: "accountStatus",
            type: "select",
            label: "Status",
            placeHolder: "Status",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            options: StaticKey.RETAILERSTATUSDROPDOWN,
            selectShowEmpty: true
        },
        {
            rowId:1,
            "name": "fromDate",
            "type": "datetime",
            "label": "Start Date",
            // "placeHolder": "Start Date",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId:1,
            "name": "toDate",
            "id": "toDate",
            "type": "datetime",
            "label": "End Date",
            // "placeHolder": "End Date",
            "maxLength": currentDate,
            // "disabled": true,
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId:1,
            name: "dateRange",
            type: "select",
            label: "Date Range",
            placeHolder: "Date Range",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            defaultValue: "",
            required: false,
            options: StaticKey.DATERANGE,
            selectShowEmpty: true
        }
    ]

    viewRetailerFields: any = {
        "A. General Information": [
            {
                "name": "companyId",
                "type": "text",
                "label": "Company",
                // "placeHolder": "Company",
                "maxLength": 50,
                "autoFocus": true,
                "class": "form-control",
               "required": false, readonly:true,
                "disabled":"disabled",
                
            },
            {
                "name": "distributorId",
                "type": "text",
                "label": "Distributor",
                // "placeHolder": "Distributor",
                "maxLength": 50,
                "autoFocus": true,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
                
            },
            {
                "name": "retailerName",
                "type": "text",
                "label": "Retailer Name",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            // {"": []},
            {
                "name": "terminalType",
                "type": "text",
                "label": "Terminal Type",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
                "options":StaticKey.TERMINALTYPE,
                "disabled":"disabled",
            },            
            {
                "name": "username1",
                "type": "text",
                "label": "Agency Code",
                // "placeHolder": "Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                onblur: "checkUserExist",
                "disabled":"disabled",
            },
            {
                "name": "userAddress1",
                "type": "text",
                "label": "Address1",
                "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userAddress2",
                "type": "text",
                "label": "Address2",
                "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {"": []},
            {
                "name": "userPinCode",
                "type": "text",
                "label": "Pincode",
                // "placeHolder": "PinCode",
                "maxLength": 10,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
            },
            {
                "name": "userLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true, 
               "disabled":"disabled",               
                
                
            },
            {
                "name": "userTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "userCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "userState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            // {"": []},
            {
                "name": "userMobileNo",
                "type": "text",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userWhatsappNo",
                "type": "text",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userTelegramNo",
                "type": "text",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "userEmail",
                "type": "text",
                "label": "Email Id",
                // "placeHolder": "Email",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },   
            {"": []},          
            {
                "name": "typeOfOrganisation",
                "type": "text",
                "label": "Type of Organisation",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "options":StaticKey.TYPEOFORGANISATION,
                "selectShowEmpty":true,
                "disabled":"disabled",
            },         
            {
                "name": "natureOfBusiness",
                "type": "text",
                "label": "Nature of Business",
                // "placeHolder": "Email",
                "maxLength": 5,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
        ],
        "B. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                // "placeHolder": "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "---- ---- ----",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },            
            {"": []},
            {
                "name": "registrationIfscCode",
                "type": "text",
                "label": "IFSC Code",
                // "placeHolder": "Registration Ifsc Code",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
                onblur:"handleIfsc",
                "disabled":"disabled",
            },
            {
                "name": "registrationBankName",
                "type": "text",
                "label": "Bank Name",
                // "placeHolder": "Registration Bank Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
               
            },
            {"": []},
            {
                "name": "registrationBranchName",
                "type": "text",
                "label": "Branch Name",
                // "placeHolder": "Registration Branch Name",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
               
            },
            {
                "name": "registrationAccountNo",
                "type": "text",
                "label": "Account No",
                // "placeHolder": "Registration Account No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
        ],
        "C. Establishment Details": [
            {
                "": [
                    {
                        "name": "establishmentType",
                        "type": "text",
                        "label": "Owned / Rental",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-control",
                        "required": false,
                        "disabled":"disabled",
                    },
                    {
                        "name": "establishmentNearBySchool",
                        "type": "text",
                        "label": "Is there a School, Place of worship within the limits of 200 meters from your establishment",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-check-input",
                        "required": false,
                        "options": {
                            1: "Yes",
                            2: "No"
                        },
                        "disabled":"disabled",
                    },
                    {
                        "name": "establishmentFarTransport",
                        "type": "text",
                        "label": "How far is the Railway station / Main Market / bus stand from the establishment (in meters)",
                        // "placeHolder": "Business Office Type",
                        "maxLength": 50,
                        "autoFocus": false,
                        "class": "form-control",
                        "required": false,
                        "disabled":"disabled",
                    }
                ]
            },
        ],
        "D. Personal Detail": [
           
            {
                "name": "personalAddress1",
                "type": "text",
                "label": "Personal Address1",
                "placeHolder": "Address1",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {
                "name": "personalAddress2",
                "type": "text",
                "label": "Personal Address2",
                "placeHolder": "Address2",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "disabled":"disabled",
            },
            {"": []},
            {
                "name": "personalPinCode",
                "type": "text",
                "label": "PinCode",
                // "placeHolder": "PinCode",
                "maxLength": 6,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
                
            },
            {
                "name": "personalLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,   
               "disabled":"disabled",             
                
            },
            {
                "name": "personalTaluka",
                "type": "text",
                "label": "Taluka",
                // "placeHolder": "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "personalCity",
                "type": "text",
                "label": "City",
                // "placeHolder": "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {
                "name": "personalState",
                "type": "text",
                "label": "State",
                // "placeHolder": "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
               "required": false, readonly:true,
               "disabled":"disabled",
            },
            {"": []},
            {
                "name": "personalMobileNo",
                "type": "text",
                "label": "Mobile No",
                // "placeHolder": "Mobile No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "personalWhatsappNo",
                "type": "text",
                "label": "Whatsapp No",
                // "placeHolder": "Whatsapp No",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "personalTelegramNo",
                "type": "text",
                "label": "Telegram Id",
                // "placeHolder": "Telegram Id",
                "maxLength": 12,
                "autoFocus": false,
                "class": "form-control",
                "required": false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            // {
            //     "name": "uploadAadhar",
            //     "type": "file",
            //     "label": "Aadhar",
            //     // "placeHolder": "Upload Aadhar",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
            // {
            //     "name": "uploadPanCard",
            //     "type": "file",
            //     "label": "Pan Card",
            //     // "placeHolder": "Upload Pan Card",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
            // {
            //     "name": "uploadPhoto",
            //     "type": "file",
            //     "label": "Photo",
            //     // "placeHolder": "Upload Photo",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
            // {
            //     "name": "uploadDrivingLicence",
            //     "type": "file",
            //     "label": "Driving Licence",
            //     // "placeHolder": "Upload Driving Licence",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
            // {
            //     "name": "uploadEBBill",
            //     "type": "file",
            //     "label": "Light Bill",
            //     // "placeHolder": "Upload EBBill",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
            // {
            //     "name": "uploadPassport",
            //     "type": "file",
            //     "label": "Passport",
            //     // "placeHolder": "Upload Passport",
            //     "maxLength": 50,
            //     "autoFocus": false,
            //     "class": "form-control",
            //     "required": false
            // },
        ],
    }
    handleGeneratePdf = (e:any,partnerId:any,partnerName:any) => {	
        this.uiData.updateShowLoading(true)
        e.preventDefault();	
        this.getRetailerDetail(partnerId)
        setTimeout(()=>{
            const Canvas:any = document.getElementById("downloadPdf");
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.internal.pageSize.getWidth();
            pdf.internal.pageSize.getHeight();
            const opt:any = {
                callback: function (pdf:any) {
                    pdf.save(partnerName+".pdf");
                },
                margin: [10, 10, 10, 2],
                autoPaging: 'text',
                html2canvas: {
                    allowTaint: true,
                    dpi: 50,
                    letterRendering: true,
                    logging: false,
                    scale: .2
                }
            };
            pdf.html(Canvas, opt);
            this.uiData.updateShowLoading(false)
        },3000);
	};

    getRetailerDetail = (id: any) => {
        
        const searchCondition = {
            "userGuid": id,
        }
        const response = this.DownlineManageServices.getRetailerDetail(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                if (!searchPartnerLimitResponse.errorStatus) {
                    const responseData = searchPartnerLimitResponse.data;
                    this.setState({
                        ...this.state,
                        retailerData: searchPartnerLimitResponse.data,
                        guId: id
                    }) 
                    let dataTerminal = "";  
                    for(let keyKoop = 0;keyKoop<StaticKey.TERMINALTYPE.length;keyKoop++){
                        if(responseData.terminalType == StaticKey.TERMINALTYPE[keyKoop].id){
                            dataTerminal=StaticKey.TERMINALTYPE[keyKoop].name;
                        }
                    }     
                    // console.log(dataTerminal);      
                    this.setState({
                        ...this.state.fields,
                        fields: {
                            companyId:responseData.level3Name ? responseData.level3Name : "-",
                            distributorId: responseData.level5Name ? responseData.level5Name : "-",
                            retailerName: responseData.retailerName ? responseData.retailerName : "-",
                            username1: responseData.username ? responseData.username : "-",
                            userAddress1: responseData.userAddress1 ? responseData.userAddress1 : "-",
                            userAddress2: responseData.userAddress2 ? responseData.userAddress2 : "-",
                            terminalType: dataTerminal,
                            userPinCode: responseData.userPincode ? responseData.userPincode : "-", 
                            userLocality: responseData.userLocality ? responseData.userLocality : "-",
                            userTaluka: responseData.userTaluk ? responseData.userTaluk : "-",
                            userCity: responseData.userCity ? responseData.userCity : "-",
                            userState: responseData.userState ? responseData.userState : "-",
                            userMobileNo: responseData.userMobileNo ? responseData.userMobileNo : "-",
                            userWhatsappNo: responseData.userWhatsappNo ? responseData.userWhatsappNo : "-",
                            userTelegramNo: responseData.userTelegramNo ? responseData.userTelegramNo : "-",
                            userwebsite: responseData.userwebsite ? responseData.userwebsite : "-",
                            userEmail: responseData.userEmail ? responseData.userEmail : "-",
                            personalAddress1: responseData.personalAddress ? responseData.personalAddress : "-",
                            personalAddress2: responseData.personalAddress1 ? responseData.personalAddress1 : "-",
                            personalPinCode: responseData.personalZipCode ? responseData.personalZipCode : "-",
                            personalLocality: responseData.personalLocality ? responseData.personalLocality : "-",
                            personalTaluka: responseData.personalTaluk ? responseData.personalTaluk : "-",
                            personalCity: responseData.personalCityName ? responseData.personalCityName : "-",
                            personalState: responseData.personalStateId ? responseData.personalStateId : "-",
                            personalMobileNo: responseData.personalMobileNo ? responseData.personalMobileNo : "-",
                            personalWhatsappNo: responseData.personalWhatsappNo ? responseData.personalWhatsappNo : "-",
                            personalTelegramNo: responseData.personalTelegramNo ? responseData.personalTelegramNo : "-",
                            personalwebsite: responseData.personalwebsite ? responseData.personalwebsite : "-",
                            personalEmail: responseData.personalEmailId ? responseData.personalEmailId : "-",
                            typeOfOrganisation: StaticKey.TYPEOFORGANISATION[responseData.typeOfOrganisation].name,
                            natureOfBusiness: responseData.natureOfBusiness ? responseData.natureOfBusiness : "-",
                            userLandLineNo: responseData.userLandLineNo ? responseData.userLandLineNo : "-",
                            establishmentType: responseData.establishmentType == 1 ? "Owned" : "Rental",
                            establishmentNearBySchool: responseData.establishmentNearbySchool ? responseData.establishmentNearbySchool : "-",
                            establishmentFarTransport: responseData.establishmentFarTransport ? responseData.establishmentFarTransport : "-",
                            registrationPanCardNo: responseData.pancardNo ? responseData.pancardNo : "-",
                            registrationAadharCardNo: responseData.aadharNo ? responseData.aadharNo : "-",
                            registrationIfscCode: responseData.ifscCode ? responseData.ifscCode : "-",
                            registrationBankName: responseData.bankName ? responseData.bankName : "-",
                            registrationBranchName: responseData.branchName ? responseData.branchName : "-",
                            registrationAccountNo: responseData.accountNo ? responseData.accountNo : "-", 
                            uploadAadhar: responseData.aadharImage !="" ? responseData.aadharImage : "-",
                            uploadAadharVal: responseData.aadharImageUrl ?  responseData.aadharImageUrl : "-",
                            uploadPanCard: responseData.pancardImage  !="" ? responseData.pancardImage : "-",
                            uploadPanCardVal:responseData.pancardImageUrl ?  responseData.pancardImageUrl : "-",
                            uploadDrivingLicence: responseData.drivingLicenceImage  !="" ? responseData.drivingLicenceImage : "-",
                            uploadDrivingLicenceVal: responseData.drivingLicenceImageUrl ?  responseData.drivingLicenceImageUrl : "-",
                            uploadEBBill: responseData.ebBillImage  !="" ? responseData.ebBillImage : "-",
                            uploadEBBillVal: responseData.ebBillImageUrl ?  responseData.ebBillImageUrl : "-",
                            uploadPassport: responseData.passportImage  !="" ? responseData.passportImage : "-",
                            uploadPassportVal: responseData.passportImageUrl ?  responseData.passportImageUrl : "-",
                            uploadPhoto: responseData.photo  !="" ? responseData.photo : "-", 
                            uploadPhotoVal: responseData.photoUrl ?  responseData.photoUrl : "-"
                        }
                    })
                } else {
                    this.setState({
                        retailerData: []
                    })
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
                this.setState({
                    retailerData: []
                })
            })
    }
    handleSearchPartnerLimitReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.getRetailerListFields).map((keys: any, values: any) => {
            {
                this.setState({[this.getRetailerListFields[keys].name]: ""})
                return this.getRetailerListFields.map((fieldValObject: any, fieldValIndex: any) => {
                    return (
                        (newArrayError[fieldValObject.name + "Error"] = ""),
                        (newArrayFields[fieldValObject.name] = ""),
                        (newArrayFields[fieldValObject.name + "Val"] = "")
                    )
                })
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields,
            loginId: "",
            username: "",
            accountStatus:"",
            fromDateSearch:"",
            toDateSearch:"",
            retailerSearchData: [],
            showRetailerListing: false,
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 2,
            lastPage: 0,
            fromPagePagination: 1,
            toPagePagination: 5
        })
        
    }
    validateCreatePartnerLimitSearchForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.getRetailerListFields).map((keys: any) => {
            {
                return this.getRetailerListFields.map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true

                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })
        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        this.dataArray=[];
        this.setState({btnDisabled:true});
        setTimeout(() => {
            this.handlePartnerSearchAjax("pagination")
        }, 50)
    }
    handlePartnerSearchAjax(params: any) {
        if (params == "search") {
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
            this.setState({
                // username: "",
                // accountStatus: "",
                LedgerSearchData: [],
                showLedgerSearchListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }
        if (this.validateCreatePartnerLimitSearchForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                username: this.state.fields.username,
                companyName: this.state.fields.companyName,
                distributorName: this.state.fields.distributorName,
                accountStatus: this.state.accountStatus,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                loginId: this.state.fields.loginId,
                page: this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            } 
            const storage:any = [
                this.state.fields.username,
                this.state.fields.companyName,
                 this.state.fields.distributorName,
                 this.state.accountStatus,
                 this.state.toDate,
                 this.state.fromDate,
                 this.state.fields.loginId,
                 this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                 this.state.fields.limit,
                 this.state.fields.dateRange,
                 this.state.toDateSearch,
                 this.state.fromDateSearch,
            ]
          //console.log(storage)
            const string = JSON.stringify(storage)
            this.uiData.setRetailerBackList(false)
            this.localStorageService.removeSearchConditionLocalStorage();
            this.localStorageService.setSearchConditionLocalStorage(string);
            if(this.state.fields['accountStatus'] == "PENDING"){
                this.setState({pendingStatus:true});
            }else{
                this.setState({pendingStatus:false});
            }
            this.dataArray=[];
            this.setState({btnDisabled:true});
          //console.log(searchCondition)
            const response = this.DownlineManageServices.getRetailerListSearch(searchCondition)
            response
                .then((searchRetailerResponse: any) => {
                    if (!searchRetailerResponse.errorStatus) {
                        this.setState({
                            retailerSearchData: searchRetailerResponse.data,
                            showRetailerListing: true,
                            totalCount: searchRetailerResponse.totalRecords
                        })
                        const data = []

                        for (
                            let i = 0;
                            i < searchRetailerResponse.totalRecords / this.state.noRow;
                            i++
                        ) {
                            data.push(i)
                        }
                        const divData = searchRetailerResponse.totalRecords / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        } 
                        const dataResponse = searchRetailerResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                let value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                if(this.state.exportBody[j] == "ACCOUNT_STATUS"){                                    
                                    value = StaticKey.RETAILERSTATUS[dataResponse[i][this.state.exportBody[j]]].name
                                }
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})
                        this.closeLoader()
                    } else {
                        this.setState({
                            retailerSearchData: [],
                            showRetailerListing: false
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                  //console.log("handleCreatePartnerLimitSearchErr", err)
                    this.closeLoader()
                    this.setState({
                        retailerSearchData: [],
                        showRetailerListing: false
                    })
                })
        } else {
            this.uiData.updateShowLoading(false)
        }
    }
    handleCreatePartnerLimitSearch = (e: any) => {
        e.preventDefault()
        this.handlePartnerSearchAjax("search")
    }
    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
    componentDidMount(): void {
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
        
        const retailerBack = this.uiData.getRetailerBackList()
        if(retailerBack){
            const localDataSearch:any = this.localStorageService.getSearchConditionLocalStorage();
            const localSearchData = JSON.parse(localDataSearch)
          //console.log(localSearchData)
            this.setState({
                username: localSearchData[0],
                companyName: localSearchData[1],
                distributorName: localSearchData[2],
                accountStatus: localSearchData[3],
                toDate: localSearchData[4]!= "" ? new Date(localSearchData[4]) : "",
                fromDate:  localSearchData[5]!= "" ? new Date(localSearchData[5]) : "",
                toDateSearch: localSearchData[10],
                fromDateSearch: localSearchData[11],
                loginId: localSearchData[6],
                dateRange: localSearchData[9],
                fields:{
                    username: localSearchData[0],
                    companyName: localSearchData[1],
                    distributorName: localSearchData[2],
                    accountStatus: localSearchData[3],
                    toDate: new Date(localSearchData[4]),
                    fromDate: new Date(localSearchData[5]),
                    loginId: localSearchData[6],
                    dateRange: localSearchData[9],
                    page: 0,
                    limit:this.state.fields.limit
                }
            })
            if(localSearchData[1] != "" && localSearchData[1] != null && localSearchData[1] != undefined ){
                this.getDistributorsList({
                    "partnerTypeId": 5,
                    "companyId": localSearchData[1]
                })
            }
            
            setTimeout(()=>{
            this.handlePartnerSearchAjax("search")                
                //this.localStorageService.removeSearchConditionLocalStorage();
            },100)           
        }
        if (loginData.partnerTypeId == 0) {
            this.getCompanyList({
                "partnerTypeId": 3
            })
        } else if (loginData.partnerTypeId == 3) {
            this.getDistributorsList({
                "partnerTypeId": 5
            })
            agentLoginHideFields.push("companyName")
        } else {
            agentLoginHideFields.push("companyName")
            agentLoginHideFields.push("distributorName")
        }

        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                    LoginpartnerTypeId: loginResponse.partnerTypeId
            })
        }
        
        return this.getRetailerListFields.map((val: any, ind: any) => {
            // console.log(val.name)
            if (agentLoginHideFields.includes(val.name)) {
                return delete this.getRetailerListFields[ind]
            }
            if(loginData.partnerTypeId != 0){
                if (loginData.partnerTypeId == 3 && val.name == "distributorName") {
                    // this.getRetailerListFields[ind].autoFocus=true;
                    document.getElementById("distributorName")?.focus();
                }
                if (loginData.partnerTypeId ==5 && val.name == "username") {
                    // this.getRetailerListFields[ind].autoFocus=true;
                    document.getElementById("username")?.focus();
                }
                if (val.name == "accountStatus") {
                    this.getRetailerListFields[ind].rowId=0;
                }
                
            }
        })
    }

    handlePartnerCreditLimit(e: any) {
        e.preventDefault()
    }
    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.getRetailerListFields[0].options = getCompanyListResponse.data                   
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
              //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    getDistributorsList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getDistributorsListDropdown(data)
        response
            .then((distributorListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!distributorListResponse.errorStatus) {
                    this.getRetailerListFields[1].options = distributorListResponse.data
                    this.setState({distributorList: distributorListResponse.data})
                }
            })
            .catch((err: any) => {
              //console.log("getDistributorsListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
      //console.log(date)
        this.setState({
            fromDate: dates,
            fromDateSearch:finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            toDate: eDate,
            toDateSearch:finalDate
        })
    }
    partnerLimitControlFilterChange = (e: any) => {
        const value = e.target.value
        // this.setState({
        //     ...this.state,
        //     [e.target.name]: value,
        //     [e.target.name + "Error"]: ""
        // })
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            }
        })
        this.setState({[e.target.name]: value})
        if (this.state[e.target.name + "Callback"]) {
            // if (e.target.name == "fromDate") {
            //     this.setState({toDate: ""})
            //     this.getRetailerListFields[4].min = value
            //     this.getRetailerListFields[4].type = "datetime-local"
            //     // this.getRetailerListFields[4].required = true
            //     document.getElementById("toDate")?.removeAttribute("readonly")
            // }
            if (e.target.name == "companyName") {
                this.getDistributorsList({
                    "partnerTypeId": 5,
                    "companyId": value
                })
            }
            
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        dateRange:e.target.value,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            dateRange:e.target.value,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
    }
    handleCreateLoginID(guid : any,distributorName:any){
        Swal.fire({
            title: "Are you sure to Create Login ID for "+distributorName+" ?",
            timer: 10000,
            icon: "info",
            showCancelButton:true,
        }).then(result => {
            if (result.isConfirmed) {
                this.handleCreateLoginIdService(guid)              
            }
        });
    }
    handleCreateLoginIdService(guid:any){
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            userGuid: guid
        }
        const response = this.DownlineManageServices.createUserLoginId(searchCondition)
        response
            .then((searchDistributorResponse: any) => {
                if (!searchDistributorResponse.errorStatus) {
                    this.uiData.showToastMessage("Login ID has been created Successfully","");
                    this.handlePartnerSearchAjax("search")                      
                    this.closeLoader()
                } else {
                    this.uiData.showToastMessage("Something went wrong","error");
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // console.log("handlePartnerSearchErr", err)
                this.closeLoader()
            })
    }
    handleRejectDistributor(guid : any,distributorName:any){
        Swal.fire({
            title: "Are you sure to Reject "+distributorName+" ?",
            timer: 10000,
            icon: "info",
            showCancelButton:true,
        }).then(result => {
            if (result.isConfirmed) {
                this.handleUserVerifyService(guid, "","REJECTED")
            }
        });
    }
    handleUserVerifyService(guid:any,name:any,type:any){
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            name: name ? name : "",
            userGuid: guid,
            applicationStatus:type
        }
      //console.log(searchCondition);
        const response = this.DownlineManageServices.changeUserStatus(searchCondition)
        response
            .then((searchDistributorResponse: any) => {
                if (!searchDistributorResponse.errorStatus) {
                    let showMessage = "";
                    if(type == "VERIFIED"){
                        showMessage = "Application has been Verified Successfully";
                    }else if(type == "REJECTED"){
                        showMessage = "Application has been Rejected";
                    }else if(type == "PROCESSING"){
                        showMessage = "Application has been submitted Successfully";
                    }else{
                        showMessage = "Application has been Installed Successfully";
                    }
                    this.uiData.showToastMessage(showMessage,"");
                    this.handlePartnerSearchAjax("search") 
                    this.setState({"verifyPopupShow":false,"installPopupShow":false})                     
                    this.closeLoader()
                } else {
                    this.uiData.showToastMessage("Something went wrong","error");
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // console.log("handlePartnerSearchErr", err)
                this.closeLoader()
            })
    }
    
    handleSubmitUser(event:any,guid : any,distributorName:any){
        if(event.target.checked){
            this.dataArray.push(guid)
        }else{
            const index = this.dataArray.indexOf(guid);
            if (index > -1) { 
                this.dataArray.splice(index, 1); 
            }
        }
        if(this.dataArray.length > 0){
            this.setState({btnDisabled:false});
        }else{
            this.setState({btnDisabled:true});
        }
    }
    handleSubmitUserSerice(){
        const guid = this.dataArray.toString();
        this.handleUserVerifyService(guid, "","PROCESSING")
    }
    handleVerifyPopupClose = () => {
        this.setState({verifyPopupShow: false});
    }
    handleInstallPopupClose = () => {
        this.setState({installPopupShow: false});
    }
    handleVerifyUser(guid : any,distributorName:any,e:any){
        e.preventDefault();
        this.setState({verifyPopupShow: true,distributorCode:distributorName,distributorGuid:guid});        
        setTimeout(()=>{
            this.setState({...this.state, "verifyUser": "","verifyUserError": ""})
            document.getElementById("verifyUser")?.focus();
        },100)        
    }
    handleOnChangeVerifyState(e:any){
        if(e.target.value.length > 50){
            return false;
        }
        this.setState({verifyUser:e.target.value,verifyUserError:""})
    }
    handleVerifyUserSubmit(e:any){
        e.preventDefault();
        if(this.state.verifyUser == ""){
            this.setState({...this.state,"verifyUserError":"Name is required"});
            document.getElementById("verifyUser")?.focus();
            return;
        }
        this.handleUserVerifyService(this.state.distributorGuid, this.state.verifyUser,"VERIFIED")
    }
    handleInstallUser(guid : any,distributorName:any,e:any){
        e.preventDefault();
        this.setState({installPopupShow: true,distributorCode:distributorName,distributorGuid:guid});
        
        setTimeout(()=>{
            this.setState({...this.state, "installUser": "","installUserError": ""});
            document.getElementById("installUser")?.focus();
        },100)    
    }
    handleOnChangeInstallState(e:any){
        if(e.target.value.length > 50){
            return false;
        }
        this.setState({installUser:e.target.value,installUserError:""})
    }
    handleInstallUserSubmit(e:any){
        e.preventDefault(); 
        if(this.state.installUser == ""){
            this.setState({...this.state,"installUserError":"Name is required"});
            document.getElementById("installUser")?.focus();
            return;
        }
       
        this.handleUserVerifyService(this.state.distributorGuid, this.state.installUser,"ACTIVE")
    }
    // handleVerifyUser(guid : any,distributorName:any){
    //     Swal.fire({
    //         title: "Verify "+distributorName+" ?",
    //         // timer: 10000,
    //         // icon: "info",
    //         input: 'text',
    //         inputPlaceholder: 'Name',
    //         inputAttributes: {
    //             autocapitalize: "off",
    //             autocomplete:"off",
    //             id: "numberId",
    //             required:"true",
    //         },
    //         showCancelButton:true,
    //         // didOpen: () => {
    //         //     // Swal.disableButtons()
    //         //     const input: any = Swal.getInput()
    //         //     input.oninput = () => {
    //         //         if (input.value.length > 0) {
    //         //             Swal.enableButtons()
    //         //         } else {
    //         //             Swal.disableButtons()
    //         //         }
    //         //     }
    //         // },
    //     }).then(result => {
    //         if (result.isConfirmed) {
    //             if (result.isConfirmed) {
    //                 const input: any = Swal.getInput()
    //                 if (input.value != "" || input.value != null) {
    //                     this.handleUserVerifyService(guid, input.value,"VERIFIED")
    //                 } else {
    //                   //console.log("asd")
    //                 }
    //             }
    //         }
    //     });
    // }
    
    // handleInstallUser(guid : any,distributorName:any){
    //     Swal.fire({
    //         title: "Install "+distributorName+" ?",
    //         input: 'text',
    //         inputPlaceholder: 'Name',
    //         inputAttributes: {
    //             autocapitalize: "off",
    //             autocomplete:"off",
    //             id: "installName",
    //             required:"true",
    //         },
    //         showCancelButton:true,
    //         // didOpen: () => {
    //         //     Swal.disableButtons()
    //         //     const input: any = Swal.getInput()
    //         //     input.oninput = () => {
    //         //         if (input.value.length > 0) {
    //         //             Swal.enableButtons()
    //         //         } else {
    //         //             Swal.disableButtons()
    //         //         }
    //         //     }
    //         // },
    //     }).then(result => {
    //         if (result.isConfirmed) {

    //             const input: any = Swal.getInput()
    //             if (input.value != "" || input.value != null) {
    //                 this.handleUserVerifyService(guid, input.value,"ACTIVE")
    //             } else {
    //               //console.log("asd")
    //             }
    //         }
    //     });
    // }
    
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    render() {
        return (
            <div className={classes["right-section"]}>
                <div className="error-msg-section"></div>
                <div className={classes["main-div"]}>
                    <h2>Retailer Report</h2>
                    <form>
                    {this.state.searchFilterRow.map((valRow:any,i:any)=>{
                        return (
                        <div className={classes["main-div-input-sec"]} key={i}>
                            {this.getRetailerListFields.map((val: any, ind: any) => {
                                 if(val.rowId == valRow){
                                return (
                                    <div className={classes["sub-div"]} key={ind}>
                                        <label htmlFor="name">{val.label}</label>

                                        {val.required ? (
                                            <span className={classes["mandatory-symbol"]}> * </span>
                                        ) : (
                                            ""
                                        )}
                                        <InputBox
                                            id={val.name}
                                            autoComplete={val.autoComplete}
                                            maxLength={val.maxLength}
                                            className={"form-control " + classes[val.class]}
                                            autoFocus={val.autoFocus}
                                            placeholder={val.placeHolder}
                                            name={val.name}
                                            type={val.type}
                                            tabIndex={1}
                                            value={this.state[val.name]}
                                            min={val.min}
                                            options={val.options}
                                            required={val.required}
                                            selectShowEmpty={val.selectShowEmpty} 
                                            onChange={
                                                val.type === "datetime"
                                                    ? val.name == "fromDate"
                                                        ? this.handleStartDateChange
                                                        : this.handleEndDateChange
                                                    : this
                                                          .partnerLimitControlFilterChange
                                            }
                                            dateFormat={val.dateFormat}
                                            startMaxDate={this.maxDate}
                                            endMinDate={this.minDate}
                                        />
                                        <div className={classes["error"]}>
                                            {this.state.errors[val.name + "Error"]}
                                        </div>
                                    </div>
                                )
                                        }
                            })}
                        </div>
                        )})}
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="submit"
                                    value="Search"
                                    onClick={() => this.handleCreatePartnerLimitSearch(event)}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="reset"
                                    value="Reset"
                                    onClick={() => this.handleSearchPartnerLimitReset()}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                {this.state.showRetailerListing && (
                    <>
                    <div className="d-flex">
                        {/* <div className={"ms-2 me-2"}>
                            <div className={classes["total_section_box"]}>
                                <span className={classes["total_section_box_sub1"]}>
                                    Total Records
                                </span>
                                :
                                <span className={classes["total_section_box_sub2"]}>
                                    {this.state.totalCount !=""  ? this.state.totalCount : "0"}
                                </span>
                            </div>
                        </div>   
                                                    */}
                                                    
                            <TotalRecords totalCount={this.state.totalCount}/>
                             {this.state.totalCount > 0 ? (
                                <>
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                </>
                            ):""}
                    </div>
                    <div className={classes["main-div"] + " " + classes["table-main-div"]} style={{maxHeight:"400px"}}>
                        <Table
                            className={
                                "table table-bordered table-hover table-condensed" +
                                classes["table"] +
                                " " +
                                classes["table-bordered"] +
                                " " +
                                classes["table-hover"] +
                                " " +
                                classes["table-condensed"]
                            }
                            id="provider_table_id"
                        >
                            <Thead>
                                <Tr>
                                    {this.state.pendingStatus && <Th>#</Th>}
                                    <Th>Sl.No</Th>
                                    <Th>Application No</Th>
                                    <Th>Login ID</Th>
                                    <Th>Retailer Code</Th>
                                    <Th>Retailer Name</Th>
                                    <Th>Terminal Type</Th>
                                    <Th>Distributor Login ID</Th>
                                    <Th>Distributor Name</Th>
                                    <Th>Address</Th>
                                    {/* <Th>Username</Th>
                                    {this.state.LoginpartnerTypeId == 0 ? <Th>Company Name</Th> : <></> }
                                    {this.state.LoginpartnerTypeId != 5 ? <Th>Distributor Name</Th> : <></> }  
                                    <Th>Credit Limit</Th>
                                    <Th>Balance</Th> */}
                                    <Th>Status</Th>
                                    <Th style={{zIndex:1}}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {this.state.retailerSearchData.length > 0 ? (
                                    <>
                                        {this.state.retailerSearchData.map((val: any, ind: any) => {
                                            let status = "Active"
                                            if (val.ACCOUNT_STATUS == 0) {
                                                status = "Inactive"
                                            }
                                            let oddEven = "odd"
                                            if (ind % 2 == 0) {
                                                oddEven = "even"
                                            }
                                            return (
                                                <Tr className={classes["tr_" + oddEven]} key={ind}>
                                                    {this.state.pendingStatus  &&     <Td>
                                                        <input type="checkbox" value={val.USER_GUID} onChange={(event)=>this.handleSubmitUser(event,val.USER_GUID,val.retaillerName)}/>
                                                    </Td>
                                                    }
                                                    <Td>{((this.state.currentPage-1)*this.state.noRow)+ind+1}</Td>
                                                    <Td>
                                                        {val.applicationNo != "" ? <>
                                                            {val.ACCOUNT_STATUS == 2 ? 
                                                                <>
                                                                    {this.state.LoginpartnerTypeId < 5 ?
                                                                    <> 
                                                                        <a  href="#" onClick={()=>this.handleVerifyUser(val.USER_GUID,val.retaillerName,event)} >
                                                                            {val.applicationNo} 
                                                                        </a> </>
                                                                        : 
                                                                        <>{val.applicationNo}</>
                                                                    }          
                                                                </> :<>{val.applicationNo}</>
                                                            }
                                                        </> : "-"}  
                                                    </Td>
                                                    <Td>{val.loginId && val.loginId != "" ? val.loginId : "-"}</Td>
                                                    <Td>{val.USERNAME && val.USERNAME != "" ? val.USERNAME : "-"}</Td>
                                                    <Td>{val.retaillerName && val.retaillerName != "" ? val.retaillerName : "-"}</Td>
                                                    <Td>{val.terminalType && val.terminalType != "" ? val.terminalType : "-"}</Td>
                                                    <Td>{val.distributorLoginId && val.distributorLoginId != "" ? val.distributorLoginId : "-"}</Td>
                                                    <Td>{val.distributorName && val.distributorName != "" ? val.distributorName : "-"}</Td>
                                                    <Td>
                                                        {val.address && val.address != "" ? val.address : " "}
                                                        {val.address1 && val.address1 != "" ? ","+val.address1 : " "}
                                                    </Td>
                                                    <Td>{StaticKey.RETAILERSTATUS[val.ACCOUNT_STATUS].name}</Td>
                                                    <Td>
                                            <Link to={"/view-retailer.html?id=" + val.USER_GUID} >
                                                <div className={classes["tooltip"]}>
                                                    <i className="fa fa-file-text-o text-primary"></i> 
                                                    <span id="tooltiptext" className={classes["tooltiptext"] +" "+classes["tooltiptext-primary"]}>View Detail</span>
                                                </div>  
                                            </Link> &nbsp;
                                            
                                            <a
                                                href="#"
                                                onClick={()=>this.handleGeneratePdf(event,val.USER_GUID,val.retaillerName)}
                                            >
                                                <div
                                                    className={" text-success "+
                                                        classes["tooltip"]
                                                    }
                                                >
                                                    <i id={"pdf_"+val.USER_GUID} className="fa fa-file-pdf-o"></i>
                                                    <span
                                                        id="tooltiptext"
                                                        className={
                                                            classes[
                                                                "tooltiptext"
                                                            ] +
                                                            " " +
                                                            classes[
                                                                "tooltiptext-success"
                                                            ]
                                                        }
                                                    >
                                                        Download Pdf
                                                    </span>
                                                </div>
                                            </a>{" "}
                                            &nbsp;
                                            {this.state.LoginpartnerTypeId < 5 && (
                                                <>
                                                {val.ACCOUNT_STATUS == 0 || val.ACCOUNT_STATUS == 2 || val.ACCOUNT_STATUS == 3  ? 
                                            <>
                                                <a  href="#" onClick={()=>this.handleRejectDistributor(val.USER_GUID,val.retaillerName)} >
                                                    <div className={classes["tooltip"]}>
                                                        <i className="fa fa-close text-danger"></i> 
                                                        <span id="tooltiptext" className={classes["tooltiptext"]  +" "+classes["tooltiptext-danger"]}>Reject</span>
                                                    </div>  
                                                </a> &nbsp;
                                            </> : <></>}
                                            {val.ACCOUNT_STATUS == 3 &&  val.loginId == 0  ? 
                                            <>
                                                <a onClick={() => this.handleCreateLoginID(val.USER_GUID,val.retaillerName) }>
                                                    <div className={classes["tooltip"]}>
                                                        <i className="fa fa-plus-circle text-success"></i> 
                                                        <span id="tooltiptext" className={classes["tooltiptext"]  +" "+classes["tooltiptext-success"]}>Create Login ID</span>
                                                    </div>  
                                                </a>
                                            </> : <></> }
                                            {val.ACCOUNT_STATUS == 3  &&  val.loginId > 0  ? 
                                            <>
                                                <a onClick={() => this.handleInstallUser(val.USER_GUID,val.retaillerName,event) }>
                                                    <div className={classes["tooltip"]}>
                                                        <i className="fa fa-download text-info"></i> 
                                                        <span id="tooltiptext" className={classes["tooltiptext"]  +" "+classes["tooltiptext-info"]}>Installation</span>
                                                    </div>  
                                                </a>
                                            </> : <></> }
                                            </>

                                            )}
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <Tr className={classes["no_data_found"]}>
                                        <Td colSpan={15}>No record(s) available</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </div>
                    {this.state.pendingStatus  && this.state.retailerSearchData.length > 0 && 
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="button"
                                    disabled={this.state.btnDisabled} 
                                    value="submit"
                                    onClick={() => this.handleSubmitUserSerice()}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="button"
                                    disabled={this.state.btnDisabled} 
                                    value="Cancel"
                                    onClick={() => this.handleSearchPartnerLimitReset()}
                                />
                            </div>
                        </div>
                    }
                    {this.state.totalCountPagination.length > 0 ? (
                        <div className={"d-none"} id={"printTable"}>
                            <PrintTableElement tableData={this.state.retailerSearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/> 
                            {this.state.excelExport && (
                                <ExportExcelReport mockData={this.state.mockData} sheetName="retailer_list"/> 
                            )}
                        </div>
                    ):""}
                        <div className={"table_pagination_div"}>
                            {this.state.totalCountPagination.length > 0 ? (
                                <>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event => this.handlePagination(event, 1)}
                                    >
                                        First
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event =>
                                            this.handlePagination(event, this.state.previousPage)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left"></i> Previous
                                    </button>
                                    {this.state.totalCountPagination.map((val: any, ind: any) => {
                                        let btnClass = ""
                                        if (
                                            ind + 1 >= this.state.fromPagePagination &&
                                            ind + 1 <= this.state.toPagePagination
                                        ) {
                                            if (this.state.currentPage == ind + 1) {
                                                btnClass = "active"
                                            }
                                            return (
                                                <>
                                                    <button
                                                        className={
                                                            classes["buttonClass"] +
                                                            " " +
                                                            classes[btnClass]
                                                        }
                                                        onClick={event =>
                                                            this.handlePagination(event, ind + 1)
                                                        }
                                                    >
                                                        {ind + 1}
                                                    </button>
                                                </>
                                            )
                                        }
                                    })}
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.nextPage)
                                        }
                                    >
                                        Next <i className="fa fa-angle-double-right"></i>
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.lastPage)
                                        }
                                    >
                                        Last
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        
                     {/* {this.state.verifyPopupShow == "show" && ( */}
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.verifyPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Verify Retailer {this.state.distributorCode ? "("+this.state.distributorCode+")" : "" }</h4>
                                <span
                                    onClick={()=>this.setState({"verifyPopupShow":false})}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2 mt-2">
                                    <div>
                                        <div>
                                            Are you sure to verify the Retailer?
                                        </div> 
                                        <div>                                         
                                            <InputBox
                                                name={"verifyUser"}
                                                id={"verifyUser"}
                                                placeholder={"Name"}
                                                className={"form-control "+classes['form-control']}
                                                value={this.state.verifyUser}
                                                required={"required"}
                                                // onChange={(event:any)=>this.setState({verifyUser:event.target.value,verifyUserError:""})}
                                                onChange = {()=>this.handleOnChangeVerifyState(event)}
                                                autoFocus={true}
                                                autoComplete={"off"}
                                            />
                                        </div>   
                                        <div className={classes["error"]+" "+classes["error-field"]}>{this.state.verifyUserError}</div>  
                                    </div>     
                                </div>
                                <div className="modal-footer d-flex">
                                        <InputBox
                                            id="submit_form_verify"
                                            className="btn btn-success"
                                            type="submit"
                                            value="Ok"
                                            onClick={() => this.handleVerifyUserSubmit(event)}
                                        />
                                        <InputBox
                                            id="reset_form_verify"
                                            className="btn btn-danger"
                                            type="reset"
                                            value="Cancel"
                                            onClick={()=>this.setState({"verifyPopupShow":false})}
                                            // onClick={() => this.handleSearchGameHistorySearchReset()}
                                        />       
                                </div>
                            </form>
                        </Modal>
                     {/* )} */}
                     {/* {this.state.installPopupShow == "show" && ( */}
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.installPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Install Retailer {this.state.distributorCode ? "("+this.state.distributorCode+")" : "" }</h4>
                                <span
                                    onClick={()=>this.setState({"installPopupShow":false})}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2 mt-2">
                                    <div>
                                        <div>
                                            Are you sure to install the Retailer?
                                        </div> 
                                        <div>                                         
                                            <InputBox
                                                name={"installUser"}
                                                id={"installUser"}
                                                placeholder={"Name"}
                                                className={"form-control "+classes['form-control']}
                                                value={this.state.installUser}
                                                required={"required"}
                                                // onChange={(event:any)=>this.setState({installUser:event.target.value,installUserError:""})}
                                                onChange = {()=>{this.handleOnChangeInstallState(event)}}
                                                autoFocus={true}
                                                autoComplete={"off"}
                                            />
                                        </div>     
                                        <div className={classes["error"]+" "+classes["error-field"]}>{this.state.installUserError}</div>  
                                    </div>     
                                </div>
                                <div className="modal-footer d-flex">
                                        <InputBox
                                            id="submit_form_install"
                                            className="btn btn-success"
                                            type="submit"
                                            value="Ok"
                                            onClick={() => this.handleInstallUserSubmit(event)}
                                        />
                                        <InputBox
                                            id="reset_form_install"
                                            className="btn btn-danger"
                                            type="reset"
                                            value="Cancel"
                                            onClick={()=>this.setState({"installPopupShow":false})}
                                            // onClick={() => this.handleSearchGameHistorySearchReset()}
                                        />       
                                </div>
                            </form>
                        </Modal>
                    {/* )} */}
                    <div  style={{height:"0",overflow:"auto"}}> 
                        <div id="downloadPdf" > 
                            {Object.keys(this.viewRetailerFields).map(
                                (keys: any, values: any) => {
                                    return (
                                        <div key={values} className={classes["main-div-input-sec-2"]}>
                                            <h5>{keys}</h5>
                                            <div className={classes["main-div-input-sec"]}>
                                                {this.viewRetailerFields[keys].map(
                                                    (fieldValObject: any,fieldValIndex: any) => {
                                                        if (Object.keys(fieldValObject).length == 1) {
                                                            return Object.keys(fieldValObject).map((keys1: any,values1: any) => {
                                                                return (
                                                                    <div key={values1} className={classes["main-div-input-sec-2-sub"]}>
                                                                        <h6>{keys1}</h6>
                                                                        <div className={classes["main-div-input-sec"]}> 
                                                                            {fieldValObject[keys1].map((fieldValObject1: any,fieldValIndex1: any) => {
                                                                                return (
                                                                                    <div className={classes["sub-div"]} key={fieldValIndex1}>
                                                                                        <label htmlFor="name">{fieldValObject1.label}</label>  
                                                                                        <InputBox
                                                                                            id={fieldValObject1.id}
                                                                                            autoComplete="off"
                                                                                            maxLength={fieldValObject1.maxLength}
                                                                                            disabled={fieldValObject1.disabled}
                                                                                            className={"form-control " +classes[fieldValObject1.class]}
                                                                                            autoFocus={fieldValObject1.autoFocus}
                                                                                            name={fieldValObject1.name}
                                                                                            readonly={fieldValObject1.readonly}
                                                                                            value={this.state.fields[fieldValObject1.name]}
                                                                                            type={fieldValObject1.type}    
                                                                                            placeholder={fieldValObject1.placeHolder}
                                                                                            required={fieldValObject1.required}    
                                                                                            options={fieldValObject1.options}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        } else {
                                                            let classNew = ""
                                                            if (fieldValObject.label =="Terms Agreed") {
                                                                classNew = "subdiv-full-width"
                                                            }
                                                            return (
                                                                <>
                                                                    {!fieldValObject.dnone && 
                                                                        <div className={classes["sub-div"] +" " +classes[classNew]} key={fieldValIndex}>
                                                                            <div>
                                                                                <label htmlFor={fieldValObject.id}>{fieldValObject.label}</label>
                                                                                {fieldValObject.type != "file" && (
                                                                                    <InputBox
                                                                                        id={fieldValObject.name}
                                                                                        disabled={fieldValObject.disabled}
                                                                                        autoComplete="off"
                                                                                        maxLength={fieldValObject.maxLength}
                                                                                        readonly={fieldValObject.readonly}
                                                                                        className={"form-control " +classes[fieldValObject.class] +" " +classes["upload_input_field"]}
                                                                                        autoFocus={fieldValObject.autoFocus}
                                                                                        accept={fieldValObject.type =="file"? ".png, .jpg, .svg, .pdf" : ""}
                                                                                        value={fieldValObject.type !="file" ? this.state.fields[fieldValObject.name]: ""}
                                                                                        name={fieldValObject.name}
                                                                                        type={fieldValObject.type}
                                                                                        placeholder={fieldValObject.placeHolder}
                                                                                        required={fieldValObject.required}
                                                                                        label1={fieldValObject.label1}
                                                                                        options={fieldValObject.type =="select"? fieldValObject.options: ""}
                                                                                        selectShowEmpty={fieldValObject.selectShowEmpty}                                                                           
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    }  
                                                                </>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                    </>
                )}
            </div>
        )
    }
}
