import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/drawmanagement/drawListing.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DrawManageService} from "../../../game_core/type_script/services/drawmanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {DataService} from "../../../game_core/type_script/socket_communication_services/lobby/DataService"

export class DrawResultUpdate extends Component {
    DrawManageServices = DrawManageService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    DataService: DataService = DataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        selectedType1:[],
        selectedType2:[],
        selectedType3:"",
        selectedType4:[],
        selectedType1Length:0,
        selectedType2Length:0,
        selectedType3Length:0,
        selectedType4Length:0,
        selectedType1Required:false,
        selectedType2Required:false,
        selectedType3Required:false,
        selectedType4Required:false,
        selectedType1Error:"",
        selectedType2Error:"",
        selectedType3Error:"",
        selectedType4Error:"",
        drawGameId: "",
        drawIsDemo: "no",       
        fromDate: "",
        fromDateSearch: "",
        drawSearchResult:[],
        showSearchList:false,
        fields: {
            "drawGameId": "",
            "drawReferrenceId": "",
            "fromDate": "",
        },
        errors: {
            "drawGameIdError": "",
            "drawReferrenceIdError": "",
            "fromDateError": "",
        },
        showDrawResult: false,
        DrawSearchData: []
    }
    createDrawsFields: any = {
        "": [
            {
                rowId:0,
                "name": "drawGameId",
                "type": "select",
                "label": "Game",
                "placeHolder": "Game",
                "autoFocus": true,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true,
                "getDrawInfo": true
            },
            {
                rowId:0,
                "name": "fromDate",
                "type": "date",
                "label": "Draw Date",
                "placeHolder": "Draw Date",
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "dateFormat": "dd-MM-yyyy" 
            },
            {
                rowId:0,
                "name": "drawReferrenceId",
                "type": "select",
                "label": "Draw Number",
                "placeHolder": "Draw Number",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": true,
                "options": {},
                "selectShowEmpty": true,
                "defaultValue":""
            },  
        ]
    }
    validateDrawResultForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createDrawsFields).map((keys: any) => {
            {
                return this.createDrawsFields[keys].map((fieldValObject: any) => {
                    
                    const errorData = this.uiData.textConversion(fieldValObject.label);
                    if (fieldValObject.required && this.state.fields[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = errorData + " is required")
                        
                    }  else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    componentDidMount() {
        this.getGameList(1)
    }
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    handleDrawResultReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createDrawsFields).map((keys: any, values: any) => {
            {
                this.setState({[this.createDrawsFields[keys].name]: ""})
                return this.createDrawsFields[keys].map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        if (Object.keys(fieldValObject).length == 1) {
                            return Object.keys(fieldValObject).map((keys1: any, values1: any) => {
                                fieldValObject[keys1].map(
                                    (fieldValObject1: any, fieldValIndex1: any) => {
                                      //console.log(fieldValObject1);
                                        return (
                                            (newArrayError[fieldValObject1.name + "Error"] = ""),
                                            (newArrayFields[fieldValObject1.name] = ""),
                                            (newArrayFields[fieldValObject1.name + "Val"] = "")
                                        )
                                    }
                                )
                            })
                        } else {
                            return (
                                (newArrayError[fieldValObject.name + "Error"] = ""),
                                (newArrayFields[fieldValObject.name] = ""),
                                (newArrayFields[fieldValObject.name + "Val"] = "")
                            )
                        }
                    }
                )
            }
        })
        this.createDrawsFields[""][2].options = {}
        this.dataCheckedData = [];
        this.dataChecked = 0;  
        this.dataCheckedDataType2 = [];
        this.dataCheckedType2 = 0;
        this.setState({
            ...this.state,
            showSearchList:false,
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
            errors: newArrayError, 
            fields: newArrayFields
        })
    }
    handleDrawUpdateReset = () => {
        this.dataCheckedData = [];
        this.dataChecked = 0;  
        this.dataCheckedDataType2 = [];
        this.dataCheckedType2 = 0;
        this.setState({
            ...this.state,
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
        })
    }
    handleUpdateFunction =(e:any)=>{
        e.preventDefault();
        const drawType1 : any =[];
        const drawType2 : any =[];
        const drawType3 : any =[];
        const drawType4 : any =[];
        const result:any = []
        const drawWinNumbers: any = []
        if(this.state.selectedType1Required){
            if(this.state.selectedType1Length != this.state.selectedType1.length){
                this.setState({
                    ...this.state,
                    selectedType1Error:"Please Pick " +this.state.selectedType1Length+" Numbers"
                })
                document.getElementById('select1Error')?.focus({preventScroll: true })
                return;
            }else{
                this.setState({
                    ...this.state,
                    selectedType1Error:""
                })
                Object.keys(this.state.selectedType1).map((values1: any, ind: any) => {
                    {
                        drawWinNumbers[ind] = this.state.selectedType1[values1]
                    }
                })
                drawType1['type'] = 1;
                drawType1['winNumber'] = drawWinNumbers;
                result.push(drawType1)
            }
        }
        if(this.state.selectedType2Required){
            if(this.state.selectedType2Length != this.state.selectedType2.length){
                this.setState({
                    ...this.state,
                    selectedType2Error:"Please Pick " +this.state.selectedType2Length+" Rashi"
                })
                document.getElementById('select2Error')?.focus()
                return;
            }else{
                this.setState({
                    ...this.state,
                    selectedType2Error:""
                })
               
                Object.keys(this.state.selectedType2).map((values1: any, ind: any) => {
                    {
                        drawWinNumbers[ind] = this.state.selectedType2[values1]
                    }
                })
                drawType2['type'] = 2;
                drawType2['winNumber'] = drawWinNumbers;
                result.push(drawType2)
            }
        }
        if(this.state.selectedType3Required){
            if(this.state.selectedType3 == ""){
                this.setState({
                    ...this.state,
                    selectedType3Error:"please Enter " +this.state.selectedType3Length+" Digit No."
                })
                document.getElementById('select3Error')?.focus()
                return;
            }else{
                this.setState({
                    ...this.state,
                    selectedType3Error:""
                })
                drawType3['type'] = 3;
                drawType3['winNumber'] = this.state.selectedType3;
                result.push(drawType3)
            }
        }
        if(this.state.selectedType4Required){
            if(this.state.selectedType4Length != this.state.selectedType4.length){
                this.setState({
                    ...this.state,
                    selectedType4Error:"Please Pick " +this.state.selectedType4Length+" Numbers"
                })
                document.getElementById('select4Error')?.focus()
                return;
            }else{
                this.setState({
                    ...this.state,
                    selectedType4Error:""
                })
                
                
                Object.keys(this.state.selectedType4).map((values1: any, ind: any) => {
                    {
                        drawWinNumbers[ind] = this.state.selectedType4[values1]
                    }
                })
                drawType4['type'] = 4;
                drawType4['winNumber'] = drawWinNumbers;
                result.push(drawType4)
            }
        }

        // console.log(result);
        // return;
        // const drawWinNumbers: any = []
        // Object.keys(this.state.selectedType1).map((values1: any, ind: any) => {
        //     {
        //         drawWinNumbers[ind] = this.state.selectedType1[values1]
        //     }
        // })       



        const params = {
            "drawId": this.state.fields.drawReferrenceId,
            "winNumber": drawWinNumbers,
            "result":result,
            "type_1":this.state.selectedType1 !="" ? this.state.selectedType1.toString():"",
            "type_2":this.state.selectedType2 !="" ? this.state.selectedType2.toString():"",
            "type_3":this.state.selectedType3 !="" ? this.state.selectedType3:"",
            "type_4":this.state.selectedType4 !="" ? this.state.selectedType4.toString():"",
        }      
        // console.log(params);
        // return;  
        // setTimeout(() => {
            const response = this.DrawManageServices.drawResultUpdate(params)
            response
                .then((updateResultResponse: any) => {
                    if (!updateResultResponse.errorStatus) {
                        this.uiData.showToastMessage(updateResultResponse.message, "")
                    } else {
                        this.uiData.showToastMessage(updateResultResponse.message, "error")
                    }
                    document.getElementById('reset_form')?.click()
                })
                .catch((err: any) => {
                  //console.log("handleDrawSearch", err)
                    this.uiData.updateShowLoading(false)
                })
        // }, 500);
        // const response = this.DrawManageServices.drawResultUpdate(params)
        // response
        //     .then((updateResultResponse: any) => {
        //         if (!updateResultResponse.errorStatus) {
        //             this.uiData.showToastMessage(updateResultResponse.message, "")
        //         } else {
        //             this.uiData.showToastMessage(updateResultResponse.message, "error")
        //         }
        //         document.getElementById('reset_form')?.click()
        //     })
        //     .catch((err: any) => {
        //       //console.log("handleDrawSearch", err)
        //         this.uiData.updateShowLoading(false)
        //     })

    }
    dataCheckedData:any = [];
    dataChecked = 0;
    dataCheckedDataType2:any = [];
    dataCheckedType2 = 0;
    dataCheckedDataType4:any = [];
    dataCheckedType4 = 0;
    handleSelectType4Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType4.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedDataType4.push(value)           
        }else{
            const index = this.dataCheckedDataType4.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedDataType4.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType4:this.dataCheckedDataType4,
            selectedType4Error:"",
        })
    }
    handleSelectType1Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType1.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedData.push(value)           
        }else{
            const index = this.dataCheckedData.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedData.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType1:this.dataCheckedData,
            selectedType1Error:"",
        })
    }
    handleSelectType2Function(e:any,value:any,length:any){
        if(e.target.checked){
            if(this.state.selectedType2.length >= length){
                e.preventDefault()
                e.target.checked = false
                return false;
            }
            this.dataCheckedDataType2.push(value)           
        }else{
            const index = this.dataCheckedDataType2.indexOf(e.target.value);
            if (index > -1) { 
                this.dataCheckedDataType2.splice(index, 1); 
            }
        }
        this.setState({
            ...this.state,
            selectedType2:this.dataCheckedDataType2,
            selectedType2Error:"",
        })
    }
    handleSelectType3Function(e:any,minNumber:any,maxNumber:any){
      //console.log(e)
        if(e.target.value > maxNumber){
            e.preventDefault();
        }else{
            this.setState({
                selectedType3:e.target.value
            })            
        }
        if(e.target.value.length < minNumber.length){
          //console.log(e.target.value.length)
            this.setState({
                selectedType3:""
            })   
        }else{
            this.setState({
                ...this.state,
                selectedType3:e.target.value,
                selectedType3Error:"",
            })            
        }
    }
    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""        
        const currentYear = date.getFullYear()
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay}`);
        this.setState({
            fields:{
                ...this.state.fields,
                fromDate:date,
            },
            errors:{
                ...this.state.errors,
                fromDateError:"",
            },
            fromDateSearch:finalDate
        })
        const getDrawList = {
            "drawGameId":this.state.fields.drawGameId,
            "drawDate":finalDate
        };
      //console.log(getDrawList)
        if(this.state.fields.drawGameId != ""){
            this.uiData.updateShowLoading(true)
            const response = this.DrawManageServices.getAllDrawList(getDrawList)
            response.then((getGameListResponse: any) => {
                if (!getGameListResponse.errorStatus) {
                  //console.log(getGameListResponse.data)
                    const dataArray:any = [];
                    getGameListResponse.data.map((val:any,key:any)=>{
                        const arrayIn:any = [];
                        arrayIn['id']=val.drawNumber;
                        arrayIn['name']=val.drawStartTime+" ("+val.drawNumber+")";
                        dataArray.push(arrayIn)
                    })
                    this.createDrawsFields[""][2].options = dataArray
                    this.setState({companyList: dataArray})
                    this.closeLoader()
                }
                this.closeLoader()
            }).catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
        }else{
            this.setState({
                errors:{
                    drawGameIdError:'Please Select Game'
                }
            })
        }
    }
   
    handlecreateDrawInputChange = (e: any) => {
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        if(e.target.name == "drawGameId"){
            this.setState({
                fields:{
                    ...this.state.fields,
                    fromDate:"",
                    drawReferrenceId:"",
                    [e.target.name]: value
                },
                errors:{
                    ...this.state.errors,
                    fromDateError:"",
                    drawReferrenceIdError:"",
                    [e.target.name + "Error"]: ""
                },
                fromDateSearch:""
            })
        }else{
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [e.target.name]: value
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
        
       
    }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getAllMinigames(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                  //console.log(getGameListResponse.data)
                    this.createDrawsFields[""][0].options = getGameListResponse.data
                    this.setState({companyList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }
    handlesearchFunction(event:any) {  
        event.preventDefault()   
        
    this.dataCheckedData = [];
    this.dataChecked = 0;  
    this.dataCheckedDataType2 = [];
    this.dataCheckedType2 = 0;
        this.setState({
            // ...this.state,            
            selectedType1:[],
            selectedType2:[],
            selectedType3:"",
            selectedType4:[],
            selectedType1Required:false,
            selectedType2Required:false,
            selectedType3Required:false,
            selectedType4Required:false,
            selectedType1Error:"",
            selectedType2Error:"",
            selectedType3Error:"",
            selectedType4Error:"",
            drawSearchResult:[],
            showSearchList:false,
        })
        if (this.validateDrawResultForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                drawGameId: this.state.fields.drawGameId,
                drawNumber:this.state.fields.drawReferrenceId,
                drawDate:this.state.fromDateSearch, 
            }
          //console.log(searchCondition)
            const response = this.DrawManageServices.getDrawResultDetail(searchCondition)
            response
                .then((searchDrawResponse: any) => {
                    if (!searchDrawResponse.errorStatus) {
                            const data = searchDrawResponse.data['gameDetail'];
                            const drawDetailResult = searchDrawResponse.data['drawDetail']; 
                            if(drawDetailResult != "" && drawDetailResult != null){
                                const drawDetail = searchDrawResponse.data['drawDetail']['result']; 
                                for(let i=0;i<drawDetail.length;i++){
                                    if(drawDetail[i].type == 1){
                                        this.setState({...this.state,selectedType1:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedData = drawDetail[i]['winNumber']
                                    }
                                    if(drawDetail[i].type == 2){
                                        this.setState({...this.state,selectedType2:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedDataType2 = drawDetail[i]['winNumber']
                                    }
                                    if(drawDetail[i].type == 3){
                                        this.setState({...this.state,selectedType3:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                    }
                                    if(drawDetail[i].type == 4){
                                        this.setState({...this.state,selectedType4:drawDetail[i]['winNumber'],selectedType1Required:true}); 
                                        this.dataCheckedDataType4 = drawDetail[i]['winNumber']
                                    }
                                }
                            }
                            for(let i=0;i<data.length;i++){
                                if(data[i].type == 1){
                                    this.setState({...this.state,selectedType1Length:data[i].length,selectedType1Required:true}); 
                                }
                                if(data[i].type == 2){
                                    this.setState({...this.state,selectedType2Length:data[i].length,selectedType2Required:true}); 
                                }
                                if(data[i].type == 3){
                                    this.setState({...this.state,selectedType3Length:data[i].maxLength,selectedType3Required:true}); 
                                }
                                if(data[i].type == 4){
                                    this.setState({...this.state,selectedType4Length:data[i].length,selectedType4Required:true}); 
                                }
                            }
                            this.setState({
                                drawSearchResult:data,
                                showSearchList:true,
                            })
                           
                        this.closeLoader()
                    } else {
                        this.setState({
                            drawSearchResult:[],
                            showSearchList:false,
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    this.setState({
                        drawSearchResult:[],
                        showSearchList:false,
                    })
                    this.closeLoader()
                })
        }else{            
            this.setState({
                DrawSearchData: [],
                showDrawResult: false,
            })
            this.closeLoader()
        }
    }
    closeLoader(){        
        this.uiData.updateShowLoading(false)
    }
    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>Add Draw Result</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.createDrawsFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div className="w-100" key={values}>
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.createDrawsFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                           return (
                                                                <div
                                                                    className={classes["sub-div"]}
                                                                    key={fieldValIndex}
                                                                >
                                                                    <div>
                                                                        <label
                                                                            htmlFor={
                                                                                fieldValObject.id
                                                                            }
                                                                        >
                                                                            {fieldValObject.label}
                                                                        </label>
                                                                        {fieldValObject.required ? (
                                                                            <span
                                                                                className={
                                                                                    classes[
                                                                                        "mandatory-symbol"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                *{" "}
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <InputBox
                                                                            id={fieldValObject.id}
                                                                            autoFocus={fieldValObject.autoFocus}
                                                                            autoComplete="off"
                                                                            maxLength={
                                                                                fieldValObject.maxLength
                                                                            }
                                                                            min={fieldValObject.min}
                                                                            max={fieldValObject.max}
                                                                            placeholder={
                                                                                fieldValObject.placeHolder
                                                                            }
                                                                            className={
                                                                                "form-control " +
                                                                                classes[
                                                                                    fieldValObject
                                                                                        .class
                                                                                ]
                                                                            }
                                                                            value={
                                                                                fieldValObject.type !=
                                                                                "file"
                                                                                    ? this.state
                                                                                          .fields[
                                                                                          fieldValObject
                                                                                              .name
                                                                                      ]
                                                                                    : ""
                                                                            }
                                                                            name={
                                                                                fieldValObject.name
                                                                            }
                                                                            type={
                                                                                fieldValObject.type
                                                                            }
                                                                            tabIndex={tabIndex++}
                                                                            required={
                                                                                fieldValObject.required
                                                                            }
                                                                            onChange={
                                                                                fieldValObject.type === "date"
                                                                                        ? this.handleStartDateChange             
                                                                                    : this.handlecreateDrawInputChange
                                                                            }
                                                                            dateFormat={fieldValObject.dateFormat}
                                                                            startMaxDate={this.maxDate}
                                                                            endMinDate={this.minDate}
                                                                            label1={
                                                                                fieldValObject.label1
                                                                            }
                                                                            options={
                                                                                fieldValObject.type ==
                                                                                    "select" ||
                                                                                fieldValObject.type ==
                                                                                    "radio"
                                                                                    ? fieldValObject.options
                                                                                    : ""
                                                                            }
                                                                            selectShowEmpty={
                                                                                fieldValObject.selectShowEmpty
                                                                            }
                                                                        />
                                                                        <div>
                                                                            {fieldValObject.type ==
                                                                                "file" &&
                                                                                this.state.fields[
                                                                                    fieldValObject.name +
                                                                                        "Val"
                                                                                ]}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                classes["error"]
                                                                            }
                                                                        >
                                                                            {
                                                                                this.state.errors[
                                                                                    fieldValObject.name +
                                                                                        "Error"
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Search"
                                        onClick={() => this.handlesearchFunction(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleDrawResultReset()}
                                    />
                                </div>
                            </div>
                        </form>
                        <>
                        {this.state.showSearchList && Object.keys(this.state.drawSearchResult).map((val:any,key:any)=>{
                            const dataResult = this.state.drawSearchResult[key]
                            if(dataResult['type'] == "1"){
                                const minNumber = parseInt(dataResult['minNumber']);
                                const maxNumber = parseInt(dataResult['maxNumber']);
                                const length = parseInt(dataResult['length']);
                                const numberArray:any = [];
                                for(let i=minNumber;i<=maxNumber;i++){
                                    numberArray.push(i.toString())
                                }
                                return(
                                    <>
                                        <div className={classes["pick-number"]}>
                                            Pick {length} numbers
                                            <div id="select1Error"  className={classes["error1"]}>
                                                {this.state.selectedType1Error}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {(numberArray).map((val:any,key:any)=>{
                                                    let checked = false;
                                                 this.state.selectedType1.map((val1:any,key1:any)=>{
                                                    if(val1.toString() == val.padStart(2,0)){
                                                        checked = true
                                                        return
                                                    }
                                                })
                                                return (
                                                    <div className={"draw-res-chk-bx "+classes['box-class-top']} key={key}>
                                                        <InputBox
                                                            id={"type1_"+val}
                                                            name={"type1_"+val}
                                                            // className={classes["drawButtonClass"]}
                                                            type="checkbox"
                                                            label1={val.padStart(2,0)}
                                                            value={val.padStart(2,0)}
                                                            checked={checked}
                                                            onChange={() => this.handleSelectType1Function(event,val.padStart(2,0),length)}
                                                            tabIndex={tabIndex++}
                                                        />
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                        <div  className="d-flex flex-wrap">
                                            {this.state.selectedType1.map((val:any,key:any)=>{
                                                return(
                                                    <div className="draw-res-chk-bx text-center" key={key}>
                                                        <div className={classes["box-class"]}>{val}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            }
                            if(dataResult['type'] == "4"){
                                const minNumber = parseInt(dataResult['minNumber']);
                                const maxNumber = parseInt(dataResult['maxNumber']);
                                const length = parseInt(dataResult['length']);
                                const numberArray:any = [];
                                for(let i=minNumber;i<=maxNumber;i++){
                                    numberArray.push(i.toString())
                                }
                                return(
                                    <>
                                        <div className={classes["pick-number"]}>
                                            Select Strike Ball
                                            <div id="select4Error"  className={classes["error1"]}>
                                                {this.state.selectedType4Error}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {(numberArray).map((val:any,key:any)=>{
                                                 let checked = false;
                                                 this.state.selectedType4.map((val1:any,key1:any)=>{
                                                    if(val1.toString() == val.padStart(2,0)){
                                                        checked = true
                                                        return
                                                    }
                                                })
                                                return (
                                                    <div className={"draw-res-chk-bx "+classes['box-class-top']} key={key}>
                                                        <InputBox
                                                            id={"type4_"+val}
                                                            name={"type4_"+val}
                                                            // className={classes["drawButtonClass"]}
                                                            type="checkbox"
                                                            label1={val.padStart(2,0)}
                                                            value={val.padStart(2,0)}
                                                            checked={checked}
                                                            onChange={() => this.handleSelectType4Function(event,val.padStart(2,0),length)}
                                                            tabIndex={tabIndex++}
                                                        />
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                        <div  className="d-flex flex-wrap">
                                            {this.state.selectedType4.map((val:any,key:any)=>{
                                                return(
                                                    <div className="draw-res-chk-bx text-center" key={key}>
                                                        <div className={classes["box-class"]}>{val}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            }
                            if(dataResult['type'] == "2"){
                                const winOption = dataResult['winOption'];
                                const length = parseInt(dataResult['length']);
                                return(
                                    <>
                                        <div className={classes["pick-number"]}>
                                            Pick {length} Rashi
                                            <div id="select2Error"  className={classes["error1"]}>
                                                {this.state.selectedType2Error}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                        {(winOption).map((val:any,key:any)=>{
                                                let checked = false;
                                                this.state.selectedType2.map((val1:any,key1:any)=>{
                                                    if(val1 == val){
                                                        checked = true
                                                        return
                                                    }
                                                })
                                                return (
                                                    <div className={"draw-res-chk-bx "+classes['box-class-top']} key={key}>
                                                        <InputBox
                                                            id={"type2_"+val}
                                                            name={"type2_"+val}
                                                            className={"drawButtonClass"}
                                                            type="checkbox"
                                                            label1={val}
                                                            value={val}
                                                            checked={checked}
                                                            onChange={() => this.handleSelectType2Function(event,val,length)}
                                                            tabIndex={tabIndex++}
                                                        />
                                                     </div>
                                                )
                                            }) }
                                        </div>
                                        <div  className="d-flex flex-wrap">
                                            {this.state.selectedType2.map((val:any,key:any)=>{
                                                return(
                                                    <div className="draw-res-chk-bx text-center" key={key}>
                                                        <div className={classes["box-class"]}>{val}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            } 
                            if(dataResult['type'] == "3"){
                                const minNumber = parseInt(dataResult['minNumber']);
                                const maxNumber = parseInt(dataResult['maxNumber']);
                                const minLength = parseInt(dataResult['minLength']);
                                const maxLength = parseInt(dataResult['maxLength']);
                                const length = parseInt(dataResult['length']);
                                const numberArray:any = [];
                                for(let i=1;i<=length;i++){
                                    numberArray.push(i.toString())
                                }
                                return(
                                    <>
                                        <div className={classes["pick-number"]}>
                                            Enter {maxLength} Digit No.
                                            <div id="select3Error"  className={classes["error1"]}>
                                                {this.state.selectedType3Error}
                                            </div>
                                        </div>
                                        <div>
                                        {(numberArray).map((val:any,key:any)=>{
                                            
                                                return (
                                                    <>
                                           <InputBox
                                                id={"type3"}
                                                name={"type3"}
                                                className={"form-control "+classes['form-control']}
                                                type="input"
                                                min={minNumber}
                                                label={"number"}
                                                max={maxNumber}
                                                autoComplete={"off"}
                                                minLength={minLength}
                                                maxLength={maxLength}
                                                value={this.state.selectedType3}
                                                required={true}
                                                onChange={() => this.handleSelectType3Function(event,minNumber,maxNumber)}
                                                onBlur={() => this.handleSelectType3Function(event,minNumber,maxNumber)}
                                                tabIndex={tabIndex++}
                                            />
                                             </>
                                                )
                                            }) }
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="draw-res-chk-bx text-center" key={key}>
                                                {this.state.selectedType3 != "" && (
                                                <div className={classes["box-class"]}>{this.state.selectedType3}</div> )}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                         })}
                         </>
                         {this.state.showSearchList && 
                         <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="update_result_form"
                                        className="form-control"
                                        type="submit"
                                        value="update"
                                        onClick={() => this.handleUpdateFunction(event)}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_update_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        tabIndex={tabIndex++}
                                        onClick={() => this.handleDrawUpdateReset()}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}
