import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/staticReports/styles.module.scss"
import { StaticKey } from "../../../game_core/type_script/static_key"
import { InputBox } from "../common/elements/inputbox"
import { UIDataService } from "../../../game_core/type_script/ui-data-service";
import { TotalRecords } from "../common/totalRecords";
import { DrawManageService } from "../../../game_core/type_script/services/drawmanagement";
import { DownlineManageServices } from "../../../game_core/type_script/services/downlinemanagement";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import { PaginationElement } from "../common/PaginationElement";
import { PrintTableElement } from "../common/PrintTableElement";
import { ExportExcelReport } from "../downlinemanagement/ExportExcelReport";

const date = new Date();
const currentDay= String(date.getDate()).padStart(2, '0');
const currentMonth = String(date.getMonth()+1).padStart(2,"0");
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`;

export class DrawWiseTransactionReport extends Component {
    drawWiseTransactionReportLang: any = StaticKey.LOCALE_DATA.drawWiseTransactionReport
    DrawManageServices = DrawManageService.getInstance()
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    gameList:any = {}
    state: any = {
        drawWiseTransactionReportData:[],
        searchFilterRow:[0,1],
        gameName:"",
        drawDate:"",
        startDate:"",
        endDate:"",
        drawId:"",
        drawDateSearch:"",
        startDateSearch:"",
        endDateSearch:"",
        fromPagePagination: 1,
        toPagePagination: 5,
        noRow: 25,
        page: 0,
        limit: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        showdrawWiseTransactionReportList:false,
        errors: {
            "gameNameError":"",
            "drawDateError":"",
            "drawIdError":"",
            "startDateError":"",
            "endDateError":"",
        },
        sheetName:"draw_wise_transaction_report",
        excelExport: false,
        mockData: [{}],
        tableHead: [
            "Sl.NO",
            "Game Name",
            "Agency Name",
            "Agency Code",
            "Dist Name",
            "Dist Code",
            "Transaction Time",
            "Tsn",
            "Qty",
            "MRP",
            "Actual Win",
            "Claim Amount",
            "Claim Time"
        ],
        tableBody: [
            "gameName",
            "companyName",
            "companyCode",
            "distributorName",
            "distributorCode",
            "transactionTime",
            "tsn",
            "qty",
            "mrp",
            "win",
            "claim",
            "claimTime"
        ],
        exportHead: [
            "Game Name",
            "Agency Name",
            "Agency Code",
            "Dist Name",
            "Dist Code",
            "Transaction Time",
            "Tsn",
            "Qty",
            "MRP",
            "Actual Win",
            "Claim Amount",
            "Claim Time"
        ],
        exportBody: [
            "gameName",
            "companyName",
            "companyCode",
            "distributorName",
            "distributorCode",
            "transactionTime",
            "tsn",
            "qty",
            "mrp",
            "win",
            "claim",
            "claimTime"
        ],
    }
    
    searchFields:any =  {
        "":[
                {
                    rowId:0,
                    id: "gameName",
                    name: "gameName",
                    label: this.drawWiseTransactionReportLang.gameName,
                    type: "select",
                    autoFocus: true,
                    required: false,
                    options:{},
                    selectShowEmpty:true,
                },
                {
                    rowId:1,
                    id: "startDate",
                    name: "startDate",
                    label: this.drawWiseTransactionReportLang.startDate,
                    type: "date",
                    autoFocus: false,
                    autoComplete: "off",
                    maxLength: 50,
                    required: true,
                    dateFormat: "dd-MM-yyyy HH:mm:ss"
                },
                {
                    rowId:1,
                    id: "endDate",
                    name: "endDate",
                    label: this.drawWiseTransactionReportLang.endDate,
                    type: "date",
                    autoFocus: false,
                    autoComplete: "off",
                    maxLength: 50,
                    required: true,
                    dateFormat: "dd-MM-yyyy HH:mm:ss"
                },
                {
                    rowId: 1,
                    name: "dateRange",
                    type: "select",
                    label: this.drawWiseTransactionReportLang.dateRange,
                    placeHolder: "Date Range",
                    maxLength: "50",
                    autoFocus: false,
                    class: "form-control",
                    defaultValue: "",
                    required: false,
                    options: StaticKey.DATERANGE,
                    selectShowEmpty: false
                }
            ]
        }
    componentDidMount(): void {
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
            this.getGameList({
                "partnerTypeId": 3
            })
            this.handleSetDateFunction();
        }
        handleSetDateFunction(){
            
            const date = new Date()
            const currentDay = String(date.getDate()).padStart(2, "0")
            const lastDay = String(date.getDate()).padStart(2, "0")
            const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
            const  lastMonth = String(date.getMonth() + 1).padStart(2, "0")
           
            const currentDateNew =  new Date(`${currentYear}-${currentMonth}-${currentDay} 00:00:00`)
            const currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
            this.setState({
                drawWiseTransactionReportData: [],
                showdrawWiseTransactionReportList: false,                
                totalCount:0,
                totalCountPagination:[],
                currentPage:1,
                previousPage:0,
                nextPage:2,
                lastPage:0,            
                fromPagePagination:1,
                toPagePagination:5,
                startDate: currentDateNew,
                startDateSearch: (
                    `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                ),
                endDate: currentEndDateNew,
                endDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                fields: {
                    ...this.state.fields,
                    startDate: currentDateNew,
                    endDate: currentEndDateNew
                }
            })
        }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getGameListDropdown(data)
        response.then((getGameListResponse: any) => {
            if (!getGameListResponse.errorStatus) {
                Object.keys(this.searchFields).map((keys: any, values: any) => {
                    this.searchFields[keys].map((fieldValObject: any,val:any) => {
                        if(fieldValObject.name == "gameName"){
                            this.searchFields[""][val].options = getGameListResponse.data
                        }
                    })
                })
            }
            this.uiData.updateShowLoading(false)
        }).catch((err: any) => {
            this.uiData.updateShowLoading(false)
        })
    }
    handleInputChange = (e:any)=>{
        e.preventDefault();
        const value = e.target.value
        if (e.target.name == "dateRange") {
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let valid = true
            const date = new Date()
            if (e.target.value == "") {                    
                this.setState({
                    ...this.state,
                    startDate: "",
                    startDateSearch: "",
                    endDate: "",
                    endDateSearch: "",
                })
            }else{
                if (e.target.value == "today") {
                    currentDay = String(date.getDate()).padStart(2, "0")
                    lastDay = String(date.getDate()).padStart(2, "0")
                    currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "yesterday") {
                    const yesterday = new Date(date)
                    yesterday.setDate(date.getDate() - 1)
                    currentDay = String(yesterday.getDate()).padStart(2, "0")
                    lastDay = String(yesterday.getDate()).padStart(2, "0")
                    currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thisWeek") {
                    const today = new Date()
                    const startOfWeek = new Date(today)
                    const endOfWeek = new Date(today)

                    startOfWeek.setDate(today.getDate() - today.getDay())
                    endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                    const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                    const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                    currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                    lastDay = String(endDate.getDate()).padStart(2, "0")
                    currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastweek") {
                    const dateL = new Date()
                    const to = new Date(
                        dateL.setTime(
                            dateL.getTime() -
                                (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                        )
                    )
                    const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                    currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                    lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                    currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastmonth") {
                    const d = new Date()
                    d.setDate(1)
                    const to = new Date(d.setHours(-1))
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(to.getDate()).padStart(2, "0")
                    currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thismonth") {
                    const d = new Date()
                    const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(lastDays.getDate()).padStart(2, "0")
                    currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                } else {
                    valid = false
                }
                let currentDateNew = null
                let currentEndDateNew = null
                if (valid) {
                    currentDateNew = new Date(
                        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                    )
                    currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                }

                this.setState({
                    ...this.state,
                    startDate: currentDateNew,
                    startDateSearch: (
                        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                    ),
                    endDate: currentEndDateNew,
                    endDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                    errors:{
                        ...this.state.errors,
                        startDateError:"",
                        endDateError:"",

                    }
                })
            }
        }else{
            this.setState({
                ...this.state,
                [e.target.name]: value,
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
    }    
    handleDateInputChange = (date:any)=>{
      //console.log(date)
        if(date != "" && date != null && date != undefined){
            this.searchFields[""][2].required=true;
            this.minDate = date
            const dates = new Date(date)
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let getHours = "00"
            let getMinutes = "00"
            let getSeconds = "00"
            if(String(dates.getHours()) != "0" ){            
                getHours = String(dates.getHours()).padStart(2, "0")
                getMinutes = String(dates.getMinutes()).padStart(2, "0")
                getSeconds = String(dates.getSeconds()).padStart(2, "0")
            }
            dates.setDate(date.getDate())
            currentDay = String(dates.getDate()).padStart(2, "0")
            lastDay = String(dates.getDate()).padStart(2, "0")
            currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
            lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
            const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
            this.setState({
                ...this.state,
                startDate: dates,
                startDateSearch:finalDate,
                errors:{
                    ...this.state.errors,
                    startDateError:""
                }
            })
        }else{
            this.setState({
                ...this.state,
                startDate: "",
                startDateSearch:"",
                errors:{
                    ...this.state.errors,
                    startDateError:"Start date is required"
                }
            })
        }        
    }
    handleEndDateChange = (date:any)=>{
        if(date != "" && date != null && date != undefined){
            this.searchFields[""][1].required=true;
            const dates = new Date(date)
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let getHours = "00"
            let getMinutes = "00"
            let getSeconds = "00"
            if(String(dates.getHours()) != "0" ){            
                getHours = String(dates.getHours()).padStart(2, "0")
                getMinutes = String(dates.getMinutes()).padStart(2, "0")
                getSeconds = String(dates.getSeconds()).padStart(2, "0")
            }
            dates.setDate(date.getDate())
            currentDay = String(dates.getDate()).padStart(2, "0")
            lastDay = String(dates.getDate()).padStart(2, "0")
            currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
            lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
            const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
            this.setState({
                ...this.state,
                endDate: dates,
                endDateSearch:finalDate,
                errors:{
                    ...this.state.errors,
                    endDateError:""
                }
            })
        }else{
            this.setState({
                ...this.state,
                endDate: "",
                endDateSearch:"",
                errors:{
                    ...this.state.errors,
                    endDateError:"End date is required"
                }
            })
        }        
    }    
    handledrawWiseTransactionReportSearchFunctionn = (e: any) => {
        e.preventDefault()
        this.handledrawWiseTransactionReportSearch("search")
    }
    handledrawWiseTransactionReportSearch=(params: any)=> {
        if (params == "search") {
            this.setState({
                ...this.state,
                page:1,
            })
            this.setState({
                drawWiseTransactionReportSearchData: [],
                showdrawWiseTransactionReportSearchListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }
        if (this.validatedrawWiseTransactionReportSearch()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                "gameId":this.state.gameName,
                "startDate":this.state.startDateSearch,
                "endDate":this.state.endDateSearch,
                page: this.state.page == 0 ? 0 : this.state.page - 1,
                length: this.state.limit
            }
          //console.log(searchCondition)
            const response = this.DownlineManageServices.getDrawWiseTransReport(searchCondition)
            response.then((searchDistributorResponse: any) => {
                if (!searchDistributorResponse.errorStatus) {
                    this.setState({
                        tBody:searchDistributorResponse.data,
                        drawWiseTransactionReportData: searchDistributorResponse.data,
                        showdrawWiseTransactionReportList: true,
                        totalCount: searchDistributorResponse.totalRecords
                    })
                    const data = []

                    for (
                        let i = 0;
                        i < searchDistributorResponse.totalRecords / this.state.noRow;
                        i++
                    ) {
                        data.push(i)
                    }
                    const divData = searchDistributorResponse.totalRecords / this.state.noRow
                    let lastpage = divData
                    if (divData.toString().includes(".")) {
                        const SplitData = divData.toString().split(".")
                        lastpage = parseInt(SplitData[0]) + 1
                    }
                    // this.setState({totalCountPagination: data, lastPage: lastpage})
                    const dataResponse = searchDistributorResponse.data
                    const obj: any = []
                    for (let i = 0; i < dataResponse.length; i++) {
                        const obj1: any = {}
                        for (let j = 0; j < this.state.exportBody.length; j++) {
                            const keyDa = this.state.exportHead[j]
                            let value: any = dataResponse[i][this.state.exportBody[j]]
                                ? dataResponse[i][this.state.exportBody[j]]
                                : "-"
                            if (this.state.exportBody[j] == "retailerStatus") {
                                value =
                                    StaticKey.RETAILERSTATUS[
                                        dataResponse[i][this.state.exportBody[j]]
                                    ].name
                            }
                            obj1[keyDa] = value
                        }
                        obj.push(obj1)
                    }
                  //console.log(obj)
                    this.setState({
                        totalCountPagination: data,
                        lastPage: lastpage,
                        mockData: obj
                    })
                  //console.log(data)
                    this.closeLoader()
                } else {
                    this.setState({
                        drawWiseTransactionReportData: [],
                        showdrawWiseTransactionReportList: false,
                        totalCount: 0,
                        totalCountPagination: [],
                        currentPage: 1,
                        previousPage: 0,
                        nextPage: 2,
                        lastPage: 0,
                        fromPagePagination: 1,
                        toPagePagination: 5
                    })
                    this.closeLoader()
                }
            }).catch((error:any)=>{
              //console.log(error)
            })
            this.uiData.updateShowLoading(false)
        }
    }
    validatedrawWiseTransactionReportSearch= () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.searchFields).map((keys: any) => {
            {
                return this.searchFields[keys].map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true

                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }
    handledrawWiseTransactionReportReset = () => {
        // const newArrayError = this.state.errors
        // const newArrayFields = this.state
        // Object.keys(this.searchFields).map((keys: any, values: any) => {
        //     {
        //         this.setState({[this.searchFields[keys].name]: ""})
        //         return this.searchFields[keys].map((fieldValObject: any, fieldValIndex: any) => {
        //             return (
        //                 (newArrayError[fieldValObject.name + "Error"] = ""),
        //                 (newArrayFields[fieldValObject.name] = ""),
        //                 (newArrayFields[fieldValObject.name + "Val"] = "")
        //             )
        //         })
        //     }
        // })
        // this.searchFields[""][1].required=false;
        // this.searchFields[""][2].required=false;
        // this.setState({errors: newArrayError, fields: newArrayFields,
        //     loginId: "",
        //     username: "",
        //     accountStatus:"",
        //     startDateSearch:"",
        //     endDateSearch:"",
        //     totalCount: 0,
        //     totalCountPagination: [],
        //     currentPage: 1,
        //     previousPage: 0,
        //     nextPage: 2,
        //     lastPage: 0,
        //     fromPagePagination: 1,
        //     toPagePagination: 5
        // })
        this.handleSetDateFunction();
    }
    
    handlePagination=(e: any, page: any)=> {
      //console.log(page); 
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            page: page,
        })
        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        setTimeout(() => {
            this.handledrawWiseTransactionReportSearch("pagination")
        }, 50)
    }    
    closeLoader() {
        this.uiData.updateShowLoading(false)
    }
    handlePrint = () => {
        const divContents: any = document.getElementById("printTable")?.innerHTML
        const a: any = window.open("", "", "height:700px,width:700px")
        a.document.write("<html>")
        a.document.write(divContents)
        a.document.write("</body></html>")
        a.document.close()
        a.print()
        setTimeout(function () { a.close(); }, 200); 
        
    }
    
    handleExportExcel = (e: any) => {
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport: true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport: false
            })
        }, 100)
    }
    
    render() {
        return(
            <>
                <div className={classes["right-section"]}>
                    <div className={classes["main-div"]}>
                        <h2>{this.drawWiseTransactionReportLang.pageTitle}</h2>
                        <form>
                            {Object.keys(this.searchFields).map((keys: any, values: any) => {
                                    return (
                                        <div key={values}>
                                            {Object.keys(this.state.searchFilterRow).map((valRow: any, i: any) => {
                                                return (
                                                    <>
                                                        <div className={classes["main-div-input-sec"]} key={i}>
                                                        {this.searchFields[keys].map((value: any, key: any) => {
                                                            if (value.rowId == valRow) {
                                                                return (
                                                                    <div className={classes["sub-div"]} key={key}>
                                                                        <label htmlFor="name">{value.label}</label>
                                                                        {value.required && (                                                                            
                                                                            <span className={classes["mandatory-symbol"]}>
                                                                                * &nbsp;
                                                                            </span>
                                                                        )}
                                                                        <InputBox
                                                                            id={value.id}
                                                                            autoComplete={value.autoComplete}
                                                                            maxLength={value.maxLength}
                                                                            className={
                                                                                "form-control " + classes["form-control"]
                                                                            }
                                                                            autoFocus={value.autoFocus}
                                                                            name={value.name}
                                                                            type={value.type}
                                                                            value={this.state[value.name]}
                                                                            options={value.options}
                                                                            selectShowEmpty={value.selectShowEmpty} 
                                                                            onChange={
                                                                                value.type === "date"
                                                                                ? value.name == "startDate"
                                                                                    ? this.handleDateInputChange
                                                                                    : this.handleEndDateChange
                                                                                        : this.handleInputChange
                                                                            }
                                                                            dateFormat={value.dateFormat}
                                                                            startMaxDate={this.maxDate}
                                                                            endMinDate={this.minDate}
                                                                        />
                                                                        <div className={classes["error"]}>
                                                                            {this.state.errors[value.name + "Error"]}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}                        
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        )
                                    }
                            )}
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Search"
                                        onClick={() => this.handledrawWiseTransactionReportSearchFunctionn(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        onClick={() => this.handledrawWiseTransactionReportReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                {this.state.showdrawWiseTransactionReportList && (
                    <>
                         <div className="d-flex">
                        <TotalRecords totalCount={this.state.totalCount}/>
                        {this.state.totalCount > 0 ? (
                            <>
                                <input
                                    type="button"
                                    className={"btn btn-primary " + classes["printBtn"]}
                                    value="Print"
                                    onClick={this.handlePrint}
                                />
                                <input
                                    type="button"
                                    className={"btn btn-primary " + classes["printBtn"]}
                                    value="Export Excel"
                                    onClick={this.handleExportExcel}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        </div>
                        <div className={classes["main-div"] + " " + classes["table-main-div"]} style={{maxHeight: "400px"}}>
                            <Table className={"table table-bordered table-hover table-condensed" +
                                    classes["table"] +
                                    " " +
                                    classes["table-bordered"] +
                                    " " +
                                    classes["table-hover"] +
                                    " " +
                                    classes["table-condensed"]
                                } id="provider_table_id" >
                                <Thead>
                                    <Tr>
                                        <Th style={{zIndex:0}}>Sl.No</Th>
                                        <Th style={{zIndex:0}}>Game Name</Th>
                                        <Th style={{zIndex:0}}>Agency Name</Th>
                                        <Th style={{zIndex:0}}>Agency Code</Th>
                                        <Th style={{zIndex:0}}>Dist Name</Th>
                                        <Th style={{zIndex:0}}>Dist Code</Th>
                                        <Th style={{zIndex:0}}>Transaction Time</Th>
                                        <Th style={{zIndex:0}}>Tsn</Th>
                                        <Th style={{zIndex:0}}>Qty</Th>
                                        <Th style={{zIndex:0}}>MRP</Th>
                                        <Th style={{zIndex:0}}>Actual Win</Th>
                                        <Th style={{zIndex:0}}>Claim Amount</Th>
                                        <Th style={{zIndex:0}}>Claim Time</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                   {this.state.drawWiseTransactionReportData.length > 0 ? (
                                        <>
                                            {this.state.drawWiseTransactionReportData.map(
                                                (val: any, ind: any) => {  let status = "Active"
                                                if (val.ACCOUNT_STATUS == 0) {
                                                    status = "Inactive"
                                                }
                                                let oddEven = "odd"
                                                if (ind % 2 == 0) {
                                                    oddEven = "even"
                                                }
                                                return (
                                                    <Tr
                                                        className={classes["tr_" + oddEven]}
                                                        key={ind}
                                                    >
                                                        <Td>
                                                            {(this.state.currentPage - 1) *
                                                                this.state.noRow +
                                                                ind +
                                                                1}
                                                        </Td>
                                                        <Td>{val.gameName ? val.gameName : "-"}</Td>
                                                        <Td>{val.companyName ? val.companyName : "-"}</Td>
                                                        <Td>{val.companyCode ? val.companyCode : "-"}</Td>
                                                        <Td>{val.distributorName ? val.distributorName : "-"}</Td>
                                                        <Td>{val.distributorCode ? val.distributorCode : "-"}</Td>
                                                        <Td>{val.transactionTime ? val.transactionTime : "-"}</Td>
                                                        <Td>{val.tsn ? val.tsn : "-"}</Td>
                                                        <Td>{val.qty ? val.qty : "-"}</Td>
                                                        <Td>{val.mrp ? val.mrp : "-"}</Td>
                                                        <Td>{val.win ? val.win : "-"}</Td>
                                                        <Td>{val.claim ? val.claim : "-"}</Td>
                                                        <Td>{val.claimTime ? val.claimTime : "-"}</Td>
                                                    </Tr>
                                                )}
                                                )}
                                            </>
                                        ) : (
                                            <Tr className={classes["no_data_found"]}>
                                                <Td colSpan={15}>No record(s) available</Td>
                                            </Tr>
                                        )}
                                </Tbody>
                            </Table>
                        </div>     
                        {this.state.totalCountPagination.length > 0 ? (
                                <div className={"d-none"} id={"printTable"}>
                                    <PrintTableElement
                                        tableData={this.state.drawWiseTransactionReportData}
                                        currentPage={this.state.currentPage}
                                        noRow={this.state.noRow}
                                        tHead={this.state.tableHead}
                                        tBody={this.state.tableBody}
                                    />
                                    {this.state.excelExport && (
                                        <ExportExcelReport
                                            mockData={this.state.mockData}
                                            sheetName={this.state.sheetName}
                                        />
                                    )}
                                </div>
                            ) : (
                                ""
                            )}  
                        {this.state.totalCountPagination.length > 0 ? (                 
                            <PaginationElement                 
                                fromPagePagination = {this.state.fromPagePagination}
                                toPagePagination = {this.state.toPagePagination}
                                noRow = {this.state.noRow}
                                totalCount = {this.state.totalCount}
                                totalCountPagination = {this.state.totalCountPagination}
                                currentPage = {this.state.currentPage}
                                previousPage = {this.state.previousPage}
                                nextPage = {this.state.nextPage}
                                lastPage = {this.state.lastPage}
                                handlePagination= {this.handlePagination}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
                </div>
            </>

        )
    }
}