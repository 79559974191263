import React, {Component} from "react"
import classes from "../../../configuration/style/base/screens/limitcontrol/partnerlimitcontrol.module.scss"
import {GameHistoryService} from "../../../../game_core/type_script/services/gamehistory"
import {UIDataService} from "../../../../game_core/type_script/ui-data-service"
import {InputBox} from "../../common/elements/inputbox"
import {Link} from "react-router-dom"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import { StaticKey } from "../../../../game_core/type_script/static_key"
import { PrintTableElement } from "../../common/PrintTableElement"
import { ExportExcelReport } from "../../downlinemanagement/ExportExcelReport"
import { DataStorage } from "../../../../game_core/type_script/services/localstorage"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`

export class GameHistory extends Component {
    GameHistoryServices = GameHistoryService.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    itemOffset = 0
    minDate: any = new Date()
    maxDate: any = new Date()
    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Retailer Name","Retailer Code","Total Bet","Total Win","Total Refund","Win/Loss"],
        tableBody:["retailerName","username","betAmount_toFixed","winAmount_toFixed","refundAmount_toFixed","winLossAmount_toFixed"],
        exportHead:["Retailer Name","Retailer Code","Total Bet","Total Win","Total Refund","Win/Loss"],
        exportBody:["retailerName","username","betAmount_toFixed","winAmount_toFixed","refundAmount_toFixed","winLossAmount_toFixed"],
        searchFilterRow: [0, 1],
        fromPagePagination: 1,
        toPagePagination: 5,
        noRow: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        username: "",
        fromDate: "",
        toDate: "",
        fromDateSearch: "",
        toDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        processedBy: "",
        transactionId: "",
        toDateError: "",
        showGameHistorySearchListing: false,
        fields: {
            "toDate": "",
            "fromDate": "",
            "username": "",
            "gameName": "",
            "gameReferenceNo": "",
            "limit": 25,
            "page": 0
        },
        errors: {
            "toDateError": "",
            "fromDateError": "",
            "usernameError": ""
        },
        GameHistorySearchData: [],
        GameHistorySearchRecordData: []
    }

    createGameHistorySearchFields: any = {
        "": [
            {
                rowId: 0,
                name: "username",
                type: "text",
                label: "Playername",
                placeHolder: "Username",
                maxLength: "50",
                autoFocus: true,
                class: "form-control",
                required: false,
                style: {},
                autoComplete: "off"
            },
            {
                rowId: 0,
                "name": "gameName",
                "id": "gameName",
                "type": "select",
                "label": "Game Name",
                "placeHolder": "Game",
                "autoFocus": false,
                "class": "form-control",
                "options": {},
                "selectShowEmpty": true,
                "getDIstributorInfo": true
            },
            {
                rowId: 0,
                "name": "gameReferenceNo",
                "id": "gameReferenceNo",
                "type": "text",
                "label": "Game Referrence No.",
                "placeHolder": "Game Referrence No.",
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                rowId: 1,
                "name": "fromDate",
                "type": "datetime",
                "label": "Start Date",
                "placeHolder": "Start Date",
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"      
            },
            {
                rowId: 1,
                "name": "toDate",
                "id": "toDate",
                "type": "datetime",
                "label": "End Date",
                "placeHolder": "End Date",
                "maxLength": currentDate,
                // "disabled": true,
                "autoFocus": false,
                "class": "form-control",
                "required": false,
                "dateFormat": "dd-MM-yyyy HH:mm:ss"
            },
            {
                rowId:1,
                name: "dateRange",
                type: "select",
                label: "Date Range",
                placeHolder: "Date Range",
                maxLength: "50",
                autoFocus: false,
                class: "form-control",
                defaultValue: "",
                required: false,
                options: StaticKey.DATERANGE,
                // showEmpty: true
            }
        ]
    }

    endOffset = this.itemOffset + 5
    currentItems = this.state.totalCountPagination.slice(this.itemOffset, this.endOffset)
    pageCount = Math.ceil(this.state.totalCountPagination.length / 5)

    handlePageClick(event: any) {
        const newOffset = (event.selected * this.state.noRow) % this.items.length
        this.itemOffset = newOffset
      //console.log(event)
    }
    componentDidMount(): void {
        this.getGameList({
            "partnerTypeId": 3
        })
        this.handleSetDateFunction();
    }
    handleSetDateFunction(){
        
        const date = new Date()
        const currentDay = String(date.getDate()).padStart(2, "0")
        const lastDay = String(date.getDate()).padStart(2, "0")
        const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        const  lastMonth = String(date.getMonth() + 1).padStart(2, "0")
       
        const currentDateNew =  new Date(`${currentYear}-${currentMonth}-${currentDay} 00:00:00`)
        const currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
        this.setState({
            GameHistorySearchData: [],
            GameHistorySearchRecordData: [],  
            showGameHistorySearchListing: false,                   
            totalCount:0,
            totalCountPagination:[],
            currentPage:1,
            previousPage:0,
            nextPage:2,
            lastPage:0,            
            fromPagePagination:1,
            toPagePagination:5,
            fromDate: currentDateNew,
            fromDateSearch: (
                `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
            ),
            toDate: currentEndDateNew,
            toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
            fields: {
                ...this.state.fields,
                fromDate: currentDateNew,
                toDate: currentEndDateNew
            }
        })
    }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.GameHistoryServices.getGameListDropdown(data)
        response
            .then((getGameListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getGameListResponse.errorStatus) {
                    Object.keys(this.createGameHistorySearchFields).map((keys: any, values: any) => {
                        {
                            this.createGameHistorySearchFields[keys].map((fieldValObject: any,val:any) => {
                                if(fieldValObject.name == "gameName"){
                                    this.createGameHistorySearchFields[""][val].options = getGameListResponse.data
                                }
                            })
                        }})
                    
                    this.setState({companyList: getGameListResponse.data})
                }
            })
            .catch((err: any) => {
                this.uiData.updateShowLoading(false)
            })
    }

    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
        this.setState({
            fromDate: date,
            fromDateSearch:finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if(String(dates.getHours()) != "0" ){            
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);

        this.setState({
            toDate: eDate,
            toDateSearch:finalDate
        })
    }
    handleSearchGameHistorySearchReset = () => {
        this.setState({
            // username: "",
            // accountStatus: "",
            GameHistorySearchData: [],
            GameHistorySearchRecordData: [],
            showGameHistorySearchListing: false
        })
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.createGameHistorySearchFields).map((keys: any, values: any) => {
            {
                return this.createGameHistorySearchFields[keys].map((fieldValObject: any) => {
                    this.setState({[fieldValObject.name]: ""})
                    this.createGameHistorySearchFields[""][3].required = false
                    this.createGameHistorySearchFields[""][3].min = ""
                    document.getElementById("toDate")?.setAttribute("readonly", "readonly")
                    if (Object.keys(fieldValObject).length == 1) {
                        return Object.keys(fieldValObject).map((keys1: any) => {
                            fieldValObject[keys1].map((fieldValObject1: any) => {
                                return (
                                    (newArrayError[fieldValObject1.name + "Error"] = ""),
                                    (newArrayFields[fieldValObject1.name] = ""),
                                    (newArrayFields[fieldValObject1.name + "Val"] = "")
                                )
                            })
                        })
                    } else {
                        return (
                            (newArrayError[fieldValObject.name + "Error"] = ""),
                            (newArrayFields[fieldValObject.name] = ""),
                            (newArrayFields[fieldValObject.name + "Val"] = "")
                        )
                    }
                })
            }
        })
        this.setState({errors: newArrayError, fields: newArrayFields,
            ...this.state,
            GameHistorySearchData: [],
            GameHistorySearchRecordData: [],
            showGameHistorySearchListing: false,
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 0,
            lastPage: 0
        })
        this.handleSetDateFunction();   
    }
    validateGameHistorySearchForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.createGameHistorySearchFields[""]).map((keys: any) => {
            {
                return this.createGameHistorySearchFields[""].map((fieldValObject: any) => {
                    const errorData = this.uiData.textConversion(fieldValObject.label)
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    gameHistorySearch(params:any) {
        if (params == "search") {
            this.setState({
                ...this.state,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
        }
        if (this.validateGameHistorySearchForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                username: this.state.fields.username,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                gameName: this.state.fields.gameName,
                gameReferenceNo: this.state.fields.gameReferenceNo,
                page: this.state.fields.page == 0 ? this.state.fields.page :this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            }

            const response = this.GameHistoryServices.getGameHistorySearch(searchCondition)
            response
                .then((searchGameHistorySearchResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!searchGameHistorySearchResponse.errorStatus) {
                        this.setState({
                            GameHistorySearchData: searchGameHistorySearchResponse.data,
                            GameHistorySearchRecordData:
                                searchGameHistorySearchResponse.totalRecords,
                            showGameHistorySearchListing: true,
                            totalCount: searchGameHistorySearchResponse.totalCount
                        })
                        const data = []
                        for (
                            let i = 0;
                            i < searchGameHistorySearchResponse.totalCount / this.state.noRow;
                            i++
                        ) {
                            data.push(i)
                        }
                        const divData =
                            searchGameHistorySearchResponse.totalCount / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        }
                        const dataResponse = searchGameHistorySearchResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                let value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                if(this.state.exportBody[j].includes("_toFixed")){
                                    const dataValue = this.state.exportBody[j].replace("_toFixed","")
                                    value = dataResponse[i][dataValue] ? parseFloat(dataResponse[i] [dataValue]).toFixed(2):"0.00"
                                }
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})                     

                        this.currentItems = data.slice(this.itemOffset, this.endOffset)
                        this.pageCount = Math.ceil(data.length / 5)
                      //console.log(this.pageCount)
                    } else {
                        this.setState({
                            GameHistorySearchData: [],
                            GameHistorySearchRecordData: [],
                            showGameHistorySearchListing: false,
                            totalCount: 0,
                            totalCountPagination: [],
                            lastPage: 0
                        })
                    }
                })
                .catch((err: any) => {
                  //console.log("handleGameHistorySearchError", err)
                    this.uiData.updateShowLoading(false)
                    this.setState({
                        GameHistorySearchData: [],
                        GameHistorySearchRecordData: [],
                        showGameHistorySearchListing: false,
                        totalCount: 0,
                        totalCountPagination: [],
                        lastPage: 0
                    })
                })
        }
    }
    handleGameHistorySearch = (e: any) => {
        e.preventDefault()
        this.gameHistorySearch("search")
    }

    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })
        setTimeout(() => {
            this.gameHistorySearch("pagination")
        }, 50)
    }
    retailerLimitControlFilterChange = (e: any) => {
        const value = e.target.value
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            }
        })
        this.setState({[e.target.name]: value})
        if (this.state[e.target.name + "Callback"]) {
            if (e.target.name == "fromDate") {
                this.setState({toDate: ""})
                this.createGameHistorySearchFields[""][4].min = value
                this.createGameHistorySearchFields[""][4].type = "datetime-local"
                // this.createGameHistorySearchFields[""][4].required = true
                document.getElementById("toDate")?.removeAttribute("readonly")
            }
            
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
    }

    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
         a.print();
 setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    handleSetStorage(paramUsername:any){
        this.uiData.setRetailerBackList(true)
        const storage:any = [
            paramUsername,
            this.state.toDateSearch,
             this.state.fromDateSearch,
             this.state.fields.gameName,
             this.state.fields.gameReferenceNo,
             this.state.dateRange
        ]
        const string = JSON.stringify(storage)
        this.localStorageService.removeSearchConditionLocalStorage();
        this.localStorageService.setSearchConditionLocalStorage(string);
    }
    render() {
        return (
            <div className={classes["right-section"]}>
                <div className="error-msg-section"></div>
                <div className={classes["main-div"]}>
                    <h2>Game History</h2>
                    <form>
                        {/* <div className={classes["main-div-input-sec"]} > */}
                        {Object.keys(this.createGameHistorySearchFields).map(
                            (keys: any, values: any) => {
                                return (
                                    <div key={values}>
                                        <h5>{keys}</h5>
                                        {this.state.searchFilterRow.map((valRow: any, i: any) => {
                                            return (
                                                <div
                                                    className={classes["main-div-input-sec"]}
                                                    key={i}
                                                >
                                                    {this.createGameHistorySearchFields[keys].map(
                                                        (val: any, ind: any) => {
                                                            if (val.rowId == valRow) {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            classes["sub-div"]
                                                                        }
                                                                        key={ind}
                                                                    >
                                                                        <label htmlFor="name">
                                                                            {val.label}
                                                                        </label>
                                                                        {val.required ? (
                                                                            <span
                                                                                className={
                                                                                    classes[
                                                                                        "mandatory-symbol"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                *{" "}
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <InputBox
                                                                            id={val.id}
                                                                            autoComplete={
                                                                                val.autoComplete
                                                                            }
                                                                            maxLength={
                                                                                val.maxLength
                                                                            }
                                                                            className={
                                                                                "form-control " +
                                                                                classes[val.class]
                                                                            }
                                                                            autoFocus={
                                                                                val.autoFocus
                                                                            }
                                                                            name={val.name}
                                                                            type={val.type}
                                                                            tabIndex={1}
                                                                            value={
                                                                                this.state[val.name]
                                                                            }
                                                                            required={val.required}
                                                                            // placeholder={
                                                                            //     val.placeHolder
                                                                            // }
                                                                            min={val.min}
                                                                            options={val.options}
                                                                            selectShowEmpty={
                                                                                val.selectShowEmpty
                                                                            }
                                                                            onChange={
                                                                                val.type === "datetime"
                                                                                    ? val.name == "fromDate"
                                                                                        ? this.handleStartDateChange
                                                                                        : this.handleEndDateChange
                                                                                    : this.retailerLimitControlFilterChange
                                                                            }
                                                                            dateFormat={val.dateFormat}
                                                                            startMaxDate={this.maxDate}
                                                                            endMinDate={this.minDate}
                                                                        />
                                                                        <div
                                                                            className={
                                                                                classes["error"]
                                                                            }
                                                                        >
                                                                            {
                                                                                this.state.errors[
                                                                                    val.name +
                                                                                        "Error"
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        )}
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="submit"
                                    value="Search"
                                    onClick={() => this.handleGameHistorySearch(event)}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="reset"
                                    value="Reset"
                                    onClick={() => this.handleSearchGameHistorySearchReset()}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {this.state.showGameHistorySearchListing && (
                    <>
                        <div className="d-flex">
                            <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Records
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.GameHistorySearchRecordData !="" && this.state.GameHistorySearchRecordData["totalCount"]  ? this.state.GameHistorySearchRecordData["totalCount"] : "0"}
                                    </span>
                                </div>
                            </div>
                            <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Bet
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.GameHistorySearchRecordData !="" && this.state.GameHistorySearchRecordData["totalBet"] 
                                            ? parseFloat(
                                                  this.state.GameHistorySearchRecordData["totalBet"]
                                              ).toFixed(2)
                                            : "0.00"}
                                    </span>
                                </div>
                            </div>
                            <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Win
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.GameHistorySearchRecordData !="" &&this.state.GameHistorySearchRecordData["totalWin"] 
                                            ? parseFloat(
                                                  this.state.GameHistorySearchRecordData["totalWin"]
                                              ).toFixed(2)
                                            : "0.00"}
                                    </span>
                                </div>
                            </div>
                            <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Refund
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.GameHistorySearchRecordData !="" && this.state.GameHistorySearchRecordData["totalRefund"]  
                                            ? parseFloat(
                                                  this.state.GameHistorySearchRecordData[
                                                      "totalRefund"
                                                  ]
                                              ).toFixed(2)
                                            : "0.00"}
                                    </span>
                                </div>
                            </div>
                            
                             {this.state.totalCount > 0 ? (
                                <>
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                </>
                            ):""}
                        </div>
                        <div
                            className={
                                "table-responsive " +
                                classes["main-div"] +
                                " " +
                                classes["table-main-div"]
                            } style={{maxHeight:"400px"}}
                        >
                            <Table
                                className={
                                     classes["text-whiteSpace"] + " "+"table table-bordered table-hover table-condensed" +
                                    classes["table"] +
                                    " " +
                                    classes["table-bordered"] +
                                    " " +
                                    classes["table-hover"] +
                                    " " +
                                    classes["table-condensed"]
                                }
                                id="provider_table_id"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Retailer Code</Th>
                                        <Th>Retailer Name</Th>
                                        <Th>Total Bet</Th>
                                        <Th>Total Win</Th>
                                        <Th>Total Refund</Th>
                                        <Th>Win/Loss</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.GameHistorySearchData.length > 0 ? (
                                        <>
                                            {this.state.GameHistorySearchData.map(
                                                (val: any, ind: any) => {
                                                    let oddEven = "odd"
                                                    if (ind % 2 == 0) {
                                                        oddEven = "even"
                                                    }
                                                    return (
                                                        <Tr
                                                            className={classes["tr_" + oddEven]}
                                                            key={ind}
                                                        >
                                                            <Td>
                                                            <Link onClick={()=>{this.handleSetStorage(val.username)}}to={"/game-history-player.html"} >{val.username} </Link>
                                                            </Td>
                                                            <Td> {val.retailerName ? val.retailerName : "-" }</Td>
                                                            <Td className={classes["text-right"]}>
                                                                <div>
                                                                    {" "}
                                                                    {val.betAmount != ""
                                                                        ? parseFloat(
                                                                              val.betAmount
                                                                          ).toFixed(2)
                                                                        : "0.00"}
                                                                </div>
                                                            </Td>
                                                            <Td className={classes["text-right"]}>
                                                                <div>
                                                                    {" "}
                                                                    {val.winAmount != ""
                                                                        ? parseFloat(
                                                                              val.winAmount
                                                                          ).toFixed(2)
                                                                        : "0.00"}
                                                                </div>
                                                            </Td>
                                                            <Td className={classes["text-right"]}>
                                                                <div>
                                                                    {val.refundAmount != ""
                                                                        ? parseFloat(
                                                                              val.refundAmount
                                                                          ).toFixed(2)
                                                                        : "0.00"}
                                                                </div>
                                                            </Td>
                                                            <Td className={classes["text-right"]}>
                                                                <div>
                                                                    {" "}
                                                                    {val.winLossAmount != ""
                                                                        ? parseFloat(
                                                                              val.winLossAmount
                                                                          ).toFixed(2)
                                                                        : "0.00"}
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                    )
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <Tr className={classes["no_data_found"]}>
                                            <Td colSpan={11}>No record(s) available</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                            
                        </div>           
                        {this.state.totalCountPagination.length > 0 ? (             
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.GameHistorySearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/>
                                {this.state.excelExport && (
                                    <ExportExcelReport mockData={this.state.mockData} sheetName="game_history"/> 
                                )}
                            </div>
                        ):""}
                        
                        <div className={"table_pagination_div"}>                    
                        {this.state.totalCountPagination.length > 0 ?  
                            <> 
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == 1? true : false} onClick={(event)=>this.handlePagination(event,1)}>First</button>
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == 1? true : false} onClick={(event)=>this.handlePagination(event,this.state.previousPage)}><i className="fa fa-angle-double-left"></i> Previous</button>
                                {this.state.totalCountPagination.map((val: any, ind: any) => {
                                    let btnClass = "";
                                    if(ind+1 >= this.state.fromPagePagination && ind+1 <= this.state.toPagePagination){
                                        if(this.state.currentPage == ind+1){
                                            btnClass = "active";
                                            }
                                        return(
                                            <>
                                                <button className={classes['buttonClass'] + " " +classes[btnClass]} onClick={(event)=>this.handlePagination(event,ind+1)}>{ind+1}</button>
                                            </>
                                        )
                                    }

                                })}
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == this.state.lastPage? true : false} onClick={(event)=>this.handlePagination(event,this.state.nextPage)}>Next <i className="fa fa-angle-double-right"></i></button>
                                <button className={classes['buttonClass']} disabled={this.state.currentPage == this.state.lastPage? true : false} onClick={(event)=>this.handlePagination(event,this.state.lastPage)}>Last</button>
                            </> :
                                <></>
                        }
                        </div>
                    </>
                )}
            </div>
        )
    }
}
