import React from "react"
import {Component} from "react"
import {Navbar} from "../../../customer_gl/screens/common/navbar"
import {SideBar} from "../common/sidebar"
import {Dashboard} from "../dashboard/"
import {ChangePassword} from "../changepassword"
import {CreateCompany} from "../downlinemanagement/createcompany"
import {CreateDraw} from "../drawmanagement/createDraw"
import {DrawListing} from "../drawmanagement/drawlisting"
import {CreateDistributor} from "../downlinemanagement/createdistributor"
import {CreateRetailer} from "../downlinemanagement/createretailer"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {CompanyListing} from "../downlinemanagement/companylisting"
import {Route, Routes} from "react-router-dom"
import {DistributorListing} from "../downlinemanagement/distributorlisting"
import {RetailerListing} from "../downlinemanagement/retailerlisting"
import {PartnerLimitControl} from "../limitcontrol/partnerlimitcontrol"
import {RetailerLimitControl} from "../limitcontrol/retailerlimitcontrol"
import {SetPartnerLimit} from "../limitcontrol/setpartnerlimit"
import {SetRetailerLimit} from "../limitcontrol/setretailerlimit"
import {LimitDetails} from "../myaccount/ledger/limit-details"
import {Ledger} from "../myaccount/ledger"
import {ViewCompany} from "../downlinemanagement/viewCompany"
import {ViewDistributor} from "../downlinemanagement/viewDistributor"
import {ViewRetailer} from "../downlinemanagement/ViewRetailer"
import {DrawResult} from "../drawmanagement/drawResult"
import {GameHistory} from "../myaccount/gamehistory"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {SetCompanyLimit} from "../limitcontrol/setcompanylimit"
import {CompanyLimitControl} from "../limitcontrol/companylimitcontrol"
import {Forcechangepassword} from "../forcechangepassword"
import {DataStorage} from "../../../game_core/type_script/services/localstorage"
import { DrawResultUpdate } from "../drawmanagement/drawResultUpdate"
import { DrawResultApprove } from "../drawmanagement/drawResultApprove"
import { EditDistributor } from "../downlinemanagement/EditDistributor"
import { UserReport } from "../downlinemanagement/userReport"
import { PrizeReport } from "../staticReports/prizeReport"
import { DrawWiseReport } from "../staticReports/drawWiseReport"
import { DrawWiseTransactionReport } from "../staticReports/DrawWiseTransactionReport"
import { DrawWiseCancellationReport } from "../staticReports/DrawWiseCancellationReport"
import { DayWiseTransactionReport } from "../staticReports/DayWiseTransactionReport"
import { ViewLiveTerminal } from "../dashboard/viewLiveTerminal"
import { SetLimit } from "../limitcontrol/setLimit"
import { CustomerList } from "../downlinemanagement/customerList"
import { Invoice } from "../downlinemanagement/invoice"
import { SalesPurchaseReport } from "../downlinemanagement/salesPurchaseReport"
import { GameHistoryPlayer } from "../myaccount/gamehistory/gamehistoryplayer"
export class Home extends Component {
    uiData: UIDataService = UIDataService.getInstance()
    dataService = new DataStorage()
    partnerTypeId: any = ""
    state = {
        clickedMenu: "dashboard",
        hideSidebar: false
    }
    componentDidMount() {
        if (this.uiData.getPasswordUpdatedStatus() == 1) {
            this.setState({hideSidebar: false})
        } else {
            this.setState({hideSidebar: true})
        }
        document.title = "Smart Lotto"
        this.uiData.on("emitMenuChangeEvent", this.handleMenuChange)
        this.partnerTypeId = parseInt(this.uiData.getLoginPartnerType())
    }
    componentWillUnmount() {
        this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
    handleMenuChange = () => {
        this.setState({clickedMenu: this.uiData.getClickedMenu()})
    }
    handleclickfun = (e: any) => {
        if (e) {
            const className = e.target.className
            if (className != "" && className != undefined && !className.includes("accordion")) {
                const width = window.innerWidth
                if (width <= 990) {
                    const allWithClass = Array.from(document.querySelectorAll(".sidebar_class"))
                    const classN = allWithClass[0].className
                    const myArray = classN.split(" ")
                    for (let i = 0; i < myArray.length; i++) {
                        if (myArray[i].includes("open")) {
                            this.uiData.toggleSideBar(false)
                        }
                    }
                }
            }
        }
    }
    render() {
        const NotFound = () => {
            localStorage.setItem("menu", "Dashboard")
            this.dataService.setCurrentClickedMenuGroup("0")
            return (
                <div style={{padding: "17px"}}>
                    <h1>{StaticKey.LOCALE_DATA.Home.notFoundTitle}</h1>
                    <p>{StaticKey.LOCALE_DATA.Home.notFoundContent}</p>
                    <a href="/">{StaticKey.LOCALE_DATA.Home.clickToHome}</a>
                </div>
            )
        }
        return (
            <div>
                {!this.state.hideSidebar ? (
                    <div>
                        <Navbar />
                        <div
                            className="main-body full-width-menu d-flex"
                            onClick={() => this.handleclickfun(event)}
                        >
                            <SideBar selectedMenu={this.dataService.getCurrentClickedMenuGroup()} />
                            {/* <div className="w-100" onClick={() => this.handleclickfun()}> */}
                            <Routes>
                                <Route path="*" element={<NotFound />} />
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/change-password.html" element={<ChangePassword />} />
                                {this.partnerTypeId == 0 && (
                                    <>
                                        <Route
                                            path="/create-company.html"
                                            element={<CreateCompany />}
                                        />
                                        <Route
                                            path="/list-company.html"
                                            element={<CompanyListing />}
                                        />
                                        <Route
                                            path="/list-distributor.html"
                                            element={<DistributorListing />}
                                        />
                                        <Route
                                            path="/list-retailer.html"
                                            element={<RetailerListing />}
                                        />
                                        <Route
                                            path="/partner-limit-settings.html"
                                            element={<PartnerLimitControl />}
                                        />
                                        <Route
                                            path="/company-limit-settings.html"
                                            element={<CompanyLimitControl />}
                                        />
                                        <Route
                                            path="/retailer-limit-settings.html"
                                            element={<RetailerLimitControl />}
                                        />
                                        <Route
                                            path="/set-limit.html"
                                            element={<SetPartnerLimit />}
                                        />
                                        <Route
                                            path="/set-company-limit.html"
                                            element={<SetCompanyLimit />}
                                        />
                                        <Route
                                            path="/view-company.html"
                                            element={<ViewCompany />}
                                        />
                                        <Route
                                            path="/view-distributor.html"
                                            element={<ViewDistributor />}
                                        />
                                        <Route
                                            path="/edit-distributor.html"
                                            element={<EditDistributor />}
                                        />
                                        <Route
                                            path="/view-retailer.html"
                                            element={<ViewRetailer />}
                                        />
                                        <Route
                                            path="/user-report.html"
                                            element={<UserReport />}
                                        />
                                        <Route
                                            path="/prize-report.html"
                                            element={<PrizeReport />}
                                        />
                                        <Route
                                            path="/draw-wise-report.html"
                                            element={<DrawWiseReport />}
                                        />
                                        <Route
                                            path="/draw-wise-transaction-report.html"
                                            element={<DrawWiseTransactionReport />}
                                        />
                                        <Route
                                            path="/draw-wise-cancellation-report.html"
                                            element={<DrawWiseCancellationReport />}
                                        />
                                        <Route
                                            path="/day-wise-transaction-report.html"
                                            element={<DayWiseTransactionReport />}
                                        />
                                        <Route
                                            path="/set-retailer-limit.html"
                                            element={<SetRetailerLimit />}
                                        />
                                        <Route path="/create-draw.html" element={<CreateDraw />} />
                                        <Route
                                            path="/draw-listing.html"
                                            element={<DrawListing />}
                                        />
                                        <Route
                                            path="/game-history.html"
                                            element={<GameHistory />}
                                        />
                                        <Route
                                            path="/game-history-player.html"
                                            element={<GameHistoryPlayer />}
                                        />
                                        <Route
                                            path="/invoice.html"
                                            element={<Invoice />}
                                        />
                                        <Route
                                            path="/salesPurchaseReport.html"
                                            element={<SalesPurchaseReport />}
                                        />
                                        <Route path="/draw-result-update.html" element={<DrawResultUpdate />} />
                                        <Route path="/draw-result-approve.html" element={<DrawResultApprove />} />
                                        <Route path="/draw-result.html" element={<DrawResult />} />
                                        <Route path="/limit-details.html" element={<LimitDetails />} />
                                        <Route path="/ledger.html" element={<Ledger />} />
                                        <Route path="/view-live-terminal.html" element={<ViewLiveTerminal />} />
                                        <Route path="/set-limit-control.html" element={<SetLimit />} />
                                        <Route path="/customer-list.html" element={<CustomerList />} />
                                    </>
                                )}
                                {this.partnerTypeId <= 3 && (
                                    <>
                                        <Route
                                            path="/create-distributor.html"
                                            element={<CreateDistributor />}
                                        />
                                        <Route
                                            path="/list-distributor.html"
                                            element={<DistributorListing />}
                                        />
                                        <Route
                                            path="/view-distributor.html"
                                            element={<ViewDistributor />}
                                        />
                                        <Route
                                            path="/edit-distributor.html"
                                            element={<EditDistributor />}
                                        />
                                        <Route
                                            path="/view-retailer.html"
                                            element={<ViewRetailer />}
                                        />
                                        <Route
                                            path="/user-report.html"
                                            element={<UserReport />}
                                        />
                                        <Route
                                            path="/list-retailer.html"
                                            element={<RetailerListing />}
                                        />
                                        <Route
                                            path="/partner-limit-settings.html"
                                            element={<PartnerLimitControl />}
                                        />
                                        <Route
                                            path="/retailer-limit-settings.html"
                                            element={<RetailerLimitControl />}
                                        />
                                        <Route
                                            path="/set-limit.html"
                                            element={<SetPartnerLimit />}
                                        />
                                        <Route
                                            path="/set-retailer-limit.html"
                                            element={<SetRetailerLimit />}
                                        />
                                        <Route path="/create-draw.html" element={<CreateDraw />} />
                                        <Route
                                            path="/draw-listing.html"
                                            element={<DrawListing />}
                                        />
                                        <Route path="/draw-result-approve.html" element={<DrawResultApprove />} />
                                        
                                        <Route path="/draw-result-update.html" element={<DrawResultUpdate />} />
                                        <Route path="/draw-result.html" element={<DrawResult />} />
                                        <Route
                                            path="/game-history.html"
                                            element={<GameHistory />}
                                        />
                                        <Route
                                            path="/game-history-player.html"
                                            element={<GameHistoryPlayer />}
                                        />
                                        <Route
                                            path="/invoice.html"
                                            element={<Invoice />}
                                        />
                                        <Route
                                            path="/salesPurchaseReport.html"
                                            element={<SalesPurchaseReport />}
                                        />
                                        <Route path="/limit-details.html" element={<LimitDetails />} />
                                        <Route path="/ledger.html" element={<Ledger />} />
                                        <Route path="/view-live-terminal.html" element={<ViewLiveTerminal />} />
                                        <Route path="/set-limit-control.html" element={<SetLimit />} />
                                        <Route path="/customer-list.html" element={<CustomerList />} />
                                        <Route
                                            path="/prize-report.html"
                                            element={<PrizeReport />}
                                        />
                                        <Route
                                            path="/draw-wise-report.html"
                                            element={<DrawWiseReport />}
                                        />
                                        <Route
                                            path="/draw-wise-transaction-report.html"
                                            element={<DrawWiseTransactionReport />}
                                        />
                                        <Route
                                            path="/draw-wise-cancellation-report.html"
                                            element={<DrawWiseCancellationReport />}
                                        />
                                        <Route
                                            path="/day-wise-transaction-report.html"
                                            element={<DayWiseTransactionReport />}
                                        />
                                    </>
                                )}
                                {this.partnerTypeId <= 5 && (
                                    <>
                                        <Route
                                            path="/create-retailer.html"
                                            element={<CreateRetailer />}
                                        />
                                        <Route
                                            path="/list-retailer.html"
                                            element={<RetailerListing />}
                                        />
                                        <Route
                                            path="/view-retailer.html"
                                            element={<ViewRetailer />}
                                        />
                                        <Route
                                            path="/user-report.html"
                                            element={<UserReport />}
                                        />
                                        <Route
                                            path="/retailer-limit-settings.html"
                                            element={<RetailerLimitControl />}
                                        />
                                        <Route
                                            path="/set-retailer-limit.html"
                                            element={<SetRetailerLimit />}
                                        />
                                        <Route
                                            path="/game-history.html"
                                            element={<GameHistory />}
                                        />
                                        <Route
                                            path="/game-history-player.html"
                                            element={<GameHistoryPlayer />}
                                        />
                                        <Route
                                            path="/invoice.html"
                                            element={<Invoice />}
                                        />
                                        <Route
                                            path="/salesPurchaseReport.html"
                                            element={<SalesPurchaseReport />}
                                        />
                                        <Route path="/limit-details.html" element={<LimitDetails />} />
                                        <Route path="/ledger.html" element={<Ledger />} />
                                        <Route path="/view-live-terminal.html" element={<ViewLiveTerminal />} />
                                        <Route path="/set-limit-control.html" element={<SetLimit />} />
                                        <Route path="/customer-list.html" element={<CustomerList />} />
                                        <Route
                                            path="/prize-report.html"
                                            element={<PrizeReport />}
                                        />
                                        <Route
                                            path="/draw-wise-report.html"
                                            element={<DrawWiseReport />}
                                        />
                                        <Route
                                            path="/draw-wise-transaction-report.html"
                                            element={<DrawWiseTransactionReport />}
                                        />
                                        <Route
                                            path="/draw-wise-cancellation-report.html"
                                            element={<DrawWiseCancellationReport />}
                                        />
                                        <Route
                                            path="/day-wise-transaction-report.html"
                                            element={<DayWiseTransactionReport />}
                                        />
                                    </>
                                )}
                            </Routes>
                            {/* </div> */}
                        </div>
                    </div>
                ) : (
                    <>
                        <Routes>
                            <Route
                                path="/force-change-password.html"
                                element={<Forcechangepassword />}
                            />
                        </Routes>
                    </>
                )}
            </div>
        )
    }
}
