import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/limitcontrol/partnerlimitcontrol.module.scss"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {StaticKey} from "../../../game_core/type_script/static_key"
import { Link, Navigate } from "react-router-dom"
import {Modal} from "reactstrap"
import copy from "copy-to-clipboard"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"

export class ViewCompany extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    state: any = {
        goToBack: false,
        newPasswordError:"",
        confirmPasswordError:"",
        confirmPassword:"",
        newPassword:"",
        companyData: [],
        guId: "",
        tooltipText:"Click to Copy",
        securityCodeUsername:"",
        securityCode:"",
        alertPopupShow: false,
        changePasswordPopupShow: false,
        fields: {
            newPassword:"",
            confirmPassword:""
        }
    }
    
    passwordFields: any = [
        {
            "name": "newPassword",
            "id": "newPassword",
            "type": "password",
            "label": "New Password",
            // "max": "49",
            "autoFocus": true,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
        {
            "name": "confirmPassword",
            "id": "confirmPassword",
            "type": "password",
            "label": "Confirm Password",
            // "max": "49",
            "autoFocus": false,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
    ]

    componentDidMount() {
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        this.checkPartnerLimit(id)
    }

    checkPartnerLimit = (id: any) => {
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            "partnerGuid": id,
            "partnerTypeId":3
        }
        const response = this.DownlineManageServices.getCompanyListSearch(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
              //console.log(searchPartnerLimitResponse)
                if (!searchPartnerLimitResponse.errorStatus) {
                    this.setState({
                        companyData: searchPartnerLimitResponse.data[0],
                        guId: id
                    })
                    this.closeLoader()
                } else {
                    this.setState({
                        companyData: [],                       
                    })
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
              //console.log("handleCreatePartnerLimitSearchErr", err)
                this.setState({
                    companyData: [],                   
                })
                this.closeLoader()
            })
    }
    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
    
    handleAlertPopupClose = () => {
        this.setState({alertPopupShow: false, 
        securityCode: "",
        securityCodeUsername: "",tooltipText:"Click to Copy"});
    }
    handleSecurityCode = (evt:any,username:any, val: any) => {
        evt.preventDefault();
        // const data = username + "," + val
        this.setState({
            securityCode: val,
            securityCodeUsername: username,
            alertPopupShow: true,
        })
        // const data=username+","+val;
        // this.uiData.showToastMessageToCopy(data, "info", "copy")
    }

    copyToClipboard(){
        this.setState({tooltipText:"Copied"});
        copy(this.state.securityCode);
    }
    handlePopupShow = (e: any) => {
        e.preventDefault()
        this.setState({
            changePasswordPopupShow: true
        })
        setTimeout(()=>{
            document.getElementById("resetForm")?.click();
            document.getElementById("newPassword")?.focus();
        },100)
    }
    
    handleGoBack = (e: any) => {
        e.preventDefault()
        this.setState({
            goToBack: true
        })
        this.uiData.setRetailerBackList(true)
    }
    handleOnchange(e:any){
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value,
            [e.target.name + "Error"]:""
        })
    }
    handleResetPassword(e:any){
        e.preventDefault();    
        this.setState({
            ...this.state,
            "confirmPasswordError":"",
            "newPasswordError":"",
            "newPassword":"",
            "confirmPassword":"",
            fields : {
                ...this.state.fields,
                "newPassword":"",
                "confirmPassword":"",
            }
        })
        document.getElementById("newPassword")?.focus();
    }
    
    handleChangePassword(e:any){
        e.preventDefault();        
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        if(this.validatecreateDrawForm()){
            this.uiData.updateShowLoading(true)
            if (this.state["newPassword"] != this.state["confirmPassword"]) {
                document.getElementById('confirmPassword')?.focus();
                this.setState({
                    ["confirmPasswordError"]:"New password and Confirm Password must be same"
                })
                this.uiData.updateShowLoading(false)
                return false
            }
            const searchCondition = {
                "partnerTypeId":3,
                "partnerGuid":id,
                "newPassword":this.state['newPassword'],
                "confirmPassword":this.state['confirmPassword']
            }
            const response = this.DownlineManageServices.partnerForcePasswordUpdate(searchCondition)
            response
                .then((updateResultResponse: any) => {
                    if (!updateResultResponse.errorStatus) {
                        this.uiData.showToastMessage(updateResultResponse.message, "")
                        this.setState({
                            ...this.state,
                            "confirmPasswordError":"",
                            "newPasswordError":"",
                            changePasswordPopupShow:false,
                            fields : {
                                ...this.state.fields,
                                "newPassword":"",
                                "confirmPassword":"",
                            }
                        })
                    } else {
                        this.uiData.showToastMessage(updateResultResponse.message, "error")
                    }
                    this.uiData.updateShowLoading(false)
                })
                .catch((err: any) => {
                    this.uiData.showToastMessage("Something went Wrong", "error")
                  //console.log("handleDrawSearch", err)
                    this.uiData.updateShowLoading(false)
                })
        }
      
    }
    validatecreateDrawForm(){
        let flag = true
        // const errorMessage = StaticKey.LOCALE_DATA.Home
        this.passwordFields.map((val: any) => {
            if (val.required) {
                if (this.state[val.name] == "") {
                    document.getElementById(val.name)?.focus();
                    flag = false
                    const errorData = this.uiData.textConversion(val.label)
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.Home.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        let pattern: any
                        let error: any
                        if (val.name == "newPassword" || val.name == "confirmPassword") {
                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                            error = StaticKey.PASSWORD_CONDITION
                        } 
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            document.getElementById(val.name)?.focus();
                            this.setState({
                                [val.name + "Error"]: error
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        return flag
    }

    render() {
        const limitData = this.state.companyData
        return (
            <div className={classes["right-section"]} style={{textAlign: "left"}}>
                <div className={classes["main-div"]}>
                    <h2>Company - {limitData.LEVEL_3_NAME}</h2>
                    <div className={classes["action-class"]}>
                        <a href="#" onClick={()=>this.handleGoBack(event)} >
                                <i className="fa fa-arrow-left"></i> Go to List
                        </a> &nbsp;|&nbsp;
                        {this.state.goToBack && (
                            <Navigate to="/list-company.html" replace={true} />
                        )}
                        <a href="#" onClick={()=>this.handlePopupShow(event)} >Change Password</a>
                    </div>
                    <div className="list-detail-page">
                        <div className={"row"}>
                            <div className="col-sm-6">
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Username
                                    </div>
                                    <div className="col-6">
                                        {limitData.PARTNER_USERNAME}
                                    </div>
                                </div>
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Name
                                    </div>
                                    <div className="col-6">
                                        {limitData.LEVEL_3_NAME}
                                    </div>
                                </div> */}
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Available Limit
                                    </div>
                                    <div className="col-6">
                                        {limitData.creditLimit}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Opening OS
                                    </div>
                                    <div className="col-6">
                                        {limitData.creditLimit}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Closing Balance
                                    </div>
                                    <div className="col-6">
                                        {limitData.closingBalance}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Payable
                                    </div>
                                    <div className="col-6">
                                        {limitData.payable}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Account Status
                                    </div>
                                    <div className="col-6">
                                        {limitData.ACCOUNT_STATUS == 1 ? "Active" : "In-Active"}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Net. Sale Possible
                                    </div>
                                    <div className="col-6">
                                        {limitData.netSalePossible}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        MRP
                                    </div>
                                    <div className="col-6">
                                        {limitData.mrpSale}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Payout
                                    </div>
                                    <div className="col-6">
                                        {limitData.payout}
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="col-6">
                                    Collectable
                                    </div>
                                    <div className="col-6">
                                        {limitData.receivable}
                                    </div>
                                </div>
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Security Code
                                    </div>
                                    <div className="col-6">
                                        <a href="#" onClick={() => this.handleSecurityCode( event,limitData.PARTNER_USERNAME , limitData. DECRYPT_SECURITY_CODE ) } >
                                            <i className="fa fa-eye text-warning"></i>
                                        </a>
                                    </div>
                                </div> */}
                                {/* <div className="col-12 d-flex">
                                    <div className="col-6">
                                        Created Date
                                    </div>
                                    <div className="col-6">
                                        {limitData.PARTNER_CREATED_DATE}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    {/* {this.state.alertPopupShow && (
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.alertPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Security Code</h4>
                                <span
                                    onClick={()=>this.setState({alertPopupShow:false})}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <div className="modal-body mb-2 d-flex">
                                <div className="me-5">
                                    {this.state.securityCodeUsername}
                                </div>
                                <div className="me-5">
                                    {this.state.securityCode} 
                                </div>            
                                <div onClick={()=>this.copyToClipboard()}  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                    <i className="fa fa-copy"></i>
                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{this.state.tooltipText}</span>
                                </div>                  
                            </div>
                        </Modal>
                    )} */}
                      {/* {this.state.changePasswordPopupShow == "show" && ( */}
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.changePasswordPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Change Password</h4>
                                <span
                                    onClick={()=>this.setState({changePasswordPopupShow:false})}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2">
                                {this.passwordFields.map((obj: any, key: any) => {
                                            
                                            return (
                                                <>
                                    <div className="row  mb-4">
                                        <div className="col-4">
                                            <label htmlFor={obj.name}>{obj.label}</label>  
                                            <span className={classes["mandatory-symbol"]}>
                                                    *
                                                </span>
                                        </div>
                                        <div className="col-8">
                                            <InputBox
                                                className={
                                                    "form-control " +
                                                    classes[
                                                        obj.class
                                                    ]
                                                }
                                                name={obj.name}
                                                type={obj.type}
                                                id={obj.name}
                                                label1={obj.label}
                                                value={this.state[obj.name]}
                                                onChange={() =>
                                                    this.handleOnchange(event)
                                                }
                                                tabIndex={key+1}
                                                autoFocus={obj.autoFocus}
                                            />
                                            <div  className={classes["errorPass"]}>{this.state[obj.name+"Error"]}</div>
                                        </div>
                                    </div>
                                    </>
                                            )})}
                                </div>
                                <div className="modal-footer d-flex">
                                    <InputBox
                                        id={"submitForm"}
                                        autoComplete="off"
                                        className={"btn btn-success w-25"}
                                        autoFocus={false}
                                        name={"submitForm"}
                                        value={"Submit"}
                                        type={"submit"}
                                        tabIndex={3}
                                        onClick={()=>this.handleChangePassword(event)}
                                        required={true}
                                    />
                                    <InputBox
                                        id={"resetForm"}
                                        autoComplete="off"
                                        className={"btn btn-danger w-25"}
                                        autoFocus={false}
                                        name={"resetForm"}
                                        value={"Reset"}
                                        type={"reset"}
                                        tabIndex={4}
                                        onClick={()=>this.handleResetPassword(event)}
                                        required={true}
                                    />
                                </div>
                            </form>
                        </Modal>
                    {/* )} */}
                </>
            </div>
        )
    }
}
