import React, {Component} from "react"
import {DEFAULT_LANGUAGE_CODE, StaticKey} from "../../game_core/type_script/static_key"
import {ChangeScreen, SCREEN_NAMES} from "../../game_core/type_script/screen_maintenace"
import {
    CHANGE_SCREEN_EVENT,
    LANGUAGE_CHANGE_UPDATE
} from "../../game_core/type_script/event-emit-actions"
import classes from "../configuration/style/base/screens/styles.module.scss"
import "../configuration/style/base/style_color_theme.css"
import {Home} from "./home"
import {Login} from "./login"
import {UIDataService} from "../../game_core/type_script/ui-data-service"

import {Loader} from "../screens/common/loader"
import {DataStorage} from "../../game_core/type_script/services/localstorage"
import {AuthCheck} from "../../game_core/type_script/services/authcheck"
import {Navigate} from "react-router-dom"

export class MainScreen extends Component {
    uiData: UIDataService = UIDataService.getInstance()
    dataService = new DataStorage()
    authCheck = AuthCheck.getInstance()
    state = {
        logoutButtonClicked: false,
        redirectToForcePassword: false,
        screenName: SCREEN_NAMES.PRELOADER,
        showPageLoader: false,
        isLoggedIn: false,
        postLoginValues: {
            username: "",
            balance: ""
        },
        showLogoutMessage: false,
        showPage: false
    }
    componentDidMount() {
        this.addListeners()
        this.uiData.updateShowLoading(true)
        this.authCheck.checkLoginStatus()
    }
    updateShowLoading = () => {
        this.setState({showPageLoader: this.uiData.getShowLoading()})
    }
    componentWillUnmount() {
        this.removeListeners()
    }
    addListeners = () => {
        StaticKey.GLOBAL_EMITTER.on(CHANGE_SCREEN_EVENT, this.onScreenChange)
        StaticKey.GLOBAL_EMITTER.on(LANGUAGE_CHANGE_UPDATE, this.onLanguageChangeUpdate)
        this.uiData.on("setLoginResponse", this.setLoginResponse)
        this.uiData.on("setLogoutResponse", this.setLogoutResponse)
        this.uiData.on("updatedShowLoading", this.updateShowLoading)
        window.addEventListener("beforeunload", this.handleBeforeUnload)
    }
    onLanguageChangeUpdate = (langCode = DEFAULT_LANGUAGE_CODE) => {
        this.dataService.setSiteLanguage(langCode)
        StaticKey.languageChangeUpdate(langCode).then(() => {
            this.setState({})
        })
    }
    handleBeforeUnload = () => {
        this.dataService.setUnload(1)
    }
    removeListeners = () => {
        StaticKey.GLOBAL_EMITTER.off(CHANGE_SCREEN_EVENT, this.onScreenChange)
        StaticKey.GLOBAL_EMITTER.off(LANGUAGE_CHANGE_UPDATE, this.onLanguageChangeUpdate)
        this.uiData.off("updatedShowLoading", this.updateShowLoading)
        this.uiData.off("setLoginResponse", this.setLoginResponse)
        this.uiData.off("setLogoutResponse", this.setLogoutResponse)
        window.removeEventListener("beforeunload", this.handleBeforeUnload)
    }
    onScreenChange = (_data: ChangeScreen) => {
        this.setState({
            screenName: _data.screenName
        })
    }

    setLoginResponse = () => {
        const loginResponse = this.uiData.getLoginResponse()
        if (loginResponse && this.uiData.getLoginStatus()) {
            const passwordUpdatedStatus = loginResponse.passwordResetStatus == 0 ? true : false
            this.uiData.setPasswordUpdatedStatus(loginResponse.passwordResetStatus)
            this.setState({
                logoutButtonClicked: false,
                redirectToForcePassword: passwordUpdatedStatus,
                isLoggedIn: true,
                showPage: true,
                postLoginValues: {
                    username: loginResponse.adminUsername,
                    balance: loginResponse.partnerBalance,
                    partnerTypeId: loginResponse.partnerTypeId
                }
            })
            this.uiData.updateShowLoading(false)
        } else {
            this.setState({
                redirectToForcePassword: false,
                isLoggedIn: false,
                showPage: true,
                postLoginValues: {
                    username: "",
                    balance: "",
                    partnerTypeId: ""
                }
            })
            this.uiData.updateShowLoading(false)
        }
    }
    setLogoutResponse = () => {
        this.setState({
            logoutButtonClicked: true,
            redirectToForcePassword: false,
            isLoggedIn: false,
            showPage: true,
            postLoginValues: {
                username: "",
                balance: "",
                partnerTypeId: ""
            },
            showLogoutMessage: true
        })
        this.uiData.updateShowLoading(false)
        this.dataService.clearAllData()
    }
    handleLogoutMessageClose = () => {
        this.setState({
            showLogoutMessage: false
        })
    }
    render() {
        const target: any = this.dataService.getCurrentClickedMenuPath()
        const unload = this.dataService.getUnloadStatus();
        this.dataService.removeUnloadStatus()
        return (
            <div className={classes[`${StaticKey.ORIENTATION_TYPE}_container`]}>
                {this.state.logoutButtonClicked && <Navigate to="/" replace={true} />}
                {this.state.redirectToForcePassword && (
                    <Navigate to="/force-change-password.html" replace={true} />
                )}
                {unload == "1" && target && (
                    <Navigate to={target} replace={true} />
                )}
                {(this.state.showPage && (
                    <>
                        <Loader showPageLoader={this.state.showPageLoader} />
                        {this.state.isLoggedIn ? (
                            <Home />
                        ) : (
                            <Login
                                showLogoutPopup={this.state.showLogoutMessage}
                                onCloseLogoutPopup={this.handleLogoutMessageClose}
                            />
                        )}
                    </>
                )) || (
                    <>
                        <Loader showPageLoader={!this.state.showPage} />
                    </>
                )}
            </div>
        )
    }
}
