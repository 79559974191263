import React, {Component} from "react"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {AlertPopup} from "./alertpopup"
/* import {PostLoginMyAccount} from "./postloginmyaccount"
import {NAVBAR_IMAGES} from "../../configuration/assets/base/importer/navebar"
import {LanguageSwitcher} from "./languageswithcer" */
import {LoginService} from "../../../game_core/type_script/services/login"
import "../../configuration/style/base/responsive.css"
import classes from "../../configuration/style/base/screens/navbar/styles.module.scss"
import {HeaderTimer} from "./elements/headertimer"
import Swal from "sweetalert2"
import copy from 'copy-to-clipboard';

export class Navbar extends Component {
    uiData: UIDataService = UIDataService.getInstance()
    // drawListing =  DrawListing.changeConfiramtion();
    isLoggedIn = this.uiData.getLoginStatus()
    isBankEntered = this.uiData.getBankEnteredStatus()
    state = {
        isLoggedIn: false,
        postLoginValues: {
            username: "",
            balance: "",
            partnerTypeId:"",
            ipAddress:"",
            lastLogin:"",
        },
        balanceRefresh: {
            refresh: ""
        },
        showLoginPopup: "hide",
        username: "",
        alertPopupShow: "hide",
        alertType: "",
        alertMessage: "",
        isScrolling: "",
        showMobileTogglePopup: false,
        showBankAccAddModal: false,
        showScrollTop: false,
        toggleSideBar: false
    }
    LoginService: any = LoginService.getInstance()
    componentDidMount() {
        this.uiData.on("setAlertShowMessage", this.setAlertShowMessage)
        this.uiData.on("showToastMessage", this.showToastMessage)
        this.uiData.on("toggleSideBar", this.toggleSideBar)
        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                isLoggedIn: 1,
                postLoginValues: {
                    username: loginResponse.adminUsername,
                    balance: loginResponse.partnerAvailableLimit,
                    partnerTypeId: loginResponse.partnerTypeId,
                    ipAddress: loginResponse.ipAddress,
                    lastLogin: loginResponse.lastLoginDate,
                }
            })
        }
        window.addEventListener("scroll", this.handleScroll)
    }

    toggleSideBar = () => {
        const toggleStatus = this.uiData.getToggleStatus()
        this.setState({toggleSideBar: toggleStatus})
    }
    toggleSideBarChange = () => {
        this.uiData.toggleSideBar(!this.state.toggleSideBar)
    }

    showToastMessage = () => {

        // console.log("toastData")
        const toastData: any = this.uiData.getToastMessageData()
        /* const toastProp: any = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        }
        if (toastData.type == "error") {
            toast.error(toastData.msg, toastProp)
        } else {
            toast(toastData.msg, toastProp)
        } */
        //alert(toastData.html);
        if (toastData.html == undefined || toastData.html == "") {
            Swal.fire({
                title: toastData.msg,
                timer: 4000,
                icon: toastData.type == "error" ? "error" : "success"
            })
        } else {
            if(toastData.html == "copy"){
                const dataArr = toastData.msg.split(',');
                const htmlData = "<div style='font-size:20px;color:red' class='text-start'>Security code</div><hr><div class='d-flex arrayClass' style='font-size: 16px;'><div class='col-4 ps-0 ms-0'><b>"+dataArr[0]+"</b></div> <div  class='col-2'>"+ dataArr[1]+"</div></div>";
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                      confirmButton: 'btn btn-warning'
                    },
                    buttonsStyling: true,
                  })

                swalWithBootstrapButtons.fire({
                    
                    html: htmlData,
                    // timer: 4000,
                    confirmButtonText: 'Copy',
                    //<div style="background:#ffc935;border-radius:5px;color:#000;padding:7px"> </div>
                    // confirmButtonColor: "#ffc935",
                    focusConfirm: false,
                }).then(result => {
                    if (result.isConfirmed) {
                        copy(dataArr[1]);
                        // navigator.clipboard.writeText(toastData.msg)
                    }
                })
            }else{
                Swal.fire({
                    title: toastData.msg,
                    timer: 4000,
                    confirmButtonText: "Yes",
                    showDenyButton:true,
                    denyButtonAriaLabel:"No",
                    icon:"info"
                }).then(result => {
                    if (result.isConfirmed) {
                        // DrawListing.changeConfiramtion('confirm');
                    }else{
                        // DrawListing.changeConfiramtion('deny');
                        // DrawListing.setState({alertConfirmation:false});
                    }
                })
            }
        }
    }
    componentWillUnmount() {
        this.uiData.off("setAlertShowMessage", this.setAlertShowMessage)
        this.uiData.off("toggleSideBar", this.toggleSideBar)
        this.uiData.off("showToastMessage", this.showToastMessage)
        window.removeEventListener("scroll", this.handleScroll)
        document.body.style.overflow = "unset"
    }
    handleScroll = () => {
        if (window.scrollY === 0) {
            this.setState({isScrolling: "", showScrollTop: false})
        } else if (window.scrollY !== 0) {
            this.setState({isScrolling: "is-scrolling", showScrollTop: true})
        }
    }
    handleScrollTopClick = (e: any) => {
        e.preventDefault()
        window.scrollTo(0, 0)
    }
    setAlertShowMessage = () => {
        const alertData: any = this.uiData.getAlertShowMessage()
        this.setState({
            alertPopupShow: alertData.showAlert,
            alertType: alertData.alertType,
            alertMessage: alertData.alertMessage
        })
    }
    handleAlertPopupClose = () => {
        this.uiData.setAlertShowMessage("hide", "", "")
        this.setState({alertPopupShow: "hide"})
    }

    handleLoginPopupShow = (e: any) => {
        e.preventDefault()
        this.uiData.updateMyData("")
        this.setState({showLoginPopup: "show"})
    }

    handleLoginPopupClose = () => {
        this.setState({showLoginPopup: "hide"})
    }

    handleBankAddPopupClose = () => {
        this.setState({showBankAccAddModal: false})
    }

    handleLogout = (e: any) => {
        e.preventDefault()
        this.uiData.updateShowLoading(true)
        const response = this.LoginService.logoutUser()
        response
            .then(() => {
                this.uiData.logoutUser(true)
                this.uiData.updateShowLoading(false)
                localStorage.setItem('logout',"true");
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId:"",
                        ipAddress:"",
                        lastLogin:""
                    }
                })
                //window.location.href = "/"    
                localStorage.removeItem('menu');
            })
            .catch((err: any) => {
                // console.log("handleLogoutError", err)
                this.uiData.logoutUser(true)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: "",
                        partnerTypeId:"",
                        ipAddress:"",
                        lastLogin:""
                    }
                })
            })
    }

    render() {
        //let style = "nav_scroll_default"
        if (window.location.pathname.indexOf("my_account") > -1 || this.state.isScrolling != "") {
            //style = "nav_scroll_scrolling"
        } else {
            window.scrollTo(0, 0)
        }
        /* const classes = `collapse navbar-collapse pull-right ${
            this.state.showMobileTogglePopup ? "show" : ""
        }` */
        const scrollTopStyle = {
            display: "inline"
        }
        const toggleStatus = this.state.toggleSideBar ? "open" : ""
        return (
            <React.Fragment>
                {this.state.alertPopupShow == "show" && (
                    <AlertPopup
                        alertPopupShow={this.state.alertPopupShow}
                        alertType={this.state.alertType}
                        alertMessage={this.state.alertMessage}
                        handleAlertPopupClose={this.handleAlertPopupClose}
                    ></AlertPopup>
                )}
                <div className={classes["main-header"]}>
                    <div className={classes["main-header-top"]}>
                        <div className={classes["header-logo"] + " " + classes[toggleStatus]}>
                            SmartLotto
                        </div>
                        <div className={classes["header-nav"]} onClick={this.toggleSideBarChange}>
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </div>
                        <div className={classes["header-user"]}>
                            <div className={classes["header-user-details"] + " " + "header_username_det"}>
                                <span className={classes["header_username_head"]}>Welcome : </span>
                                <span className={classes["header_username_txt"]}>
                                    {this.state.postLoginValues.username}{" "}
                                    {this.state.postLoginValues["partnerTypeId"] != "0" && (
                                        <>                                        
                                        {this.state.postLoginValues["partnerTypeId"] == "3" && (
                                            "(Company)"
                                        )}                                     
                                        {this.state.postLoginValues["partnerTypeId"] == "5" && (
                                            "(Distributor)"
                                        )}
                                        </>
                                    )}   
                                </span>   
                            </div>  
                            <div
                                className={
                                    classes["header-user-details"] +
                                    " " +
                                    classes["server-datetime-sec"]
                                }
                            >
                                <HeaderTimer />
                            </div>   
                            <div className={classes["header-user-details"] + " " + "header_username_det"}>                        
                                <div className={"ms-2 "+classes["dropdown"]}>
                                    <span><i className="fa fa-user"></i></span>
                                    <div className={classes["dropdown-content"]}>
                                        
                                    {this.state.postLoginValues["partnerTypeId"] != "0" && (
                                        <div className="d-flex">
                                            <span className={classes["header_username_head"]}>Available Limit: &nbsp;</span>
                                            <span className={classes["header_username_txt"]}>
                                                {this.state.postLoginValues.balance  ? parseFloat(this.state.postLoginValues.balance).toFixed(2) : "0.00"}   
                                            </span>
                                        </div>
                                    )}
                                        <div className="d-flex">
                                            <span className={"col-4 " +classes["header_username_head"]}>Ip Address:&nbsp;</span>
                                            <span className={"col-8 " +classes["header_username_txt"]}>
                                                {this.state.postLoginValues.ipAddress}   
                                            </span>
                                        </div>
                                        <div className="d-flex">
                                            <span className={"col-4 " +classes["header_username_head"]}>Last Login:&nbsp;</span>
                                            <span className={"col-8 " +classes["header_username_txt"]}>
                                                {this.state.postLoginValues.lastLogin}   
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={classes["header-user-details"]}>
                                <a
                                    className={classes["a_logout"]}
                                    title="Logout"
                                    onClick={() => this.handleLogout(event)}
                                    href="login.html"
                                >
                                    <i className="fa fa-sign-out"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showScrollTop && (
                    <a
                        href="#"
                        id="scroll"
                        onClick={event => this.handleScrollTopClick(event)}
                        style={scrollTopStyle}
                    >
                        <span></span>
                    </a>
                )}
            </React.Fragment>
        )
    }
}
