import EventEmitter from "eventemitter3"
import * as language_dynamic from "./language-dynamic"
export const DEFAULT_LANGUAGE_CODE = "en"
export const API_ERROR_CODE = "apiError"
export const CLIENT_ERROR_CODE = "clientError"
export const ERROR_CODE = "ErrorCodeDetails"
export class StaticKey {
    // base variables common for all. dont delete it.
    static MAX_TICKET_DIGITS = 6
    static MAX_TICKET_PER_ROUND = 10
    static MAX_FILE_SIZE = 2097152
    static MAX_FILE_SIZE_ERROR = "1 MB only allowed"
    static RANDOM_PLUS_BUTTONS = [1, 3, 5, 10]
    static KEYPAD_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    static TICKET_NUMBER_STRING = {
        1: "ONE",
        2: "TWO",
        3: "THR",
        4: "FOU",
        5: "FIV",
        6: "SIX",
        7: "SEV",
        8: "EIG",
        9: "NIN",
        0: "ZER"
    }

    static PASSWORD_REG_EXP =
        /^(?=.*[!@#$%*+=?&])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!@#$%*+=?&|]{8,15}$/
    static TXN_PASSWORD_REG_EXP = /^[0-9]{1,6}$/
    static TXN_PASSWORD_MAX_LENGTH = 6
    static USERNAME_REG_EXP = /^[a-zA-Z0-9._]+$/
    static USERNAME_REG_EXP_ERROR = "Allowed only letters, numbers, period, underscore"
    static PURCHASE_HISTORY_PER_PAGE = 10
    static COLOR_THEME = "#01B460"
    static IS_LANDSCAPE: any = true
    static BANK_NAMES = ["AYA", "CB", "KBZ", "MAB", "YOMA"]
    static APK_DOWNLOAD_URL = ""
    static LOGOUT_ERROR_CODE = 300111

    static GAME_DIMENSION: any = {width: 960, height: 540, x: 0, y: 0}
    static DEFAULT_DIMENSION = {width: 960, height: 540, x: 0, y: 0}

    static ORIENTATION_TYPE = "p"

    //create your game variables below
    static GLOBAL_EMITTER: EventEmitter = new EventEmitter()
    static COMPLETE_LOCAL_DATA: any = language_dynamic
    static LOCALE_DATA: any = {}
    static ERROR_TEXT: any = {}
    static ERROR_DATA: any = {}
    static CLIENT_ERROR_TEXT: any = {}

    static SITE_LANGUAGES_NAMES = [{"en": "English"}, {"myn": "Burmese"}]
    static INVALIDPATTERN = "Invalid Password Pattern. Ex: Test@123"
    static PASSWORD_CONDITION =
        "min. 8 character, at-least one CAPITAL letter, one NUMBER and one SPECIAL character Ex:Test@123"
    static TXN_PASSWORD_CONDITION = "Transaction password must be six digit number"
    static CURRENT_TIMESTAMP = ""
    static MAX_AMOUNT_LENGTH = 15
    static MENU_LIST = {
        "0": [
            {
                "roleName": " Home",
                "icon": "fa fa-home",
                "subMenus": [
                    {
                        "roleName": "Dashboard",
                        "roleAction": "/"
                    }
                ]
            },
            {
                "roleName": " Application",
                "icon": "fa fa-users",
                "subMenus": [
                    {
                        "roleName": "Create Company",
                        "roleAction": "create-company.html"
                    },
                    {
                        "roleName": "Company Report",
                        "roleAction": "list-company.html"
                    },
                    {
                        "roleName": "Create Distributor",
                        "roleAction": "create-distributor.html"
                    },
                    {
                        "roleName": "Distributor Report",
                        "roleAction": "list-distributor.html"
                    },
                    {
                        "roleName": "Create Retailer",
                        "roleAction": "create-retailer.html"
                    },
                    {
                        "roleName": "Retailer Report",
                        "roleAction": "list-retailer.html"
                    },
                    {
                        "roleName": "Change Password",
                        "roleAction": "change-password.html"
                    },
                    {
                        "roleName": "Customer List",
                        "roleAction": "customer-list.html"
                    },
                    {
                        "roleName": "Installed / verified user report",
                        "roleAction": "user-report.html"
                    }
                ]
            },
            {
                "roleName": " Limit",
                "icon": "fa fa-rupee",
                "subMenus": [
                    {
                        "roleName": "Company Limit",
                        "roleAction": "company-limit-settings.html"
                    },
                    {
                        "roleName": "Distributor Limit",
                        "roleAction": "partner-limit-settings.html"
                    },
                    {
                        "roleName": "Retailer Limit",
                        "roleAction": "retailer-limit-settings.html"
                    },
                    {
                        "roleName": "Limit Details",
                        "roleAction": "limit-details.html"
                    },
                    {
                        "roleName": "Ledger",
                        "roleAction": "ledger.html"
                    },
                    {
                        "roleName": "Set Limit",
                        "roleAction": "set-limit-control.html"
                    }
                ]
            },
            {
                "roleName": " Reports",
                "icon": "fa fa-bar-chart",
                "subMenus": [
                    {
                        "roleName": "Game History",
                        "roleAction": "game-history.html"
                    }
                    // ,
                    // {
                    //     "roleName": "Sale / Purchase",
                    //     "roleAction": "salesPurchaseReport.html"
                    // },
                    // {
                    //     "roleName": "Invoice",
                    //     "roleAction": "invoice.html"
                    // }
                ]
            },
            {
                "roleName": " Static Reports",
                "icon": "fa fa-area-chart",
                "subMenus": [
                    {
                        "roleName": "Prize Report",
                        "roleAction": "prize-report.html"
                    },
                    {
                        "roleName": "Draw Wise",
                        "roleAction": "draw-wise-report.html"
                    },
                    {
                        "roleName": "Draw Wise Transaction",
                        "roleAction": "draw-wise-transaction-report.html"
                    },
                    {
                        "roleName": "Draw Wise Cancellation",
                        "roleAction": "draw-wise-cancellation-report.html"
                    },
                    {
                        "roleName": "Day Wise Transaction",
                        "roleAction": "day-wise-transaction-report.html"
                    }
                ]
            },
            {
                "roleName": " Game Management",
                "icon": "fa fa-cog",
                "subMenus": [
                    {
                        "roleName": "Create Draw",
                        "roleAction": "create-draw.html"
                    },
                    {
                        "roleName": "Draw List",
                        "roleAction": "draw-listing.html"
                    },
                    // {
                    //     "roleName": "List Draw Results",
                    //     "roleAction": "draw-result.html"
                    // },
                    {
                        "roleName": "Add Draw Result",
                        "roleAction": "draw-result-update.html"
                    },
                    {
                        "roleName": "Approve Draw Result",
                        "roleAction": "draw-result-approve.html"
                    }
                ]
            }
        ],
        "3": [
            {
                "roleName": " Home",
                "icon": "fa fa-home",
                "subMenus": [
                    {
                        "roleName": "Dashboard",
                        "roleAction": "/"
                    }
                ]
            },
            {
                "roleName": " Application",
                "icon": "fa fa-users",
                "subMenus": [
                    {
                        "roleName": "Create Distributor",
                        "roleAction": "create-distributor.html"
                    },
                    {
                        "roleName": "Distributor Report",
                        "roleAction": "list-distributor.html"
                    },
                    {
                        "roleName": "Create Retailer",
                        "roleAction": "create-retailer.html"
                    },
                    {
                        "roleName": "Retailer Report",
                        "roleAction": "list-retailer.html"
                    },
                    {
                        "roleName": "Change Password",
                        "roleAction": "change-password.html"
                    },
                    {
                        "roleName": "Customer List",
                        "roleAction": "customer-list.html"
                    },
                    {
                        "roleName": "Installed / verified user report",
                        "roleAction": "user-report.html"
                    }
                ]
            },
            {
                "roleName": " Limit",
                "icon": "fa fa-rupee",
                "subMenus": [
                    {
                        "roleName": "Distributor Limit",
                        "roleAction": "partner-limit-settings.html"
                    },
                    {
                        "roleName": "Retailer Limit",
                        "roleAction": "retailer-limit-settings.html"
                    },
                    {
                        "roleName": "Limit Details",
                        "roleAction": "limit-details.html"
                    },
                    {
                        "roleName": "Ledger",
                        "roleAction": "ledger.html"
                    },
                    {
                        "roleName": "Set Limit",
                        "roleAction": "set-limit-control.html"
                    }
                ]
            },
            {
                "roleName": " Static Reports",
                "icon": "fa fa-area-chart",
                "subMenus": [
                    {
                        "roleName": "Prize Report",
                        "roleAction": "prize-report.html"
                    },
                    {
                        "roleName": "Draw Wise",
                        "roleAction": "draw-wise-report.html"
                    },
                    {
                        "roleName": "Draw Wise Transaction",
                        "roleAction": "draw-wise-transaction-report.html"
                    },
                    {
                        "roleName": "Draw Wise Cancellation",
                        "roleAction": "draw-wise-cancellation-report.html"
                    },
                    {
                        "roleName": "Day Wise Transaction",
                        "roleAction": "day-wise-transaction-report.html"
                    }
                ]
            },
            {
                "roleName": " Reports",
                "icon": "fa fa-bar-chart",
                "subMenus": [
                    {
                        "roleName": "Game History",
                        "roleAction": "game-history.html"
                    }
                    // ,
                    // {
                    //     "roleName": "Sale / Purchase",
                    //     "roleAction": "salesPurchaseReport.html"
                    // },
                    // {
                    //     "roleName": "Invoice",
                    //     "roleAction": "invoice.html"
                    // },
                ]
            },
            {
                "roleName": " Game Management",
                "icon": "fa fa-cog",
                "subMenus": [
                    {
                        "roleName": "Create Draw",
                        "roleAction": "create-draw.html"
                    },
                    {
                        "roleName": "Draw List",
                        "roleAction": "draw-listing.html"
                    },
                    // {
                    //     "roleName": "List Draw Results",
                    //     "roleAction": "draw-result.html"
                    // },
                    {
                        "roleName": "Add Draw Result",
                        "roleAction": "draw-result-update.html"
                    },
                    {
                        "roleName": "Approve Draw Result",
                        "roleAction": "draw-result-approve.html"
                    }
                ]
            }
        ],
        "5": [
            {
                "roleName": " Home",
                "icon": "fa fa-home",
                "subMenus": [
                    {
                        "roleName": "Dashboard",
                        "roleAction": "/"
                    }
                ]
            },
            {
                "roleName": " Application",
                "icon": "fa fa-users",
                "subMenus": [
                    {
                        "roleName": "Create Retailer",
                        "roleAction": "create-retailer.html"
                    },
                    {
                        "roleName": "Retailer Report",
                        "roleAction": "list-retailer.html"
                    },
                    {
                        "roleName": "Change Password",
                        "roleAction": "change-password.html"
                    },
                    {
                        "roleName": "Customer List",
                        "roleAction": "customer-list.html"
                    },
                    {
                        "roleName": "Installed / verified user report",
                        "roleAction": "user-report.html"
                    }
                ]
            },
            {
                "roleName": " Limit",
                "icon": "fa fa-rupee",
                "subMenus": [
                    {
                        "roleName": "Retailer Limit",
                        "roleAction": "retailer-limit-settings.html"
                    },
                    {
                        "roleName": "Limit Details",
                        "roleAction": "limit-details.html"
                    },
                    {
                        "roleName": "Ledger",
                        "roleAction": "ledger.html"
                    },
                    {
                        "roleName": "Set Limit",
                        "roleAction": "set-limit-control.html"
                    }
                ]
            },
            {
                "roleName": " Static Reports",
                "icon": "fa fa-area-chart",
                "subMenus": [
                    {
                        "roleName": "Prize Report",
                        "roleAction": "prize-report.html"
                    },
                    {
                        "roleName": "Draw Wise",
                        "roleAction": "draw-wise-report.html"
                    },
                    {
                        "roleName": "Draw Wise Transaction",
                        "roleAction": "draw-wise-transaction-report.html"
                    },
                    {
                        "roleName": "Draw Wise Cancellation",
                        "roleAction": "draw-wise-cancellation-report.html"
                    },
                    {
                        "roleName": "Day Wise Transaction",
                        "roleAction": "day-wise-transaction-report.html"
                    }
                ]
            },
            {
                "roleName": " Reports",
                "icon": "fa fa-bar-chart",
                "subMenus": [
                    {
                        "roleName": "Game History",
                        "roleAction": "game-history.html"
                    }
                    // ,
                    // {
                    //     "roleName": "Sale / Purchase",
                    //     "roleAction": "salesPurchaseReport.html"
                    // },
                    // {
                    //     "roleName": "Invoice",
                    //     "roleAction": "invoice.html"
                    // }
                ]
            }
        ]
    }
    static languageChangeUpdate = (langCode: any = DEFAULT_LANGUAGE_CODE) => {
        // alert("asd")
        return new Promise(resolve => {
            if (!StaticKey.COMPLETE_LOCAL_DATA[langCode]) {
                langCode = DEFAULT_LANGUAGE_CODE
            }
            console.log(StaticKey.COMPLETE_LOCAL_DATA)
            StaticKey.LOCALE_DATA = StaticKey.COMPLETE_LOCAL_DATA[langCode]
            StaticKey.ERROR_TEXT = StaticKey.COMPLETE_LOCAL_DATA[langCode + "_" + API_ERROR_CODE]
                ? StaticKey.COMPLETE_LOCAL_DATA[langCode + "_" + API_ERROR_CODE]
                : StaticKey.COMPLETE_LOCAL_DATA[API_ERROR_CODE]
            StaticKey.ERROR_DATA = StaticKey.COMPLETE_LOCAL_DATA[langCode + "_" + ERROR_CODE]
                ? StaticKey.COMPLETE_LOCAL_DATA[langCode + "_" + ERROR_CODE]
                : StaticKey.COMPLETE_LOCAL_DATA[ERROR_CODE]
            StaticKey.CLIENT_ERROR_TEXT = StaticKey.COMPLETE_LOCAL_DATA[
                langCode + "_" + CLIENT_ERROR_CODE
            ]
                ? StaticKey.COMPLETE_LOCAL_DATA[langCode + "_" + CLIENT_ERROR_CODE]
                : StaticKey.COMPLETE_LOCAL_DATA[CLIENT_ERROR_CODE]
            resolve({})
        })
    }
    static DRAWMINPRICE = 10
    static DRAWMMAXPRICE = 20
    static DATERANGE = [
        {
            "id": "today",
            "name": "Today"
        },
        {
            "id": "yesterday",
            "name": "Yesterday"
        },
        {
            "id": "thisWeek",
            "name": "This Week"
        },
        {
            "id": "lastweek",
            "name": "Last Week"
        },
        {
            "id": "thismonth",
            "name": "This Month"
        },
        {
            "id": "lastmonth",
            "name": "Last Month"
        }
    ]
    static ACTIVEINACTIVESTATUS = [
        {
            "id": "",
            "name": "Select"
        },
        {
            "id": "active",
            "name": "Active"
        },
        {
            "id": "inactive",
            "name": "Inactive"
        }
    ]
    static DRAWACTIVEINACTIVESTATUS = [
        // {
        //     "id": "",
        //     "name": "Select"
        // },
        {
            "id": "active",
            "name": "Active"
        },
        {
            "id": "inactive",
            "name": "Inactive"
        }
    ]
    static ACTIVEINACTIVESTATUS01 = [
        {
            "id": 1,
            "name": "Active"
        },
        {
            "id": 0,
            "name": "Inactive"
        }
    ]
    static PARTNERUSERLIST: any = {
        "1": "Main Agent",
        "2": "Super Distributor",
        "3": "Company",
        "4": "Sub Distributor",
        "5": "Distributor"
    }
    static POINTSTRANSFERTYPE = [
        {
            "id": "add",
            "name": "Add"
        },
        {
            "id": "remove",
            "name": "Remove"
        }
    ]
    static KEYDRAWACTIVEINACTIVESTATUS = [
        {
            "id": "active",
            "name": "Active"
        },
        {
            "id": "inactive",
            "name": "Inactive"
        }
    ]
    static COMPANYSTATUS = [
        {
            "id": "ACTIVE",
            "name": "Active"
        },
        {
            "id": "INACTIVE",
            "name": "Inactive"
        }
    ]
    static DRAWSTATUS = [
        // {
        //     "id": "",
        //     "name": "Select"
        // },
        {
            "id": "1",
            "name": "New"
        },
        {
            "id": "2",
            "name": "Running"
        },
        {
            "id": "3",
            "name": "Completed"
        },
        {
            "id": "4",
            "name": "Cancelled"
        },
        {
            "id": "5",
            "name": "Win No Announced"
        },
        {
            "id": "6",
            "name": "Win No Published"
        },
        {
            "id": "7",
            "name": "Win No Approved"
        },
        {
            "id": "8",
            "name": "Result Rejected"
        }
    ]
    static DRAWSTATUSRESULT = [
        {
            "id": "5",
            "name": "Win No Announced"
        },
        {
            "id": "6",
            "name": "Win No Published"
        }
    ]
    static DRAWRESULTSTATUS = [
        {
            "id": "",
            "name": "Select"
        },
        {
            "id": "approved",
            "name": "Approve"
        },
        {
            "id": "rejected",
            "name": "Reject"
        }
    ]
    static DRAWINTERVALOPTION = [
        {
            "id": "",
            "name": "Select"
        },
        {
            "id": "daily",
            "name": "All Days"
        },
        {
            "id": "mon-tue-sun",
            "name": "Monday,Tuesday,Sunday"
        },
        {
            "id": "wed-fri",
            "name": "Wednesday and Friday"
        },
        {
            "id": "sat",
            "name": "Saturday only"
        },
        {
            "id": "thu",
            "name": "Thursday only"
        }
        // {
        //     "id": "5minutes",
        //     "name": "Five Minutes"
        // }
    ]
    static TERMINALTYPE = [
        {
            "id": "1",
            "name": "Terminal"
        },
        {
            "id": "2",
            "name": "Kiosk"
        }
    ]
    static TYPEOFORGANISATION = [
        {
            "id": "1",
            "name": "Proprietorship"
        },
        {
            "id": "2",
            "name": "Partnership"
        },
        {
            "id": "3",
            "name": "Individual/HUF"
        },
        {
            "id": "4",
            "name": "PVT.LTD/LTD"
        }
    ]
    static DISTRIBUTORSTATUS = [
        {
            "id": "PENDING",
            "name": "Pending"
        },
        {
            "id": "ACTIVE",
            "name": "Active"
        },
        {
            "id": "VERIFIED",
            "name": "Verified"
        },
        {
            "id": "REJECTED",
            "name": "Rejected"
        },
        {
            "id": "INACTIVE",
            "name": "In Active"
        }
    ]
    static DISTRIBUTORACTIONDROPDOWN = [
        // {
        //     "id":0,
        //     "name":"Login Id Created",
        // },
        {
            "id":1,
            "name":"Installed",
        },
        {
            "id":2,
            "name":"Verified",
        },
    ]
    static RETAILERACTIONDROPDOWN = [
        // {
        //     "id":0,
        //     "name":"Login Id Created",
        // },
        {
            "id":1,
            "name":"Installed",
        },
        {
            "id":3,
            "name":"Verified",
        },
    ]
    static DISTRIBUTORSTATUSDROPDOWN = [
        {
            "id": "PENDING",
            "name": "Pending"
        },
        {
            "id": "VERIFIED",
            "name": "Verified"
        },
        {
            "id": "ACTIVE",
            "name": "Active"
        },
        {
            "id": "REJECTED",
            "name": "Rejected"
        },
        {
            "id": "INACTIVE",
            "name": "In Active"
        }
    ]
    static RETAILERSTATUSDROPDOWN = [
        {
            "id": "PENDING",
            "name": "Pending"
        },
        {
            "id": "PROCESSING",
            "name": "Processing"
        },
        {
            "id": "VERIFIED",
            "name": "Verified"
        },
        {
            "id": "ACTIVE",
            "name": "Active"
        },
        {
            "id": "INSTALLATION",
            "name": "Installation"
        },
        {
            "id": "REJECTED",
            "name": "Rejected"
        },
        {
            "id": "INACTIVE",
            "name": "In Active"
        }
    ]
    static RETAILERSTATUS = [
        {
            "id": "PENDING",
            "name": "Pending"
        },
        {
            "id": "ACTIVE",
            "name": "Active"
        },
        {
            "id": "PROCESSING",
            "name": "Processing"
        },
        {
            "id": "VERIFIED",
            "name": "Verified"
        },
        {
            "id": "REJECTED",
            "name": "Rejected"
        },
        {
            "id": "INSTALLATION",
            "name": "Installation"
        },
        {
            "id": "INACTIVE",
            "name": "In Active"
        }
    ]
    static socketNamespace: any = {
        "super_lotto_thursday": "thursdaylotto",
        "super_lotto_thursday_mob": "thursdaylotto",
        "lotto_4D_raashi": "raashi",
        "lotto_4D_raashi_mob": "raashi",
        "fast_lotto": "fastlotto",
        "fast_lotto_mob": "fastlotto",
        "lotto_jaldi5": "jaldifive",
        "lotto_jaldi5_mob": "jaldifive",
        "lotto_saturday": "saturdaylotto",
        "lotto_saturday_mob": "saturdaylotto",
        "lotto_strike10": "striketen",
        "lotto_strike10_mob": "striketen",
        "lotto_keno": "keno",
        "lotto_keno_mob": "keno"
    }
    static userType:any=[        
        {
            "id": "company",
            "name": "Company"
        },
        {
            "id": "distributor",
            "name": "Distributor"
        },
        {
            "id": "retailer",
            "name": "Retailer"
        }
    ]
    static userTypeComp:any=[        
        {
            "id": "distributor",
            "name": "Distributor"
        },
        {
            "id": "retailer",
            "name": "Retailer"
        }
    ]
    static userTypeDist:any=[        
       {
            "id": "retailer",
            "name": "Retailer"
        }
    ]
}
