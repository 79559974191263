import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import { HTTPMockDataService } from "../../http-mock-data-service"
import { StaticKey } from "../../static_key"

export class DownlineManageServices extends HTTPConnectionDatasService {
    params: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any = HTTPConnectionDatasService.getInstance()
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID = "default"): any {
        if (!DownlineManageServices.classIns[_instanceID]) {
            return (DownlineManageServices.classIns[_instanceID] = new DownlineManageServices(_instanceID))
        } else {
            return DownlineManageServices.classIns[_instanceID]
        }
    }

    getMethodCall = (paramsData: any,apiMethod:any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/`+apiMethod
            this.httpConnectionIns.sendHttpRequest(apiMethod, requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    createCompany = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createCompany`
            this.httpConnectionIns.sendHttpRequest("createCompany", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    checkPartnerExist = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/checkPartnerExist`
            this.httpConnectionIns.sendHttpRequest("checkPartnerExist", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    checkUserExist = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/checkUserExist`
            this.httpConnectionIns.sendHttpRequest("checkUserExist", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getCompanyListDropdown = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerDropdown`
            this.httpConnectionIns.sendHttpRequest("partnerDropdown", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getDistributorsListDropdown = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerDropdown`
            this.httpConnectionIns.sendHttpRequest("partnerDropdown", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getRetailersListDropdown = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/retailerDropdown`
            this.httpConnectionIns.sendHttpRequest("retailerDropdown", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getCompanyListDropdownDay = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerDropdownDay`
            this.httpConnectionIns.sendHttpRequest("partnerDropdownDay", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getDistributorsListDropdownDay = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerDropdownDay`
            this.httpConnectionIns.sendHttpRequest("partnerDropdownDay", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getRetailersListDropdownDay = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/retailerDropdownDay`
            this.httpConnectionIns.sendHttpRequest("retailerDropdownDay", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    createDistributor = (paramsData: any) => {
        console.log('paramsData',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createDistributor`
            this.httpConnectionIns.sendHttpRequest("createDistributor", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    createRetailer = (paramsData: any) => {
        console.log('paramsDatacreateRetailer',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createRetailer`
            this.httpConnectionIns.sendHttpRequest("createRetailer", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getCompanyListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/searchCompany`
            this.httpConnectionIns.sendHttpRequest("searchCompany", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getPrizeReportListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getPrizeReportList`
            this.httpConnectionIns.sendHttpRequest("getPrizeReportList", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDrawWiseReport = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDrawWiseReport`
            this.httpConnectionIns.sendHttpRequest("getDrawWiseReport", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDrawWiseTransReport = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDrawWiseTransReport`
            this.httpConnectionIns.sendHttpRequest("getDrawWiseTransReport", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDrawCancellationListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDrawCancellationList`
            this.httpConnectionIns.sendHttpRequest("getDrawCancellationList", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDayWiseListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDayWiseList`
            this.httpConnectionIns.sendHttpRequest("getDayWiseList", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDistributorListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/searchPartner`
            this.httpConnectionIns.sendHttpRequest("searchPartner", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    getRetailerListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/searchRetailer`
            this.httpConnectionIns.sendHttpRequest("searchRetailer", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    changeDistributorStatus = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/applicationStatusUpdate`
            this.httpConnectionIns.sendHttpRequest("applicationStatusUpdate", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    createLoginID = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createLoginId`
            this.httpConnectionIns.sendHttpRequest("createLoginId", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    } 
    changeUserStatus = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/userApplicationStatusUpdate`
            this.httpConnectionIns.sendHttpRequest("userApplicationStatusUpdate", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getDistributorDetail = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDistributorDetails`
            this.httpConnectionIns.sendHttpRequest("getDistributorDetails", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getRetailerDetail = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getRetailerDetail`
            this.httpConnectionIns.sendHttpRequest("getRetailerDetail", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    createUserLoginId = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createUserLoginId`
            this.httpConnectionIns.sendHttpRequest("createUserLoginId", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    userForcePasswordUpdate = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/userForcePasswordUpdate`
            this.httpConnectionIns.sendHttpRequest("userForcePasswordUpdate", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    partnerForcePasswordUpdate = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerForcePasswordUpdate`
            this.httpConnectionIns.sendHttpRequest("partnerForcePasswordUpdate", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    editDistributor = (paramsData: any) => {
        console.log('paramsData',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/editDistributor`
            this.httpConnectionIns.sendHttpRequest("editDistributor", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    fileUpload = (paramsData: any) => {
        console.log('paramsData',paramsData);
        const  headers :any = {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "type": "formData"
              };
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/fileUpload`
            this.httpConnectionIns.sendHttpRequest("fileUpload", requestURL, paramsData,headers).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    getLoginRetailer = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getLoginRetailer`
            this.httpConnectionIns.sendHttpRequest("getLoginRetailer", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    getCustomerListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getCustomerList`
            this.httpConnectionIns.sendHttpRequest("getCustomerList", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    getVerifiedInstalledListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getVerifiedInstalledList`
            this.httpConnectionIns.sendHttpRequest("getVerifiedInstalledList", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    changeStatusRetailer = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/changeStatusRetailer`
            this.httpConnectionIns.sendHttpRequest("changeStatusRetailer", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

}
