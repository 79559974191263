import React, {Component} from "react"
//import { MainScreen } from "./screens";
import {CHANGE_MAIN_EVENT} from "../game_core/type_script/event-emit-actions"
import {ChangeScreen, SCREEN_NAMES} from "../game_core/type_script/screen_maintenace"
import {DEFAULT_LANGUAGE_CODE, StaticKey} from "../game_core/type_script/static_key"
import {MaintenanceScreen} from "./screens/maintenace"
import classes from "./configuration/style/base/styles.module.scss"
import {MainScreen} from "./screens"
import {DataStorage} from "../game_core/type_script/services/localstorage"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.min.css'

export class CustomerManager extends Component {
    dataService = new DataStorage()
    splashCompleteCBCnt = 0
    screenObj: ChangeScreen = {screenName: ""}
    gameRatio: number = 16 / 9
    state = {
        isLoadScreen: false,
        screenName: SCREEN_NAMES.SPLASHSCREEN
    }
    //Splash screen
    componentDidMount() {
        StaticKey.languageChangeUpdate(
            this.dataService.getSiteLanguage()
                ? this.dataService.getSiteLanguage()
                : DEFAULT_LANGUAGE_CODE
        )
        this.addListeners()
        this.setState({isLoadScreen: true})
    }

    componentWillUnmount() {
        this.removeListeners()
    }
    onScreenChange = (_data: ChangeScreen) => {
        this.setState({
            screenName: _data.screenName
        })
    }

    onLayoutChange = () => {
        const dimention = {width: window.innerWidth, height: window.innerHeight}
        if (dimention.width > dimention.height) {
            StaticKey.IS_LANDSCAPE = true
            //Check aspect ratio width is exceeded beyond screen width or not
            //16/9 mean aspect ratio of 16:9 for our game. we are maintain the game play on this ratio
            if (dimention.height * this.gameRatio > dimention.width) {
                dimention.height = dimention.width / this.gameRatio
                dimention.width = dimention.height * this.gameRatio
            } else {
                dimention.width = dimention.height * this.gameRatio
            }
        } else {
            StaticKey.IS_LANDSCAPE = false
            if (dimention.width * this.gameRatio > dimention.height) {
                dimention.width = dimention.height / this.gameRatio
                dimention.height = dimention.width * this.gameRatio
            } else {
                dimention.height = dimention.width * this.gameRatio
            }
        }

        StaticKey.GAME_DIMENSION.x = dimention.width / 2
        StaticKey.GAME_DIMENSION.y = dimention.height / 2
        StaticKey.GAME_DIMENSION.width = dimention.width //set game width to global
        StaticKey.GAME_DIMENSION.height = dimention.height //set game height to global
        StaticKey.ORIENTATION_TYPE = StaticKey.IS_LANDSCAPE ? "l" : "p"
        this.setState({})
    }
    addListeners = () => {
        window.addEventListener("resize", this.onLayoutChange)
        StaticKey.GLOBAL_EMITTER.on(CHANGE_MAIN_EVENT, this.onScreenChange)
    }
    removeListeners = () => {
        window.removeEventListener("resize", this.onLayoutChange)
        StaticKey.GLOBAL_EMITTER.off(CHANGE_MAIN_EVENT, this.onScreenChange)
    }
    onComplete = () => {
        this.screenObj.screenName = SCREEN_NAMES.MAINSCREEN
        StaticKey.GLOBAL_EMITTER.emit(CHANGE_MAIN_EVENT, this.screenObj)
    }

    render() {
        return (
            <div className="container">
                <div className={classes[`${StaticKey.ORIENTATION_TYPE}_container`]}>
                    <ToastContainer />
                    {this.state.screenName === SCREEN_NAMES.SPLASHSCREEN && (
                        <div className={classes.splashContainer}>
                            {<MaintenanceScreen onComplete={this.onComplete} />}
                        </div>
                    )}
                    {this.state.screenName === SCREEN_NAMES.MAINSCREEN && <MainScreen />}
                </div>
            </div>
        )
    }
}
