import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/staticReports/styles.module.scss"
import { StaticKey } from "../../../game_core/type_script/static_key"
import { InputBox } from "../common/elements/inputbox"
import { UIDataService } from "../../../game_core/type_script/ui-data-service";
import { TotalRecords } from "../common/totalRecords";
import { DrawManageService } from "../../../game_core/type_script/services/drawmanagement";
import { DownlineManageServices } from "../../../game_core/type_script/services/downlinemanagement";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import { PaginationElement } from "../common/PaginationElement";
import { PrintTableElement } from "../common/PrintTableElement"
import { ExportExcelReport } from "../downlinemanagement/ExportExcelReport"

const date = new Date();
const currentDay= String(date.getDate()).padStart(2, '0');
const currentMonth = String(date.getMonth()+1).padStart(2,"0");
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;
const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`;

export class ViewLiveTerminal extends Component {
    viewLiveTerminalLang: any = StaticKey.LOCALE_DATA.viewLiveTerminal
    DrawManageServices = DrawManageService.getInstance()
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    minDate: any = new Date()
    maxDate: any = new Date()
    gameList:any = {}
    state: any = { excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Player Name","Total Bet","Total Win","Total Refund","Win/Loss"],
        tableBody:["username","betAmount_toFixed","winAmount_toFixed","refundAmount_toFixed","winLossAmount_toFixed"],
        exportHead:["Player Name","Total Bet","Total Win","Total Refund","Win/Loss"],
        exportBody:["username","betAmount_toFixed","winAmount_toFixed","refundAmount_toFixed","winLossAmount_toFixed"],
        viewLiveTerminalData:[],
        searchFilterRow:[0,1],
        distributorName:"",
        companyName:"",
        drawDate:"",
        startDate:"",
        endDate:"",
        drawId:"",
        drawDateSearch:"",
        startDateSearch:"",
        endDateSearch:"",
        fromPagePagination: 1,
        toPagePagination: 5,
        noRow: 25,
        page: 0,
        limit: 25,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        showLoginRetailerReport:false,
        errors: {
            "distributorNameError":"",
            "companyNameError":"",
            "drawDateError":"",
            "drawIdError":"",
            "startDateError":"",
            "endDateError":"",
        },
    }
    
    searchFields:any =  {
        "":[
                {
                    rowId:0,
                    id: "companyName",
                    name: "companyName",
                    label: this.viewLiveTerminalLang.agencyName,
                    type: "select",
                    autoFocus: true,
                    required: false,
                    options:{},
                    selectShowEmpty:true,
                },
                {
                    rowId:0,
                    id: "distributorName",
                    name: "distributorName",
                    label: this.viewLiveTerminalLang.distributorName,
                    type: "select",
                    autoFocus: false,
                    required: false,
                    options:{},
                    selectShowEmpty:true,
                },
                {
                    rowId:1,
                    id: "startDate",
                    name: "startDate",
                    label: this.viewLiveTerminalLang.startDate,
                    type: "date",
                    autoFocus: false,
                    autoComplete: "off",
                    maxLength: 50,
                    required: false,
                    dateFormat: "dd-MM-yyyy HH:mm:ss"
                },
                {
                    rowId:1,
                    id: "endDate",
                    name: "endDate",
                    label: this.viewLiveTerminalLang.endDate,
                    type: "date",
                    autoFocus: false,
                    autoComplete: "off",
                    maxLength: 50,
                    required: false,
                    dateFormat: "dd-MM-yyyy HH:mm:ss"
                },
                {
                    rowId: 1,
                    name: "dateRange",
                    type: "select",
                    label: this.viewLiveTerminalLang.dateRange,
                    placeHolder: "Date Range",
                    maxLength: "50",
                    autoFocus: false,
                    class: "form-control",
                    defaultValue: "",
                    required: false,
                    options: StaticKey.DATERANGE,
                    selectShowEmpty: true
                }
            ]
        }
    componentDidMount(): void {
        const loginData = this.uiData.getLoginResponse()
        const agentLoginHideFields: any = []
        if (loginData.partnerTypeId == 0) {
            this.getCompanyList({
                "partnerTypeId": 3
            })
        } else if (loginData.partnerTypeId == 3) {
            this.getDistributorsList({
                "partnerTypeId": 5
            })
            agentLoginHideFields.push("companyName")
        } else {
            //console.log("asdas")
            agentLoginHideFields.push("companyName")
            agentLoginHideFields.push("distributorName")
        }
        return this.searchFields[""].map((val: any, ind: any) => {
            if (agentLoginHideFields.includes(val.name)) {
                return delete this.searchFields[""][ind]
            }
            if(loginData.partnerTypeId != 0){
                if (loginData.partnerTypeId == 3 && val.name == "distributorName") {
                    document.getElementById("distributorName")?.focus();
                }
                if (val.name == "accountStatus") {
                    this.searchFields[ind].rowId=0;
                }
                
            }
        })
    }
    getGameList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DrawManageServices.getGameListDropdown(data)
        response.then((getGameListResponse: any) => {
            if (!getGameListResponse.errorStatus) {
                Object.keys(this.searchFields).map((keys: any, values: any) => {
                    this.searchFields[keys].map((fieldValObject: any,val:any) => {
                        if(fieldValObject.name == "distributorName"){
                            this.searchFields[""][val].options = getGameListResponse.data
                        }
                    })
                })
            }
            this.uiData.updateShowLoading(false)
        }).catch((err: any) => {
            this.uiData.updateShowLoading(false)
        })
    }
    handleInputChange = (e:any)=>{
        e.preventDefault();
        this.uiData.updateShowLoading(true)
        const value = e.target.value
        if (e.target.name == "companyName") {
            this.getDistributorsList({
                "partnerTypeId": 5,
                "companyId": value
            })
        }
        if (e.target.name == "dateRange") {
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let valid = true
            const date = new Date()
            if (e.target.value == "") {                    
                this.setState({
                    ...this.state,
                    startDate: "",
                    startDateSearch: "",
                    endDate: "",
                    endDateSearch: "",
                })
            }else{
                if (e.target.value == "today") {
                    currentDay = String(date.getDate()).padStart(2, "0")
                    lastDay = String(date.getDate()).padStart(2, "0")
                    currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "yesterday") {
                    const yesterday = new Date(date)
                    yesterday.setDate(date.getDate() - 1)
                    currentDay = String(yesterday.getDate()).padStart(2, "0")
                    lastDay = String(yesterday.getDate()).padStart(2, "0")
                    currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thisWeek") {
                    const today = new Date()
                    const startOfWeek = new Date(today)
                    const endOfWeek = new Date(today)

                    startOfWeek.setDate(today.getDate() - today.getDay())
                    endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                    const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                    const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                    currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                    lastDay = String(endDate.getDate()).padStart(2, "0")
                    currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastweek") {
                    const dateL = new Date()
                    const to = new Date(
                        dateL.setTime(
                            dateL.getTime() -
                                (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                        )
                    )
                    const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                    currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                    lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                    currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "lastmonth") {
                    const d = new Date()
                    d.setDate(1)
                    const to = new Date(d.setHours(-1))
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(to.getDate()).padStart(2, "0")
                    currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                } else if (e.target.value == "thismonth") {
                    const d = new Date()
                    const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                    currentDay = String("1").padStart(2, "0")
                    lastDay = String(lastDays.getDate()).padStart(2, "0")
                    currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                } else {
                    valid = false
                }
                let currentDateNew = null
                let currentEndDateNew = null
                if (valid) {
                    currentDateNew = new Date(
                        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                    )
                    currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                }

                this.setState({
                    ...this.state,
                    startDate: currentDateNew,
                    startDateSearch: (
                        `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                    ),
                    endDate: currentEndDateNew,
                    endDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                    errors:{
                        ...this.state.errors,
                        startDateError:"",
                        endDateError:"",

                    }
                })
            }
        }else{
            this.setState({
                ...this.state,
                [e.target.name]: value,
                errors: {
                    ...this.state.errors,
                    [e.target.name + "Error"]: ""
                }
            })
        }
        this.uiData.updateShowLoading(false)
    }    
    handleDateInputChange = (date:any)=>{
        //console.log(date)
        if(date != "" && date != null && date != undefined){
            const loginData = this.uiData.getLoginResponse()
            this.searchFields[""][3].required=true;
            this.minDate = date
            const dates = new Date(date)
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let getHours = "00"
            let getMinutes = "00"
            let getSeconds = "00"
            if(String(dates.getHours()) != "0" ){            
                getHours = String(dates.getHours()).padStart(2, "0")
                getMinutes = String(dates.getMinutes()).padStart(2, "0")
                getSeconds = String(dates.getSeconds()).padStart(2, "0")
            }
            dates.setDate(date.getDate())
            currentDay = String(dates.getDate()).padStart(2, "0")
            lastDay = String(dates.getDate()).padStart(2, "0")
            currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
            lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
            const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
            this.setState({
                ...this.state,
                startDate: dates,
                startDateSearch:finalDate,
                errors:{
                    ...this.state.errors,
                    startDateError:""
                }
            })
        }else{
            this.setState({
                ...this.state,
                startDate: "",
                startDateSearch:"",
                errors:{
                    ...this.state.errors,
                    startDateError:"Start date is required"
                }
            })
        }        
    }
    handleEndDateChange = (date:any)=>{
        if(date != "" && date != null && date != undefined){
            this.searchFields[""][2].required=true;
            const dates = new Date(date)
            let currentDay = ""
            let lastDay = ""
            let currentMonth = ""
            let lastMonth = ""
            let getHours = "00"
            let getMinutes = "00"
            let getSeconds = "00"
            if(String(dates.getHours()) != "0" ){            
                getHours = String(dates.getHours()).padStart(2, "0")
                getMinutes = String(dates.getMinutes()).padStart(2, "0")
                getSeconds = String(dates.getSeconds()).padStart(2, "0")
            }
            dates.setDate(date.getDate())
            currentDay = String(dates.getDate()).padStart(2, "0")
            lastDay = String(dates.getDate()).padStart(2, "0")
            currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
            lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
            const finalDate = (`${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`);
            this.setState({
                ...this.state,
                endDate: dates,
                endDateSearch:finalDate,
                errors:{
                    ...this.state.errors,
                    endDateError:""
                }
            })
        }else{
            this.setState({
                ...this.state,
                endDate: "",
                endDateSearch:"",
                errors:{
                    ...this.state.errors,
                    endDateError:"End date is required"
                }
            })
        }        
    }    
    handleviewLiveTerminalSearchFunctionn = (e: any) => {
        e.preventDefault()
        this.handleviewLiveTerminalSearch("search")
    }
    handleviewLiveTerminalSearch=(params: any)=> {
        if (params == "search") {
            this.setState({
                ...this.state,
                page:1,
            })
            this.setState({
                viewLiveTerminalData: [],
                showPrizeReportSearchListing: false,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5
            })
        }
        if (this.validateviewLiveTerminalSearch()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                "companyName":this.state.companyName,
                "distributorName":this.state.distributorName,
                "startDate":this.state.startDateSearch,
                "endDate":this.state.endDateSearch,
                page: this.state.page == 0 ? 0 : this.state.page - 1,
                length: this.state.limit
            }
            //console.log(searchCondition)
            
        const loginData = this.uiData.getLoginResponse()
        if(loginData.partnerTypeId != 5) {

            this.searchFields[""][1].required=false;
            this.searchFields[""][2].required=false;
        }

            const response = this.DownlineManageServices.getLoginRetailer(searchCondition)
            response.then((searchLoginRetailerResponse: any) => {
                if (!searchLoginRetailerResponse.errorStatus) {
                    this.setState({
                        tBody:searchLoginRetailerResponse.data,
                        viewLiveTerminalData: searchLoginRetailerResponse.data,
                        showLoginRetailerReport: true,
                        totalCount: searchLoginRetailerResponse.totalRecords
                    })
                    const data = []

                    for (
                        let i = 0;
                        i < searchLoginRetailerResponse.totalRecords / this.state.noRow;
                        i++
                    ) {
                        data.push(i)
                    }
                    const divData = searchLoginRetailerResponse.totalRecords / this.state.noRow
                    let lastpage = divData
                    if (divData.toString().includes(".")) {
                        const SplitData = divData.toString().split(".")
                        lastpage = parseInt(SplitData[0]) + 1
                    }
                    this.setState({totalCountPagination: data, lastPage: lastpage})
                    //console.log(data)
                    this.closeLoader()
                } else {
                    this.setState({
                        viewLiveTerminalData: [],
                        showLoginRetailerReport: false,
                        totalCount: 0,
                        totalCountPagination: [],
                        currentPage: 1,
                        previousPage: 0,
                        nextPage: 2,
                        lastPage: 0,
                        fromPagePagination: 1,
                        toPagePagination: 5
                    })
                    this.closeLoader()
                }
            }).catch((error:any)=>{
                //console.log(error)
            })
            this.uiData.updateShowLoading(false)
        }
    }
    validateviewLiveTerminalSearch= () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.searchFields).map((keys: any) => {
            {
                return this.searchFields[keys].map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true

                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }
    handleviewLiveTerminalReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state
        Object.keys(this.searchFields).map((keys: any, values: any) => {
            {
                this.setState({[this.searchFields[keys].name]: ""})
                return this.searchFields[keys].map((fieldValObject: any, fieldValIndex: any) => {
                    return (
                        (newArrayError[fieldValObject.name + "Error"] = ""),
                        (newArrayFields[fieldValObject.name] = ""),
                        (newArrayFields[fieldValObject.name + "Val"] = "")
                    )
                })
            }
        })
         
        const loginData = this.uiData.getLoginResponse()
        if(loginData.partnerTypeId != 5) {
            this.searchFields[""][1].required=false;
            this.searchFields[""][2].required=false;
        }
        this.setState({errors: newArrayError, fields: newArrayFields,
            viewLiveTerminalData:[],
            showLoginRetailerReport:false,
            loginId: "",
            username: "",
            accountStatus:"",
            startDateSearch:"",
            endDateSearch:"",
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 2,
            lastPage: 0,
            fromPagePagination: 1,
            toPagePagination: 5
        })
    }
    
    handlePagination=(e: any, page: any)=> {
        //console.log(page); 
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            page: page,
        })
        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        setTimeout(() => {
            this.handleviewLiveTerminalSearch("pagination")
        }, 50)
    }    
    closeLoader() {
        this.uiData.updateShowLoading(false)
    }
    
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.searchFields[""][0].options = getCompanyListResponse.data                   
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    getDistributorsList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getDistributorsListDropdown(data)
        response
            .then((distributorListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!distributorListResponse.errorStatus) {
                    this.searchFields[""][1].options = distributorListResponse.data
                    this.setState({distributorList: distributorListResponse.data})
                }
            })
            .catch((err: any) => {
                //console.log("getDistributorsListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }
    render() {
        return(
            <>
                <div className={classes["right-section"]}>
                    <div className={classes["main-div"]}>
                        <h2>{this.viewLiveTerminalLang.pageTitle}</h2>
                        <form>
                            {/* <SearchFilter  
                                searchFields={this.searchFields}   
                                searchFilterRow={this.state.searchFilterRow}   
                                handleDateInputChange={this.handleDateInputChange}   
                                handleEndDateChange={this.handleEndDateChange}   
                                handleInputChange={this.handleInputChange}   
                                maxDate={this.maxDate}   
                                minDate={this.minDate}   
                                errors={this.state.errors}   
                                state={this.state}   
                            /> */}
                            {Object.keys(this.searchFields).map((keys: any, values: any) => {
                                    return (
                                        <div key={values}>
                                            {Object.keys(this.state.searchFilterRow).map((valRow: any, i: any) => {
                                                return (
                                                    <div className={classes["main-div-input-sec"]} key={i}>
                                                    {this.searchFields[keys].map((value: any, key: any) => {
                                                        if (value.rowId == valRow) {
                                                            return (
                                                                <div className={classes["sub-div"]} key={key}>
                                                                    <label htmlFor="name">{value.label}</label>
                                                                    {value.required && (                                                                            
                                                                        <span className={classes["mandatory-symbol"]}>
                                                                            * &nbsp;
                                                                        </span>
                                                                    )}
                                                                    <InputBox
                                                                        id={value.id}
                                                                        autoComplete={value.autoComplete}
                                                                        maxLength={value.maxLength}
                                                                        className={
                                                                            "form-control " + classes["form-control"]
                                                                        }
                                                                        autoFocus={value.autoFocus}
                                                                        name={value.name}
                                                                        type={value.type}
                                                                        value={this.state[value.name]}
                                                                        options={value.options}
                                                                        selectShowEmpty={value.selectShowEmpty} 
                                                                        onChange={
                                                                            value.type === "date"
                                                                            ? value.name == "startDate"
                                                                                ? this.handleDateInputChange
                                                                                : this.handleEndDateChange
                                                                                    : this.handleInputChange
                                                                        }
                                                                        dateFormat={value.dateFormat}
                                                                        startMaxDate={this.maxDate}
                                                                        endMinDate={this.minDate}
                                                                    />
                                                                    <div className={classes["error"]}>
                                                                        {this.state.errors[value.name + "Error"]}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}                        
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        )
                                    }
                            )}
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value="Search"
                                        onClick={() => this.handleviewLiveTerminalSearchFunctionn(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value="Reset"
                                        onClick={() => this.handleviewLiveTerminalReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                {this.state.showLoginRetailerReport && (
                    <>
                        <div className={"d-flex"}>
                            <TotalRecords totalCount={this.state.totalCount}/>
                            
                            {this.state.totalCount > 0 ? (
                                    <>
                                        <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                        <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                    </>
                                ):""}
                        </div>
                        
                        {/* <TableElement tHead={this.state.tHead} tData={this.state.tBody}/> */}
                        <div className={classes["main-div"] + " " + classes["table-main-div"]} style={{maxHeight: "400px"}}>
                            <Table className={"table table-bordered table-hover table-condensed" +
                                    classes["table"] +
                                    " " +
                                    classes["table-bordered"] +
                                    " " +
                                    classes["table-hover"] +
                                    " " +
                                    classes["table-condensed"]
                                } id="provider_table_id" >
                                <Thead>
                                    <Tr>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.slNo}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.agencyCode}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.agencyName}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.distributorCode}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.distributorName}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.retailerCode}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.retailerName}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.phone}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.lastLogin}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.lastSale}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.currentSale}</Th>
                                        <Th style={{zIndex:0}}>{this.viewLiveTerminalLang.previousDaySale}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                   {this.state.viewLiveTerminalData.length > 0 ? (
                                        <>
                                            {this.state.viewLiveTerminalData.map(
                                                (val: any, ind: any) => {  
                                                let oddEven = "odd"
                                                if (ind % 2 == 0) {
                                                    oddEven = "even"
                                                }
                                                return (
                                                    <Tr
                                                        className={classes["tr_" + oddEven]}
                                                        key={ind}
                                                    >
                                                        <Td>
                                                            {(this.state.currentPage - 1) *
                                                                this.state.noRow +
                                                                ind +
                                                                1}
                                                        </Td>
                                                        <Td>{val.companyCode}</Td>
                                                        <Td>{val.companyName}</Td>
                                                        <Td>{val.distributorCode}</Td>
                                                        <Td>{val.distributorName}</Td>
                                                        <Td>{val.retailerCode}</Td>
                                                        <Td>{val.retailerName}</Td>
                                                        <Td>{val.retailerPhone}</Td>
                                                        <Td>{val.lastLogin}</Td>
                                                        <Td>{val.lastSale}</Td>
                                                        <Td>{val.currentSale}</Td>
                                                        <Td>{val.previousDaySale}</Td>
                                                    </Tr>
                                                )}
                                                )}
                                            </>
                                        ) : (
                                            <Tr className={classes["no_data_found"]}>
                                                <Td colSpan={12}>No record(s) available</Td>
                                            </Tr>
                                        )}
                                </Tbody>
                            </Table>
                        </div>     
                                
                        {this.state.totalCountPagination.length > 0 ? (             
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.viewLiveTerminalData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/>
                                {this.state.excelExport && (
                                    <ExportExcelReport mockData={this.state.mockData} sheetName="game_history"/> 
                                )}
                            </div>
                        ):""}
                        {this.state.totalCountPagination.length > 0 ? (                 
                            <PaginationElement                 
                                fromPagePagination = {this.state.fromPagePagination}
                                toPagePagination = {this.state.toPagePagination}
                                noRow = {this.state.noRow}
                                totalCount = {this.state.totalCount}
                                totalCountPagination = {this.state.totalCountPagination}
                                currentPage = {this.state.currentPage}
                                previousPage = {this.state.previousPage}
                                nextPage = {this.state.nextPage}
                                lastPage = {this.state.lastPage}
                                handlePagination= {this.handlePagination}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
                </div>
            </>

        )
    }
}