export const COMMON_IMAGES = {
    "headerimg": require("../images/header.png"),
    "lottoBanner": require("../images/lotto-banner.png"),
    "ball1": require("../images/ball1.png"),
    "ball2": require("../images/ball2.png"),
    "ball3": require("../images/ball3.png"),
    "ball4": require("../images/ball4.png"),
    "bankDetails": require("../images/myaccount/bank-details.png"),
    "favIcon": require("../images/common/fav.jpg"),
    "maintenTechnicalLandscape": require("../images/common/technical_landscape.jpg"),
    "maintenTechnicalPort": require("../images/common/technical-port.jpg")
}
