import React, {Component} from "react"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {LOGIN_IMAGES} from "../../configuration/assets/base/importer/login"
import {LoginService} from "../../../game_core/type_script/services/login"
import styleClasses from "../../configuration/style/base/screens/login/styles.module.scss"
import {Alert} from "reactstrap"
import {AuthCheck} from "../../../game_core/type_script/services/authcheck"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"
import { InputBox } from "../common/elements/inputbox"
interface Props {
    showLogoutPopup?: any
    onCloseLogoutPopup?: any
}
export class Login extends Component<Props> {
    uiData: UIDataService = UIDataService.getInstance()
    loginResponse = this.uiData.getLoginResponse()
    isLoggedIn = this.uiData.getLoginStatus()
    LoginService = LoginService.getInstance()
    localStorageService = new DataStorage()
    nameInput: any
    state: any = {
        logoutVariable: "true",
        username: "",
        loginId: "",
        password: "",
        securityCode: "",
        captcha: "",
        alertPopupShow: "hide",
        showAlert: false,
        captchaUrl: "",
        refreshSpin: "fa-spin",
        securityCodeEnabled: false,
        captchaEnabled: false,
        loginErrorMsg: "",
        rememberMe: false,
        loginPageInputFieldsObject: [
            {
                id: "loginId",
                name: "loginId",
                faIcon: "fa-sign-in",
                placeHolder: StaticKey.LOCALE_DATA.Login.loginId,
                label: StaticKey.LOCALE_DATA.Login.loginId,
                type: "number",
                autoFocus: true,
                autoComplete: "off",
                maxLength: 15,
                required: true,
                style: {},
                enabled: true
            },
            {
                id: "username",
                name: "username",
                faIcon: "fa-user",
                type: "text",
                autoFocus: false,
                placeHolder: StaticKey.LOCALE_DATA.Login.agencyCode,
                label: StaticKey.LOCALE_DATA.Login.agencyCode,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                enabled: true
            },
            {
                id: "password",
                name: "password",
                faIcon: "fa-lock",
                placeHolder: StaticKey.LOCALE_DATA.Login.password,
                label: StaticKey.LOCALE_DATA.Login.password,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 50,
                required: true,
                style: {},
                enabled: true
            },
            {
                id: "securityCode",
                name: "securityCode",
                faIcon: "fa-key",
                placeHolder: StaticKey.LOCALE_DATA.Login.securityCode,
                label: StaticKey.LOCALE_DATA.Login.securityCode,
                type: "password",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 6,
                required: true,
                style: {},
                enabled: false
            },
            {
                id: "captcha",
                name: "captcha",
                faIcon: "fa-question-circle",
                placeHolder: StaticKey.LOCALE_DATA.Login.captcha,
                label: StaticKey.LOCALE_DATA.Login.captcha,
                type: "number",
                autoFocus: false,
                autoComplete: "off",
                maxLength: 4,
                required: true,
                style: {
                    width: "1%"
                },
                enabled: false
            }
        ]
    }
    passwordStyle = {
        cursor: "pointer"
    }
    authCheck = AuthCheck.getInstance()
    componentDidMount() {
        setTimeout(() => {
            this.setState({...this.state, logoutVariable: localStorage.getItem("logout")})
        }, 100)
        if (this.uiData.getLoginStatus()) {
            this.setState({
                isLoggedIn: 1,
                postLoginValues: {
                    username: this.loginResponse.username,
                    balance: this.loginResponse.userBalance
                }
            })
        }
        this.uiData.updateShowLoading(true)
        this.getConfigDetails()
        this.refreshCaptcha()
        // this.authCheck.checkLoginStatus()
        setTimeout(() => {
            this.setState({...this.state, logoutVariable: "false"})
        }, 5000)
        const rememberMeStorage:any = this.localStorageService.getRememberMeLocalStorage();
        if(rememberMeStorage != "0"){
            const rememberMeStorageJSONParsed:any = JSON.parse(rememberMeStorage);
            this.setState({
                ...this.state,
                loginId:rememberMeStorageJSONParsed.loginId?rememberMeStorageJSONParsed.loginId:'',
                username:rememberMeStorageJSONParsed.username?rememberMeStorageJSONParsed.username:'',
                // password:rememberMeStorageJSONParsed.password?rememberMeStorageJSONParsed.password:'',
                securityCode:rememberMeStorageJSONParsed.securityCode?rememberMeStorageJSONParsed.securityCode:'',
                rememberMe:true
            })
        }
        localStorage.removeItem("logout")
        localStorage.removeItem("accordian")
    }
    componentWillUnmount() {
        document.body.style.overflow = "unset"
    }
    focusInput = () => {
        this.nameInput.focus()
    }
    getConfigDetails = () => {
        const response = this.LoginService.getConfigDetails()
        response
            .then((configResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!configResponse.errorStatus) {
                    this.state.loginPageInputFieldsObject.map((val: any) => {
                        if (!val.enabled) {
                            val.enabled = configResponse.configDetails[val.name]
                            this.setState({
                                [val.name + "Enabled"]: configResponse.configDetails[val.name]
                            })
                        }
                        return val
                    })
                }
            })
            .catch((err: any) => {
              //console.log("getConfigDetailsErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    handleLoginFormInputChange = (e: any) => {
        const value = e.target.value
        if (e.target.name == "loginId") {
            if (value.length > 10) {
                e.preventDefault()
                return false
            }
        }
        if (e.target.type == "number" && value.length > e.target.maxLength) {
            e.preventDefault()
            return false
        }
        if (e.target.name == "securityCode") {
            const regex = /^[0-9]*$/
            if (!regex.test(value)) {
                e.preventDefault()
                return false
            }
        }
        this.setState({
            ...this.state,
            [e.target.name]: value,
            loginErrorMsg: "",
            [e.target.name + "Error"]: ""
        })
    }

    handleLoginFormSubmit = (e: any) => {
        e.preventDefault()
        this.setState({loginErrorMsg: ""})
        if (this.validateLoginForm()) {
            const errorLanguage: any = StaticKey.LOCALE_DATA.ERRORS
            this.uiData.updateShowLoading(true)
            const loginRequestData: any = {}
            this.state.loginPageInputFieldsObject.map((val: any) => {
                if (val.required && val.enabled) {
                    loginRequestData[val.name] = this.state[val.name]
                }
            })
            if(this.state.rememberMe){
                const localStorageData = {
                    "loginId":this.state.loginId,
                    "username":this.state.username,
                    // "password":this.state.password,
                    "securityCode":this.state.securityCode,
                    "rememberMe":true
                }
                this.localStorageService.removeRememberMeLocalStorage();
                const string = JSON.stringify(localStorageData)
                this.localStorageService.setRememberMeLocalStorage(string);
            }else{
                this.localStorageService.removeRememberMeLocalStorage();
            }
            const response = this.LoginService.sendLoginRequest(loginRequestData)
            response
                .then((value: any) => {
                    this.focusInput()
                    //this.resetLoginForm()
                    this.refreshCaptcha()
                    this.uiData.updateShowLoading(false)
                    // console.log(value.loginResponse);
                    // return;
                    if (!value.errorStatus) {
                        /* if (value.loginResponse.passwordResetStatus == 0) {
                            this.uiData.setLoginResponse(value.loginResponse)
                            window.location.href = "/force-change-password.html"
                        } else { */
                        localStorage.setItem("menu", StaticKey.LOCALE_DATA.Home.dashboard)
                        this.uiData.setLoginStatus(true)
                        this.uiData.setLoginResponse(value.loginResponse)
                        // window.location.href = "/"
                        //}
                    } else {
                        this.isLoggedIn = false
                        this.setState({
                            username: "",
                            loginId: "",
                            password: "",
                            securityCode: "",
                            captcha: "",
                            loginErrorMsg: errorLanguage[value.errorCode],
                            showLoginLoader: false
                        })
                    }
                })
                .catch((err: any) => {
                    this.focusInput()
                  //console.log("handleLoginFormSubmitErr", err)
                    this.resetLoginForm()
                    this.refreshCaptcha()
                    this.uiData.updateShowLoading(false)
                    this.isLoggedIn = false
                    this.setState({
                        loginErrorMsg: StaticKey.LOCALE_DATA.Login.loginFailed,
                        showLoginLoader: false
                    })
                })
        }
    }
    handleRememberMe(e:any){
        this.setState({...this.state,rememberMe:e.target.checked})
    }
    validateLoginForm = () => {
        const errorMessage = StaticKey.LOCALE_DATA.Login
        let flag = true
        this.state.loginPageInputFieldsObject.map((val: any) => {
            const errorData = this.uiData.textConversion(errorMessage[val.name])
            if (val.required && val.enabled) {
                if (this.state[val.name] == "") {
                    flag = false
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.Login.isRequired
                    })
                    return
                } else {
                    this.setState({[val.name + "Error"]: ""})
                }
            }
        })
        return flag
    }

    handlePasswordVisibleToggle = () => {
        //
    }

    resetLoginForm = () => {
        this.setState({
            username: "",
            password: "",
            securityCode: "",
            captcha: ""
        })
    }

    handleLogout = () => {
        this.uiData.updateShowLoading(true)
        const response = this.LoginService.logoutUser()
        response
            .then(() => {
                this.uiData.updateShowLoading(false)
                this.uiData.logoutUser(true)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: ""
                    }
                })
            })
            .catch((err: any) => {
              //console.log("handleLogoutErr", err)
                this.uiData.logoutUser(true)
                this.setState({
                    isLoggedIn: false,
                    postLoginValues: {
                        username: "",
                        balance: ""
                    }
                })
            })
    }
    handleAlertClose = () => {
        this.setState({
            showAlert: !this.state.showAlert
        })
    }
    refreshCaptcha = () => {
        // this.uiData.updateShowLoading(true)
        this.setState({
            refreshSpin: "fa-spin"
        })
        const response = this.LoginService.getCaptchaImage()
        response
            .then((captchaRes: any) => {
                this.uiData.updateShowLoading(false)
                if (!captchaRes.errorStatus) {
                    this.setState({
                        captchaUrl: captchaRes.captchaInfo.captchaImgUrl,
                        refreshSpin: ""
                    })
                }
            })
            .catch((err: any) => {
              //console.log("refreshCaptchaErr", err)
                this.setState({
                    refreshSpin: ""
                })
                // this.uiData.updateShowLoading(false)
            })
    }
    captchaImgLoaded = () => {
        this.setState({
            refreshSpin: ""
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className={styleClasses["login-box-main"] + " loginbox-pg-bx"}>
                    <div
                        className={
                            styleClasses["col-half"] + " " + styleClasses["background-white"]
                        }
                        style={{backgroundImage: "url(" + LOGIN_IMAGES["loginBg"] + ")"}}
                    >
                        {/* {this.props.showLogoutPopup && ( */}
                        {this.state.logoutVariable == "true" && (
                            <Alert closeClassName="close" closeAriaLabel="close">
                                You have logged out successfully
                                <button
                                    onClick={this.props.onCloseLogoutPopup}
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    style={{width: "3px", height: "6px", marginLeft: "24px"}}
                                ></button>
                            </Alert>
                        )}
                        <div className={styleClasses["login-box"]}>
                            <div id="header" className={styleClasses["login-logo"]}>
                                <img
                                    src={LOGIN_IMAGES["loginLogo"]}
                                    className={styleClasses["logo"] + " img-fluid"}
                                />
                                <p>{StaticKey.LOCALE_DATA.Login.backofficeManager}</p>
                                <div className={styleClasses["login-error-message"]}>
                                {this.state.loginErrorMsg}
                            </div>
                            </div>
                            
                            <form method="post" acceptCharset="utf-8">
                                {this.state.loginPageInputFieldsObject.map(
                                    (value: any, key: any) => {
                                        if (value.enabled) {
                                            return (
                                                <div
                                                    className={styleClasses["form-group"]}
                                                    key={key}
                                                >
                                                    <div className={styleClasses["input-group"]}>
                                                        <span
                                                            className={
                                                                styleClasses["input-group-addon"]
                                                            }
                                                        >
                                                            <i className={"fa " + value.faIcon}></i>
                                                        </span>
                                                        <input
                                                            ref={
                                                                value.name == "loginId"
                                                                    ? input => {
                                                                          this.nameInput = input
                                                                      }
                                                                    : ""
                                                            }
                                                            type={value.type}
                                                            name={value.name}
                                                            id={value.id}
                                                            autoComplete={value.autoComplete}
                                                            placeholder={value.placeHolder}
                                                            maxLength={value.maxLength}
                                                            className={
                                                                "form-control " +
                                                                styleClasses["form-control"]
                                                            }
                                                            autoFocus={value.autoFocus}
                                                            required={value.required}
                                                            onChange={
                                                                this.handleLoginFormInputChange
                                                            }
                                                            value={this.state[value.name]}
                                                        />
                                                        {value.name == "captcha" ? (
                                                            <>
                                                                <div className="col-4 captchaimg_xs">
                                                                    <span
                                                                        id="captImg"
                                                                        className={
                                                                            styleClasses["captImg"]
                                                                        }
                                                                    >
                                                                        <img
                                                                            onLoad={
                                                                                this
                                                                                    .captchaImgLoaded
                                                                            }
                                                                            src={
                                                                                this.state
                                                                                    .captchaUrl
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 refersh_xs">
                                                                    <a
                                                                        href="#"
                                                                        className={
                                                                            styleClasses[
                                                                                "refersh_xs_a"
                                                                            ]
                                                                        }
                                                                        onClick={
                                                                            this.refreshCaptcha
                                                                        }
                                                                    >
                                                                        <i
                                                                            className={
                                                                                "fa fa-refresh " +
                                                                                this.state
                                                                                    .refreshSpin
                                                                            }
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className={styleClasses["error"]}>
                                                        {this.state[value.name + "Error"]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                )}
                                <div className={styleClasses["form-group"] + " mb-1"}>
                                    <InputBox
                                            type="checkbox"
                                            name="rememberMe"
                                            id="rememberMe"
                                            onChange={()=>this.handleRememberMe(event)}
                                            checked = {this.state.rememberMe}
                                            label1="Remember Me"
                                        />
                                </div>
                                <div className={styleClasses["form-group"] + " mb-1"}>
                                    <input
                                        type="submit"
                                        name="login_submit"
                                        value="Login"
                                        id="login_submit"
                                        className={
                                            "btn btn-primary btn-lg btn-block " +
                                            styleClasses["btn-primary"] +
                                            " " +
                                            styleClasses["login-button"] +
                                            " " +
                                            styleClasses["login-submit-btn"]
                                        }
                                        onClick={this.handleLoginFormSubmit}
                                    />
                                    <label
                                        id="captcha-error"
                                        className="error"
                                        htmlFor="captcha"
                                    ></label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
