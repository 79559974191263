import {EventEmitter} from "eventemitter3"
export class UIDataService extends EventEmitter {
    static classIns: any = {}

    myData = ""
    showLoading: any
    loginData: any
    alertMessage: any
    toastMessage: any
    alertType: any
    toastErrorType: any
    toastHtml: any
    showAlert: any
    loginStatus: any = false
    bankEnteredStatus: any = false
    userBalance: any = 0
    menuName: any = "dashboard"
    toggleStatus: any = ""
    passwordUpdatedStatus: any = 1
    retailerBackList: any = false
    currentClickedMenuGroup: any = "0"

    constructor(_instanceID: string) {
        super()
    }
    static getInstance(_instanceID: any = "default"): any {
        if (!UIDataService.classIns[_instanceID]) {
            return (UIDataService.classIns[_instanceID] = new UIDataService(_instanceID))
        } else {
            return UIDataService.classIns[_instanceID]
        }
    }

    updateMyData(data = "") {
        this.myData = data
    }

    getMyData() {
        return this.myData
    }

    updateShowLoading(data = false) {
        this.showLoading = data
        this.emit("updatedShowLoading")
    }

    getShowLoading() {
        return this.showLoading
    }

    getLoginResponse() {
        return this.loginData
    }

    getLoginPartnerType() {
        const partnerTypeId =
            this.loginData && this.loginData.partnerTypeId ? this.loginData.partnerTypeId : 0
        return partnerTypeId
    }

    setLoginResponse(setLoginData: any) {
        this.loginData = setLoginData
        this.emit("setLoginResponse")
    }

    updateUserBalance() {
        this.emit("updateUserBalance")
    }

    getAlertShowMessage() {
        return {
            "showAlert": this.showAlert,
            "alertType": this.alertType,
            "alertMessage": this.alertMessage
        }
    }

    setAlertShowMessage(showAlert: any, alertType: any, alertMessage: any) {
        this.showAlert = showAlert
        this.alertType = alertType
        this.alertMessage = alertMessage
        this.emit("setAlertShowMessage")
    }

    showBankAccountPopup() {
        this.emit("showBankAccountPopupEmit")
    }
    showBetConfirmPopup() {
        this.emit("showBetConfirmPopup")
    }

    setLoginStatus(status = false) {
        this.loginStatus = status
    }
    getLoginStatus() {
        return this.loginStatus
    }

    setBankEnteredStatus(status: any = false) {
        this.bankEnteredStatus = Number(status) ? true : false
    }
    getBankEnteredStatus() {
        return this.bankEnteredStatus
    }

    setUserBalance(balance = 0) {
        this.userBalance = balance
        this.emit("updateViewUserBalance")
    }
    getUserBalance() {
        return this.userBalance
    }
    logoutUser(type = false) {
        if (type == true) {
            this.myData = "refresh"
        }
        this.loginStatus = false
        this.loginData = {}
        this.emit("setLogoutResponse")
    }

    menuChange(menuName = "") {
        this.menuName = menuName
        this.emit("emitMenuChangeEvent")
    }
    getClickedMenu() {
        return this.menuName
    }

    showToastMessage(toastMessage: any, errorType: any) {
        this.toastMessage = toastMessage
        this.toastErrorType = errorType
        this.emit("showToastMessage")
    }
    showToastMessageToCopy(toastMessage: any, errorType: any, html: any) {
        this.toastMessage = toastMessage
        this.toastErrorType = errorType
        this.toastHtml = html
        this.emit("showToastMessage")
    }

    getToastMessageData() {
        const dataHTML = this.toastHtml
        this.toastHtml = ""
        return {"msg": this.toastMessage, "type": this.toastErrorType, "html": dataHTML}
    }

    toggleSideBar(toggleStatus: any) {
        this.toggleStatus = toggleStatus
        this.emit("toggleSideBar")
    }

    getToggleStatus() {
        return this.toggleStatus
    }

    textConversion(data: any) {
        return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
    }

    setPasswordUpdatedStatus(status: any) {
        this.passwordUpdatedStatus = status
    }

    getPasswordUpdatedStatus() {
        return this.passwordUpdatedStatus
    }

    setRetailerBackList(status: any) {
        this.retailerBackList = status
    }
    getRetailerBackList() {
        return this.retailerBackList
    }
}
