import React, {Component,useRef} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/distributorlisting.module.scss"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Link} from "react-router-dom"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {Modal} from "reactstrap"
import copy from "copy-to-clipboard"
import Swal from "sweetalert2"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"
import {jsPDF} from 'jspdf';
import { PrintTableElement } from "../common/PrintTableElement"
import { TotalRecords } from "../common/totalRecords"
import { ExportExcelReport } from "./ExportExcelReport"
import axios from "axios"

const date = new Date()
const currentDay = String(date.getDate()).padStart(2, "0")
const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
const currentYear = date.getFullYear()
const currentDate = `${currentDay}-${currentMonth}-${currentYear} 00:00:00`
const currentEndDate = `${currentDay}-${currentMonth}-${currentYear} 23:59:59`

export class DistributorListing extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    minDate: any = new Date()
    maxDate: any = new Date()
//  certificateTemplateRef:any = useRef<any>(null);

    state: any = {
        excelExport:false,
        mockData : [{}],
        tableHead:["Sl.NO","Application No","Login ID","Distributor Code","Distributor Name","Address","Status"],
        tableBody:["applicationNo","loginId","PARTNER_USERNAME","PARTNER_NAME","address","ACCOUNT_STATUS"],
        exportHead:["Application No","Login ID","Distributor Code","Distributor Name","Address","Status"],
        exportBody:["applicationNo","loginId","PARTNER_USERNAME","PARTNER_NAME","address","ACCOUNT_STATUS"],
        selectedFile: null,
        LoginpartnerTypeId: "",
        tooltipText: "Click to Copy",
        searchFilterRow: [0, 1],
        fromPagePagination: 1,
        toPagePagination: 5,
        noRow: 25,
        alertPopupShow: false,
        verifyPopupShow: false,
        installPopupShow: false,
        totalCount: 0,
        totalCountPagination: [],
        currentPage: 1,
        previousPage: 0,
        nextPage: 2,
        lastPage: 0,
        username: "",
        accountStatus: "",
        fromDate: null,
        fromDateSearch: "",
        fromDateCallback: true,
        dateRangeCallback: true,
        toDate: null,
        toDateSearch: "",
        toDateError: "",
        showDistributorListing: false,
        distributorSearchData: [],
        securityCodeUsername: "",
        securityCodeLoginId: "",
        securityCode: "",
        companyName: "",
        loginId: "",
        verifyUser: "",
        verifyUserError: "",
        installUser: "",
        installUserError: "",
        distributorCode: "",
        distributorGuid: "",
        pdfFields: {
            newPassword:"",
            confirmPassword:"",
            companyId: "",
            noOfPartnership: "",
            partnerName: "",
            partnerAadharCardNo: "",
            // partnerUsername: "",
            // partnerPassword: "",
            // partnerTransactionPassword: "",
            partnerPanCardNo: "",
            partnerAddress1: "",
            partnerAddress2: "",
            // partnerAddress3: "",
            partnerAddress4: "",
            partnerPinCode: "",
            partnerLocality: "",
            partnerTaluka: "",
            partnerCity: "",
            partnerState: "",
            partnerMobileNo: "",
            partnerWhatsappNo: "",
            partnerTelegramNo: "",
            // partnerwebsite: "",
            partnerEmail: "",
            // partnerLandLineNo: "",
            businessTotalYears: "",
            businessTotalManpower: "",
            businessSalesManpower: "",
            businessOfficeArea: "",
            businessOfficeManpower: "",
            businessOfficeCount: "",
            businessOfficeType: "",
            businessWarehouseArea: "",
            businessWarehouseManpower: "",
            businessWarehouseCount: "",
            businessWarehouseType: "",
            registrationPanCardNo: "",
            registrationAadharCardNo: "",
            registrationIfscCode: "",
            registrationBankName: "",
            registrationBranchName: "",
            registrationAccountNo: "",
            companyName1: "",
            companyName2: "",
            companyName3: "",
            companyCount: "",
            companyProductHandle: "",
            companyOwnedVehicleCount: "",
            companyTowneCount: "",
            uploadAadhar: "",
            uploadAadharVal: "",
            uploadPanCard: "",
            uploadPanCardVal: "",
            uploadDrivingLicence: "",
            uploadDrivingLicenceVal: "",
            uploadEBBill: "",
            uploadEBBillVal: "",
            uploadPassport: "",
            uploadPassportVal: "",
            uploadPhoto: "",
            uploadPhotoVal: "",
            termsAgreed: ""
        },
        fields: {
            "accountStatus": "",
            "loginId": "",
            "companyName": "",
            "toDate": "",
            "fromDate": "",
            "username": "",
            "limit": 25,
            "page": 1
        },
        errors: {
            "loginIdError": "",
            "accountStatusError": "",
            "toDateError": "",
            "fromDateError": "",
            "usernameError": ""
        }
    }
    getDistributorListFields: any = [
        {
            rowId: 0,
            name: "companyName",
            type: "select",
            label: "Company Name",
            // placeHolder: "companyName",
            maxLength: "50",
            autoFocus: true,
            class: "form-control",
            required: false,
            style: {},
            options: {},
            autoComplete: "off",
            "selectShowEmpty": true
        },
        {
            rowId: 0,
            name: "username",
            type: "text",
            label: "Distributor Code",
            // placeHolder: "Username",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            style: {},
            autoComplete: "off"
        },
        {
            rowId: 0,
            name: "loginId",
            label: "Login ID",
            type: "number",
            // placeHolder: "Login ID",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false
        },
        {
            rowId: 1,
            "name": "fromDate",
            "type": "datetime",
            "label": "Start Date",
            // "placeHolder": "Start Date",
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId: 1,
            "name": "toDate",
            "id": "toDate",
            "type": "datetime",
            "label": "End Date",
            // "placeHolder": "End Date",
            "maxLength": currentDate,
            // "disabled": true,
            "autoFocus": false,
            "class": "form-control",
            "required": false,
            "dateFormat": "dd-MM-yyyy HH:mm:ss"
        },
        {
            rowId: 0,
            name: "accountStatus",
            type: "select",
            label: "Status",
            // placeHolder: "Status",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            required: false,
            options: StaticKey.DISTRIBUTORSTATUSDROPDOWN,
            selectShowEmpty: true
        },
        {
            rowId: 1,
            name: "dateRange",
            type: "select",
            label: "Date Range",
            placeHolder: "Date Range",
            maxLength: "50",
            autoFocus: false,
            class: "form-control",
            defaultValue: "",
            required: false,
            options: StaticKey.DATERANGE,
            selectShowEmpty: true
        }
    ]
    
    viewDIstributorFields: any = {
        "A. General Information": [
            {
                name: "noOfPartnership",
                type: "text",
                label: "No of Partnership",
                //placeHolder: "No of Partnership",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"": []},
            {
                name: "partner1name",
                type: "text",
                label: "Name 1",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partner2name",
                type: "text",
                label: "Name 2",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partner3name",
                type: "text",
                label: "Name 3",
                // placeHolder:  "Third Partner Name",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
                // options: {},
                // selectShowEmpty: true
            },
            {"": []},
            // {
            //     name: "companyId",
            //     type: "text",
            //     label: "Company",
            //     //placeHolder: "Company",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true,
                
            // },
            {
                name: "partnerName",
                type: "text",
                label: "Distributor Name",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerUsername",
                type: "text",
                label: "Distributor Code",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"": []},
            {
                name: "partnerAddress1",
                type: "text",
                label: "Address 1",
                placeHolder: "Address1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerAddress2",
                type: "text",
                label: "Address 2",
                placeHolder: "Address2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "partnerPinCode",
                "type": "text",
                "label": "Pincode",
                //"placeHolder": "PinCode",
                "maxLength": 7,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true, 
                 "disabled":"disabled", 
            },
            {
                "name": "partnerTaluka",
                "type": "text",
                "label": "Taluka",
                //"placeHolder: "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerCity",
                "type": "text",
                "label": "City",
                //"placeHolder: "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerState",
                "type": "text",
                "label": "State",
                //"placeHolder: "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            // {
            //     name: "partnerAadharCardNo",
            //     type: "text",
            //     label: "Aadhar Card No",
            //     //placeHolder: "Partner Aadhar Card No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },
            // {
            //     name: "partnerPanCardNo",
            //     type: "text",
            //     label: "PAN No",
            //     //placeHolder: "Partner Pan No",
            //     maxLength: 10,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },  
            // {"": []},
            {
                name: "partnerMobileNo",
                type: "text",
                label: "Mobile No",
                //placeHolder: "Mobile No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            // {
            //     name: "partnerLandLineNo",
            //     type: "text",
            //     label: "Landline No",
            //     //placeHolder: "Landline No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },
            {
                name: "partnerWhatsappNo",
                type: "text",
                label: "Whatsapp No",
                //placeHolder: "Whatsapp No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerTelegramNo",
                type: "text",
                label: "Telegram Id",
                //placeHolder: "Telegram Id",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerEmail",
                type: "text",
                label: "Email Id",
                //placeHolder: "Email",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            // {
            //     name: "partnerwebsite",
            //     type: "text",
            //     label: "Website Address",
            //     //placeHolder: "Website Address",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // }
        ],
        "B. Current Business Information": [
            {
                name: "businessTotalYears",
                type: "text",
                label: "Number of years in business",
                //placeHolder: "Business Total Years",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "businessTotalManpower",
                type: "text",
                label: "Total Manpower",
                //placeHolder: "Total Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "businessSalesManpower",
                type: "text",
                label: "Sales Manpower",
                //placeHolder: "Sales Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                Office: [
                    {
                        name: "businessOfficeArea",
                        type: "text",
                        label: "Total Office Area",
                        //placeHolder: "Total Office Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeManpower",
                        type: "text",
                        label: "Office Manpower",
                        //placeHolder: "Office Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeCount",
                        type: "text",
                        label: "Total No.Of Office",
                        //placeHolder: "Business Office Count",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeType",
                        type: "text",
                        label: "Owned / Rental",
                        //placeHolder: "Business Office Type",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                       
                    }
                ]
            },
            {
                Warehouse: [
                    {
                        name: "businessWarehouseArea",
                        type: "text",
                        label: "Total Warehouse Area",
                        //placeHolder: "Business Warehouse Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseManpower",
                        type: "text",
                        label: "Warehouse Manpower",
                        //placeHolder: "Business Warehouse Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseCount",
                        type: "text",
                        label: "Total No.Of Warehouse",
                        //placeHolder: "Business Warehouse Count",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseType",
                        type: "text",
                        label: "Owned / Rental",
                        //placeHolder: "Business Warehouse Type",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    }
                ]
            }
        ],
        "C. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                //"placeHolder: "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            }, 
            {
                "name": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "---- ---- ----",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            },   
            {"": []},
            {
                name: "registrationIfscCode",
                type: "text",
                label: "IFSC Code",
                //placeHolder: "Registration Ifsc Code",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "registrationBankName",
                type: "text",
                label: "Bank Name",
                //placeHolder: "Registration Bank Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            {
                name: "registrationBranchName",
                type: "text",
                label: "Branch Name",
                //placeHolder: "Registration Branch Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "registrationAccountNo",
                type: "text",
                label: "Account No",
                //placeHolder: "Registration Account No",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
        ],
        "D. Company Details As Distributor/Dealer": [
            {
                name: "companyName1",
                type: "text",
                label: "Companies represented1",
                placeHolder: "Company Name1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyName2",
                type: "text",
                label: "Companies represented 2",
                placeHolder: "Company Name2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyName3",
                type: "text",
                label: "Companies represented3",
                placeHolder: "Company Name3",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            {
                name: "companyCount",
                type: "text",
                label: "No.of Companies represented",
                //placeHolder: "Company Count",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyProductHandle",
                type: "text",
                label: "Product Handle",
                //placeHolder: "Product Handle",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyOwnedVehicleCount",
                type: "text",
                label: "No.of Owned vehicles",
                //placeHolder: "No.of Owned vehicles",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyTowneCount",
                type: "text",
                label: "No.of Town covered",
                //placeHolder: "No.of Town covered",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            }
        ],
        // "Uploaded Files": [
        //     {
        //         "name": "uploadAadhar",
        //         "type": "file",
        //         "label": "Aadhar",
        //         //"placeHolder: "Upload Aadhar",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //          required: false, readonly:true
        //     },
        //     {
        //         "name": "uploadPanCard",
        //         "type": "file",
        //         "label": "Pan Card",
        //         //"placeHolder: "Upload Pan Card",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //          required: false, readonly:true
        //     },
        //     {
        //         "name": "uploadDrivingLicence",
        //         "type": "file",
        //         "label": "Driving Licence",
        //         //"placeHolder: "Upload Driving Licence",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //         "required": false
        //     },
        //     {
        //         "name": "uploadEBBill",
        //         "type": "file",
        //         "label": "Light Bill",
        //         //"placeHolder: "Upload EBBill",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //         "required": false
        //     },
        //     {
        //         "name": "uploadPassport",
        //         "type": "file",
        //         "label": "Passport",
        //         //"placeHolder: "Upload Passport",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //         "required": false
        //     },
        //     {
        //         "name": "uploadPhoto",
        //         "type": "file",
        //         "label": "Photo",
        //         //"placeHolder: "Upload Photo",
        //         "maxLength": 50,
        //         "autoFocus": false,
        //         "class": "form-control",
        //         "required": false
        //     }
        // ],
    }

    handleSearchPartnerLimitReset = () => {
        const newArrayError = this.state.errors
        const newArrayFields = this.state.fields
        Object.keys(this.getDistributorListFields).map((keys: any, values: any) => {
            {
                this.setState({[this.getDistributorListFields[keys].name]: ""})
                return this.getDistributorListFields.map(
                    (fieldValObject: any, fieldValIndex: any) => {
                        this.getDistributorListFields[4].required = false
                        this.getDistributorListFields[4].min = ""
                        // document.getElementById("toDate")?.setAttribute("readonly", "readonly")
                        // this.getDistributorListFields[4].type = "text";
                        return (
                            (newArrayError[fieldValObject.name + "Error"] = ""),
                            (newArrayFields[fieldValObject.name] = ""),
                            (newArrayFields[fieldValObject.name + "Val"] = "")
                        )
                    }
                )
            }
        })

        const date = new Date()
        const currentDay = String(date.getDate()).padStart(2, "0")
        const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
        const currentYear = date.getFullYear()
        const currentDate = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
        const currentEndDate = `${currentYear}-${currentMonth}-${currentDay} 23:59:59`

        this.setState({errors: newArrayError, fields: newArrayFields,
            fromDate: "",
            toDate: "",
            fromDateSearch:"",
            toDateSearch:"",
            loginId: "",
            username: "",
            accountStatus: "",
            distributorSearchData: [],
            showDistributorListing: false,
            totalCount: 0,
            totalCountPagination: [],
            currentPage: 1,
            previousPage: 0,
            nextPage: 2,
            lastPage: 0,
            fromPagePagination: 1,
            toPagePagination: 5,
        })
    }
    validateCreatePartnerLimitSearchForm = () => {
        const flagError: any = []
        const newErrorMsgArray: any = []
        Object.keys(this.getDistributorListFields).map((keys: any) => {
            {
                return this.getDistributorListFields.map((fieldValObject: any) => {
                    if (fieldValObject.required && this.state[fieldValObject.name] == "") {
                        flagError[fieldValObject.name + "Error"] = true

                        const errorData = this.uiData.textConversion(fieldValObject.label)
                        return (newErrorMsgArray[fieldValObject.name + "Error"] =
                            errorData + " is required")
                    } else {
                        return (newErrorMsgArray[fieldValObject.name + "Error"] = "")
                    }
                })
            }
        })
        // this.uiData.updateShowLoading(false)
        this.setState({errors: newErrorMsgArray})
        return Object.keys(flagError).length > 0 ? false : true
    }

    handlePagination(e: any, page: any) {
        this.setState({
            ...this.state,
            currentPage: page,
            previousPage: page - 1,
            nextPage: page + 1,
            fields: {...this.state.fields, page: page}
        })
        const nPage = page + 1
        let fromPage = nPage - 2 > 1 ? nPage - 2 : page
        let toPage = page == this.state.lastPage ? this.state.lastPage : nPage + 2
        if (page <= 2) {
            fromPage = 0
            toPage = 5
            this.setState({
                fromPagePagination: fromPage,
                toPagePagination: toPage
            })
        } else if (page == this.state.lastPage || page == this.state.lastPage - 1) {
            this.setState({
                fromPagePagination: this.state.lastPage - 4,
                toPagePagination: this.state.lastPage
            })
        } else {
            this.setState({
                fromPagePagination: fromPage - 1,
                toPagePagination: toPage - 1
            })
        }
        setTimeout(() => {
            this.handlePartnerSearchAjax("pagination")
        }, 50)
    }
    handlePartnerSearch = (e: any) => {
        e.preventDefault()
        this.handlePartnerSearchAjax("search")
    }
    handlePartnerSearchAjax(params: any) {
        if (params == "search") {
            this.setState({
                ...this.state,
                totalCount: 0,
                totalCountPagination: [],
                currentPage: 1,
                previousPage: 0,
                nextPage: 2,
                lastPage: 0,
                fromPagePagination: 1,
                toPagePagination: 5,
                fields: {
                    ...this.state.fields,
                    page: 1
                }
            })
        }
        if (this.validateCreatePartnerLimitSearchForm()) {
            this.uiData.updateShowLoading(true)
            const searchCondition = {
                loginId: this.state.fields.loginId,
                username: this.state.fields.username,
                companyName: this.state.fields.companyName,
                accountStatus: this.state.accountStatus,
                toDate: this.state.toDateSearch,
                fromDate: this.state.fromDateSearch,
                partnerTypeId: 5,
                page: this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                length: this.state.fields.limit
            }
            
            const storage:any = [
                this.state.fields.loginId,
                this.state.fields.username,
                 this.state.fields.companyName,
                 this.state.accountStatus,
                 this.state.toDate,
                 this.state.fromDate,
                 this.state.fields.page == 0 ? 0 : this.state.fields.page - 1,
                 this.state.fields.limit,
                 this.state.fields.dateRange,
                 this.state.toDateSearch,
                 this.state.fromDateSearch,
            ]
            //console.log(storage)
            const string = JSON.stringify(storage)
            this.uiData.setRetailerBackList(false)
            this.localStorageService.removeSearchConditionLocalStorage();
            this.localStorageService.setSearchConditionLocalStorage(string);

            //console.log(searchCondition)
            const response = this.DownlineManageServices.getDistributorListSearch(searchCondition)
            response
                .then((searchDistributorResponse: any) => {
                    // //console.log(searchDistributorResponse.data);
                    if (!searchDistributorResponse.errorStatus) {
                        this.setState({
                            distributorSearchData: searchDistributorResponse.data,
                            showDistributorListing: true,
                            totalCount: searchDistributorResponse.totalRecords
                        })
                        const data = []
                        for (
                            let i = 0;
                            i < searchDistributorResponse.totalRecords / this.state.noRow;
                            i++
                        ) {
                            data.push(i)
                        }
                        const divData = searchDistributorResponse.totalRecords / this.state.noRow
                        let lastpage = divData
                        if (divData.toString().includes(".")) {
                            const SplitData = divData.toString().split(".")
                            lastpage = parseInt(SplitData[0]) + 1
                        }
                        const dataResponse = searchDistributorResponse.data;
                        const obj:any = [];
                        for(let i=0; i<dataResponse.length; i++){
                            const obj1:any = {};
                            for(let j=0; j<this.state.exportBody.length; j++){  
                                const keyDa = this.state.exportHead[j];
                                let value:any = dataResponse[i][this.state.exportBody[j]] ? dataResponse[i][this.state.exportBody[j]] : "-";
                                if(this.state.exportBody[j] == "ACCOUNT_STATUS"){
                                    value =  StaticKey.DISTRIBUTORSTATUS[
                                        dataResponse[i][this.state.exportBody[j]]
                                    ].name
                                }
                                obj1[keyDa]= value;
                            }
                            obj.push(obj1)
                        }
                        this.setState({totalCountPagination: data, lastPage: lastpage,mockData:obj})
                        this.closeLoader()
                    } else {
                        this.setState({
                            distributorSearchData: [],
                            showDistributorListing: false,
                            totalCount: 0,
                            totalCountPagination: [],
                            currentPage: 1,
                            previousPage: 0,
                            nextPage: 2,
                            lastPage: 0,
                            fromPagePagination: 1,
                            toPagePagination: 5
                        })
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    // //console.log("handlePartnerSearchErr", err)
                    this.closeLoader()
                    this.setState({
                        distributorSearchData: [],
                        showDistributorListing: false,
                        totalCount: 0,
                        totalCountPagination: [],
                        currentPage: 1,
                        previousPage: 0,
                        nextPage: 2,
                        lastPage: 0,
                        fromPagePagination: 1,
                        toPagePagination: 5
                    })
                })
        }
    }

    closeLoader() {
        //setTimeout(() => {
        this.uiData.updateShowLoading(false)
        // }, 500)
    }
    componentDidMount(): void {
        // document.getElementById('toDate')?.setAttribute('readonly',"readonly");
        document.getElementById("reset_form")?.click()
        const loginData = this.uiData.getLoginResponse()

        const retailerBack = this.uiData.getRetailerBackList()
        if(retailerBack){
            const localDataSearch:any = this.localStorageService.getSearchConditionLocalStorage();
            const localSearchData = JSON.parse(localDataSearch)
            //console.log(localSearchData)
            this.setState({
                username: localSearchData[1],
                companyName: localSearchData[2],
                accountStatus: localSearchData[3],
                toDate: localSearchData[4]!= "" ? new Date(localSearchData[4]) : "",
                fromDate:  localSearchData[5]!= "" ? new Date(localSearchData[5]) : "",
                toDateSearch: localSearchData[9] != "" ? new Date(localSearchData[9]) : "",
                fromDateSearch: localSearchData[10] != "" ? new Date(localSearchData[10]) : "",
                loginId: localSearchData[0],
                dateRange: localSearchData[8],
                fields:{
                    username: localSearchData[1],
                    companyName: localSearchData[2],
                    accountStatus: localSearchData[3],
                    toDate: localSearchData[4]!= "" ? new Date(localSearchData[4]) : "",
                    fromDate:  localSearchData[5]!= "" ? new Date(localSearchData[5]) : "",
                    loginId: localSearchData[0],
                    dateRange: localSearchData[8],
                    page: 0,
                    limit:this.state.fields.limit
                }
            })
            if(localSearchData[1] != "" && localSearchData[1] != null && localSearchData[1] != undefined ){
                this.getCompanyList({
                    "partnerTypeId": 3
                })
            }
            
            setTimeout(()=>{
            this.handlePartnerSearchAjax("search")                
                //this.localStorageService.removeSearchConditionLocalStorage();
            },100)           
        }

        this.setState({
            LoginpartnerTypeId: loginData.partnerTypeId
        })
        const agentLoginHideFields: any = []
        if (loginData.partnerTypeId == 0) {
            this.getCompanyList({
                "partnerTypeId": 3
            })
        } else {
            agentLoginHideFields.push("companyName")
            return this.getDistributorListFields.map((val: any, ind: any) => {
                if (val.name == "companyName") {
                    this.getDistributorListFields.splice(val, 1)
                }
                if (loginData.partnerTypeId == 3) {
                    this.getDistributorListFields[0].autoFocus = true
                }
            })
        }
    }

    getCompanyList(data: any) {
        this.uiData.updateShowLoading(true)
        const response = this.DownlineManageServices.getCompanyListDropdown(data)
        response
            .then((getCompanyListResponse: any) => {
                this.uiData.updateShowLoading(false)
                if (!getCompanyListResponse.errorStatus) {
                    this.getDistributorListFields[0].options = getCompanyListResponse.data
                    this.setState({companyList: getCompanyListResponse.data})
                }
            })
            .catch((err: any) => {
                // //console.log("getCompanyListErr", err)
                this.uiData.updateShowLoading(false)
            })
    }

    handlePartnerCreditLimit(e: any) {
        e.preventDefault()
    }

    handleAlertPopupClose = () => {
        this.setState({
            alertPopupShow: false,
            securityCode: "",
            securityCodeLoginId: "",
            securityCodeUsername: "",
            tooltipText: "Click to Copy"
        })
    }
    handleVerifyPopupClose = () => {
        this.setState({verifyPopupShow: false})
    }
    handleInstallPopupClose = () => {
        this.setState({installPopupShow: false})
    }
    handleSecurityCode = (evt: any, username: any, val: any, loginId: any) => {
        evt.preventDefault()
        // const data = username + "," + val
        this.setState({
            securityCode: val,
            securityCodeUsername: username,
            securityCodeLoginId: loginId,
            alertPopupShow: true
        })
        // const data=username+","+val;
        // this.uiData.showToastMessageToCopy(data, "info", "copy")
    }

    copyToClipboard() {
        this.setState({tooltipText: "Copied"})
        copy(this.state.securityCode)
    }
    handleSecurityCodeBlur = () => {
        this.setState({
            tooltipText: "Click to Copy"
        })
    }
    partnerLimitControlFilterChange = (e: any) => {
        const value = e.target.value
        // this.setState({
        //     ...this.state,
        //     [e.target.name]: value,
        //     [e.target.name + "Error"]: ""
        // })
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: value
            },
            errors: {
                ...this.state.errors,
                [e.target.name + "Error"]: ""
            }
        })
        this.setState({[e.target.name]: value})
        if (this.state[e.target.name + "Callback"]) {
            // if (e.target.name == "fromDate") {
            //     this.getDistributorListFields[4].min = value
            //     this.getDistributorListFields[4].type = "datetime-local"
            // }
            if (e.target.name == "dateRange") {

                let currentDay = ""
                let lastDay = ""
                let currentMonth = ""
                let lastMonth = ""
                let valid = true
                const date = new Date()
                if (e.target.value == "") {                    
                    this.setState({
                        ...this.state,
                        fromDate: "",
                        fromDateSearch: "",
                        toDate: "",
                        toDateSearch: "",
                        fields: {
                            ...this.state.fields,
                            dateRange:"",
                            fromDate: "",
                            toDate: ""
                        }
                    })
                }else{
                    if (e.target.value == "today") {
                        currentDay = String(date.getDate()).padStart(2, "0")
                        lastDay = String(date.getDate()).padStart(2, "0")
                        currentMonth = String(date.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(date.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "yesterday") {
                        const yesterday = new Date(date)
                        yesterday.setDate(date.getDate() - 1)
                        currentDay = String(yesterday.getDate()).padStart(2, "0")
                        lastDay = String(yesterday.getDate()).padStart(2, "0")
                        currentMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(yesterday.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thisWeek") {
                        const today = new Date()
                        const startOfWeek = new Date(today)
                        const endOfWeek = new Date(today)

                        startOfWeek.setDate(today.getDate() - today.getDay())
                        endOfWeek.setDate(today.getDate() - today.getDay() + 6)
                        const startDate = new Date(startOfWeek.setHours(0, 0, 0, 0))
                        const endDate = new Date(endOfWeek.setHours(23, 59, 59, 999))

                        currentDay = String(startDate.getDate() + 1).padStart(2, "0")
                        lastDay = String(endDate.getDate()).padStart(2, "0")
                        currentMonth = String(startDate.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(endDate.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastweek") {
                        const dateL = new Date()
                        const to = new Date(
                            dateL.setTime(
                                dateL.getTime() -
                                    (dateL.getDay() ? dateL.getDay() : 7) * 24 * 60 * 60 * 1000
                            )
                        )
                        const from = new Date(dateL.setTime(dateL.getTime() - 6 * 24 * 60 * 60 * 1000))
                        currentDay = String(from.getDate() - from.getDay() + 1).padStart(2, "0")
                        lastDay = String(to.getDate() - to.getDay()).padStart(2, "0")
                        currentMonth = String(from.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "lastmonth") {
                        const d = new Date()
                        d.setDate(1)
                        const to = new Date(d.setHours(-1))
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(to.getDate()).padStart(2, "0")
                        currentMonth = String(to.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(to.getMonth() + 1).padStart(2, "0")
                    } else if (e.target.value == "thismonth") {
                        const d = new Date()
                        const lastDays = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        currentDay = String("1").padStart(2, "0")
                        lastDay = String(lastDays.getDate()).padStart(2, "0")
                        currentMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                        lastMonth = String(lastDays.getMonth() + 1).padStart(2, "0")
                    } else {
                        valid = false
                    }
                    let currentDateNew = null
                    let currentEndDateNew = null
                    if (valid) {
                        currentDateNew = new Date(
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        )
                        currentEndDateNew = new Date(`${currentYear}-${lastMonth}-${lastDay} 23:59:59`)
                    }

                    this.setState({
                        ...this.state,
                        dateRange:e.target.value,
                        fromDate: currentDateNew,
                        fromDateSearch: (
                            `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
                        ),
                        toDate: currentEndDateNew,
                        toDateSearch: (`${currentYear}-${lastMonth}-${lastDay} 23:59:59`),
                        fields: {
                            ...this.state.fields,
                            dateRange:e.target.value,
                            fromDate: currentDateNew,
                            toDate: currentEndDateNew
                        }
                    })
                }
            }
        }
    }
    handleCreateLoginID(guid: any, distributorName: any) {
        Swal.fire({
            title: "Are you sure to Create Login ID for " + distributorName + " ?",
            timer: 10000,
            icon: "info",
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {
                this.handleCreateLoginIdService(guid)
            }
        })
    }
    handleRejectDistributor(guid: any, distributorName: any) {
        Swal.fire({
            title: "Are you sure to Reject " + distributorName + " ?",
            // timer: 10000,
            icon: "info",
            showCancelButton: true,
            width: "400px",
            heightAuto: false,
            customClass: {
                icon: "swal-icon",
                popup: "swal-height",
                title: "swal-title",
                confirmButton: "swal-button",
                denyButton: "swal-button",
                cancelButton: "swal-button"
            },
            imageHeight: "100px"
        }).then(result => {
            if (result.isConfirmed) {
                this.handleUserVerifyService(guid, "", "REJECTED")
            }
        })
    }
    //handleInstallUser(guid : any,distributorName:any){
    //     Swal.fire({
    //         title: "Install "+distributorName+" ?",
    //         input: 'text',
    //         inputPlaceholder: 'Name',
    //         inputAttributes: {
    //             autocapitalize: "off",
    //             autocomplete:"off",
    //             id: "installName",
    //             required:"true",
    //         },
    //         showCancelButton:true,
    //         didOpen: () => {
    //             // Swal.disableButtons()
    //             const input: any = Swal.getInput()
    //             input.oninput = () => {
    //                 if (input.value.length > 0) {
    //                     Swal.enableButtons()
    //                 } else {
    //                     Swal.disableButtons()
    //                 }
    //             }
    //         },
    //     }).then(result => {
    //         if (result.isConfirmed) {
    //             const input: any = Swal.getInput()
    //             if (input.value != "" || input.value != null) {
    //                 this.handleUserVerifyService(guid, input.value,"ACTIVE")
    //             } else {
    //                 //console.log("asd")
    //             }
    //         }
    //     });
    // }
    handleVerifyUser(guid: any, distributorName: any, e: any) {
        e.preventDefault()
        this.setState({
            verifyPopupShow: true,
            distributorCode: distributorName,
            distributorGuid: guid
        })
        setTimeout(() => {
            this.setState({...this.state, "verifyUser": "", "verifyUserError": ""})
            document.getElementById("verifyUser")?.focus()
        }, 100)
    }
    handleOnChangeVerifyState(e:any){
        if(e.target.value.length > 50){
            return false;
        }
        this.setState({verifyUser:e.target.value,verifyUserError:""})
    }
    handleVerifyUserSubmit(e: any) {
        e.preventDefault()
        if (this.state.verifyUser == "") {
            this.setState({...this.state, "verifyUserError": "Name is required"})
            document.getElementById("verifyUser")?.focus()
            return
        }
        this.handleUserVerifyService(this.state.distributorGuid, this.state.verifyUser, "VERIFIED")
    }
    handleInstallUser(guid: any, distributorName: any, e: any) {
        e.preventDefault()
        this.setState({
            installPopupShow: true,
            distributorCode: distributorName,
            distributorGuid: guid
        })
        setTimeout(() => {
            this.setState({...this.state, "installUser": "", "installUserError": ""})
            document.getElementById("installUser")?.focus()
        }, 100)
    }
    handleOnChangeInstallState(e:any){
        if(e.target.value.length > 50){
            return false;
        }
        this.setState({installUser:e.target.value,installUserError:""})
    }
    handleInstallUserSubmit(e: any) {
        e.preventDefault()
        if (this.state.installUser == "") {
            this.setState({...this.state, "installUserError": "Name is required"})
            document.getElementById("installUser")?.focus()
            return
        }

        this.handleUserVerifyService(this.state.distributorGuid, this.state.installUser, "ACTIVE")
    }
    handleCreateLoginIdService(guid: any) {
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            partnerGuid: guid
        }
        const response = this.DownlineManageServices.createLoginID(searchCondition)
        response
            .then((searchDistributorResponse: any) => {
                if (!searchDistributorResponse.errorStatus) {
                    this.uiData.showToastMessage("Login ID has been created Successfully", "")
                    this.handlePartnerSearchAjax("search")
                    this.closeLoader()
                } else {
                    this.uiData.showToastMessage("Something went wrong", "error")
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // //console.log("handlePartnerSearchErr", err)
                this.closeLoader()
            })
    }
    handleUserVerifyService(guid: any, name: any, type: any) {
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            name: name ? name : "",
            partnerGuid: guid,
            applicationStatus: type
        }
        //console.log(searchCondition)
        const response = this.DownlineManageServices.changeDistributorStatus(searchCondition)
        response
            .then((searchDistributorResponse: any) => {
                if (!searchDistributorResponse.errorStatus) {
                    let showMessage = ""
                    if (type == "VERIFIED") {
                        showMessage = "Application has been Verified Successfully"
                    } else if (type == "REJECTED") {
                        showMessage = "Application has been Rejected"
                    } else {
                        showMessage = "Application has been Installed Successfully"
                    }
                    this.uiData.showToastMessage(showMessage, "")
                    this.handlePartnerSearchAjax("search")
                    this.closeLoader()
                    this.handleVerifyPopupClose()
                    this.handleInstallPopupClose()
                } else {
                    this.uiData.showToastMessage("Something went wrong", "error")
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // //console.log("handlePartnerSearchErr", err)
                this.closeLoader()
            })
    }
    handleStartDateChange = (date: any) => {
        this.minDate = date
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if (String(dates.getHours()) != "0") {
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = `${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`
        this.setState({
            fromDate: date,
            fromDateSearch: finalDate
            //toDate: null
        })
    }
    handleEndDateChange = (date: any) => {
        this.maxDate = date
        const eDate = new Date(date)
        const hours = eDate.getHours()
        const mins = eDate.getMinutes()
        const secs = eDate.getSeconds()
        if (hours == 0 && mins == 0 && secs == 0) {
            eDate.setHours(23)
            eDate.setMinutes(59)
            eDate.setSeconds(59)
        }
        const dates = new Date(date)
        let currentDay = ""
        let lastDay = ""
        let currentMonth = ""
        let lastMonth = ""
        let getHours = "00"
        let getMinutes = "00"
        let getSeconds = "00"
        if (String(dates.getHours()) != "0") {
            getHours = String(dates.getHours()).padStart(2, "0")
            getMinutes = String(dates.getMinutes()).padStart(2, "0")
            getSeconds = String(dates.getSeconds()).padStart(2, "0")
        }
        dates.setDate(date.getDate())
        currentDay = String(dates.getDate()).padStart(2, "0")
        lastDay = String(dates.getDate()).padStart(2, "0")
        currentMonth = String(dates.getMonth() + 1).padStart(2, "0")
        lastMonth = String(dates.getMonth() + 1).padStart(2, "0")
        const finalDate = `${currentYear}-${lastMonth}-${lastDay} ${getHours}:${getMinutes}:${getSeconds}`

        this.setState({
            toDate: eDate,
            toDateSearch: finalDate
        })
    }
    handleGeneratePdf = (e:any,partnerId:any,partnerName:any) => {	
        this.uiData.updateShowLoading(true)
        e.preventDefault();	
        this.getDistributorDetail(partnerId)
        setTimeout(()=>{
            const Canvas:any = document.getElementById("downloadPdf");
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.internal.pageSize.getWidth();
            pdf.internal.pageSize.getHeight();
            const opt:any = {
                callback: function (pdf:any) {
                    pdf.save(partnerName+".pdf");
                },
                margin: [10, 10, 10, 2],
                autoPaging: 'text',
                html2canvas: {
                    allowTaint: true,
                    dpi: 50,
                    letterRendering: true,
                    logging: false,
                    scale: .2
                }
            };
            pdf.html(Canvas, opt);
            this.uiData.updateShowLoading(false)
        },2000);
	};
    
    getDistributorDetail = (id: any) => {
        
        // this.uiData.updateShowLoading(true)
        const searchCondition = {
            "partnerGuid": id,
        }
        const response = this.DownlineManageServices.getDistributorDetail(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                //console.log(searchPartnerLimitResponse)
                if (!searchPartnerLimitResponse.errorStatus) {
                    const responseData = searchPartnerLimitResponse.data;
                    this.setState({
                        distributorData: searchPartnerLimitResponse.data,
                        guId: id
                    })
                            if(responseData.noOfPartnership == 1){
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=false;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=true;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 2){
                                
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.removeAttribute('readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=true;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=false;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 3){
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.removeAttribute('readonly');
                                document.getElementById('partner3name')?.removeAttribute('readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=true;
                                this.viewDIstributorFields["A. General Information"][4].required=true;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=false;
                                this.viewDIstributorFields["A. General Information"][4].dnone=false;
                                
                            }
                            if(responseData.noOfPartnership == 0 || responseData.noOfPartnership == ""){
                                document.getElementById('partner1name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner2name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=false;
                                this.viewDIstributorFields["A. General Information"][3].required=false;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=true;
                                this.viewDIstributorFields["A. General Information"][3].dnone=true;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                                
                            }
                    let partner1,partner2,partner3 ="-";
                    if(responseData.partnershipName && responseData.partnershipName !=""){
                        const dataSplit = responseData.partnershipName.split(",");
                        partner1 = dataSplit[0] ? dataSplit[0] : "-";
                        partner2 = dataSplit[1] ? dataSplit[1] : "-";
                        partner3 = dataSplit[2] ? dataSplit[2] : "-";
                    }  
                    this.setState({
                        ...this.state.fields,
                        fields: {
                            noOfPartnership: responseData.noOfPartnership ? responseData.noOfPartnership:"0",
                            companyId: responseData.companyId ? responseData.companyId:"-",
                            partnerName: responseData.partnerName ? responseData.partnerName:"-",
                            partner1name: partner1,
                            partner2name: partner2,
                            partner3name: partner3,
                            // partnerAadharCardNo: "1231 2312 3123",
                            partnerUsername: responseData.partnerUsername ? responseData.partnerUsername : "-",
                            // partnerPassword: "",
                            // partnerTransactionPassword: "",
                            // partnerPanCardNo: "",
                            partnerAddress1:  responseData.address? responseData.address:"-",
                            partnerAddress2: responseData.address1 ? responseData.address1:"-",
                            // partnerAddress3: "",
                            // partnerAddress4: "",
                            partnerPinCode: responseData.zipcode ? responseData.zipcode:"-",
                            partnerLocality: responseData.locality ? responseData.locality:"-",
                            partnerTaluka: responseData.taluk ? responseData.taluk:"-",
                            partnerCity: responseData.cityName ? responseData.cityName:"-",
                            partnerState: responseData.stateName ? responseData.stateName:"-",
                            partnerMobileNo: responseData.mobileNo ? responseData.mobileNo:"-",
                            partnerWhatsappNo: responseData.whatsappNo ? responseData.whatsappNo:"-",
                            partnerTelegramNo: responseData.telegramNo ? responseData.telegramNo:"-",
                            // partnerwebsite: "",
                            partnerEmail: responseData.emailId ? responseData.emailId:"-",
                            // partnerLandLineNo: "",
                            businessTotalYears: responseData.noOfYearsBusiness ? responseData.noOfYearsBusiness:"-",
                            businessTotalManpower: responseData.totalManPower ? responseData.totalManPower:"-",
                            businessSalesManpower: responseData.salesManPower ? responseData.salesManPower:"-",
                            businessOfficeArea:  responseData.totalOfficeArea ? responseData.totalOfficeArea:"-",
                            businessOfficeManpower: responseData.officeManPower ? responseData.officeManPower:"-",
                            businessOfficeCount: responseData.totalNoOfOffice ? responseData.totalNoOfOffice:"-",
                            businessOfficeType: responseData.officeType == 1 ? "Owned" : "Rental",
                            businessWarehouseArea: responseData.totalWarehouseArea ? responseData.totalWarehouseArea:"-",
                            businessWarehouseManpower: responseData.warehouseManpower ? responseData.warehouseManpower:"-",
                            businessWarehouseCount: responseData.totalNoofWarehouse ? responseData.totalNoofWarehouse:"-",
                            businessWarehouseType: responseData.warehouseType == 1 ? "Owned" : "Rental",
                            registrationPanCardNo: responseData.pancardNo ? responseData.pancardNo:"-",
                            registrationAadharCardNo: responseData.aadharNo ? responseData.aadharNo:"-",
                            registrationIfscCode: responseData.ifscCode ? responseData.ifscCode:"-",
                            registrationBankName: responseData.bankName ? responseData.bankName:"-",
                            registrationBranchName: responseData.branchName ? responseData.branchName:"-",
                            registrationAccountNo: responseData.accountNo ? responseData.accountNo:"-",
                            companyName1: responseData.listOfCompany1 ? responseData.listOfCompany1:"-",
                            companyName2:  responseData.listOfCompany2 ? responseData.listOfCompany2:"-",
                            companyName3:  responseData.listOfCompany3 ? responseData.listOfCompany3:"-",
                            companyCount: responseData.noOfCompanies ? responseData.noOfCompanies:"-",
                            companyProductHandle: responseData.productHandled ? responseData.productHandled:"-",
                            companyOwnedVehicleCount: responseData.noOfOwnedVehicles ? responseData.noOfOwnedVehicles:"-",
                            companyTowneCount: responseData.noOfTownCovered ? responseData.noOfTownCovered:"-",
                            uploadAadhar: responseData.aadharImage !="" ? responseData.aadharImage : "-",
                            uploadAadharVal: responseData.aadharImageUrl ? responseData.aadharImageUrl:"-" ,
                            uploadPanCard: responseData.pancardImage  !="" ? responseData.pancardImage : "-",
                            uploadPanCardVal:responseData.pancardImageUrl ? responseData.pancardImageUrl:"-",
                            uploadDrivingLicence: responseData.drivingLicenceImage  !="" ? responseData.drivingLicenceImage : "-",
                            uploadDrivingLicenceVal: responseData.drivingLicenceImageUrl ? responseData.drivingLicenceImageUrl:"-",
                            uploadEBBill: responseData.ebBillImage  !="" ? responseData.ebBillImage : "-",
                            uploadEBBillVal: responseData.ebBillImageUrl ? responseData.ebBillImageUrl:"-",
                            uploadPassport: responseData.passportImage  !="" ? responseData.passportImage : "-",
                            uploadPassportVal: responseData.passportImageUrl ? responseData.passportImageUrl:"-",
                            uploadPhoto: responseData.photo  !="" ? responseData.photo : "-", 
                            uploadPhotoVal: responseData.photoUrl ? responseData.photoUrl:"-",
                            termsAgreed: ""
                        }
                    })
                    // this.closeLoader()
                } else {
                    this.setState({
                        distributorData: []
                    })
                    // this.closeLoader()
                }
            })
            .catch((err: any) => {
                // //console.log("handleCreatePartnerLimitSearchErr", err)
                // this.uiData.updateShowLoading(false)
                this.setState({
                    distributorData: []
                })
                // this.closeLoader()
            })
    }
    onFileUpload = () => {
        const formData = new FormData();
        formData.append("myFile",this.state.selectedFile,);
        formData.append("userAgent","");
        formData.append("ucid","stringucid");
        formData.append("channel","1");
        formData.append("ipAddress","");
        // const data: any = {
        //     "file":this.state.selectedFile
        // };
        // const response = this.DownlineManageServices.fileUpload(formData)
        // response
        //     .then((searchDistributorResponse: any) => {
        //         //console.log(searchDistributorResponse)
        //     });
        axios.post("http://qaslapi.xlivegaming.com/fileUpload",formData).then((res) => {
            //console.log("ins",res);
        }).catch((err)=>{
            //console.log("err",err)
        })
     
    };
    fileData = () => {
 
        if (this.state.selectedFile) {
 
            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
 
                    <p>File Type: {this.state.selectedFile.type}</p>
 
                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
 
                </div>
            );
        // } else {
        //     return (
        //         <div>
        //             <br />
        //             <h4>Choose before Pressing the Upload button</h4>
        //         </div>
        //     );
        }
    };
    onFileChange = (event:any) => {
        this.setState({ selectedFile: event.target.files[0] }); 
    };
    handlePrint =()=>{
        const divContents:any = document.getElementById("printTable")?.innerHTML;
        const a:any = window.open("","","height:700px,width:700px");
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        setTimeout(function () { a.close(); }, 200);
    }
    handleExportExcel=(e:any)=>{
        e.preventDefault()
        this.setState({
            ...this.state,
            excelExport:true
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                excelExport:false
            })            
        }, 100);
    }
    render() {
        return (
            <div className={classes["right-section"]}>
                <div className="error-msg-section"></div>
                <div className={classes["main-div"]}>
                    <h2>Distributor Report</h2>
                    <form>
                    {/* <div ref={this.certificateTemplateRef}>
                        <PdfTemplate />
                    </div> */}
                    
                    {/* <div>
                    <input type="file" onChange={this.onFileChange} />
                    <button type="button" onClick={this.onFileUpload}>
                        Upload!
                    </button>
                </div>
                {this.fileData()} */}
                        {this.state.searchFilterRow.map((valRow: any, i: any) => {
                            return (
                                <div className={classes["main-div-input-sec"]} key={i}>
                                    {this.getDistributorListFields.map((val: any, ind: any) => {
                                        // //console.log(val.name , this.state[val.name])
                                        if (val.rowId == valRow) {
                                            return (
                                                <div className={classes["sub-div"]} key={ind}>
                                                    <label htmlFor="name">{val.label}</label>

                                                    {val.required ? (
                                                        <span
                                                            className={classes["mandatory-symbol"]}
                                                        >
                                                            {" "}
                                                            *{" "}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <InputBox
                                                        id={val.name}
                                                        autoComplete={val.autoComplete}
                                                        maxLength={val.maxLength}
                                                        className={
                                                            "form-control " + classes[val.class]
                                                        }
                                                        autoFocus={val.autoFocus}
                                                        placeholder={val.placeHolder}
                                                        name={val.name}
                                                        type={val.type}
                                                        tabIndex={1}
                                                        value={
                                                            val.type == "datetime"
                                                                ? val.name == "fromDate"
                                                                    ? this.state.fromDate
                                                                    : this.state.toDate
                                                                : this.state[val.name]
                                                        }
                                                        min={val.min}
                                                        options={val.options}
                                                        required={val.required}
                                                        selectShowEmpty={val.selectShowEmpty}
                                                        onChange={
                                                            val.type === "datetime"
                                                                ? val.name == "fromDate"
                                                                    ? this.handleStartDateChange
                                                                    : this.handleEndDateChange
                                                                : this
                                                                      .partnerLimitControlFilterChange
                                                        }
                                                        dateFormat={val.dateFormat}
                                                        startMaxDate={this.maxDate}
                                                        endMinDate={this.minDate}
                                                    />
                                                    <div className={classes["error"]}>
                                                        {this.state.errors[val.name + "Error"]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            )
                        })}
                        <div className={classes["submit-clear-section"]}>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="submit_form"
                                    className="form-control"
                                    type="submit"
                                    value="Search"
                                    onClick={() => this.handlePartnerSearch(event)}
                                />
                            </div>
                            <div className={classes["sub-div"]}>
                                <InputBox
                                    id="reset_form"
                                    className="form-control"
                                    type="reset"
                                    value="Reset"
                                    onClick={() => this.handleSearchPartnerLimitReset()}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                {this.state.showDistributorListing && (
                    <>
                        <div className="d-flex">
                            {/* <div className={"ms-2 me-2"}>
                                <div className={classes["total_section_box"]}>
                                    <span className={classes["total_section_box_sub1"]}>
                                        Total Records
                                    </span>
                                    :
                                    <span className={classes["total_section_box_sub2"]}>
                                        {this.state.totalCount !=""  ? this.state.totalCount : "0"}
                                    </span>
                                </div>
                            </div>                              */}
                            <TotalRecords totalCount={this.state.totalCount}/>
                             {this.state.totalCount > 0 ? (
                                <>
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Print" onClick={this.handlePrint} />
                                    <input type="button" className={"btn btn-primary " + classes["printBtn"]} value="Export Excel" onClick={this.handleExportExcel} />
                                </>
                            ):""}
                        </div>
                        <div 
                            className={classes["main-div"] + " " + classes["table-main-div"]}
                            style={{maxHeight: "500px"}}
                        >
                            <Table
                                className={
                                    "table table-bordered table-hover table-condensed" +
                                    classes["table"] +
                                    " " +
                                    classes["table-bordered"] +
                                    " " +
                                    classes["table-hover"] +
                                    " " +
                                    classes["table-condensed"] +
                                    " " +
                                    classes["fixed-header"]
                                }
                                id="provider_table_id"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Sl.NO</Th>
                                        <Th>Application No</Th>
                                        <Th>Login ID</Th>
                                        <Th>Distributor Code</Th>
                                        <Th>Distributor Name</Th>
                                        <Th>Address</Th>
                                        <Th>Status</Th>
                                        <Th style={{zIndex: 1}}>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.distributorSearchData.length > 0 ? (
                                        <>
                                            {this.state.distributorSearchData.map(
                                                (val: any, ind: any) => {
                                                    let oddEven = "odd"
                                                    if (ind % 2 == 0) {
                                                        oddEven = "even"
                                                    }
                                                    return (
                                                        <Tr
                                                            className={classes["tr_" + oddEven]}
                                                            key={ind}
                                                        >
                                                            <Td>
                                                                {(this.state.currentPage - 1) *
                                                                    this.state.noRow +
                                                                    ind +
                                                                    1}
                                                            </Td>
                                                            <Td>
                                                                {val.applicationNo != "" ? (
                                                                    <>
                                                                        {val.ACCOUNT_STATUS == 0 ? (
                                                                            <>
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={() =>
                                                                                        this.handleVerifyUser(
                                                                                            val.PARTNER_GUID,
                                                                                            val.PARTNER_NAME,
                                                                                            event
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        val.applicationNo
                                                                                    }
                                                                                </a>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {val.applicationNo}{" "}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </Td>
                                                            <Td>
                                                                {val.loginId != ""
                                                                    ? val.loginId
                                                                    : "-"}
                                                            </Td>
                                                            <Td>{val.PARTNER_USERNAME}</Td>
                                                            <Td>
                                                                {val.PARTNER_NAME &&
                                                                val.PARTNER_NAME != ""
                                                                    ? val.PARTNER_NAME
                                                                    : "-"}
                                                            </Td>
                                                            <Td>
                                                                {val.address && val.address != ""
                                                                    ? val.address
                                                                    : " "}
                                                                {val.address1 && val.address1 != ""
                                                                    ? "," + val.address1
                                                                    : ""}
                                                            </Td>
                                                            <Td>
                                                                {
                                                                    StaticKey.DISTRIBUTORSTATUS[
                                                                        val.ACCOUNT_STATUS
                                                                    ].name
                                                                }
                                                            </Td>
                                                            <Td >
                                                                <Link
                                                                    to={
                                                                        "/view-distributor.html?id=" +
                                                                        val.PARTNER_GUID
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes["tooltip"]
                                                                        }
                                                                    >
                                                                        <i className="fa fa-file-text-o text-primary"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-primary"
                                                                                ]
                                                                            }
                                                                        >
                                                                            View Detail
                                                                        </span>
                                                                    </div>
                                                                </Link>{" "}
                                                                &nbsp;
                                                                <a
                                                                    href="#"
                                                                    onClick={()=>this.handleGeneratePdf(event,val.PARTNER_GUID,val.PARTNER_NAME)}
                                                                >
                                                                    <div
                                                                        className={" text-success "+
                                                                            classes["tooltip"]
                                                                        }
                                                                    >
                                                                        <i id={"pdf_"+val.PARTNER_GUID} className="fa fa-file-pdf-o"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-success"
                                                                                ]
                                                                            }
                                                                        >
                                                                            Download Pdf
                                                                        </span>
                                                                    </div>
                                                                </a>{" "}
                                                                &nbsp;
                                                                 {/* <Link
                                                                    to={
                                                                        "/edit-distributor.html?id=" +
                                                                        val.PARTNER_GUID
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes["tooltip"]
                                                                        }
                                                                    >
                                                                        <i className="fa fa-edit text-success"></i>
                                                                        <span
                                                                            id="tooltiptext"
                                                                            className={
                                                                                classes[
                                                                                    "tooltiptext"
                                                                                ] +
                                                                                " " +
                                                                                classes[
                                                                                    "tooltiptext-success"
                                                                                ]
                                                                            }
                                                                        >
                                                                            Edit Detail
                                                                        </span>
                                                                    </div>
                                                                </Link>{" "}
                                                                &nbsp; */}
                                                                {val.ACCOUNT_STATUS == 0 ||
                                                                val.ACCOUNT_STATUS == 2 ? (
                                                                    <>
                                                                        <a
                                                                            href="#"
                                                                            onClick={() =>
                                                                                this.handleRejectDistributor(
                                                                                    val.PARTNER_GUID,
                                                                                    val.PARTNER_NAME
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes[
                                                                                        "tooltip"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <i className="fa fa-close text-danger"></i>
                                                                                <span
                                                                                    id="tooltiptext"
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltiptext"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "tooltiptext-danger"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    Reject
                                                                                </span>
                                                                            </div>
                                                                        </a>{" "}
                                                                        &nbsp;
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {val.ACCOUNT_STATUS == 1 ||
                                                                val.ACCOUNT_STATUS == 4 ? (
                                                                    <>
                                                                        <a
                                                                            onClick={() =>
                                                                                this.handleSecurityCode(
                                                                                    event,
                                                                                    val.PARTNER_USERNAME,
                                                                                    val.DECRYPT_SECURITY_CODE,
                                                                                    val.loginId
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes[
                                                                                        "tooltip"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <i className="fa fa-eye text-warning"></i>
                                                                                <span
                                                                                    id="tooltiptext"
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltiptext"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    View Security
                                                                                    Code
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {val.ACCOUNT_STATUS == 2 &&
                                                                val.loginId == 0 ? (
                                                                    <>
                                                                        <a
                                                                            onClick={() =>
                                                                                this.handleCreateLoginID(
                                                                                    val.PARTNER_GUID,
                                                                                    val.PARTNER_NAME
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes[
                                                                                        "tooltip"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <i className="fa fa-plus-circle text-success"></i>
                                                                                <span
                                                                                    id="tooltiptext"
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltiptext"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "tooltiptext-success"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    Create Login ID
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {val.ACCOUNT_STATUS == 2 &&
                                                                val.loginId > 0 ? (
                                                                    <>
                                                                        <a
                                                                            onClick={() =>
                                                                                this.handleInstallUser(
                                                                                    val.PARTNER_GUID,
                                                                                    val.PARTNER_NAME,
                                                                                    event
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes[
                                                                                        "tooltip"
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <i className="fa fa-download text-info"></i>
                                                                                <span
                                                                                    id="tooltiptext"
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltiptext"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "tooltiptext-info"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    Installation
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {/* <Link to={"/set-limit.html?id=" + val.PARTNER_GUID} >
                                                Transfer Points
                                            </Link> */}
                                                            </Td>
                                                            {/* <Td>
                                            <div  className={"text-center"}>
                                                <a href="#" onClick={() => this.handleSecurityCode( event,val.PARTNER_USERNAME , val. DECRYPT_SECURITY_CODE ) } >
                                                    <i className="fa fa-eye text-warning text-bold"></i>
                                                </a>
                                            </div>
                                        </Td> */}
                                                            {/* <td>{val.PARTNER_CREATED_DATE}</td> */}
                                                        </Tr>
                                                    )
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <Tr className={classes["no_data_found"]}>
                                                <Td colSpan={10}>No record(s) available</Td>
                                            </Tr>
                                        </>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        {this.state.totalCountPagination.length > 0 ? (
                            <div className={"d-none"} id={"printTable"}>
                                <PrintTableElement tableData={this.state.distributorSearchData} currentPage={this.state.currentPage} noRow={this.state.noRow} tHead={this.state.tableHead} tBody={this.state.tableBody}/>
                                {this.state.excelExport && (
                                    <ExportExcelReport mockData={this.state.mockData} sheetName="distributor_list"/> 
                                )}
                            </div>
                        ):""}
                        <div className={"table_pagination_div"}>
                            {this.state.totalCountPagination.length > 0 ? (
                                <>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event => this.handlePagination(event, 1)}
                                    >
                                        First
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={this.state.currentPage == 1 ? true : false}
                                        onClick={event =>
                                            this.handlePagination(event, this.state.previousPage)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left"></i> Previous
                                    </button>
                                    {this.state.totalCountPagination.map((val: any, ind: any) => {
                                        let btnClass = ""
                                        if (
                                            ind + 1 >= this.state.fromPagePagination &&
                                            ind + 1 <= this.state.toPagePagination
                                        ) {
                                            if (this.state.currentPage == ind + 1) {
                                                btnClass = "active"
                                            }
                                            return (
                                                <>
                                                    <button
                                                        className={
                                                            classes["buttonClass"] +
                                                            " " +
                                                            classes[btnClass]
                                                        }
                                                        onClick={event =>
                                                            this.handlePagination(event, ind + 1)
                                                        }
                                                    >
                                                        {ind + 1}
                                                    </button>
                                                </>
                                            )
                                        }
                                    })}
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.nextPage)
                                        }
                                    >
                                        Next <i className="fa fa-angle-double-right"></i>
                                    </button>
                                    <button
                                        className={classes["buttonClass"]}
                                        disabled={
                                            this.state.currentPage == this.state.lastPage
                                                ? true
                                                : false
                                        }
                                        onClick={event =>
                                            this.handlePagination(event, this.state.lastPage)
                                        }
                                    >
                                        Last
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                )}

                <>
                    {/* {this.state.alertPopupShow == "show" && ( */}
                    <Modal
                        className={"modal-dialog-centered"}
                        isOpen={this.state.alertPopupShow}
                        onClosed={() => {
                            document.body.style.overflow = "unset"
                        }}
                        onExit={() => {
                            document.body.style.overflow = "unset"
                        }}
                        unmountOnClose={true}
                    >
                        <div className={classes["modal-header"]}>
                            <h4 className={classes["modal-title"]}>Security Code</h4>
                            <span
                                onClick={this.handleAlertPopupClose}
                                className={classes["login-modal-close"]}
                                aria-hidden="true"
                            >
                                &times;
                            </span>
                        </div>
                        <div className="modal-body mb-2 d-flex">
                            <div className="me-5">{this.state.securityCodeLoginId}</div>
                            <div className="me-5">{this.state.securityCodeUsername}</div>
                            <div className="me-5">
                                <b>{this.state.securityCode}</b>
                            </div>
                            <div
                                onClick={() => this.copyToClipboard()}
                                onMouseLeave={() => this.handleSecurityCodeBlur()}
                                className={classes["tooltip"] + " " + classes["copyDiv"]}
                            >
                                <i className="fa fa-copy"></i>
                                <span id="tooltiptext" className={classes["tooltiptext"]}>
                                    {this.state.tooltipText}
                                </span>
                            </div>
                        </div>
                    </Modal>
                    {/* )} */}
                    {/* {this.state.verifyPopupShow == "show" && ( */}
                    <Modal
                        className={"modal-dialog-centered"}
                        isOpen={this.state.verifyPopupShow}
                        onClosed={() => {
                            document.body.style.overflow = "unset"
                        }}
                        onExit={() => {
                            document.body.style.overflow = "unset"
                        }}
                        unmountOnClose={true}
                    >
                        <div className={classes["modal-header"]}>
                            <h4 className={classes["modal-title"]}>
                                Verify Distributor{" "}
                                {this.state.distributorCode
                                    ? "(" + this.state.distributorCode + ")"
                                    : ""}
                            </h4>
                            <span
                                onClick={() => this.setState({"verifyPopupShow": false})}
                                className={classes["login-modal-close"]}
                                aria-hidden="true"
                            >
                                &times;
                            </span>
                        </div>
                        <form>
                            <div className="modal-body mb-2 mt-2">
                                <div>
                                    <div>Are you sure to verify the distributor?</div>
                                    <div>
                                        <InputBox
                                            name={"verifyUser"}
                                            id={"verifyUser"}
                                            placeholder={"Name"}
                                            className={"form-control " + classes["form-control"]}
                                            value={this.state.verifyUser}
                                            required={"required"}
                                            // onChange={(event: any) =>
                                            //     this.setState({
                                            //         verifyUser: event.target.value,
                                            //         verifyUserError: ""
                                            //     })
                                            // }
                                            onChange = {()=>this.handleOnChangeVerifyState(event)}
                                            autoFocus={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                    <div
                                        className={classes["error"] + " " + classes["error-field"]}
                                    >
                                        {this.state.verifyUserError}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex">
                                <InputBox
                                    id="submit_form_verify"
                                    className="btn btn-success"
                                    type="submit"
                                    value="Ok"
                                    onClick={() => this.handleVerifyUserSubmit(event)}
                                />
                                <InputBox
                                    id="reset_form_verify"
                                    className="btn btn-danger"
                                    type="reset"
                                    value="Cancel"
                                    onClick={() => this.setState({"verifyPopupShow": false})}
                                    // onClick={() => this.handleSearchGameHistorySearchReset()}
                                />
                            </div>
                        </form>
                    </Modal>
                    {/* )} */}
                    {/* {this.state.installPopupShow == "show" && ( */}
                    <Modal
                        className={"modal-dialog-centered"}
                        isOpen={this.state.installPopupShow}
                        onClosed={() => {
                            document.body.style.overflow = "unset"
                        }}
                        onExit={() => {
                            document.body.style.overflow = "unset"
                        }}
                        unmountOnClose={true}
                    >
                        <div className={classes["modal-header"]}>
                            <h4 className={classes["modal-title"]}>
                                Install Distributor{" "}
                                {this.state.distributorCode
                                    ? "(" + this.state.distributorCode + ")"
                                    : ""}
                            </h4>
                            <span
                                onClick={() => this.setState({"installPopupShow": false})}
                                className={classes["login-modal-close"]}
                                aria-hidden="true"
                            >
                                &times;
                            </span>
                        </div>
                        <form>
                            <div className="modal-body mb-2 mt-2">
                                <div>
                                    <div>Are you sure to install the distributor?</div>
                                    <div>
                                        <InputBox
                                            name={"installUser"}
                                            id={"installUser"}
                                            placeholder={"Name"}
                                            className={"form-control " + classes["form-control"]}
                                            value={this.state.installUser}
                                            required={"required"}
                                            // onChange={(event: any) =>
                                            //     this.setState({
                                            //         installUser: event.target.value,
                                            //         installUserError: ""
                                            //     })
                                            // }
                                            onChange = {()=>{this.handleOnChangeInstallState(event)}}
                                            autoFocus={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                    <div
                                        className={classes["error"] + " " + classes["error-field"]}
                                    >
                                        {this.state.installUserError}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex">
                                <InputBox
                                    id="submit_form_install"
                                    className="btn btn-success"
                                    type="submit"
                                    value="Ok"
                                    onClick={() => this.handleInstallUserSubmit(event)}
                                />
                                <InputBox
                                    id="reset_form_install"
                                    className="btn btn-danger"
                                    type="reset"
                                    value="Cancel"
                                    onClick={() => this.setState({"installPopupShow": false})}
                                    // onClick={() => this.handleSearchGameHistorySearchReset()}
                                />
                            </div>
                        </form>
                    </Modal>
                    {/* )} */}
                    <div  style={{height:"0",overflow:"auto"}}> 
                        <div id="downloadPdf" > 
                            {Object.keys(this.viewDIstributorFields).map(
                                (keys: any, values: any) => {
                                    return (
                                        <div key={values} className={classes["main-div-input-sec-2"]}>
                                            <h5>{keys}</h5>
                                            <div className={classes["main-div-input-sec"]}>
                                                {this.viewDIstributorFields[keys].map(
                                                    (fieldValObject: any,fieldValIndex: any) => {
                                                        if (Object.keys(fieldValObject).length == 1) {
                                                            return Object.keys(fieldValObject).map((keys1: any,values1: any) => {
                                                                return (
                                                                    <div key={values1} className={classes["main-div-input-sec-2-sub"]}>
                                                                        <h6>{keys1}</h6>
                                                                        <div className={classes["main-div-input-sec"]}> 
                                                                            {fieldValObject[keys1].map((fieldValObject1: any,fieldValIndex1: any) => {
                                                                                return (
                                                                                    <div className={classes["sub-div"]} key={fieldValIndex1}>
                                                                                        <label htmlFor="name">{fieldValObject1.label}</label>  
                                                                                        <InputBox
                                                                                            id={fieldValObject1.id}
                                                                                            autoComplete="off"
                                                                                            maxLength={fieldValObject1.maxLength}
                                                                                            disabled={fieldValObject1.disabled}
                                                                                            className={"form-control " +classes[fieldValObject1.class]}
                                                                                            autoFocus={fieldValObject1.autoFocus}
                                                                                            name={fieldValObject1.name}
                                                                                            readonly={fieldValObject1.readonly}
                                                                                            value={this.state.fields[fieldValObject1.name]}
                                                                                            type={fieldValObject1.type}    
                                                                                            placeholder={fieldValObject1.placeHolder}
                                                                                            required={fieldValObject1.required}    
                                                                                            options={fieldValObject1.options}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        } else {
                                                            let classNew = ""
                                                            if (fieldValObject.label =="Terms Agreed") {
                                                                classNew = "subdiv-full-width"
                                                            }
                                                            return (
                                                                <>
                                                                    {!fieldValObject.dnone && 
                                                                        <div className={classes["sub-div"] +" " +classes[classNew]} key={fieldValIndex}>
                                                                            <div>
                                                                                <label htmlFor={fieldValObject.id}>{fieldValObject.label}</label>
                                                                                {fieldValObject.type != "file" && (
                                                                                    <InputBox
                                                                                        id={fieldValObject.name}
                                                                                        disabled={fieldValObject.disabled}
                                                                                        autoComplete="off"
                                                                                        maxLength={fieldValObject.maxLength}
                                                                                        readonly={fieldValObject.readonly}
                                                                                        className={"form-control " +classes[fieldValObject.class] +" " +classes["upload_input_field"]}
                                                                                        autoFocus={fieldValObject.autoFocus}
                                                                                        accept={fieldValObject.type =="file"? ".png, .jpg, .svg, .pdf" : ""}
                                                                                        value={fieldValObject.type !="file" ? this.state.fields[fieldValObject.name]: ""}
                                                                                        name={fieldValObject.name}
                                                                                        type={fieldValObject.type}
                                                                                        placeholder={fieldValObject.placeHolder}
                                                                                        required={fieldValObject.required}
                                                                                        label1={fieldValObject.label1}
                                                                                        options={fieldValObject.type =="select"? fieldValObject.options: ""}
                                                                                        selectShowEmpty={fieldValObject.selectShowEmpty}                                                                           
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    }  
                                                                </>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </>
                
            </div>
        )
    }
}

