import React, {Component} from "react"
//import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
// import classes from "../../configuration/style/base/screens/changepassword/styles.module.scss"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {Modal} from "reactstrap"
import { Navigate } from "react-router"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"

export class ViewDistributor extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    localStorageService = new DataStorage
    state: any = {
        goToBack: false,
        distributorData: [],
        alertPopupShow: false,
        noOfPartnershipCallback:true,
        registrationAadharCardNoCallback:true,
        // registrationIfscCodeCallback:true,
        companyList: {},
        imageName:"",
        imageUrl:"",
        newPasswordError:"",
        confirmPasswordError:"",
        confirmPassword:"",
        newPassword:"",
        fields: {
            newPassword:"",
            confirmPassword:"",
            companyId: "",
            noOfPartnership: "",
            partnerName: "",
            partnerAadharCardNo: "",
            // partnerUsername: "",
            // partnerPassword: "",
            // partnerTransactionPassword: "",
            partnerPanCardNo: "",
            partnerAddress1: "",
            partnerAddress2: "",
            // partnerAddress3: "",
            partnerAddress4: "",
            partnerPinCode: "",
            partnerLocality: "",
            partnerTaluka: "",
            partnerCity: "",
            partnerState: "",
            partnerMobileNo: "",
            partnerWhatsappNo: "",
            partnerTelegramNo: "",
            // partnerwebsite: "",
            partnerEmail: "",
            // partnerLandLineNo: "",
            businessTotalYears: "",
            businessTotalManpower: "",
            businessSalesManpower: "",
            businessOfficeArea: "",
            businessOfficeManpower: "",
            businessOfficeCount: "",
            businessOfficeType: "",
            businessWarehouseArea: "",
            businessWarehouseManpower: "",
            businessWarehouseCount: "",
            businessWarehouseType: "",
            registrationPanCardNo: "",
            registrationAadharCardNo: "",
            registrationIfscCode: "",
            registrationBankName: "",
            registrationBranchName: "",
            registrationAccountNo: "",
            companyName1: "",
            companyName2: "",
            companyName3: "",
            companyCount: "",
            companyProductHandle: "",
            companyOwnedVehicleCount: "",
            companyTowneCount: "",
            uploadAadhar: "",
            uploadAadharVal: "",
            uploadPanCard: "",
            uploadPanCardVal: "",
            uploadDrivingLicence: "",
            uploadDrivingLicenceVal: "",
            uploadEBBill: "",
            uploadEBBillVal: "",
            uploadPassport: "",
            uploadPassportVal: "",
            uploadPhoto: "",
            uploadPhotoVal: "",
            termsAgreed: ""
        },
        errors: {
            newPasswordError:"",
            confirmPasswordError:"",  
            noOfPartnershipError: "",
            partnerNameError: "",
            partnerAadharCardNoError: "",
            // partnerUsernameError: "",
            partnerPanCardNoError: "",
            partnerAddress1Error: "",
            partnerAddress2Error: "",
            // partnerAddress3Error: "",
            partnerAddress4Error: "",
            partnerPinCodeError: "",
            partnerLocalityError: "",
            partnerTalukaError: "",
            partnerCityError: "",
            partnerStateError: "",
            partnerMobileNoError: "",
            partnerWhatsappNoError: "",
            partnerTelegramNoError: "",
            partnerwebsiteError: "",
            partnerEmailError: "",
            // partnerPasswordError: "",
            // partnerTransactionPasswordError: "",
            businessTotalYearsError: "",
            businessTotalManpowerError: "",
            businessSalesManpowerError: "",
            businessOfficeAreaError: "",
            businessOfficeManpowerError: "",
            businessOfficeCountError: "",
            businessOfficeTypeError: "",
            businessWarehouseAreaError: "",
            businessWarehouseManpowerError: "",
            businessWarehouseCountError: "",
            businessWarehouseTypeError: "",
            registrationPanCardNoError: "",
            registrationAadharCardNoError: "",
            registrationIfscCodeError: "",
            registrationBankNameError: "",
            registrationBranchNameError: "",
            registrationAccountNoError: "",
            companyName1Error: "",
            companyName2Error: "",
            companyName3Error: "",
            companyCountError: "",
            companyProductHandleError: "",
            companyOwnedVehicleCountError: "",
            companyTowneCountError: "",
            uploadAadharError: "",
            uploadPanCardError: "",
            uploadDrivingLicenceError: "",
            uploadEBBillError: "",
            uploadPassportError: "",
            uploadPhotoError: "",
            termsAgreedError: ""
        }
    }

    passwordFields: any = [
        {
            "name": "newPassword",
            "id": "newPassword",
            "type": "password",
            "label": "New Password",
            // "max": "49",
            "autoFocus": true,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
        {
            "name": "confirmPassword",
            "id": "confirmPassword",
            "type": "password",
            "label": "Confirm Password",
            // "max": "49",
            "autoFocus": false,
            "class": "form-control",
            "required": true,
            passwordCondition: true,
        },
    ]
    componentDidMount() {
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        
        this.getDistributorDetail(id)
        if (this.uiData.getLoginStatus()) {
            const loginResponse = this.uiData.getLoginResponse()
            this.setState({
                    LoginpartnerTypeId: loginResponse.partnerTypeId
            })
        }
    }
    handleViewImage(e:any,imageUrl:any,imageName:any,imageLabel:any){
        e.preventDefault();
        // alert(imageUrl)
        this.setState({
            ...this.state,
            imageName:imageLabel,
            alertPopupShow: "show"
        })
    }
    getDistributorDetail = (id: any) => {
        
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            "partnerGuid": id,
        }
        const response = this.DownlineManageServices.getDistributorDetail(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
              //console.log(searchPartnerLimitResponse)
                if (!searchPartnerLimitResponse.errorStatus) {
                    const responseData = searchPartnerLimitResponse.data;
                    this.setState({
                        distributorData: searchPartnerLimitResponse.data,
                        guId: id
                    })
                            if(responseData.noOfPartnership == 1){
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=false;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=true;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 2){
                                
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.removeAttribute('readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=true;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=false;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                            }
                            if(responseData.noOfPartnership == 3){
                                document.getElementById('partner1name')?.removeAttribute('readonly');
                                document.getElementById('partner2name')?.removeAttribute('readonly');
                                document.getElementById('partner3name')?.removeAttribute('readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=true;
                                this.viewDIstributorFields["A. General Information"][3].required=true;
                                this.viewDIstributorFields["A. General Information"][4].required=true;
                                this.viewDIstributorFields["A. General Information"][2].dnone=false;
                                this.viewDIstributorFields["A. General Information"][3].dnone=false;
                                this.viewDIstributorFields["A. General Information"][4].dnone=false;
                                
                            }
                            if(responseData.noOfPartnership == 0 || responseData.noOfPartnership == ""){
                                document.getElementById('partner1name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner2name')?.setAttribute('readonly','readonly');
                                document.getElementById('partner3name')?.setAttribute('readonly','readonly');
                                this.viewDIstributorFields["A. General Information"][2].required=false;
                                this.viewDIstributorFields["A. General Information"][3].required=false;
                                this.viewDIstributorFields["A. General Information"][4].required=false;
                                this.viewDIstributorFields["A. General Information"][2].dnone=true;
                                this.viewDIstributorFields["A. General Information"][3].dnone=true;
                                this.viewDIstributorFields["A. General Information"][4].dnone=true;
                                
                            }
                    let partner1,partner2,partner3 ="-";
                    if(responseData.partnershipName && responseData.partnershipName !=""){
                        const dataSplit = responseData.partnershipName.split(",");
                        partner1 = dataSplit[0] ? dataSplit[0] : "-";
                        partner2 = dataSplit[1] ? dataSplit[1] : "-";
                        partner3 = dataSplit[2] ? dataSplit[2] : "-";
                    }  
                    this.setState({
                        ...this.state.fields,
                        fields: {
                            noOfPartnership: responseData.noOfPartnership ? responseData.noOfPartnership:"0",
                            companyId: responseData.companyId ? responseData.companyId:"-",
                            partnerName: responseData.partnerName ? responseData.partnerName:"-",
                            partner1name: partner1,
                            partner2name: partner2,
                            partner3name: partner3,
                            // partnerAadharCardNo: "1231 2312 3123",
                            partnerUsername: responseData.partnerUsername ? responseData.partnerUsername : "-",
                            // partnerPassword: "",
                            // partnerTransactionPassword: "",
                            // partnerPanCardNo: "",
                            partnerAddress1:  responseData.address? responseData.address:"-",
                            partnerAddress2: responseData.address1 ? responseData.address1:"-",
                            // partnerAddress3: "",
                            // partnerAddress4: "",
                            partnerPinCode: responseData.zipcode ? responseData.zipcode:"-",
                            partnerLocality: responseData.locality ? responseData.locality:"-",
                            partnerTaluka: responseData.taluk ? responseData.taluk:"-",
                            partnerCity: responseData.cityName ? responseData.cityName:"-",
                            partnerState: responseData.stateName ? responseData.stateName:"-",
                            partnerMobileNo: responseData.mobileNo ? responseData.mobileNo:"-",
                            partnerWhatsappNo: responseData.whatsappNo ? responseData.whatsappNo:"-",
                            partnerTelegramNo: responseData.telegramNo ? responseData.telegramNo:"-",
                            // partnerwebsite: "",
                            partnerEmail: responseData.emailId ? responseData.emailId:"-",
                            // partnerLandLineNo: "",
                            businessTotalYears: responseData.noOfYearsBusiness ? responseData.noOfYearsBusiness:"-",
                            businessTotalManpower: responseData.totalManPower ? responseData.totalManPower:"-",
                            businessSalesManpower: responseData.salesManPower ? responseData.salesManPower:"-",
                            businessOfficeArea:  responseData.totalOfficeArea ? responseData.totalOfficeArea:"-",
                            businessOfficeManpower: responseData.officeManPower ? responseData.officeManPower:"-",
                            businessOfficeCount: responseData.totalNoOfOffice ? responseData.totalNoOfOffice:"-",
                            businessOfficeType: responseData.officeType == 1 ? "Owned" : "Rental",
                            businessWarehouseArea: responseData.totalWarehouseArea ? responseData.totalWarehouseArea:"-",
                            businessWarehouseManpower: responseData.warehouseManpower ? responseData.warehouseManpower:"-",
                            businessWarehouseCount: responseData.totalNoofWarehouse ? responseData.totalNoofWarehouse:"-",
                            businessWarehouseType: responseData.warehouseType == 1 ? "Owned" : "Rental",
                            registrationPanCardNo: responseData.pancardNo ? responseData.pancardNo:"-",
                            registrationAadharCardNo: responseData.aadharNo ? responseData.aadharNo:"-",
                            registrationIfscCode: responseData.ifscCode ? responseData.ifscCode:"-",
                            registrationBankName: responseData.bankName ? responseData.bankName:"-",
                            registrationBranchName: responseData.branchName ? responseData.branchName:"-",
                            registrationAccountNo: responseData.accountNo ? responseData.accountNo:"-",
                            companyName1: responseData.listOfCompany1 ? responseData.listOfCompany1:"-",
                            companyName2:  responseData.listOfCompany2 ? responseData.listOfCompany2:"-",
                            companyName3:  responseData.listOfCompany3 ? responseData.listOfCompany3:"-",
                            companyCount: responseData.noOfCompanies ? responseData.noOfCompanies:"-",
                            companyProductHandle: responseData.productHandled ? responseData.productHandled:"-",
                            companyOwnedVehicleCount: responseData.noOfOwnedVehicles ? responseData.noOfOwnedVehicles:"-",
                            companyTowneCount: responseData.noOfTownCovered ? responseData.noOfTownCovered:"-",
                            uploadAadhar: responseData.aadharImage !="" ? responseData.aadharImage : "-",
                            uploadAadharVal: responseData.aadharImageUrl ? responseData.aadharImageUrl:"-" ,
                            uploadPanCard: responseData.pancardImage  !="" ? responseData.pancardImage : "-",
                            uploadPanCardVal:responseData.pancardImageUrl ? responseData.pancardImageUrl:"-",
                            uploadDrivingLicence: responseData.drivingLicenceImage  !="" ? responseData.drivingLicenceImage : "-",
                            uploadDrivingLicenceVal: responseData.drivingLicenceImageUrl ? responseData.drivingLicenceImageUrl:"-",
                            uploadEBBill: responseData.ebBillImage  !="" ? responseData.ebBillImage : "-",
                            uploadEBBillVal: responseData.ebBillImageUrl ? responseData.ebBillImageUrl:"-",
                            uploadPassport: responseData.passportImage  !="" ? responseData.passportImage : "-",
                            uploadPassportVal: responseData.passportImageUrl ? responseData.passportImageUrl:"-",
                            uploadPhoto: responseData.photo  !="" ? responseData.photo : "-", 
                            uploadPhotoVal: responseData.photoUrl ? responseData.photoUrl:"-",
                            termsAgreed: ""
                        }
                    })
                    this.closeLoader()
                } else {
                    this.setState({
                        distributorData: []
                    })
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                // console.log("handleCreatePartnerLimitSearchErr", err)
                this.uiData.updateShowLoading(false)
                this.setState({
                    distributorData: []
                })
                this.closeLoader()
            })
    }
    
    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
  
    componentWillUnmount() {
        //this.uiData.off("emitMenuChangeEvent", this.handleMenuChange)
    }
   
    viewDIstributorFields: any = {
        "A. General Information": [
            {
                name: "noOfPartnership",
                type: "text",
                label: "No of Partnership",
                //placeHolder: "No of Partnership",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"": []},
            {
                name: "partner1name",
                type: "text",
                label: "Name 1",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partner2name",
                type: "text",
                label: "Name 2",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partner3name",
                type: "text",
                label: "Name 3",
                // placeHolder:  "Third Partner Name",
                maxLength: 200,
                autoFocus: false,
                class: "form-control",
                required: false, 
                readonly:true,
                "disabled":"disabled",
                // options: {},
                // selectShowEmpty: true
            },
            {"": []},
            // {
            //     name: "companyId",
            //     type: "text",
            //     label: "Company",
            //     //placeHolder: "Company",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true,
                
            // },
            {
                name: "partnerName",
                type: "text",
                label: "Distributor Name",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerUsername",
                type: "text",
                label: "Distributor Code",
                //placeHolder: "Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"": []},
            {
                name: "partnerAddress1",
                type: "text",
                label: "Address 1",
                placeHolder: "Address1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerAddress2",
                type: "text",
                label: "Address 2",
                placeHolder: "Address2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                "name": "partnerPinCode",
                "type": "text",
                "label": "Pincode",
                //"placeHolder": "PinCode",
                "maxLength": 7,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerLocality",
                "type": "text",
                "label": "Locality",
                //"placeHolder": "Locality",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true, 
                 "disabled":"disabled", 
            },
            {
                "name": "partnerTaluka",
                "type": "text",
                "label": "Taluka",
                //"placeHolder: "Taluka",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerCity",
                "type": "text",
                "label": "City",
                //"placeHolder: "City",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            {
                "name": "partnerState",
                "type": "text",
                "label": "State",
                //"placeHolder: "State",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                // "readonly":true,
                 required: false, readonly:true,
                 "disabled":"disabled",
            },
            // {
            //     name: "partnerAadharCardNo",
            //     type: "text",
            //     label: "Aadhar Card No",
            //     //placeHolder: "Partner Aadhar Card No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },
            // {
            //     name: "partnerPanCardNo",
            //     type: "text",
            //     label: "PAN No",
            //     //placeHolder: "Partner Pan No",
            //     maxLength: 10,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },  
            // {"": []},
            {
                name: "partnerMobileNo",
                type: "text",
                label: "Mobile No",
                //placeHolder: "Mobile No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            // {
            //     name: "partnerLandLineNo",
            //     type: "text",
            //     label: "Landline No",
            //     //placeHolder: "Landline No",
            //     maxLength: 12,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // },
            {
                name: "partnerWhatsappNo",
                type: "text",
                label: "Whatsapp No",
                //placeHolder: "Whatsapp No",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerTelegramNo",
                type: "text",
                label: "Telegram Id",
                //placeHolder: "Telegram Id",
                maxLength: 12,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "partnerEmail",
                type: "text",
                label: "Email Id",
                //placeHolder: "Email",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            // {
            //     name: "partnerwebsite",
            //     type: "text",
            //     label: "Website Address",
            //     //placeHolder: "Website Address",
            //     maxLength: 50,
            //     autoFocus: false,
            //     class: "form-control",
            //     required: false, readonly:true
            // }
        ],
        "B. Current Business Information": [
            {
                name: "businessTotalYears",
                type: "text",
                label: "Number of years in business",
                //placeHolder: "Business Total Years",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "businessTotalManpower",
                type: "text",
                label: "Total Manpower",
                //placeHolder: "Total Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "businessSalesManpower",
                type: "text",
                label: "Sales Manpower",
                //placeHolder: "Sales Manpower",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                Office: [
                    {
                        name: "businessOfficeArea",
                        type: "text",
                        label: "Total Office Area",
                        //placeHolder: "Total Office Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeManpower",
                        type: "text",
                        label: "Office Manpower",
                        //placeHolder: "Office Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeCount",
                        type: "text",
                        label: "Total No.Of Office",
                        //placeHolder: "Business Office Count",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessOfficeType",
                        type: "text",
                        label: "Owned / Rental",
                        //placeHolder: "Business Office Type",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                       
                    }
                ]
            },
            {
                Warehouse: [
                    {
                        name: "businessWarehouseArea",
                        type: "text",
                        label: "Total Warehouse Area",
                        //placeHolder: "Business Warehouse Area",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseManpower",
                        type: "text",
                        label: "Warehouse Manpower",
                        //placeHolder: "Business Warehouse Manpower",
                        maxLength: 10,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseCount",
                        type: "text",
                        label: "Total No.Of Warehouse",
                        //placeHolder: "Business Warehouse Count",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    },
                    {
                        name: "businessWarehouseType",
                        type: "text",
                        label: "Owned / Rental",
                        //placeHolder: "Business Warehouse Type",
                        maxLength: 50,
                        autoFocus: false,
                        class: "form-control",
                        required: false, readonly:true,
                        "disabled":"disabled",
                    }
                ]
            }
        ],
        "C. Registration Details": [
            {
                "name": "registrationPanCardNo",
                "type": "text",
                "label": "Pan Card No",
                //"placeHolder: "Registration Pan Card No",
                "maxLength": 20,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            }, 
            {
                "name": "registrationAadharCardNo",
                "type": "text",
                "label": "Aadhar Card No",
                "placeHolder": "---- ---- ----",
                "maxLength": 14,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true,
                 "disabled":"disabled",
            },   
            {"": []},
            {
                name: "registrationIfscCode",
                type: "text",
                label: "IFSC Code",
                //placeHolder: "Registration Ifsc Code",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "registrationBankName",
                type: "text",
                label: "Bank Name",
                //placeHolder: "Registration Bank Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            {
                name: "registrationBranchName",
                type: "text",
                label: "Branch Name",
                //placeHolder: "Registration Branch Name",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "registrationAccountNo",
                type: "text",
                label: "Account No",
                //placeHolder: "Registration Account No",
                maxLength: 20,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
        ],
        "D. Company Details As Distributor/Dealer": [
            {
                name: "companyName1",
                type: "text",
                label: "Companies represented1",
                placeHolder: "Company Name1",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyName2",
                type: "text",
                label: "Companies represented 2",
                placeHolder: "Company Name2",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyName3",
                type: "text",
                label: "Companies represented3",
                placeHolder: "Company Name3",
                maxLength: 50,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {"":[]},
            {
                name: "companyCount",
                type: "text",
                label: "No.of Companies represented",
                //placeHolder: "Company Count",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyProductHandle",
                type: "text",
                label: "Product Handle",
                //placeHolder: "Product Handle",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyOwnedVehicleCount",
                type: "text",
                label: "No.of Owned vehicles",
                //placeHolder: "No.of Owned vehicles",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            },
            {
                name: "companyTowneCount",
                type: "text",
                label: "No.of Town covered",
                //placeHolder: "No.of Town covered",
                maxLength: 10,
                autoFocus: false,
                class: "form-control",
                required: false, readonly:true,
                "disabled":"disabled",
            }
        ],
        "Uploaded Files": [
            {
                "name": "uploadAadhar",
                "type": "file",
                "label": "Aadhar",
                //"placeHolder: "Upload Aadhar",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true
            },
            {
                "name": "uploadPanCard",
                "type": "file",
                "label": "Pan Card",
                //"placeHolder: "Upload Pan Card",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                 required: false, readonly:true
            },
            {
                "name": "uploadDrivingLicence",
                "type": "file",
                "label": "Driving Licence",
                //"placeHolder: "Upload Driving Licence",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadEBBill",
                "type": "file",
                "label": "Light Bill",
                //"placeHolder: "Upload EBBill",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPassport",
                "type": "file",
                "label": "Passport",
                //"placeHolder: "Upload Passport",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            },
            {
                "name": "uploadPhoto",
                "type": "file",
                "label": "Photo",
                //"placeHolder: "Upload Photo",
                "maxLength": 50,
                "autoFocus": false,
                "class": "form-control",
                "required": false
            }
        ],
    }

    handleAlertPopupClose = () => {
        this.setState({alertPopupShow: false})
    }
    handlePopupShow = (e: any) => {
        e.preventDefault()
        this.setState({
            alertPopupShow: true
        });
        setTimeout(()=>{
            document.getElementById("resetForm")?.click();
            document.getElementById("newPassword")?.focus();
        },100)
    }
    
    validatecreateDrawForm(){
        let flag = true
        // const errorMessage = StaticKey.LOCALE_DATA.Home
        this.passwordFields.map((val: any) => {
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    const errorData = this.uiData.textConversion(val.label)
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.Home.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        let pattern: any
                        let error: any
                        if (val.name == "newPassword" || val.name == "confirmPassword") {
                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                            error = StaticKey.PASSWORD_CONDITION
                        } 
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: error
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        return flag
    }
    handleResetPassword(e:any){
        e.preventDefault();    
        this.setState({
            "confirmPasswordError":"",
            "newPasswordError":"",
            "newPassword":"",
            "confirmPassword":"",
            fields : {
                "newPassword":"",
                "confirmPassword":"",
            }
        })
        document.getElementById("newPassword")?.focus();
    }
    
    handleChangePassword(e:any){
        e.preventDefault();        
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        if(this.validatecreateDrawForm()){
            this.uiData.updateShowLoading(true)
            if (this.state["newPassword"] != this.state["confirmPassword"]) {
                this.setState({
                    ["confirmPasswordError"]:"New password and Confirm Password must be same"
                })
                this.uiData.updateShowLoading(false)
                return false
            }

            const searchCondition = {
                "partnerTypeId":5,
                "partnerGuid":id,
                "newPassword":this.state['newPassword'],
                "confirmPassword":this.state['confirmPassword']
            }
          //console.log(searchCondition);
            const response = this.DownlineManageServices.partnerForcePasswordUpdate(searchCondition)
            response
                .then((updateResultResponse: any) => {
                    if (!updateResultResponse.errorStatus) {
                        this.uiData.showToastMessage(updateResultResponse.message, "")
                        this.setState({
                            ...this.state,
                            "confirmPasswordError":"",
                            "newPasswordError":"",
                            alertPopupShow:false,
                            fields : {
                                ...this.state.fields,
                                "newPassword":"",
                                "confirmPassword":"",
                            }
                        })
                    } else {
                        this.uiData.showToastMessage(updateResultResponse.message, "error")
                    }
                    this.uiData.updateShowLoading(false)
                })
                .catch((err: any) => {
                    this.uiData.showToastMessage("Something went Wrong", "error")
                  //console.log("handleDrawSearch", err)
                    this.uiData.updateShowLoading(false)
                })
        }
      
    }

    handleOnchange(e:any){
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value,
            [e.target.name + "Error"]:""
        })
    }

    handleGoBack = (e: any) => {
        e.preventDefault()
        this.setState({
            goToBack: true
        })
        this.uiData.setRetailerBackList(true)
    }
    render() {
        let tabIndex = 1
        return (
            <>
                <div className={classes["right-section"] + " " + classes["general-form"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>View Distributor</h2>
                        <div className={classes["back-class"]}>
                                
                                <a href="#" onClick={()=>this.handleGoBack(event)} >
                                    <i className="fa fa-arrow-left"></i> Go to List
                                </a>
                                {this.state.goToBack && (
                                    <Navigate to="/list-distributor.html" replace={true} />
                                )}
                            </div>
                        <div className={classes["action-class"]}>
                            <a href="#" onClick={()=>this.handlePopupShow(event)} >Change Password</a>
                        </div>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {Object.keys(this.viewDIstributorFields).map(
                                    (keys: any, values: any) => {
                                        return (
                                            <div
                                                key={values}
                                                className={classes["main-div-input-sec-2"]}
                                            >
                                                <h5>{keys}</h5>
                                                <div className={classes["main-div-input-sec"]}>
                                                    {this.viewDIstributorFields[keys].map(
                                                        (
                                                            fieldValObject: any,
                                                            fieldValIndex: any
                                                        ) => {
                                                            if (
                                                                Object.keys(fieldValObject)
                                                                    .length == 1
                                                            ) {
                                                                {
                                                                    return Object.keys(
                                                                        fieldValObject
                                                                    ).map(
                                                                        (
                                                                            keys1: any,
                                                                            values1: any
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={values1}
                                                                                    className={
                                                                                        classes[
                                                                                            "main-div-input-sec-2-sub"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    <h6>{keys1}</h6>
                                                                                    <div
                                                                                        className={
                                                                                            classes[
                                                                                                "main-div-input-sec"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {fieldValObject[
                                                                                            keys1
                                                                                        ].map(
                                                                                            (
                                                                                                fieldValObject1: any,
                                                                                                fieldValIndex1: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            classes[
                                                                                                                "sub-div"
                                                                                                            ]
                                                                                                        }
                                                                                                        key={
                                                                                                            fieldValIndex1
                                                                                                        }
                                                                                                    >
                                                                                                        <label htmlFor="name">
                                                                                                            {
                                                                                                                fieldValObject1.label
                                                                                                            }
                                                                                                        </label>
                                                                                                        {fieldValObject1.required ? (
                                                                                                            <span
                                                                                                                className={ "text-right "+
                                                                                                                    classes[
                                                                                                                        "mandatory-symbol"
                                                                                                                    ]
                                                                                                                }
                                                                                                            >
                                                                                                                *
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            <>
                                                                                                                <InputBox
                                                                                                                    id={
                                                                                                                        fieldValObject1.id
                                                                                                                    }
                                                                                                                    autoComplete="off"
                                                                                                                    maxLength={
                                                                                                                        fieldValObject1.maxLength
                                                                                                                    }                                                                                                        disabled={
                                                                                                                        fieldValObject1.disabled
                                                                                                                    }
                                                                                                                    className={
                                                                                                                        "form-control " +
                                                                                                                        classes[
                                                                                                                            fieldValObject1
                                                                                                                                .class
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    autoFocus={
                                                                                                                        fieldValObject1.autoFocus
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        fieldValObject1.name
                                                                                                                    }
                                                                                                                    readonly={
                                                                                                                        fieldValObject1.readonly
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .fields[
                                                                                                                            fieldValObject1
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    type={
                                                                                                                        fieldValObject1.type
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        tabIndex++
                                                                                                                    }
                                                                                                                    placeholder={
                                                                                                                        fieldValObject1.placeHolder
                                                                                                                    }
                                                                                                                    required={
                                                                                                                        fieldValObject1.required
                                                                                                                    }
                 
                                                                                                                    options={
                                                                                                                        fieldValObject1.options
                                                                                                                    }
                                                                                                                />
                                                                                                                <div
                                                                                                                    className={
                                                                                                                        classes[
                                                                                                                            "error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .errors[
                                                                                                                            fieldValObject1.name +
                                                                                                                                "Error"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            } else {
                                                                let classNew = ""
                                                                if (
                                                                    fieldValObject.label ==
                                                                    "Terms Agreed"
                                                                ) {
                                                                    classNew = "subdiv-full-width"
                                                                }

                                                                return (
                                                                    <>
                                                                    {!fieldValObject.dnone && 
                                                                    <div
                                                                        className={
                                                                            classes["sub-div"] +
                                                                            " " +
                                                                            classes[classNew]
                                                                        }
                                                                        key={fieldValIndex}
                                                                    >
                                                                        <div>
                                                                            <label
                                                                                htmlFor={
                                                                                    fieldValObject.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    fieldValObject.label
                                                                                }
                                                                            </label>
                                                                            {fieldValObject.required ? (
                                                                                <span
                                                                                    className={ "text-right "+
                                                                                        classes[
                                                                                            "mandatory-symbol"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {fieldValObject.name ==
                                                                                "partnerPassword" && (
                                                                                <div
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltip"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "copyDiv"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    &nbsp;
                                                                                    <i className="fa fa-info-circle"></i>
                                                                                    <span
                                                                                        id="tooltiptext"
                                                                                        className={
                                                                                            classes[
                                                                                                "tooltiptext"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            StaticKey.PASSWORD_CONDITION
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            {fieldValObject.name ==
                                                                                "partnerTransactionPassword" && (
                                                                                <div
                                                                                    className={
                                                                                        classes[
                                                                                            "tooltip"
                                                                                        ] +
                                                                                        " " +
                                                                                        classes[
                                                                                            "copyDiv"
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    &nbsp;
                                                                                    <i className="fa fa-info-circle"></i>
                                                                                    <span
                                                                                        id="tooltiptext"
                                                                                        className={
                                                                                            classes[
                                                                                                "tooltiptext"
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            StaticKey.TXN_PASSWORD_CONDITION
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            {fieldValObject.type != "file" && (
                                                                            <InputBox
                                                                                id={
                                                                                    fieldValObject.name
                                                                                }                        disabled={
                                                                                    fieldValObject.disabled
                                                                                }
                                                                                autoComplete="off"
                                                                                maxLength={
                                                                                    fieldValObject.maxLength
                                                                                }
                                                                                readonly={
                                                                                    fieldValObject.readonly
                                                                                }
                                                                                className={
                                                                                    "form-control " +
                                                                                    classes[
                                                                                        fieldValObject
                                                                                            .class
                                                                                    ] +
                                                                                    " " +
                                                                                    classes[
                                                                                        "upload_input_field"
                                                                                    ]
                                                                                }
                                                                                autoFocus={
                                                                                    fieldValObject.autoFocus
                                                                                }
                                                                                accept={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? ".png, .jpg, .svg, .pdf"
                                                                                        : ""
                                                                                }
                                                                                value={
                                                                                    fieldValObject.type !=
                                                                                    "file"
                                                                                        ? this.state
                                                                                              .fields[
                                                                                              fieldValObject
                                                                                                  .name
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                name={
                                                                                    fieldValObject.name
                                                                                }
                                                                                type={
                                                                                    fieldValObject.type
                                                                                }
                                                                                tabIndex={
                                                                                    tabIndex++
                                                                                }
                                                                                placeholder={
                                                                                    fieldValObject.placeHolder
                                                                                }
                                                                                required={
                                                                                    fieldValObject.required
                                                                                }
                                                                                label1={
                                                                                    fieldValObject.label1
                                                                                }
                                                                                options={
                                                                                    fieldValObject.type ==
                                                                                    "select"
                                                                                        ? fieldValObject.options
                                                                                        : ""
                                                                                }
                                                                                selectShowEmpty={
                                                                                    fieldValObject.selectShowEmpty
                                                                                }
                                                                                showModalCondition={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? fieldValObject.showModalCondition
                                                                                        : ""
                                                                                }
                                                                                showModalCallback={
                                                                                    fieldValObject.name ==
                                                                                    "termsAgreed"
                                                                                        ? this
                                                                                              .handlePopupShow
                                                                                        : ""
                                                                                }
                                                                                /* style={
                                                                                    fieldValObject.type ==
                                                                                    "file"
                                                                                        ? {
                                                                                              display:
                                                                                                  "none"
                                                                                          }
                                                                                        : {}
                                                                                }
  */
                                                                            />
                                                                            )}
                                                                             {fieldValObject.type == "file" && (
                                    <div className="ms-2">
                                        {this.state.fields[fieldValObject.name] != "-" ? 
                                            <a href={this.state.fields[fieldValObject.name+"Val"] ? this.state.fields[fieldValObject.name+"Val"] : "#"} download>{this.state.fields[fieldValObject.name]} </a> 
                                        : <>-</>}
                                        </div>
                                    // onClick={()=>this.handleViewImage(event,this.state.fields[fieldValObject.name+"Val"],this.state.fields[fieldValObject.name],fieldValObject.label)}
                                )}
                                                                        </div>
                                                                    </div>
                                                                    }  </>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </form>
                    </div>
                </div>
                {/* {this.state.alertPopupShow && ( */}
                        <Modal className={"modal-dialog-centered"}
                            isOpen={this.state.alertPopupShow}
                            onClosed={() => {
                                document.body.style.overflow = "unset"
                            }}
                            onExit={() => {
                                document.body.style.overflow = "unset"
                            }}
                            unmountOnClose={true}
                        >
                            <div className={classes["modal-header"]}>
                                <h4 className={classes["modal-title"]}>Change Password</h4>
                                <span
                                    onClick={this.handleAlertPopupClose}
                                    className={classes["login-modal-close"]}
                                    aria-hidden="true"
                                >
                                    &times;
                                </span>
                            </div>
                            <form>
                                <div className="modal-body mb-2">
                                {this.passwordFields.map((obj: any, key: any) => {
                                            
                                            return (
                                                <>
                                    <div className="row  mb-4">
                                        <div className="col-4">
                                            <label htmlFor={obj.name}>{obj.label}</label>  
                                            <span className={classes["mandatory-symbol"]}>
                                                    *
                                                </span>
                                        </div>
                                        <div className="col-8">
                                            <InputBox
                                                className={
                                                    "form-control " +
                                                    classes[
                                                        obj.class
                                                    ]
                                                }
                                                name={obj.name}
                                                type={obj.type}
                                                id={obj.name}
                                                label1={obj.label}
                                                value={this.state[obj.name]}
                                                onChange={() =>
                                                    this.handleOnchange(event)
                                                }
                                                tabIndex={key+1}
                                                autoFocus={obj.autoFocus}
                                            />
                                            <div  className={classes["errorPass"]}>{this.state[obj.name+"Error"]}</div>
                                        </div>
                                    </div>
                                    </>
                                            )})}
                                </div>
                                <div className="modal-footer d-flex">
                                    <InputBox
                                        id={"submitForm"}
                                        autoComplete="off"
                                        className={"btn btn-success w-25"}
                                        name={"submitForm"}
                                        value={"Submit"}
                                        type={"submit"}
                                        tabIndex={3}
                                        onClick={()=>this.handleChangePassword(event)}
                                        required={true}
                                    />
                                    <InputBox
                                        id={"resetForm"}
                                        autoComplete="off"
                                        className={"btn btn-danger w-25"}
                                        name={"resetForm"}
                                        value={"Reset"}
                                        type={"reset"}
                                        tabIndex={4}
                                        onClick={()=>this.handleResetPassword(event)}
                                        required={true}
                                    />
                                </div>
                            </form>
                        </Modal>
                    {/* )} */}
            </>
        )
    }
}
