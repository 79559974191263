import React, {Component} from "react"
import ExcelJs from "exceljs";
const date = new Date()
interface Props {
    mockData?:any  
    sheetName?:any  
}
export class ExportExcelReport extends Component<Props> {
   
mockData:any = "";
componentDidMount(): void {
    this.mockData = this.props.mockData;
    this.exportToExcel(this.mockData)
}
componentDidUpdate(): void {
    if(this.mockData !== this.props.mockData){
        this.mockData = this.props.mockData;
        this.exportToExcel(this.mockData)
    }
}
exportToExcel = (data:any) => {
    const date = new Date()
    const currentDay = String(date.getDate()).padStart(2, "0")
    const currentMonth = String(date.getMonth() + 1).padStart(2, "0")
    const currentYear = date.getFullYear()
    const currentHour = String(date.getHours() + 1).padStart(2, "0")
    const currentMin = String(date.getMinutes() + 1).padStart(2, "0")
    const currentSec = String(date.getSeconds() + 1).padStart(2, "0")
    const sheetDate = `${currentYear}-${currentMonth}-${currentDay}-${currentHour}-${currentMin}-${currentSec}`
  const sheetName = this.props.sheetName+"_"+sheetDate+".xlsx";
  const headerName = "RequestsList";
  const workbook = new ExcelJs.Workbook();
  const sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: false }]
  });
  const columnArr = [];
  for (const i in data[0]) {
      const tempObj = { name: "" };
    tempObj.name = i;
    columnArr.push(tempObj);
  }
//   sheet.addTable({
//     name: `Header`,
//     ref: "A1",
//     headerRow: true,
//     totalsRow: false,
//     style: {
//       showRowStripes: false,
//       showFirstColumn: true,
//     },
//     columns: [{ name: "asd" }],
//     rows: [[]]
//   });
  sheet.addTable({
    name: headerName,
    ref: "A1",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
    },
    columns: columnArr ? columnArr : [{ name: "" }],
    rows: data.map((e:any) => {
      const arr = [];
      for (const i in e) {
        arr.push(e[i]);
      }
      return arr;
    })
  });

//   sheet.getCell("A1").font = { size: 20, bold: true };
  sheet.columns = sheet.columns.map((e:any) => {
    const expr = e.values[5];
    switch (expr) {
      case "Name":
        return { width: 50 };
      case "Gender":
        return { width: 40 };
      case "Height":
        return { width: 30 };
      default:
        return { width: 20 };
    }
  });

  const table:any = sheet.getTable(headerName);
  for (let i = 0; i < table.table.columns.length; i++) {
    // sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
    // sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "c5d9f1" }
    // };

    for (let j = 0; j < table.table.rows.length; j++) {
      const rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 0}`);
      rowCell.alignment = { wrapText: true };
      rowCell.border = {
        bottom: {
          style: "thin",
          color: { argb: "a6a6a6" }
        },
        // right: {
        //   style: "thin",
        //   color: { argb: "a6a6a6" }
        // }
      };
    }
  }
  table.commit();

  const writeFile = (fileName:any, content:any) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;"
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };
  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(sheetName, buffer);
  });
};
render() {
    return(
        <></>
    )
}
}