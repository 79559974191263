import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import { HTTPMockDataService } from "../../http-mock-data-service"
import { StaticKey } from "../../static_key"

export class LimitControlServices extends HTTPConnectionDatasService {
    params: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any = HTTPConnectionDatasService.getInstance()
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID = "default"): any {
        if (!LimitControlServices.classIns[_instanceID]) {
            return (LimitControlServices.classIns[_instanceID] = new LimitControlServices(_instanceID))
        } else {
            return LimitControlServices.classIns[_instanceID]
        }
    }

    getPartnerLimitListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getPartnerLimitControl`
            this.httpConnectionIns.sendHttpRequest("getPartnerLimitControl", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    getRetailerLimitListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getRetailerLimitControl`
            this.httpConnectionIns.sendHttpRequest("getRetailerLimitControl", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getPartnerLimitListSearchSingle = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getPartnerLimitControlDetail`
            this.httpConnectionIns.sendHttpRequest("getPartnerLimitControlDetail", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    getRetailerLimitListSearchSingle = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getRetailerLimitControlDetail`
            this.httpConnectionIns.sendHttpRequest("getRetailerLimitControlDetail", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    partnerSetLimit = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/partnerLimitAdjustment`
            console.log('partnerLimitAdjustment',paramsData);
            
            this.httpConnectionIns.sendHttpRequest("partnerLimitAdjustment", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    
    retailerSetLimit = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/retailerLimitAdjustment`
            console.log('retailerLimitAdjustment',paramsData);
            
            this.httpConnectionIns.sendHttpRequest("retailerLimitAdjustment", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

}
