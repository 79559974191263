import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/limitcontrol/partnerlimitcontrol.module.scss"
import {LimitControlServices} from "../../../game_core/type_script/services/limitcontrol"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"
import {StaticKey} from "../../../game_core/type_script/static_key"
import { Link, Navigate } from "react-router-dom"
import { DataStorage } from "../../../game_core/type_script/services/localstorage"

export class SetPartnerLimit extends Component {
    LimitControlServices = LimitControlServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    partnerLimitLang: any = StaticKey.LOCALE_DATA.limitControl
    localStorageService = new DataStorage
    state: any = {
        goToBack:false,
        LoginpartnerTypeId:"",
        showLimitNotFound:false,
        partnerLimitSearchData: [],
        showLimitAdjust: false,
        changedNetSale: "0.00",
        oldCreditLimit: 0,
        amount: "",
        amountError: "",
        adjustType: "add",
        adjustTypeError: "",
        guId: "",
        partnerTypeId: "",
        partner: ""
    }

    adjustCreditLimitFields = [
        {
            name: "adjustType",
            label: this.partnerLimitLang["adjustType"],
            type: "select",
            options: StaticKey.POINTSTRANSFERTYPE,
            required: true,
            autoComplete: "off",
            defaultCheck: 1,
            checked: ""
        },
        {
            name: "amount",
            id: "amount",
            label: this.partnerLimitLang["amount"],
            type: "number",
            required: true,
            autoComplete: "off",
            maxLength: 10,
            placeHolder: this.partnerLimitLang["amount"],
            autoFocus: true
        }
    ]

    componentDidMount() {
        const url = new URL(window.location.href)
        const id = url.searchParams.get("id")
        const loginData = this.uiData.getLoginResponse()
        this.setState({
            LoginpartnerTypeId: loginData.partnerTypeId
        })
        if (url.searchParams.get("type")) {
            const type = url.searchParams.get("type")
            this.setState({partnerTypeId: StaticKey.PARTNERUSERLIST[3]})
            this.checkPartnerLimit(id, type)
        } else {
            this.setState({partnerTypeId: StaticKey.PARTNERUSERLIST[5]})
            this.checkPartnerLimit(id)
        }
    }

    closeLoader() {
        // setTimeout(() => {
            this.uiData.updateShowLoading(false)
        // }, 500)
    }
    checkPartnerLimit = (id: any, type: any = "") => {
        this.uiData.updateShowLoading(true)
        const searchCondition = {
            "partnerTypeId": 5,
            "partnerGuid": id
        }
        const response = this.LimitControlServices.getPartnerLimitListSearchSingle(searchCondition)
        response
            .then((searchPartnerLimitResponse: any) => {
                if (!searchPartnerLimitResponse.errorStatus) {
                  //console.log(searchPartnerLimitResponse.data)
                    if (searchPartnerLimitResponse.data !== "") {
                      //console.log(searchPartnerLimitResponse.data.CREDIT_LIMIT)
                        this.setState({
                            oldCreditLimit: searchPartnerLimitResponse.data.CREDIT_LIMIT,
                            partner: searchPartnerLimitResponse.data.PARTNER_NAME,
                            partnerLimitSearchData: searchPartnerLimitResponse.data,
                            showLimitAdjust: true,
                            showLimitNotFound: false,
                            guId: id
                        })
                        this.closeLoader()
                    } else {
                        this.setState({
                            partnerLimitSearchData: [],
                            showLimitAdjust: false,
                            showLimitNotFound: true
                        })
                        this.closeLoader()
                    }
                } else {
                    this.setState({
                        partnerLimitSearchData: [],
                        showLimitAdjust: false,
                        showLimitNotFound: true
                    })
                    this.closeLoader()
                }
            })
            .catch((err: any) => {
                this.setState({
                    partnerLimitSearchData: [],
                    showLimitAdjust: false,
                    showLimitNotFound: true
                })
                this.closeLoader()
            })
    }

    updateCreditLimit = (e: any) => {
        e.preventDefault()
        if (this.validateAdjustLimitForm()) {
            if(this.state.adjustType == "add"){
                if(parseInt(this.state.partnerLimitSearchData.parentAvailableLimit) < parseInt(this.state.amount)){
                    this.uiData.showToastMessage("Failed to Transfer", "error")
                    return;
                }
            }else{
                if(parseInt(this.state.partnerLimitSearchData.availableLimit) < parseInt(this.state.amount) ){
                    this.uiData.showToastMessage("Failed to Transfer", "error")
                    return;
                }
            }            
            this.uiData.updateShowLoading(true)
            const customerCreateObject = {
                "limit": this.state.amount,
                "partnerGuid": this.state.guId,
                "action": this.state.adjustType
            }
            const response = this.LimitControlServices.partnerSetLimit(customerCreateObject)
            response
                .then((adjustLimitResponse: any) => {
                    if (!adjustLimitResponse.errorStatus) {
                        this.checkPartnerLimit(this.state.guId)
                        this.uiData.showToastMessage(
                            this.partnerLimitLang["limitAdjustSuccess"],
                            ""
                        )
                        document.getElementById("reset_btn")?.click()
                        this.closeLoader()
                    } else {
                        this.uiData.showToastMessage(
                            this.partnerLimitLang["limitAdjustFailed"],
                            "error"
                        )
                        document.getElementById("reset_btn")?.click()
                        this.closeLoader()
                    }
                })
                .catch((err: any) => {
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                    document.getElementById("reset_btn")?.click()
                    this.closeLoader()
                })
        }
    }

    adjustCreditLimitForm = (e: any) => {
        e.preventDefault()
        let value = e.target.value
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }
        if (e.target.type == "number" && value.length > StaticKey.MAX_AMOUNT_LENGTH) {
            return false
        }
        this.setState(
            {
                [e.target.name]: value,
                [e.target.name + "Error"]: ""
            },
            () => {
                this.setState({
                    changedNetSale:
                        this.state.adjustType == "add"
                            ? (
                                  Number(this.state.amount) + Number(this.state.oldCreditLimit)
                              ).toFixed(2)
                            : (
                                  Number(this.state.oldCreditLimit) - Number(this.state.amount)
                              ).toFixed(2)
                })
            }
        )
    }

    validateAdjustLimitForm = () => {
        let flag = true
        this.adjustCreditLimitFields.map((val: any) => {
            const errorData = this.uiData.textConversion(val.label)
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    this.setState({
                        [val.name + "Error"]: errorData + " " + this.partnerLimitLang["isRequired"]
                    })
                    return
                } else {
                    this.setState({[val.name + "Error"]: ""})
                }
            }
        })
        return flag
    }

    handleCreditLimitReset = () => {
        this.setState({
            "amount": "",
            "amountError": "",
            "adjustType": "add",
            "adjustTypeError": "",
            "changedNetSale": "0.00"
        })
    }

    handleGoBack = (e: any) => {
        e.preventDefault()
        this.setState({
            goToBack: true
        })
        this.uiData.setRetailerBackList(true)
    }
    render() {
        const limitData = this.state.partnerLimitSearchData
        let tabIndex = 1
        //this.uiData.showToastMessage("test","success")
        return (
            this.state.showLimitAdjust ? (
                <div className={classes["right-section"]} style={{textAlign: "left"}}>
                    <div className="error-msg-section"></div>
                    <div className={classes["main-div"]}>
                        <h2>{this.partnerLimitLang["setDistributorLimit"]}</h2>
                        <div className={classes["action-class"]}>
                            <a href="#" onClick={()=>this.handleGoBack(event)} >
                                    <i className="fa fa-arrow-left"></i> Go to List
                            </a>
                            {this.state.goToBack && (
                                <Navigate to="/partner-limit-settings.html" replace={true} />
                            )}
                        </div>
                        <div className="row">
                        {this.state.LoginpartnerTypeId == 0 ? 
                            <>
                            <h6 className={classes["h6-class"]}>
                                <b>{this.partnerLimitLang["parentDetails"]}</b>
                            </h6>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["companyName"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.PARENT_PARTNER_USERNAME}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["availableLimit"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.parentAvailableLimit}</div>
                            </div>
                            <div className="mb-3"></div>
                            </>: <></> }
                            <h6 className={classes["h6-class"]}>
                                <b>{this.partnerLimitLang["distributorDetails"]}</b>
                            </h6>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["distributorName"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.PARTNER_NAME}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["location"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.partnerLocation}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["outstanding"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.OPENING_BALANCE}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["mrp"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.MRP_SALE}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["payout"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.PAYOUT}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["receipt"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.RECEIPT}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["limit"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.CREDIT_LIMIT}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["availableLimit"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.availableLimit}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["netSalePossible"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.NET_SALE_POSSIBLE}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>Collectable</b>
                                </div>
                                <div className={"col-6"}>{limitData.RECEIVABLE}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>Payable</b>
                                </div>
                                <div className={"col-6"}>{limitData.PAYABLE}</div>
                            </div>
                            <div className={"col-sm-6 row"}>
                                <div className={"col-6"}>
                                    <b>{this.partnerLimitLang["closingBalance"]}</b>
                                </div>
                                <div className={"col-6"}>{limitData.CLOSING_BALANCE}</div>
                            </div>
                            <div className="mb-3"></div>
                            <h6 className={classes["h6-class"]}>
                                <b>{this.partnerLimitLang["adjustPoints"]}</b>
                            </h6>
                            <form>
                                {this.adjustCreditLimitFields.map((val: any, ind: any) => {
                                    return (
                                        <div className="row " key={ind}>
                                            <div className="col-sm-3">
                                                <b>
                                                    <label htmlFor={val.id}>{val.label}</label>
                                                </b>
                                                <InputBox
                                                    id={val.id}
                                                    autoComplete={val.autoComplete}
                                                    maxLength={val.maxLength}
                                                    className={"form-control "+classes['form-control'] +" "+ classes[val.class]}
                                                    autoFocus={val.autoFocus}
                                                    name={val.name}
                                                    type={val.type}
                                                    label1={val.label}
                                                    // placeholder={val.placeHolder}
                                                    tabIndex={tabIndex++}
                                                    readonly={val.readonly}
                                                    value={this.state[val.name]}
                                                    onChange={this.adjustCreditLimitForm}
                                                    options={val.options}
                                                    defaultCheck={val.defaultCheck}
                                                    checked={this.state[val.name]}
                                                />
                                                <div className={classes["errorForLimit"]}>
                                                    {this.state[val.name + "Error"]}
                                                </div>
                                            </div>
                                            <div className="mb-3"></div>
                                        </div>
                                    )
                                })}
                                <div className="col-12">
                                    <input
                                        className={"btn btn-warning"}
                                        type="submit"
                                        value={this.partnerLimitLang["submit"]}
                                        onClick={this.updateCreditLimit}
                                        onChange={this.adjustCreditLimitForm}
                                        tabIndex={tabIndex++}
                                    />
                                    {/* <div className="me-1"></div> */}
                                    <input
                                        id={"reset_btn"}
                                        className={"btn btn-danger ms-4"}
                                        type="reset"
                                        value={this.partnerLimitLang["reset"]}
                                        onClick={this.handleCreditLimitReset}
                                        tabIndex={tabIndex++}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="mb-3"></div>
                        <div className="row mt-2">
                            <div className="col-sm-12">
                                <b>{this.partnerLimitLang["netSalePossible"]}: </b>
                                {this.state.changedNetSale}
                            </div>
                        </div>
                    </div>
                </div>
            )
            : <div className="text-center"> 
                {this.state.showLimitNotFound ? 
                <div style={{padding: "17px"}}>
                    <h1>No record Found</h1>
                    <Link  to={"/list-distributor.html"}> Go to List Page</Link>
                </div> 
                : <></> }
            </div>
        )
    }
}
