import React, {Component} from "react"
import classes from "../../configuration/style/base/screens/downlinemanagement/companylisting.module.scss"
interface Props {
    totalCount?: any
}
export class TotalRecords extends Component<Props> {
    state:any={
        totalCount: this.props.totalCount
    }
    componentDidUpdate(){
        if(this.props.totalCount != this.state.totalCount){
            this.setState({
                totalCount:this.props.totalCount
            })
        }
    }
    render(){
        return(
            <>
                <div className="d-flex">
                    <div className={"ms-2 me-2"}>
                        <div className={classes["total_section_box"]}>
                            <span className={classes["total_section_box_sub1"]}>
                                Total Records
                            </span>
                            :
                            <span className={classes["total_section_box_sub2"]}>
                                {this.state.totalCount != ""  ? this.state.totalCount : "0"}
                            </span>
                        </div>
                    </div> 
                </div>
            </>
        )
    }
}