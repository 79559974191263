import {environment} from "../../environment"
import {HTTPConnectionDatasService} from "../../http-connection-data-service"
import { HTTPMockDataService } from "../../http-mock-data-service"
import { StaticKey } from "../../static_key"

export class DashboardService extends HTTPConnectionDatasService {
    params: any = {}
    url = `http${environment.s}://${environment.apiPath}${environment.apiVersion}`
    static classIns: any = {}
    httpConnectionIns: any = HTTPConnectionDatasService.getInstance()
    constructor(_instanceID: string) {
        super(_instanceID)
        if (environment.useMockConnection) {
            this.httpConnectionIns = HTTPMockDataService.getInstance()
        } else {
            this.httpConnectionIns = HTTPConnectionDatasService.getInstance()
        }
    }
    static getInstance(_instanceID = "default"): any {
        if (!DashboardService.classIns[_instanceID]) {
            return (DashboardService.classIns[_instanceID] = new DashboardService(_instanceID))
        } else {
            return DashboardService.classIns[_instanceID]
        }
    }

    getDashboardData = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/getDashboardInfo`
            this.httpConnectionIns.sendHttpRequest("getDashboardInfo", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    createDraw = (paramsData: any) => {
        console.log('paramsDatacreateDraw',paramsData);
        
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/createDraw`
            this.httpConnectionIns.sendHttpRequest("createDraw", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }

    getDrawListSearch = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/searchDraw`
            this.httpConnectionIns.sendHttpRequest("searchDraw", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                    console.log(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    } 
    drawStatusChange = (paramsData: any) => {
        return new Promise((resolve, reject) => {
            const requestURL = `${this.url}/drawStatusChange`
            this.httpConnectionIns.sendHttpRequest("drawStatusChange", requestURL, paramsData).then(
                (respObj: any) => {
                    StaticKey.CURRENT_TIMESTAMP = respObj.timestamp
                    resolve(respObj)
                    console.log(respObj)
                },
                (errorObj: any) => {
                    console.log("errorObj:", errorObj)
                    reject(errorObj)
                }
            )
        })
    }
    

}
