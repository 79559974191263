import React, {Component} from "react"
import classes from "../../../configuration/style/base/global.module.scss"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
interface Props {
    id?: any
    min?: any
    max?: any
    autoComplete?: any
    placeholder?: any
    maxLength?: any
    minLength?: any
    className?: any
    autoFocus?: any
    name?: any
    type?: any
    tabIndex?: any
    onBlur?: any
    value?: any
    onChange?: any
    onClick?: any
    onSubmit?: any
    accept?: any
    required?: any
    options?: any
    label1?: any
    label?: any
    selectShowEmpty?: any
    style?: any
    defaultCheck?: any
    checked?: any
    selected?: any
    onfocus?: any
    data_value?: any
    readonly?: any
    ref?: any
    showModalCondition?: any
    showModalCallback?: any
    onKeyDown?: any
    disabled?: any
    dateFormat?: any
    startMaxDate?: any
    endMinDate?: any
    filterDate?: any
}
export class InputBox extends Component<Props> {
    state:any={
        filterDate:null
    }
    componentDidMount() {
        //
    }
    componentWillUnmount() {
        //
    }
    componentDidUpdate(){
        //    
       
    }
    isWeekday = (date:Date) => {
        const day = date.getDay()
        return day !== parseInt(this.props.filterDate)
    }
    render() {
        if (this.props.type == "radio") {
            return (
                <div className={classes["input-radio-main"]}>
                    <div className={classes["input-radio-box"]}>
                        <input
                            type="radio"
                            value={1}
                            name={this.props.name}
                            id={this.props.options[1] + this.props.name}
                            className="form-check-input"
                            required={this.props.required}
                            onChange={this.props.onChange}
                            tabIndex={this.props.tabIndex}
                            checked={this.props.checked && this.props.checked == 1 ? true : false}
                        />
                        <label
                            htmlFor={this.props.options[1] + this.props.name}
                            className={"form-check-label " + classes["cursor-pointer-checkbox"]}
                        >
                            {this.props.options[1]}
                        </label>
                    </div>

                    <div className={classes["input-radio-box"]}>
                        <input
                            type="radio"
                            value={2}
                            name={this.props.name}
                            id={this.props.options[2] + this.props.name}
                            className="form-check-input"
                            required={this.props.required}
                            onChange={this.props.onChange}
                            tabIndex={this.props.tabIndex}
                            checked={this.props.checked && this.props.checked == 2 ? true : false}
                        />
                        <label
                            htmlFor={this.props.options[2] + this.props.name}
                            className={"form-check-label " + classes["cursor-pointer-checkbox"]}
                        >
                            {this.props.options[2]}
                        </label>
                    </div>
                </div>
            )
        } else if (this.props.type == "checkbox") {
            let breakTag = ""
            if (this.props.name == "draw_is_demo") {
                breakTag = "br"
            }
            let DataValue = " Retailer"
            if (this.props.label1.includes("Distributor")) {
                DataValue = " Distributor"
            }
            return (
                <>
                    {breakTag == "br" ? <br /> : ""}
                    <div className={classes["input-radio-main"]}>
                        <div className={classes["input-radio-box"]}>
                            <input
                                type="checkbox"
                                id={this.props.id}
                                name={this.props.name}
                                value={this.props.value}
                                className={
                                    "form-check-input position-absolute " +
                                    classes["cursor-pointer-checkbox"]
                                } //+
                                required={this.props.required}
                                checked={this.props.checked}
                                onChange={this.props.onChange}
                                tabIndex={this.props.tabIndex}
                            />
                            {this.props.showModalCondition ? (
                                <label
                                    htmlFor={this.props.name}
                                    className={"form-check-label w-100 ms-3 "}
                                >
                                    &nbsp;I willingly accept all the{" "}
                                    <b>
                                        <a
                                            href="#"
                                            style={{color: "#53398b"}}
                                            onClick={() => this.props.showModalCallback(event)}
                                        >
                                            terms and condition
                                        </a>
                                    </b>
                                    &nbsp;laid down by SmartLotto to assign me as an authorised
                                    {DataValue}
                                </label>
                            ) : (
                                <label
                                    htmlFor={this.props.name}
                                    className={"form-check-label w-100 ms-3  "  + classes["cursor-pointer-checkbox"]}
                                >
                                    &nbsp;{this.props.label1}
                                </label>
                            )}
                        </div>
                    </div>
                </>
            )
        } else if (this.props.type == "select") {
            return (
                <>
                    <select
                        className={"form-select " + this.props.className}
                        id={this.props.id}
                        name={this.props.name}
                        required={this.props.required}
                        onChange={this.props.onChange}
                        tabIndex={this.props.tabIndex}
                        autoFocus={this.props.autoFocus}
                        onFocus={this.props.onfocus}
                        data-value={this.props.data_value}
                        defaultValue={this.props.value}
                        style={this.props.style}
                    >
                        {this.props.selectShowEmpty  && <option value="">Select</option>}
                        {Object.entries(this.props.options).map((val: any, ind: any) => {
                            let selectedData = false
                            if (this.props.value == this.props.options[ind].id) {
                                selectedData = true
                            }
                            return (
                                <option
                                    value={this.props.options[ind].id}
                                    key={val}
                                    selected={selectedData}
                                >
                                    {this.props.options[ind].name}
                                </option>
                            )
                        })}
                    </select>
                </>
            )
        } else if (this.props.type == "date" || this.props.type == "datetime" ) {  // || this.props.type == "time"
            let val: any = null
            let flagDate: any = ""
            let minMax = ""
            if (this.props.name == "fromDate" || this.props.name == "startDate") {
                flagDate = this.props.startMaxDate
                minMax = "max"
            } else if (this.props.name == "toDate" || this.props.name == "endDate") {
                flagDate = this.props.endMinDate
                minMax = "min"
            }
            if (this.props.value) {
                val = this.props.value
            } else {
                val = null
            }
            let showTimeSelect = false;
            let showTimeSelectOnly = false;
            let dateFormat = this.props.dateFormat;
            if(this.props.type == "time"){
                showTimeSelect = true;
                showTimeSelectOnly = true;
                dateFormat = "Pp"
            }
            const dynamicProps: any = []
            if (flagDate && minMax) {
                dynamicProps[minMax + "Date"] = flagDate
            }
            let showTimeInput = true;
            if(this.props.type == "date"){
                showTimeInput =false;
            }
            return (
                <React.Fragment>
                    <DatePicker
                        selected={val}
                        timeInputLabel="Time:"
                        name={this.props.name}
                        id={this.props.id}
                        className={this.props.className}
                        onChange={this.props.onChange}
                        tabIndex={this.props.tabIndex}
                        dateFormat={dateFormat}
                        required={this.props.required}
                        showTimeInput={showTimeInput}
                        showTimeSelectOnly={showTimeSelectOnly}
                        excludeDates={showTimeSelectOnly}
                        // showTimeSelect={showTimeSelect}
                        wrapperClassName={"sub-div " + classes["date-class"]}
                        {...dynamicProps}
                        autoComplete="off"
                        showYearDropdown
                        showMonthDropdown
                        filterDate={this.isWeekday}
                    />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <input
                        id={this.props.id}
                        autoComplete={this.props.autoComplete}
                        placeholder={this.props.placeholder ? this.props.placeholder : ""}
                        min={this.props.min}
                        max={this.props.max}
                        maxLength={this.props.maxLength}
                        minLength={this.props.minLength}
                        className={this.props.className}
                        autoFocus={this.props.autoFocus}
                        name={this.props.name}
                        type={this.props.type}
                        tabIndex={this.props.tabIndex}
                        onBlur={this.props.onBlur || void ""}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        onClick={this.props.onClick}
                        onSubmit={this.props.onSubmit}
                        accept={this.props.accept}
                        required={this.props.required}
                        style={this.props.style}
                        readOnly={this.props.readonly}
                        ref={this.props.ref}
                        onKeyDown={this.props.onKeyDown}
                        disabled={this.props.disabled}
                    />
                </React.Fragment>
            )
        }
    }
}
