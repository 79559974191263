import React, {Component} from "react"
import classes from "../../configuration/style/base/global.module.scss"
import {StaticKey} from "../../../game_core/type_script/static_key"
import {DownlineManageServices} from "../../../game_core/type_script/services/downlinemanagement"
import {UIDataService} from "../../../game_core/type_script/ui-data-service"
import {InputBox} from "../common/elements/inputbox"

export class CreateCompany extends Component {
    DownlineManageServices = DownlineManageServices.getInstance()
    uiData: UIDataService = UIDataService.getInstance()
    state: any = {
        username: "",
        companyName: "",
        password: "",
        confirmPassword: "",
        transactionPassword: "",
        points: "",
        usernameError: "",
        companyNameError: "",
        passwordError: "",
        confirmPasswordError: "",
        transactionPasswordError: "",
        pointsError: "",
        createCompanyFields: [
            {
                name: "companyName",
                id: "companyName",
                type: "text",
                label: StaticKey.LOCALE_DATA.Home.companyName,
                placeHolder: StaticKey.LOCALE_DATA.Home.companyName,
                maxLength: "50",
                autoFocus: true,
                class: "form-control",
                required: true,
                style: {},
                autoComplete: "off"
            },
            {
                name: "username",
                id: "username",
                type: "text",
                label: "Company Code",
                placeHolder: StaticKey.LOCALE_DATA.Home.username,
                maxLength: "50",
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                autoComplete: "off",
                onblur: "checkPartnerExist"
            },
            {
                name: "password",
                id: "password",
                type: "password",
                label: StaticKey.LOCALE_DATA.Home.password,
                placeHolder: StaticKey.LOCALE_DATA.Home.password,
                maxLength: "15",
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                passwordCondition: true,
                autoComplete: "off"
            },
            // {
            //     name: "confirmPassword",
            //     type: "password",
            //     label: StaticKey.LOCALE_DATA.Home.confirmPassword,
            //     placeHolder: StaticKey.LOCALE_DATA.Home.confirmPassword,
            //     maxLength: "15",
            //     autoFocus: false,
            //     class: "form-control",
            //     required: true,
            //     style: {},
            //     passwordCondition: true,
            //     autoComplete: "off",
            //     onblur: "confirmPasswordCheck"
            // },
            {
                name: "transactionPassword",
                id: "transactionPassword",
                type: "password",
                label: StaticKey.LOCALE_DATA.Home.transactionPassword,
                placeHolder: StaticKey.LOCALE_DATA.Home.transactionPassword,
                maxLength: "6",
                autoFocus: false,
                class: "form-control",
                required: true,
                style: {},
                passwordCondition: true,
                autoComplete: "off"
            },
            {
                name: "points",
                id: "points",
                type: "number",
                label: StaticKey.LOCALE_DATA.Home.points,
                placeHolder: StaticKey.LOCALE_DATA.Home.points,
                // maxLength: "6",
                autoFocus: false,
                class: "form-control",
                required: false,
                style: {},
                autoComplete: "off"
            }
        ]
    }

    componentDidMount() {
        //
    }

    validateCreateCompanyForm = () => {
        let flag = true
        const errorMessage = StaticKey.LOCALE_DATA.Home
        this.state.createCompanyFields.map((val: any) => {
            if (val.required) {
                if (this.state[val.name] == "") {
                    flag = false
                    const errorData = this.uiData.textConversion(errorMessage[val.name])
                    this.setState({
                        [val.name + "Error"]:
                            errorData + " " + StaticKey.LOCALE_DATA.Home.isRequired
                    })
                    return
                } else {
                    if (val.passwordCondition) {
                        let pattern: any
                        let error: any
                        if (val.name == "password" || val.name == "confirmPassword") {
                            pattern = new RegExp(StaticKey.PASSWORD_REG_EXP)
                            error = StaticKey.PASSWORD_CONDITION
                        } else if (val.name == "transactionPassword") {
                            pattern = new RegExp(StaticKey.TXN_PASSWORD_REG_EXP)
                            error = StaticKey.TXN_PASSWORD_CONDITION
                        }
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: error
                            })
                            return
                        }
                    } else if (val.name == "username") {
                        const pattern = new RegExp(StaticKey.USERNAME_REG_EXP)
                        const error = StaticKey.USERNAME_REG_EXP_ERROR
                        if (!pattern.test(this.state[val.name])) {
                            flag = false
                            this.setState({
                                [val.name + "Error"]: error
                            })
                            return
                        }
                    } else {
                        this.setState({[val.name + "Error"]: ""})
                    }
                }
            }
        })
        return flag
    }

    handleCreateCompanySubmit = (e: any) => {
        e.preventDefault()
        if (this.validateCreateCompanyForm()) {
            this.uiData.updateShowLoading(true)
            const customerCreateObject = {
                "username": this.state.username,
                "companyName": this.state.companyName,
                "password": this.state.password,
                // "confirmPassword": this.state.confirmPassword,
                "transactionPassword": this.state.transactionPassword,
                "points": this.state.points
            }
            const response = this.DownlineManageServices.createCompany(customerCreateObject)
            response
                .then((createCompanyResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (!createCompanyResponse.errorStatus) {
                        this.uiData.showToastMessage(
                            StaticKey.LOCALE_DATA.Home.companyCreationSuccess,
                            ""
                        )
                        this.handleCreateCompanyReset()
                    } else {
                        this.uiData.showToastMessage(
                            StaticKey.LOCALE_DATA.Home.companyCreationFailed,
                            "error"
                        )
                        // this.handleCreateCompanyReset()
                    }
                })
                .catch((err: any) => {
                    //console.log("handleCreateCompanySubmitErr", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                    // this.handleCreateCompanyReset()
                })
        }
    }
    checkPasswordConfirm = () => {
        if (this.state.confirmPassword != "" && this.state.confirmPassword != this.state.password) {
            this.uiData.showToastMessage(
                StaticKey.LOCALE_DATA.Home.passwordAndConfirmPasswordShouldBeSame,
                "error"
            )
            this.setState({
                "confirmPassword": ""
            })
        }
    }
    checkPartnerExist = () => {
        if (this.state.username != "") {
            this.uiData.updateShowLoading(true)
            const customerCreateObject = {
                "username": this.state.username
            }
            const response = this.DownlineManageServices.checkPartnerExist(customerCreateObject)
            response
                .then((checkPartnerResponse: any) => {
                    this.uiData.updateShowLoading(false)
                    if (checkPartnerResponse.errorStatus) {
                        // this.uiData.showToastMessage(
                        //     StaticKey.LOCALE_DATA.Home.usernameAlreadyExist,
                        //     "error"
                        // )
                        this.setState({
                            "username": ""
                        })
                        this.setState({
                            ...this.state,
                            "username": "", 
                            "usernameError":"Company Code Already Exists",
                        })
                        document.getElementById("username")?.focus();
                    }
                })
                .catch((err: any) => {
                    //console.log("checkPartnerExistErr", err)
                    this.uiData.updateShowLoading(false)
                    this.uiData.showToastMessage(StaticKey.LOCALE_DATA.ERRORS[300001], "error")
                })
        }
    }

    handleCreateCompanyReset = () => {
        this.setState({
            "username": "",
            "companyName": "",
            "points": "",
            "usernameError": "",
            "companyNameError": "",
            "pointsError": "",
            "password": "",
            "passwordError": "",
            "confirmPassword": "",
            "confirmPasswordError": "",
            "transactionPassword": "",
            "transactionPasswordError": ""
        })
    }

    handleCreateCompanyInputChange = (e: any) => {
        const value = e.target.value
        this.setState({
            ...this.state,
            [e.target.name]: value,
            [e.target.name + "Error"]: ""
        })
    }

    render() {
        return (
            <>
                <div className={classes["right-section"]}>
                    <div className="error-msg-section"></div>

                    <div className={classes["main-div"]}>
                        <h2>{StaticKey.LOCALE_DATA.Home.createCompany}</h2>
                        <form>
                            <div className={classes["main-div-input-sec"]}>
                                {this.state.createCompanyFields.map((val: any, ind: any) => {
                                    return (
                                        <div className={classes["sub-div"]} key={ind}>
                                            <label htmlFor="name">{val.label}</label>
                                            {val.required ? (
                                                <span className={classes["mandatory-symbol"]}>
                                                    *
                                                </span>
                                            ) : (
                                                ""
                                            )}{" "}
                                            &nbsp;
                                            {val.name == "password" && (
                                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                    <i className="fa fa-info-circle"></i>
                                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.PASSWORD_CONDITION}</span>
                                                </div>   
                                                // <i
                                                //     data-html="true"
                                                //     className="fa fa-info-circle"
                                                //     data-toggle="tooltip"
                                                //     title={StaticKey.PASSWORD_CONDITION}
                                                //     aria-hidden="true"
                                                //     data-original-title=""
                                                // ></i>
                                            )}
                                            {val.name == "confirmPassword" && (
                                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                    <i className="fa fa-info-circle"></i>
                                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.PASSWORD_CONDITION}</span>
                                                </div> 
                                                // <i
                                                //     data-html="true"
                                                //     className="fa fa-info-circle"
                                                //     data-toggle="tooltip"
                                                //     title={StaticKey.PASSWORD_CONDITION}
                                                //     aria-hidden="true"
                                                //     data-original-title=""
                                                // ></i>
                                            )}
                                            {val.name == "transactionPassword" && (
                                                <div  className={classes["tooltip"] +" "+ classes['copyDiv']}>
                                                    <i className="fa fa-info-circle"></i>
                                                    <span id="tooltiptext" className={classes["tooltiptext"]}>{StaticKey.TXN_PASSWORD_CONDITION}</span>
                                                </div> 
                                                // <i
                                                //     data-html="true"
                                                //     className="fa fa-info-circle"
                                                //     data-toggle="tooltip"
                                                //     title={StaticKey.TXN_PASSWORD_CONDITION}
                                                //     aria-hidden="true"
                                                //     data-original-title=""
                                                // ></i>
                                            )}
                                            <InputBox
                                                id={val.id}
                                                autoComplete={val.autoComplete}
                                                maxLength={val.maxLength}
                                                className={"form-control " + classes[val.class]}
                                                autoFocus={val.autoFocus}
                                                //placeholder={val.placeHolder}
                                                name={val.name}
                                                type={val.type}
                                                tabIndex={1}
                                                onBlur={
                                                    val.name == "username"
                                                        ? this.checkPartnerExist
                                                        : val.name == "confirmPassword"
                                                        ? this.checkPasswordConfirm
                                                        : void ""
                                                }
                                                value={this.state[val.name]}
                                                onChange={this.handleCreateCompanyInputChange}
                                            />
                                            <div className={classes["error"]}>
                                                {this.state[val.name + "Error"]}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={classes["submit-clear-section"]}>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="submit_form"
                                        className="form-control"
                                        type="submit"
                                        value={StaticKey.LOCALE_DATA.Home.submit}
                                        onClick={() => this.handleCreateCompanySubmit(event)}
                                    />
                                </div>
                                <div className={classes["sub-div"]}>
                                    <InputBox
                                        id="reset_form"
                                        className="form-control"
                                        type="reset"
                                        value={StaticKey.LOCALE_DATA.Home.reset}
                                        onClick={() => this.handleCreateCompanyReset()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
